import { css } from "@emotion/css";
import { merge } from "lodash";
import { X } from "phosphor-react";
import { CustomAxios } from "../redux/axios/axios";
import { Button, Div, Modal, Text } from "../shared/components";
import { flex } from "../shared/shared-styles";
import { productModifier } from "./configs/product-utils";
import { Input } from "../shared/components/input";
import { useEffect, useState } from "react";

export const ProductDevSettings = ({ state, ...modalProps }) => {
  const { product, layout } = state;

  const [layoutDetails, setLayoutDetails] = useState({
    Title: layout?.Title,
    Subtitle: layout?.Subtitle,
    Description: layout?.Description,
  });

  useEffect(() => {
    setLayoutDetails({
      Title: layout?.Title,
      Subtitle: layout?.Subtitle,
      Description: layout?.Description,
    });
  }, [layout, modalProps.display]);

  const saveLayoutDetails = () => {
    productModifier.setLayout(state, {
      ...layout,
      Title: layoutDetails.Title,
      Subtitle: layoutDetails.Subtitle,
      Description: layoutDetails.Description,
    }); 
    modalProps.onClose();
  }

  const mergeDefaultLayout = async () => {
    try {
      const response = await CustomAxios.get(
        `v1/product-layout/${product?.Type}`
      );
      const layoutSections = merge(
        response?.data?.Layout?.Sections,
        layout?.Layout?.Sections
      );
      productModifier.setLayout(state, {
        ...layout,
        Layout: {
          ...layout?.Layout,
          Sections: layoutSections,
        },
      });
    } catch (err) {
      console.warn(err);
    }

    modalProps.onClose();
  };

  const resetDefaultLayout = async () => {
    try {
      const response = await CustomAxios.get(
        `v1/product-layout/${product?.Type}`
      );
      productModifier.setLayout(state, response?.data);
    } catch (err) {
      console.warn(err);
    }

    modalProps.onClose();
  };

  return (
    <Modal {...modalProps}>
      <Div
        css={css`
          width: 500px;
        `}
      >
        <Div
          css={css`
            ${flex("space-between")}
            padding: 32px;
          `}
        >
          <Text h1>Dev Settings</Text>
          <Button styles="icon" onClick={modalProps.onClose}>
            <X />
          </Button>
        </Div>
        <Div
          css={css`
            padding: 32px;
            padding-top: 0;
            > div {
              padding: 8px 0;
            }
          `}
        >
          <Div
            css={css`
              ${flex("space-between")}
            `}
          >
            <Text label>Reset to Default Layout Sections</Text>
            <Button onClick={resetDefaultLayout}>Reset</Button>
          </Div>
          <Div
            css={css`
              ${flex("space-between")}
            `}
          >
            <Text label>Merge Default Layout Sections</Text>
            <Button onClick={mergeDefaultLayout}>Merge</Button>
          </Div>

          <Div
            css={css`
              ${flex("space-between")}
            `}
          >
            <Text label>Log Current Layout</Text>
            <Button onClick={() => console.log(layout)}>Log</Button>
          </Div>
          
          <Div>
            <h2>Layout Details</h2>
            <Div css={css`padding: 8px 0;`}>
              <Text label>Title</Text>
              <Input 
                value={layoutDetails.Title} 
                onChange={(e) => setLayoutDetails({...layoutDetails, Title: e.target.value})}>
              </Input>
            </Div>
            <Div css={css`padding: 8px 0;`}>
              <Text label>Subtitle</Text>
              <Input 
                value={layoutDetails.Subtitle}
                onChange={(e) => setLayoutDetails({...layoutDetails, Subtitle: e.target.value})}>
              </Input>
            </Div>
            <Div css={css`padding: 8px 0;`}>
              <Text label>Description</Text>
              <Input 
                // css={css`height: 100px;`}
                rows={4}
                value={layoutDetails.Description}
                onChange={(e) => setLayoutDetails({...layoutDetails, Description: e.target.value})}>
              </Input>
            </Div>
            <Div
              css={css`
                ${flex("space-between")}
            `}>
              <Button onClick={saveLayoutDetails}>Set Details</Button>
            </Div>
          </Div>
        </Div>
      </Div>
    </Modal>
  );
};
