import { css } from "@emotion/css";
import { Info, Package } from "phosphor-react";
import { useState } from "react";
import { useParams } from "react-router";
import { BenefitPackageModal } from "../../../benefit-package/benefit-package-modal";
import { SelectBenefitPackage } from "../../../benefit-package/generate-guide/select-benefit-package";
import { history } from "../../../history";
import { useQueryAPI } from "../../../react-query";
import { Button, Div, Modal, Text } from "../../../shared/components";
import { flex, shadows } from "../../../shared/shared-styles";
import { colors } from "../../../shared/styles";
import { useOutsideClick } from "../../../shared/use-outside-click";
import { useStateSync } from "../../../shared/use-state-sync";

export const SyncBenefitPackage = ({
  course,
  page,
  saveCourse,
  savePage,
  ...modalProps
}) => {
  const { businessId } = useParams();
  const [info, setInfo] = useState(false);
  const outsideClick = useOutsideClick(() => setInfo(false));

  const [packageId, setPackageId, isPackageIdSynced] = useStateSync(
    course?.BenefitsPackageID,
    [course?.BenefitsPackageID]
  );

  const { data: selectedPackage } = useQueryAPI({
    url: `v1/benefitspackage/${course?.BenefitsPackageID}`,
    enabled: !!course?.BenefitsPackageID,
    defaultValue: [],
  });

  return (
    <Modal full {...modalProps}>
      <Div
        css={css`
          width: 100vw;
          height: 100vh;
          overflow: auto;
        `}
      >
        <Div
          css={css`
            ${flex("space-between")}
            width: 100vw;
            height: 100px;
            padding: 32px;
            border-bottom: 1px solid ${colors.gray[300]};
          `}
        >
          <Text h1>Sync A Benefit Package</Text>
          <Div
            css={css`
              ${flex("right")}
            `}
          >
            <Div
              css={css`
                position: relative;
              `}
            >
              <Button
                ref={outsideClick}
                styles="icon"
                onClick={() => setInfo(!info)}
                css={`
                  color: ${info ? colors.purple : colors.gray[400]};
                  margin-right: 16px;
                  :hover {
                    background: none;
                    svg {
                      color: ${info ? colors.purple : colors.gray[500]};
                    }
                  }
                `}
              >
                <Info />
              </Button>
              {info ? (
                <Div
                  css={css`
                    position: absolute;
                    top: calc(100% + 32px);
                    left: -200px;
                    width: 400px;
                    border: 2px solid ${colors.purple};
                    border-radius: 16px;
                    background-color: white;
                    padding: 32px;
                    z-index: 1000;
                    ${shadows.md}
                  `}
                >
                  <Text h4>Why Should I Sync a Benefit Package?</Text>
                  <Text
                    css={`
                      margin-top: 8px;
                    `}
                  >
                    When you sync a benefit package to a guide you can easily
                    access any information from that benefit package, and the
                    information will always be up to date.{" "}
                  </Text>
                </Div>
              ) : null}
            </Div>
            <Button
              secondary
              css={`
                margin-right: 16px;
              `}
              onClick={modalProps.onClose}
            >
              Cancel
            </Button>
            <Button
              disabled={isPackageIdSynced}
              onClick={() => saveCourse({ BenefitsPackageID: packageId })}
            >
              Save
            </Button>
          </Div>
        </Div>
        <Div
          css={css`
            position: relative;
            width: 900px;
            padding-top: 80px;
            margin: auto;
            margin-top: 0;
            ${flex("center start")}
          `}
        >
          {packageId === course?.BenefitsPackageID &&
          !!course?.BenefitsPackageID &&
          course?.BenefitsPackageID !==
            "00000000-0000-0000-0000-000000000000" ? (
            <Div
              css={css`
                width: 900px;
                border-bottom: 1px solid ${colors.gray[300]};
              `}
            >
              <Text h2>
                The following benefit package is connected to this guide
              </Text>
              <Div
                css={css`
                  border-radius: 8px;
                  border: 1px solid ${colors.gray[300]};
                  margin-top: 32px;
                  ${flex("left")}
                `}
              >
                <Div
                  css={css`
                    border-radius: 8px;
                    background-color: ${colors.black};
                    color: white;
                    ${flex("center")}
                    padding: 12px;
                    margin: 16px;
                    svg {
                      min-width: 32px;
                    }
                  `}
                >
                  <Package color="#FFFFFF" />
                </Div>
                <Text
                  h4
                  ellipsis
                  css={`
                    flex-grow: 1;
                  `}
                >
                  {selectedPackage?.Name}
                </Text>
                <Text
                  css={`
                    span {
                      padding: 8px 24px;
                      border-radius: 8px;
                      margin-right: 16px;
                    }
                    .connect {
                      color: ${colors.green[200]};
                      background-color: ${colors.green[300]};
                    }
                    .disconnect {
                      cursor: pointer;
                      color: ${colors.black};
                      background-color: ${colors.red[200]};
                    }
                    :hover {
                      .connect {
                        display: none;
                      }
                    }
                    :not(:hover) {
                      .disconnect {
                        display: none;
                      }
                    }
                  `}
                  bold
                  onClick={() => {
                    setPackageId(null);
                    saveCourse({ BenefitsPackageID: null });
                  }}
                >
                  <span className="connect">Connected</span>
                  <span className="disconnect">Disconnect</span>
                </Text>
                <Button
                  secondary
                  css={`
                    margin-right: 16px;
                  `}
                  onClick={() =>
                    history?.push(
                      `/${businessId}/packages/${selectedPackage?.ID}`
                    )
                  }
                >
                  Edit
                </Button>
              </Div>
              {!!selectedPackage?.ID ? (
                <BenefitPackageModal pkg={selectedPackage} />
              ) : null}
            </Div>
          ) : (
            <SelectBenefitPackage
              packageId={packageId}
              setPackageId={setPackageId}
            />
          )}
        </Div>
      </Div>
    </Modal>
  );
};
