import { css } from "@emotion/css";
import { flex } from "../../../shared/shared-styles";
import { Plus } from "phosphor-react";
import { useEditorResource } from "../../use-editor-resource";
import { Editable, Slate } from "slate-react";
import { slateContainer } from "../text-v2/text-v2";
import { Collapse } from "@material-ui/core";
import { editorUtils } from "../../provider/utils";
import { useTitleSlate } from "./useTitleSlate";
import { useBodySlate } from "./useBodySlate";
import { useEffect } from "react";
import { useStateSync } from "../../../shared/use-state-sync";

const rotateForward = `
  @keyframes rotateFoward {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(45deg);
    }
  }
`;

const rotateBackward = `
  @keyframes rotateBackward {
    from {
      transform: rotate(45deg);
    }
    to {
      transform: rotate(0);
    }
  }
`;

const accordionStyle = ({ isSelected, styles, expanded }) => css`
  padding: 16px;
  margin: 8px;
  width: 100%;
  border-radius: 8px;
  border: 1px solid var(--accent-color-4);
  .title {
    ${flex("left")}
    .svg-container {
      ${flex("center")}
      border-radius: 50%;
      background-color: var(--button-background-color);
      margin-right: 16px;
      min-width: 24px;
      min-height: 24px;
      svg {
        width: 16px;
        height: 16px;
        ${isSelected
          ? `
          ${rotateBackward}
          ${rotateForward}
        ${
          expanded
            ? `animation: rotateFoward .4s forwards;`
            : `animation: rotateBackward .4s forwards;`
        }
        `
          : ""}
      }
    }
  }

  .content {
    margin-left: 40px;
    margin-top: 8px;
  }
`;

export const Accordion = (props) => {
  const { editor, location } = props;

  const isSelected = editorUtils?.isLocationAtSelection(editor, location);

  const { data: liveSmartFields } = useEditorResource("liveSmartFields");
  const { data: styles } = useEditorResource("styles");
  const [expanded, setExpanded] = useStateSync(isSelected, [isSelected]);

  const resources = {
    styles,
    liveSmartFields,
  };
  const title = useTitleSlate(props, resources);
  const body = useBodySlate(props, resources);

  useEffect(() => {
    if (!isSelected) {
      title?.data?.syncState();
      body?.data?.syncState();
    }
  }, [editor?.state?.syncRenderState]);

  return (
    <div className={accordionStyle({ isSelected, styles, expanded })}>
      <div className="title">
        <div
          className="svg-container"
          onClick={(e) => {
            e.stopPropagation();
            setExpanded(!expanded);
          }}
        >
          <Plus color="var(--button-text-color)" weight="bold" />
        </div>
        <div
          className={css`
            width: 100%;
          `}
        >
          <Slate {...title?.slateProps}>
            <Editable
              {...title?.editorProps}
              readOnly={!isSelected}
              placeholder="Subject"
              className={slateContainer}
            />
          </Slate>
        </div>
      </div>
      <Collapse in={expanded}>
        <div className="content">
          <Slate {...body?.slateProps}>
            <Editable
              {...body?.editorProps}
              className={slateContainer}
              readOnly={!isSelected}
              placeholder="Description"
            />
          </Slate>
        </div>
      </Collapse>
    </div>
  );
};
