import React, { useEffect, useState } from "react";
import AddIcon from "@material-ui/icons/Add";
import TextField from "@material-ui/core/TextField";
import { css } from "@emotion/css";
import CircularProgress from "@material-ui/core/CircularProgress";
import { AddCompany } from "./AddCompany";
import { useStore } from "../store-provider/use-store";
import { useQueryAPI } from "../react-query";
import { useBusinessAuth } from "../Dashboard/use-business-auth";
import { history } from "../history";
import { Button, Text } from "../shared/components";
import { Trash } from "phosphor-react";
import { container, flex } from "../shared/shared-styles";
import { Box } from "../shared/components/box";
import { Modal } from "../shared/components/modal";
import { deleteBusinessRelationship } from "../redux/replace-redux-actions";

export const CompaniesContainer = (props) => {
  const { setBusiness } = useBusinessAuth();
  const {
    data: { parentBusiness, selectedBusiness, user },
  } = useStore();
  const businessName = parentBusiness.Name || "";

  const [modalOpen, setModalOpen] = useState(false);
  const [confirmDeleteCompany, setConfirmDeleteCompany] = useState(null);
  const [filter, setFilter] = useState("");

  const {
    data: userCompanies,
    refetch,
    isLoading,
  } = useQueryAPI({
    url: `/v2/business/${parentBusiness.ID}/children`,
    defaultValue: [],
    select: (data) => data.sort((a, b) => a.Name.localeCompare(b.Name)),
    enabled: !!parentBusiness.ID,
    staleTime: 1000 * 60 * 60,
  });

  useEffect(() => {
    if (!props.loading && !isLoading) {
      refetch();
    }
  }, [props.loading]);

  const handleAddCleanup = () => {
    setModalOpen(!modalOpen);
    refetch();
  };

  const handleDeleteCompany = async () => {
    await deleteBusinessRelationship(confirmDeleteCompany.ID, {
      businessId: selectedBusiness.ParentID,
      userId: user?.ID,
    });
    setConfirmDeleteCompany(null);
    refetch();
  };

  return (
    <Box page>
      <div
        className={css`
          display: flex;
          justify-content: space-between;
          align-items: center;
        `}
      >
        <div
          className={css`
            font-weight: bold;
            font-size: 34px;
            line-height: 42px;
            color: #25282d;
            margin-right: 12px;
            margin-left: 8px;
          `}
        >
          Companies{" "}
          <span
            className={css`
              font-size: 14px;
              line-height: 16px;
              font-weight: bold;
              margin-right: 12px;
            `}
          >
            {" "}
            Managed by {businessName}
          </span>
        </div>
        <div
          className={css`
            margin: 5px;
            margin-right: 24px;
          `}
        >
          <Button onClick={() => setModalOpen(true)} startIcon={<AddIcon />}>
            Add Company
          </Button>
        </div>
      </div>

      <div
        className={css`
          padding: 12px;
          margin-top: 12px;
          margin-right: 40px;
        `}
      >
        <TextField
          fullWidth
          variant="outlined"
          label="Search"
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
        />
      </div>

      <Box
        className={css`
          margin-top: 10px;
          margin-right: 40px;
        `}
      >
        {(props.loading || isLoading) && (
          <div
            className={css`
              display: flex;
              justify-content: center;
              margin-top: 80px;
            `}
          >
            <CircularProgress />
          </div>
        )}
        {userCompanies.length === 0 && (
          <div
            className={css`
              margin: 20px auto;
            `}
          >
            <div>No companies added yet.</div>
          </div>
        )}

        {!props.loading && !isLoading && (
          <React.Fragment>
            {userCompanies
              .filter((company) =>
                company.Name.toLowerCase().includes(filter.trim().toLowerCase())
              )
              .map((company) => (
                <div
                  key={company.ID}
                  className={css`
                    ${container.box}
                    padding: 24px;
                    margin: 12px;
                    :not(:hover) {
                      .delete {
                        display: none;
                      }
                    }
                  `}
                >
                  <div
                    className={css`
                      width: 100%;
                    `}
                  >
                    <div
                      className={css`
                        ${flex("space-between")}
                      `}
                    >
                      <div>
                        <Text h3>{company.Name}</Text>
                        <div
                          className={css`
                            ${flex("left wrap")}
                            button {
                              padding: 8px;
                              margin-right: 8px;
                            }
                          `}
                        >
                          <Button
                            link
                            onClick={() => {
                              setBusiness(company);
                              history.push(`/${company.ID}/courses`);
                            }}
                          >
                            Guides
                          </Button>
                          <Button
                            link
                            onClick={() => {
                              setBusiness(company);
                              history.push(`/${company.ID}/analytics`);
                            }}
                          >
                            Analytics
                          </Button>
                          <Button
                            link
                            onClick={() => {
                              setBusiness(company);
                              history.push(`/${company.ID}/documents`);
                            }}
                          >
                            Documents
                          </Button>
                          <Button
                            link
                            onClick={() => {
                              setBusiness(company);
                              history.push(`/${company.ID}/users`);
                            }}
                          >
                            Users
                          </Button>

                          <Button
                            link
                            onClick={() => {
                              setBusiness(company);
                              history.push(`/${company.ID}/settings`);
                            }}
                          >
                            Settings
                          </Button>
                        </div>
                      </div>
                      <Button
                        hoverLabel="Delete Company"
                        className="delete"
                        styles="icon"
                        onClick={() => setConfirmDeleteCompany(company)}
                      >
                        <Trash />
                      </Button>
                    </div>
                  </div>
                </div>
              ))}{" "}
          </React.Fragment>
        )}
      </Box>

      {modalOpen && (
        <Modal
          display
          onClose={() => setModalOpen(!modalOpen)}
          css={`
            margin: 8px;
          `}
        >
          <div
            className={css`
              font-weight: 400;
              font-size: 22px;
              line-height: 28px;
              color: #797979;
              margin: 20px;
              margin-bottom: 5px;
            `}
          >
            Add Company
          </div>

          <AddCompany closeAddCompanyModal={handleAddCleanup} />
        </Modal>
      )}

      {confirmDeleteCompany && (
        <Modal
          display
          css={`
            margin: 8px;
          `}
          onClose={() => setConfirmDeleteCompany(null)}
        >
          <div
            className={css`
              font-weight: 400;
              font-size: 22px;
              line-height: 28px;
              color: #797979;
              margin: 20px;
              margin-bottom: 5px;
            `}
          >
            Delete Company
          </div>
          <Box
            css={`
              margin: 20px;
              gap: 16px;
            `}
          >
            <p>Are you sure you want to delete {confirmDeleteCompany.Name}?</p>

            <div
              className={css`
                display: flex;
                justify-content: flex-end;
                margin-top: 20px;
              `}
            >
              <Button
                secondary
                onClick={() => setConfirmDeleteCompany(null)}
                css={`
                  margin-right: 12px;
                `}
              >
                Cancel
              </Button>
              <Button redBg onClick={handleDeleteCompany}>
                Delete
              </Button>
            </div>
          </Box>
        </Modal>
      )}
    </Box>
  );
};
