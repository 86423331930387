import { Collapse } from "@material-ui/core";
import { css } from "@emotion/css";
import { useMemo, useState } from "react";
import { CarrierLogo } from "./carrier-logo";
import { Button, Div, Modal, Text } from "../shared/components";
import { flex, shadows } from "../shared/shared-styles";
import { colors } from "../shared/styles";
import { flattenSummaryFields } from "./product-layout-constants";
import { CostSummary } from "./field-components/cost-summary";
import { SummaryField } from "./field-components/summary-field";
import { ProductComparison } from "./product-comparison";
import { Check, X } from "phosphor-react";
import { programList, programs } from "./included-programs";
import { PRODUCT_HEADERS } from "../benefit-package/constants";
import { getFieldConfigs } from "./configs/config-utils";
import { summaryFieldConfigs } from "./configs/product-summary.config";
import parse from "html-react-parser";
import { BriteLoader } from "../shared/components/brite-loader";
import { get } from "lodash";

const customDetailsIsAboveFold = ["hsa_buyup"];

const programTypesList = programList?.map(([type]) => type);

export const ProductSummary = ({ state, styles }) => {
  const {
    typeCount,
    product,
    layout,
    includedPrograms,
    network,
    fieldsObject,
    isLoading,
  } = state;

  const [hideDisclaimer, setHideDisclaimer] = useState(true);

  const isCustom = product?.Type === "custom";

  const parseDisclaimer = (text) => {
    // No idea what this function is actually doing.
    // Comes from legacy code and is not documented.
    try {
      var exp = /(?:^|[^"'])((ftp|http|https|file):\/\/[\S]+(\b|$))/gi;
      const disclaimer =
        text?.replace(exp, " <a href='$1' target='_blank'>$1</a>") || "";
      return parse(disclaimer);
    } catch {
      return "";
    }
  };

  const validIncludedPrograms = Object.keys(includedPrograms || {})?.filter(
    (type) => programTypesList.includes(type)
  );

  const hasIncludedPrograms =
    !!validIncludedPrograms?.length && product?.Type !== "hsa_buyup";

  const [compare, setCompare] = useState(false);
  const [expand, setExpand] = useState(false);
  const [modal, setModal] = useState("");

  const fieldConfigs = getFieldConfigs(summaryFieldConfigs, {
    product: product,
    fieldsObject,
  });

  const { aboveFold = [], expandable = [] } = useMemo(() => {
    if (!layout?.Layout?.Sections) {
      return {};
    }

    const allFields = flattenSummaryFields(product, layout?.Layout);

    const values = allFields?.reduce(
      (prev, field) => {
        if (field.State === "hide") {
          return prev;
        }
        const fieldConfig = fieldConfigs?.[field?.PropertyChain] || {};
        if (fieldConfig?.aboveTheFold) {
          return { ...prev, aboveFold: [...prev.aboveFold, field] };
        } else {
          return { ...prev, expandable: [...prev.expandable, field] };
        }
      },
      { aboveFold: [], expandable: [] }
    );

    const aboveFold = values?.aboveFold?.length
      ? values?.aboveFold
      : values?.expandable;
    const expandable = !values?.aboveFold?.length ? [] : values?.expandable;

    return { allFields, aboveFold, expandable };
  });

  const hasCostSection = useMemo(() => {
    const idx = layout?.Layout?.Sections?.findIndex(
      ({ DisplayValue }) => DisplayValue === "Premiums & Contributions"
    );
    if (idx > -1) {
      const cost = layout?.Layout?.Sections?.[idx];
      const field = cost?.Fields?.find(
        ({ PropertyChain }) => PropertyChain === "Cost"
      );
      return field?.State !== "hide";
    }
    return false;
  });
  const coreData =
    product?.Type === "insurance_plan"
      ? network.getProductByCategory("core")
      : product;

  const hasCallToAction =
    product?.Type === "custom" &&
    product?.CallToActionText &&
    product?.CallToActionUrl;

  const hasCustomDisclaimer = !!product?.Disclaimer;

  const splitCustomList =
    customDetailsIsAboveFold.includes(product.Type) || isCustom;

  const customDetailsList = product?.TitleDescriptions?.filter(
    ({ Autogenerated }) => !Autogenerated
  );

  const customPlanDetails = splitCustomList
    ? customDetailsList?.slice(0, 5)
    : [];
  const customDetails = splitCustomList
    ? customDetailsList?.slice(5)
    : customDetailsList;

  const conditions = product?.Details?.ConditionPercentages?.filter(
    (item) => item.Name && item.Percentage
  );

  const hasConditions =
    product.Type === "critical_illness" && conditions?.length;

  const hasMoreDetails =
    !!expandable.length || !!customDetails?.length || hasIncludedPrograms;

  const hasCustomDetailsAbove = !!customPlanDetails?.length;

  const hasEmbeddedDisclaimer = get(
    product,
    "Details.InNetworkPlanDesign.SingleEmbeddedDeductibleOOPM"
  );

  const disclaimer = parseDisclaimer(product?.Disclaimer);

  return (
    <Div
      css={css`
        position: relative;
        min-height: 200px;
        width: 100%;
        border-radius: 8px;
        padding-top: 24px;
        background-color: white;
        ${shadows.md}
      `}
    >
      <BriteLoader
        isLoading={isLoading}
        css={`
          top: 8px;
          bottom: 8px;
          right: 8px;
          left: 8px;
        `}
        size={64}
        weight={8}
      />
      <Collapse in={!isLoading && !!product?.ID}>
        <Div
          css={css`
            ${flex("center column")} width: 100%;
            padding-top: 16px;
          `}
        >
          {coreData?.Details?.IsMultiCarrier ? (
            <div
              className={css`
                display: flex;
                align-items: center;
                gap: 16px;
                justify-content: space-between;
              `}
            >
              <CarrierLogo
                carrierID={
                  coreData?.ProviderID || "00000000-0000-0000-0000-000000000000"
                }
                planCarrierName={coreData?.ProviderName || ""}
                maxWidth="150px"
                maxHeight="100px"
                position="center"
              />
              <div
                className={css`
                  border-left: 1px solid #26282d;
                  height: 50px;
                `}
              ></div>
              <CarrierLogo
                carrierID={
                  coreData?.Details?.MultiCarrierID ||
                  "00000000-0000-0000-0000-000000000000"
                }
                planCarrierName={coreData?.Details?.MultiCarrierName || ""}
                maxWidth="150px"
                maxHeight="100px"
                position="center"
              />
            </div>
          ) : (
            <CarrierLogo
              carrierID={
                coreData?.ProviderID || "00000000-0000-0000-0000-000000000000"
              }
              planCarrierName={coreData?.ProviderName || ""}
              maxWidth="350px"
              maxHeight="100px"
              position="center"
            />
          )}

          <Text
            styles="h2"
            className={css`
              margin-top: 16px;
              padding: 0 32px;
              text-align: center;
            `}
          >
            {coreData?.ProductName ||
              `${PRODUCT_HEADERS?.[product?.Type] || ""} Plan Summary`}
          </Text>
          {isCustom ? (
            <Text
              css={`
                margin: 0 32px;
              `}
            >
              {product?.Description}
            </Text>
          ) : null}
          {product?.Details?.NetworkName ? (
            <Text
              css={`
                ${flex("center")} padding-top: 8px;
              `}
            >
              {product?.Details?.NetworkName}{" "}
              {product?.Details?.MultiCarrierNetworkName &&
              coreData?.Details?.IsMultiCarrier
                ? `| ${product?.Details?.MultiCarrierNetworkName}`
                : ""}
            </Text>
          ) : null}

          {coreData?.Details?.IsMultiCarrier && (
            <Text
              label
              css={`
                margin-top: 8px;
                background-color: ${colors.gray[100]};
                padding: 4px 8px;
                border-radius: 16px;
              `}
            >
              Multi Carrier
            </Text>
          )}
        </Div>
        <Div styles="alternatingList">
          {hasCostSection && <CostSummary state={state} />}
        </Div>
        <Div styles="alternatingList">
          {aboveFold?.map((field) => {
            return (
              <SummaryField
                product={product}
                field={field}
                config={fieldConfigs?.[field?.PropertyChain] || {}}
              />
            );
          })}
        </Div>
        {hasCustomDetailsAbove && customPlanDetails?.length ? (
          <Div styles="alternatingList">
            {customPlanDetails?.map(({ Title }, idx) => (
              <SummaryField
                product={{ TitleDescriptions: customPlanDetails }}
                field={{
                  DisplayValue: Title,
                  State: "show",
                  Type: "text-input",
                  PropertyChain: `TitleDescriptions[${idx}].Description`,
                }}
              />
            ))}
          </Div>
        ) : null}

        {hasConditions ? (
          <>
            <Text
              label
              bold
              css={css`
                padding: 16px 32px;
              `}
            >
              Listed Conditions
            </Text>
            <Div styles="alternatingListOdd">
              {conditions?.map(({ Name }, idx) => {
                return (
                  <SummaryField
                    product={{
                      Details: { ConditionPercentages: conditions },
                    }}
                    field={{
                      DisplayValue: Name,
                      State: "show",
                      Type: "percent",
                      PropertyChain: `Details.ConditionPercentages[${idx}].Percentage`,
                    }}
                  />
                );
              })}
            </Div>
          </>
        ) : null}

        {hasMoreDetails ? (
          <>
            <Collapse in={expand}>
              <>
                <Div styles="alternatingList">
                  {expandable?.map((field) => {
                    return (
                      <SummaryField
                        product={product}
                        field={field}
                        config={fieldConfigs?.[field?.PropertyChain] || {}}
                      />
                    );
                  })}
                </Div>

                {hasIncludedPrograms && (
                  <>
                    <Text
                      label
                      bold
                      css={css`
                        padding: 16px 32px;
                      `}
                    >
                      Included Programs
                    </Text>
                    <Div styles="alternatingListOdd">
                      {programList?.map(([programType, value]) =>
                        programType in includedPrograms ? (
                          <Div
                            key={programType}
                            className="alternating-item"
                            css={css`
                              padding: 16px 32px;
                              ${flex("space-between")}
                              svg {
                                color: var(
                                  --button-background-color,
                                  ${colors.purple}
                                );
                              }
                            `}
                          >
                            <Text label>
                              {includedPrograms?.[programType]?.RawData?.Details
                                ?.Name || programs[programType]}
                            </Text>
                            <Check size={24} />
                          </Div>
                        ) : null
                      )}
                    </Div>
                  </>
                )}

                {customDetails?.length > 0 && (
                  <Text
                    label
                    bold
                    className={css`
                      padding: 16px 32px;
                      padding-top: 32px;
                    `}
                  >
                    Other Details
                  </Text>
                )}
                <Div styles="alternatingListOdd">
                  {customDetails?.map(({ Title }, idx) => (
                    <SummaryField
                      product={{ TitleDescriptions: customDetails }}
                      field={{
                        DisplayValue: Title,
                        State: "show",
                        Type: "text-input",
                        PropertyChain: `TitleDescriptions[${idx}].Description`,
                      }}
                    />
                  ))}
                </Div>
              </>
            </Collapse>
          </>
        ) : (
          <Div
            css={css`
              margin-top: 16px;
            `}
          />
        )}
        {hasEmbeddedDisclaimer ? (
          <Text
            css={`
              margin: 16px;
              font-size: 0.9em;
            `}
          >
            * All dependents deductibles & OOPM are subject to the single
            deductible & OOPM.
          </Text>
        ) : null}
        <Div
          css={
            hasMoreDetails || hasCallToAction || typeCount > 1
              ? css`
                  margin-bottom: 16px;
                `
              : ""
          }
        >
          <Div
            css={css`
              ${flex("space-around wrap")}
              ${hasMoreDetails ||
              hasCallToAction ||
              (typeCount > 1 && product?.Type !== "custom")
                ? `margin-bottom: 32px;`
                : ""}
            `}
          >
            {hasMoreDetails ? (
              <Button
                className={css`
                  min-width: 200px;
                  margin: 0 32px;
                  margin-top: 16px;
                  flex-grow: 1;
                `}
                onClick={() => setExpand(!expand)}
                styles="secondary"
              >
                {expand ? "Less Detail" : "More Details"}
              </Button>
            ) : null}
            {hasCallToAction ? (
              <Button
                className={css`
                  min-width: 200px;
                  margin: 0 32px;
                  margin-top: 16px;
                  flex-grow: 1;
                `}
                style={{
                  ...(styles?.button || {}),
                  backgroundColor: `var(--button-background-color, ${colors.purple})`,
                  width: "calc(100% - 64px)",
                }}
              >
                {product?.CallToActionText}
              </Button>
            ) : null}
          </Div>

          {typeCount > 1 && !isCustom ? (
            <Button
              className={css`
                margin: 16px 32px;
                margin-bottom: 32px;
              `}
              style={{
                ...(styles?.button || {}),
                backgroundColor: `var(--button-background-color, ${colors.purple})`,
                width: "calc(100% - 64px)",
              }}
              onClick={() => setCompare(true)}
            >
              Compare Plans
            </Button>
          ) : null}
        </Div>
      </Collapse>

      {hasCustomDisclaimer ? (
        <Text
          className={css`
            font-size: 14px;
            padding: 32px;
            margin-top: -16px;
            padding-top: 0;
          `}
        >
          {disclaimer.slice(0, 200)}
          {disclaimer?.length > 200 ? "..." : ""}
          {disclaimer?.length > 200 ? (
            <Text
              as="span"
              onClick={() => setHideDisclaimer(!hideDisclaimer)}
              css={`
                margin-left: 16px;
                text-decoration: underline;
                color: ${colors.black};
                cursor: pointer;
                :hover {
                  font-weight: bold;
                }
              `}
            >
              See All
            </Text>
          ) : null}
        </Text>
      ) : null}

      <Modal
        display={!hideDisclaimer}
        onClose={() => setHideDisclaimer(!hideDisclaimer)}
      >
        <div
          className={css`
            padding: 32px;
            width: 500px;
            position: relative;
          `}
        >
          <Text h2>Plan Disclaimer</Text>
          <div
            className={css`
              max-height: 40vh;
              overflow: auto;
              padding-top: 16px;
              padding-bottom: 64px;
            `}
          >
            <Text>{disclaimer}</Text>
          </div>
          <Button
            onClick={() => setHideDisclaimer(!hideDisclaimer)}
            css={`
              position: absolute;
              bottom: 24px;
              width: 120px;
              left: calc(50% - 60px);
            `}
          >
            <X /> Close
          </Button>
        </div>
      </Modal>

      {modal === "info" && (
        <Modal display={modal === "info"} onClose={() => setModal("")}>
          <Div
            css={css`
              width: 500px;
            `}
          >
            <Div
              css={css`
                ${flex("space-between")} padding: 32px;
              `}
            >
              <Text h2>Benefit Terminology</Text>
              <Button styles="icon" onClick={() => setModal("")}>
                <X />
              </Button>
            </Div>
            <Div
              css={css`
                padding: 0 32px;
                p {
                  margin: 8px 0;
                }
              `}
            >
              <Text label>AD = After Deductible</Text>
              <Text label>OOPM = Out Of Pocket Max</Text>
              <Text label>RX = Medical Prescription</Text>

              <Div
                css={css`
                  ${flex("space-between")}
                  margin-top: 16px;
                `}
              >
                <Text label bold>
                  Copay
                </Text>
                <Text label>
                  <em>noun</em>
                </Text>
              </Div>
              <Text>
                The amount of money that a patient with health insurance pays
                for each healthcare service.
              </Text>

              <Div
                css={css`
                  ${flex("space-between")}
                  margin-top: 16px;
                `}
              >
                <Text label bold>
                  Deductible
                </Text>
                <Text label>
                  <em>adjective</em>
                </Text>
              </Div>
              <Text>
                The amount you pay for covered health care services before your
                insurance plan starts to pay.
              </Text>
            </Div>
            <Div
              css={css`
                ${flex("right")}
                padding: 32px;
              `}
            >
              <Button onClick={() => setModal("")}>Done</Button>
            </Div>
          </Div>
        </Modal>
      )}

      {compare && (
        <ProductComparison
          display={compare}
          onClose={() => setCompare(false)}
          productId={product?.ID}
          productType={product?.Type}
          packageId={state?.packageId}
          businessId={state?.businessId}
        />
      )}
    </Div>
  );
};
