import { useEffect, useState } from "react";
import { CustomAxios } from "../redux/axios/axios";

export const useBackendConfig = () => {
  const [config, setConfig] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (loading || Object.keys(config).length !== 0) {
      return;
    }
    setLoading(true);
    const fetchConfig = async () => {
      try {
        const resp = await CustomAxios.get("/v1/brite/config");
        if (resp && resp.data) {
          setConfig(resp.data);
        }
      } catch (e) {
        console.error("error loading backend config", e);
      } finally {
        setLoading(false);
      }
    };
    fetchConfig();
  }, []);

  return config;
};
