import { css } from "@emotion/css";
import {
  DotsThree,
  LinkSimple,
  LinkSimpleBreak,
  ListBullets,
  ListNumbers,
  TextAlignCenter,
  TextAlignLeft,
  TextAlignRight,
  TextBolder,
  TextH,
  TextItalic,
  TextStrikethrough,
  TextT,
  TextUnderline,
} from "phosphor-react";
import { useCallback, useEffect, useState } from "react";
import { Editor, Range, Transforms } from "slate";
import { ReactEditor, useSlate } from "slate-react";
import { Button, Div, DropMenu, Icon, Text } from "../../shared/components";
import { flex } from "../../shared/shared-styles";
import { colors } from "../../shared/styles";
import {
  getActiveColor,
  insertNode,
  isBlockActive,
  isInlineActive,
  isLinkActive,
  removeAllMarks,
  toggleBlock,
  toggleInline,
  updateColor,
} from "../editor-components/text-v2/slate-utils";
import { ColorPicker } from "./menu-components";
import { TextV2LinkModal } from "./menu-components/text-v2-link-modal";
import { TypographyDropdown } from "./text-toolbar";
import { ReactComponent as SmartText } from "../../images/svg/smart-text.svg";
import { useEditorResource } from "../use-editor-resource";
import { modifyContent } from "../provider/utils";
import { useFeatureFlagPayload } from "posthog-js/react";

export const TextV2Toolbar = ({ editor, element, location, toolbar }) => {
  const onUpdate = (updates) => modifyContent.merge(editor, location, updates);
  const { data: styles } = useEditorResource("styles");
  const mergeTagFF = useFeatureFlagPayload("merge-tags");
  const slateEditor = useSlate();
  const [modal, setModal] = useState("");
  const [linkProps, setLinkProps] = useState({});

  const linkActive = isLinkActive(slateEditor);
  const linkStyles = linkActive ? "icon-active sm" : "icon sm";

  const isSmartFieldActive = isBlockActive(slateEditor, "smart-field");

  useEffect(() => {
    toolbar?.setAdditionalOptions(isSmartFieldActive ? "smart-fields" : "");
  }, [isSmartFieldActive]);

  const handleLinkProps = (data) => {
    if (modal === "edit-link") {
      ReactEditor.focus(slateEditor);
      const [parentNode, parentPath] = Editor.parent(
        slateEditor,
        slateEditor?.selection?.focus?.path
      );
      if (parentNode.type === "link") {
        Transforms.setNodes(slateEditor, data, { at: parentPath });
      }
    } else if (modal === "create-link") {
      toggleInline(slateEditor, "underline", { toggleOn: true });
      insertNode(slateEditor, { type: "link", ...data });
    }
    setModal("");
  };

  const openLinkModal = () => {
    const type = !linkActive ? "create-link" : "edit-link";
    if (!linkActive) {
      setLinkProps({
        href: "",
        linkText: "",
        target: "_blank",
      });
    } else {
      const [match] = Editor.nodes(slateEditor, {
        match: (n) => !Editor.isBlock(slateEditor, n) && n.type === "link",
      });
      if (!!match) {
        const [data] = match;
        setLinkProps(data);
      }
    }
    setModal(type);
  };

  const isMoreFormattingOptionsActive =
    isInlineActive(slateEditor, "underline") ||
    isInlineActive(slateEditor, "strikethrough") ||
    isInlineActive(slateEditor, "highlight") ||
    isInlineActive(slateEditor, "superscript") ||
    isInlineActive(slateEditor, "subscript");

  const MarkButton = useCallback(
    ({ type, label, children, ...rest }) => {
      return (
        <Button
          {...rest}
          hoverLabel={label}
          onMouseDown={(e) => e.preventDefault()}
          onClick={() => toggleInline(slateEditor, type)}
          styles={
            isInlineActive(slateEditor, type) ? "icon-active sm" : "icon sm"
          }
        >
          {children}
        </Button>
      );
    },
    [slateEditor]
  );

  const BlockButton = useCallback(
    ({ type, label, children, ...rest }) => {
      const activeBlock =
        type === "left"
          ? isBlockActive(slateEditor, type) || isBlockActive(slateEditor, "p")
          : isBlockActive(slateEditor, type);
      return (
        <Button
          {...rest}
          hoverLabel={label}
          onMouseDown={(e) => e.preventDefault()}
          onClick={() => toggleBlock(slateEditor, type)}
          styles={activeBlock ? "icon-active sm" : "icon sm"}
        >
          {children}
        </Button>
      );
    },
    [slateEditor]
  );

  const activeColor =
    getActiveColor(slateEditor) ||
    element?.attributes?.style?.color ||
    `text-${element?.type}`;

  const changeType = (type) => {
    onUpdate({ type });
  };

  const isCollapsed = slateEditor?.selection
    ? Range.isCollapsed(slateEditor?.selection)
    : true;

  const handleColorChange = (color) => {
    if (isCollapsed) {
      removeAllMarks(slateEditor, "color");
      onUpdate({
        attributes: { style: { color } },
      });
    } else {
      updateColor(slateEditor, "color", color);
    }
  };

  return (
    <Div
      css={css`
        ${flex("left")}
      `}
    >
      <TypographyDropdown
        type={element?.type}
        changeType={changeType}
        styles={styles}
      />
      <Div
        css={css`
          margin-left: 4px;
        `}
      />
      <Div
        css={css`
          ${flex("left")}
          transition: all .2s ease;
          ${!isCollapsed || linkActive
            ? `
              padding-left: 4px;
              flex-grow: 1;
              border-right: 1px solid ${colors.gray[300]};
              padding-right: 8px;
              margin-right: 4px;
            `
            : `
            width: 0;
            overflow: hidden;
            visibility: hidden;
          `}
        `}
      >
        {!isCollapsed || linkActive ? (
          <Button
            styles={linkStyles}
            onClick={openLinkModal}
            hoverLabel={linkActive ? "Edit Link" : "Add Link"}
            onMouseDown={(e) => e.preventDefault()}
          >
            {linkActive ? (
              <LinkSimple size={24} />
            ) : (
              <LinkSimpleBreak size={24} />
            )}
          </Button>
        ) : null}

        {!isCollapsed ? (
          <>
            <MarkButton type="bold" label="Bold">
              <TextBolder size={24} />
            </MarkButton>
            <MarkButton type="italic" label="Italic">
              <TextItalic size={24} />
            </MarkButton>
            <DropMenu
              button={
                <Button
                  styles={
                    isMoreFormattingOptionsActive ? "icon-active sm" : "icon sm"
                  }
                  onMouseDown={(e) => e.preventDefault()}
                >
                  <DotsThree size={24} />
                </Button>
              }
            >
              <Div
                css={css`
                  ${flex("left")}
                  margin: 0 8px;
                `}
              >
                <MarkButton type="underline" label="Underline">
                  <TextUnderline size={24} />
                </MarkButton>
                <MarkButton type="strikethrough" label="Strikethrough">
                  <TextStrikethrough size={24} />
                </MarkButton>
                <MarkButton type="highlight" label="Highlight">
                  <TextH size={24} />
                </MarkButton>
                <Div
                  css={css`
                    position: relative;
                  `}
                >
                  <MarkButton type="superscript" label="Superscript">
                    <TextT size={24} />
                  </MarkButton>
                  <Text
                    label
                    css={`
                      position: absolute;
                      font-size: 0.83em;
                      top: -3px;
                      right: 0;
                    `}
                  >
                    1
                  </Text>
                </Div>
                <Div
                  css={css`
                    position: relative;
                  `}
                >
                  <MarkButton type="subscript" label="Subscript">
                    <TextT size={24} />
                  </MarkButton>
                  <Text
                    label
                    css={`
                      position: absolute;
                      font-size: 0.83em;
                      bottom: -3px;
                      right: 0;
                    `}
                  >
                    1
                  </Text>
                </Div>
              </Div>
            </DropMenu>
            <Div
              css={css`
                margin-left: 2px;
              `}
            />
            <ColorPicker
              item={element}
              title="Font Color"
              updateColor={handleColorChange}
              currentColor={activeColor}
            />

            <Div
              css={css`
                margin-left: 4px;
              `}
            />
          </>
        ) : null}
      </Div>
      <Div
        css={css`
          margin-left: 4px;
        `}
      />
      {mergeTagFF?.value ? (
        <Button
          css={`
            margin-right: 4px;
          `}
          onClick={() =>
            toolbar.setAdditionalOptions((a) =>
              a === "smart-fields" ? "" : "smart-fields"
            )
          }
          onMouseDown={(e) => e.preventDefault()}
          hoverLabel="Tags"
          styles={
            toolbar?.additionalOptions === "smart-fields"
              ? "icon-active sm"
              : "icon sm"
          }
        >
          <Icon SVG={SmartText} size={24} />
        </Button>
      ) : null}
      <ColorPicker
        item={element}
        title="Background Color"
        updateColor={(backgroundColor) =>
          onUpdate({
            container: { attributes: { style: { backgroundColor } } },
          })
        }
        currentColor={
          element?.container?.attributes?.style?.backgroundColor ||
          "rgba(0, 0, 0, 0)"
        }
      />
      <Div
        css={css`
          margin-left: 4px;
        `}
      />
      <BlockButton type="left">
        <TextAlignLeft size={24} />
      </BlockButton>
      <BlockButton type="center">
        <TextAlignCenter size={24} />
      </BlockButton>
      <BlockButton type="right">
        <TextAlignRight size={24} />
      </BlockButton>
      <BlockButton type="unordered-list">
        <ListBullets size={24} />
      </BlockButton>
      <BlockButton type="ordered-list">
        <ListNumbers size={24} />
      </BlockButton>
      {modal === "create-link" || modal === "edit-link" ? (
        <TextV2LinkModal
          slateEditor={slateEditor}
          linkProps={linkProps}
          onUpdate={handleLinkProps}
          display={modal === "create-link" || modal === "edit-link"}
          onClose={() => setModal("")}
        />
      ) : null}
    </Div>
  );
};
