import { css } from "@emotion/css";
import { Editable, ReactEditor, Slate } from "slate-react";
import { colors } from "../../../shared/styles";
import { Toolbar } from "../../toolbar-menus";
import { useSlateEditor } from "./use-slate-editor";
import { useKeyboard } from "../../../Router/use-keyboard";

export const slateContainer = css`
  outline: none;
  width: 100%;
  cursor: text;
  p {
    margin: 0;
    padding: 0;
  }
  .center-element {
    background-color: ${colors.gray[200]};
    border-radius: 8px;
    padding: 8px;
    margin: 0;
    width: 100%;
  }
`;

export const TextV2 = (props) => {
  const { slateProps, editorProps } = useSlateEditor(props);

  const keydown = (captured, event) => {
    const isFocused = ReactEditor?.isFocused(slateProps?.editor);
    if (!isFocused && captured === "+Enter") {
      event?.stopPropagation();
      event?.preventDefault();
      ReactEditor?.focus(slateProps?.editor);
    } else {
      if (captured === "meta+Enter") {
        event?.stopPropagation();
        event?.preventDefault();
        ReactEditor?.blur(slateProps?.editor);
      }
    }
  };

  return (
    <Slate {...slateProps}>
      {props?.isSelected ? (
        <>
          <Toolbar {...props} flags={{ usingTextV2: true }} />
          <Keydown keydown={keydown} />
        </>
      ) : null}
      <Editable {...editorProps} className={slateContainer} />
    </Slate>
  );
};

const Keydown = ({ keydown }) => {
  useKeyboard({ keydown, options: { useCapture: true } });
  return null;
};
