import React, { useState } from "react";
import { css } from "@emotion/css";
import { Button, IconButton } from "@material-ui/core";
import { CustomAxios } from "../redux/axios/axios";
import { toast } from "react-toastify";
import { Modal, Toggle } from "../shared/components";

import { LinkSimple, X } from "phosphor-react";

const ShareModal = (props) => {
  const [loading, setLoading] = useState(false);
  const [publicLink, setPublicLink] = useState(false);

  const copyTextToClipboard = (text) => {
    if (!navigator.clipboard) {
      return;
    }
    navigator.clipboard.writeText(text).then(
      function () {
        setLoading(false);
        toast.success("Public link successfully copied");
      },
      function (err) {
        toast.error("Error creating shareable link. Please try again.");
        setLoading(false);
      }
    );
  };

  const createShareUrl = async () => {
    setLoading(true);
    try {
      const resp = await CustomAxios.get(
        `/v1/analytics/${props.guideId}/share`
      );
      let host = `${window.location.protocol}//${window.location.hostname}`;
      if (window.location.port) {
        host += `:${window.location.port}`;
      }
      copyTextToClipboard(
        `${host}/shared/business/${resp.data.businessID}/course/${resp.data.guideID}/analytics`
      );
    } catch (e) {
      console.log("Error creating shareable link", e);
      toast.error("Error creating shareable link. Please try again.");
    }
  };

  return (
    <Modal display onClose={props.onClose}>
      <div
        className={css`
          width: 600px;
          padding: 32px;
          padding-top: 24px;
        `}
      >
        <div
          className={css`
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 12px;
          `}
        >
          <div
            className={css`
              color: var(--700, #25282d);
              font-feature-settings: "clig" off, "liga" off;

              /* H2 */
              font-family: Roboto;
              font-size: 24px;
              font-style: normal;
              font-weight: 700;
              line-height: 32px; /* 133.333% */
            `}
          >
            Share Analytics
          </div>
          <IconButton onClick={() => props.onClose()}>
            <X />
          </IconButton>
        </div>

        <div
          className={css`
            color: var(--500, #66737f);
            font-feature-settings: "clig" off, "liga" off;

            /* Body - Dark */
            font-family: Roboto;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px; /* 150% */
            letter-spacing: 0.15px;
          `}
        >
          Use a public link to share this analytics data with non-Brite users.
        </div>

        <div
          className={css`
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 16px;
            margin-bottom: 16px;
          `}
        >
          <div
            className={css`
              color: var(--700, #25282d);
              font-feature-settings: "clig" off, "liga" off;

              /* H4 */
              font-family: Roboto;
              font-size: 18px;
              font-style: normal;
              font-weight: 700;
              line-height: 28px; /* 155.556% */
              letter-spacing: 0.15px;
            `}
          >
            Public Link
          </div>
          <div>
            <Toggle
              checked={publicLink}
              onChange={(e) => {
                setPublicLink(e.target.checked);
              }}
            />
          </div>
        </div>

        <div
          className={css`
            display: flex;
            justify-content: flex-end;
            gap: 16px;
            margin: 8px;
          `}
        >
          <Button variant="outlined" disabled={loading} onClick={props.onClose}>
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            disabled={loading || !publicLink}
            onClick={createShareUrl}
            startIcon={<LinkSimple size={24} color={"#fff"} />}
          >
            Copy Link
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ShareModal;
