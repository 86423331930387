import { MenuItem } from "@material-ui/core";
import { css } from "@emotion/css";
import { DotsThree, WarningCircle, X } from "phosphor-react";
import { useMemo, useState } from "react";
import { toast } from "react-toastify";
import { history } from "../history";
import { useQueryAPI, useResource } from "../react-query";
import { CustomAxios } from "../redux/axios/axios";
import { CompanySelect } from "../Sidebar/company-select";
import {
  Button,
  Div,
  DropMenu,
  Input,
  Select,
  Loader,
  Modal,
  NewTag,
  Text,
  Icon,
} from "../shared/components";
import { BriteLoader } from "../shared/components/brite-loader";
import { container, flex, px } from "../shared/shared-styles";
import { colors } from "../shared/styles";
import { useForm } from "../shared/use-form";
import { useStateSync } from "../shared/use-state-sync";
import { useStore } from "../store-provider/use-store";
import EmptyState from "./benefits-empty.png";
import { format } from "date-fns";
import { intervalMap } from "../BriteEditor/editor-components/benefits/plan-comparisons/use-display-settings";
import { extractNumbers } from "./auto-paster-v2/paste-utils";
import { ACLWrapper } from "../shared/components/acl-wrapper";
import { WRITE_BENEFITS_PACKAGE } from "../shared/acl-constants";
import { FlagsBadge } from "../products/flags/flags-badge";
import { needsAttention } from "../react-query/resources/needs-attention";
import { ReactComponent as BenefitPackageIcon } from "../images/svg/benefit-package.svg";

const emptyContainer = css`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  img {
    width: 460px;
    margin: 32px 0;
  }
  p {
    margin: 16px 0;
    padding: 0;
  }
`;

const transformNeedsAttention = (data) => {
  return data?.reduce(
    (prev, item) =>
      !item?.metadata?.benefit_package_id
        ? prev
        : {
            ...prev,
            [item?.metadata?.benefit_package_id]: [
              ...new Set([
                ...(prev[item?.metadata?.benefit_package_id] || []),
                item?.resource_id,
              ]),
            ],
          },
    {}
  );
};

export const BenefitPackageList = ({ query, businessId, search }) => {
  const { data, refetch, cache, dataUpdatedAt } = query;

  const needsAttentionResource = useResource(needsAttention, {
    search: {
      business_id: `eq.${businessId}`,
      resolved: "is.null",
    },
    enabled: !!businessId,
    select: transformNeedsAttention,
  });

  const {
    data: { companies, selectedBusiness },
  } = useStore();

  const [modal, setModal] = useState("");
  const [selectedCompany, setSelectedCompany] = useState(null);

  const {
    values: selectedPkg,
    setValues: setSelectedPkg,
    getProperty,
    hasChanges,
  } = useForm({}, [dataUpdatedAt]);

  const { data: attachedCourses, isFetching: isLoadingAttachedCourses } =
    useQueryAPI({
      url: `v1/benefitspackage/${selectedPkg?.ID}/course`,
      select: ({ Data }) => Data,
      enabled: !!selectedPkg?.ID && modal === "delete-package",
      retry: 1,
      cacheTime: 0,
    });

  const [pkgDescriptions, setPkgDescriptions] = useStateSync(
    () =>
      data?.reduce(
        (prev, item) =>
          item?.Description
            ? {
                ...prev,
                [item?.ID]: item?.Description ?? "",
              }
            : prev,
        {}
      ) || {},
    [dataUpdatedAt]
  );

  const editPackageName = (pkg) => {
    setModal("edit");
    setSelectedPkg(pkg);
  };

  const closeModal = () => {
    setModal("");
    setSelectedPkg({});
  };

  const updateCacheData = (id, updates) => {
    const nextData = data?.map((item) => {
      if (item.ID === id) {
        return { ...item, ...updates };
      } else {
        return item;
      }
    });
    cache.setData(nextData);
  };

  const saveDescriptions = async (pkg) => {
    try {
      await CustomAxios.put(`/v1/benefitspackage/${pkg?.ID}`, {
        ...pkg,
        Description: pkgDescriptions[pkg?.ID],
      });
    } catch (err) {
      toast.error("Error updating the description.");
    } finally {
      refetch();
    }
  };

  const updatePackage = async () => {
    try {
      updateCacheData(selectedPkg.ID, selectedPkg);
      closeModal();
      await CustomAxios.put(
        `/v1/benefitspackage/${selectedPkg.ID}`,
        selectedPkg
      );
    } catch (err) {
      toast.error(`Error updating the package details.`);
      console.warn(err);
    } finally {
      refetch();
    }
  };

  const deleteBenefitPackage = async () => {
    setModal("");
    try {
      const updates = { ID: "" };
      updateCacheData(selectedPkg?.ID, updates);
      await CustomAxios.delete(`/v1/benefitspackage/${selectedPkg?.ID}`);
    } catch (err) {
      toast.error(`Error deleting benefit package.`);
      console.warn(err);
    } finally {
      refetch();
    }
  };

  const duplicateBenefitPackage = async (pkg) => {
    try {
      console.log(selectedCompany);
      cache.setData([
        {
          Name: pkg.Name + " Copy",
          ProductCount: 0,
          CreatedAt: new Date().toISOString(),
        },
        ...data,
      ]);
      closeModal();
      await CustomAxios.post(
        `/v1/benefitspackage/${pkg.ID}/copies/${selectedCompany.ID}`
      );
      if (selectedCompany.ID !== businessId) {
        toast.success(`Successfully duplicated package.`);
      }
    } catch (err) {
      console.warn(err);
      toast.error(`Failed to duplicate the package.`);
    } finally {
      refetch();
    }
  };

  const handleDuplicate = (pkg) => {
    if (companies?.length) {
      setSelectedPkg(pkg);
      setSelectedCompany(selectedBusiness);
      setModal("duplicate-package");
    } else {
      duplicateBenefitPackage(pkg);
    }
  };

  const getDateProps = (property) => {
    const value = selectedPkg?.[property]
      ? format(
          new Date(
            selectedPkg?.[property].replace(/-/g, "/").replace(/T.+/, "")
          ),
          "yyyy-MM-dd"
        )
      : null;

    const dateProps = {
      type: "date",
      format: "date",
      value,
      onChange: (e) => {
        const value = e.target.value
          ? new Date(e.target.value).toISOString()
          : null;
        setSelectedPkg({
          ...selectedPkg,
          [property]: value,
        });
      },
    };
    return dateProps;
  };

  const list = useMemo(() => {
    if (!search) {
      return data;
    }
    const term = search?.toLowerCase();
    return data?.filter((item) => {
      return item?.Name?.toLowerCase().includes(term);
    });
  }, [search, dataUpdatedAt]);

  return !!list?.length ? (
    <>
      <Div
        css={css`
          width: 100%;
          padding: 32px 0;
          ${flex("left wrap")}
        `}
      >
        {list
          ?.toSorted((a, b) => new Date(b?.CreatedAt) - new Date(a?.CreatedAt))
          .reduce((acc, item) => {
            const year = new Date(item?.CreatedAt).getFullYear();

            if (!acc?.length || acc[acc.length - 1].Year !== year) {
              acc.push({ Year: year, Items: [] });
            }

            acc[acc.length - 1].Items.push(item);
            return acc;
          }, [])
          ?.map((group) => (
            <Div>
              <h2>{group.Year}</h2>
              <Div
                css={css`
                  width: 100%;
                  padding: 32px 0;
                  ${flex("left wrap")}
                `}
              >
                {group.Items?.toSorted((a, b) => {
                  if (a?.Name < b?.Name) return -1;
                  if (a?.Name > b?.Name) return 1;
                  return 0;
                }).map((pkg) => (
                  <Div
                    onClick={() =>
                      pkg?.ID && history.push(`packages/${pkg.ID}`)
                    }
                    css={css`
                      ${container.box}
                      ${container.hoverOutline}
                    width: 440px;
                      background-color: white;
                      margin-right: 24px;
                      margin-bottom: 24px;
                      padding: 16px 24px;
                    `}
                  >
                    <Div
                      css={css`
                        ${flex("left")}
                      `}
                    >
                      <Div
                        css={css`
                          ${flex("left")}
                          flex-grow: 1;
                        `}
                      >
                        <Icon
                          SVG={BenefitPackageIcon}
                          size={32}
                          weight="light"
                          className={css`
                            margin-right: 16px;
                          `}
                        />
                        <NewTag createdAt={pkg?.CreatedAt} />
                      </Div>
                      <FlagsBadge
                        flags={needsAttentionResource?.query?.data?.[pkg?.ID]}
                      />
                      {pkg.ID ? (
                        <ACLWrapper acls={[WRITE_BENEFITS_PACKAGE]}>
                          <DropMenu
                            button={
                              <Button styles="icon">
                                <DotsThree />
                              </Button>
                            }
                          >
                            <MenuItem onClick={() => editPackageName(pkg)}>
                              Change Details
                            </MenuItem>
                            {pkg?.ID in pkgDescriptions ? null : (
                              <MenuItem
                                onClick={() =>
                                  setPkgDescriptions((des) => ({
                                    ...des,
                                    [pkg?.ID]: "",
                                  }))
                                }
                              >
                                Add Description
                              </MenuItem>
                            )}
                            <MenuItem onClick={() => handleDuplicate(pkg)}>
                              Duplicate
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                setSelectedPkg(pkg);
                                setModal("delete-package");
                              }}
                            >
                              Delete
                            </MenuItem>
                          </DropMenu>
                        </ACLWrapper>
                      ) : (
                        <Loader />
                      )}
                    </Div>
                    <Text
                      h3
                      css={`
                      margin: 8px 0;
                      width: 100%:
                    `}
                      ellipsis
                    >
                      {pkg?.Name}
                    </Text>
                    <Div
                      css={css`
                        ${flex("left")}
                      `}
                    >
                      <Text
                        label
                        css={`
                          padding-right: 16px;
                          ${pkg?.ID in pkgDescriptions
                            ? `border-right: 1px solid ${colors.gray[300]}`
                            : ""};
                        `}
                      >
                        {pkg?.ProductCount}
                        {pkg?.ProductCount === 1 ? " Benefit" : " Benefits"}
                      </Text>
                      <Input
                        value={pkgDescriptions?.[pkg?.ID]}
                        onChange={(e) => {
                          const value = e.target.value;
                          setPkgDescriptions((des) => ({
                            ...des,
                            [pkg?.ID]: value,
                          }));
                        }}
                        onBlur={() => saveDescriptions(pkg)}
                        onClick={(e) => e.stopPropagation()}
                        placeholder="Add a description..."
                        css={`
                          input {
                            color: ${colors.gray[500]};
                          }
                          flex-grow: 1;
                          padding-top: 0;
                          padding-bottom: 0;
                          margin: 0;
                          margin-left: 8px;
                          height: 32px;
                          border: none;
                          background: transparent;
                          ${pkg?.ID in pkgDescriptions
                            ? ""
                            : "visibility: hidden;"}
                          :hover {
                            border: none;
                          }
                        `}
                      />
                    </Div>
                  </Div>
                ))}
              </Div>
            </Div>
          ))}
      </Div>

      <Modal display={modal === "delete-package"} onClose={closeModal}>
        <Div
          css={css`
            width: 600px;
          `}
        >
          <Div
            css={css`
              ${flex("space-between")} padding: 32px;
            `}
          >
            <Text h2>Delete Benefit Package</Text>
            <Button styles="icon" onClick={closeModal}>
              <X />
            </Button>
          </Div>

          <Div
            css={css`
              position: relative;
              ${flex("space-between")}
            `}
          >
            <BriteLoader
              isLoading={isLoadingAttachedCourses}
              className={css`
                height: 100%;
              `}
            />
            {!attachedCourses?.length ? (
              <Text
                css={`
                  padding: 0 32px;
                `}
              >
                Are you sure you want to delete this benefit package? This
                action cannot be undone.
              </Text>
            ) : (
              <Div>
                <Div
                  css={css`
                    padding: 16px 32px;
                  `}
                >
                  <Text>
                    If you delete this benefit package, it will be missing from
                    any guide it is syncing with
                  </Text>
                  <Div
                    css={css`
                      ${flex("left")} border-radius: 8px;
                      background-color: ${colors.red[100]}33;
                      padding: 16px 32px;
                      svg {
                        margin-right: 16px;
                      }
                      margin: 16px 0;
                    `}
                  >
                    <WarningCircle color={colors.red[100]} />
                    <Text label>
                      This benefit package is currently syncing with{" "}
                      {attachedCourses?.length} guide
                      {attachedCourses?.length === 1 ? "" : "s"}
                    </Text>
                  </Div>
                </Div>
                <Div
                  css={css`
                    border-top: 1px solid ${colors.gray[300]};
                    border-bottom: 1px solid ${colors.gray[300]};
                    padding: 16px 32px;
                    svg {
                      margin-right: 16px;
                    }
                    max-height: 200px;
                    overflow-y: auto;
                  `}
                >
                  {attachedCourses.map(({ Name }) => (
                    <Text
                      label
                      css={`
                        padding: 4px 0;
                      `}
                    >
                      {Name}
                    </Text>
                  ))}
                </Div>
              </Div>
            )}
          </Div>

          <Div
            css={css`
              ${flex("right")} padding: 32px;
            `}
          >
            <Button styles="secondary mr" onClick={closeModal}>
              Cancel
            </Button>
            <Button
              onClick={deleteBenefitPackage}
              css={`
                color: white;
                background-color: ${colors.red[100]};
              `}
            >
              Delete
            </Button>
          </Div>
        </Div>
      </Modal>

      <Modal display={modal === "edit"} onClose={closeModal}>
        <Div
          css={css`
            width: 500px;
            padding: ${px.xl};
          `}
        >
          <Div
            css={css`
              ${flex("space-between")}
            `}
          >
            <Text h2>{selectedPkg?.ID ? "Edit" : "Add"} a Benefit Package</Text>
            <Button styles="icon" onClick={closeModal}>
              <X />
            </Button>
          </Div>

          <Div
            css={css`
              margin: 32px 0;
            `}
          >
            <Text label bold>
              Package Name
            </Text>
            <Input {...getProperty("Name")} />
          </Div>

          <Div
            css={css`
              max-height: 50vh;
              overflow: auto;
            `}
          >
            <Div
              css={css`
                ${flex("space-between")}
                padding: 8px 0;
                .shared-input {
                  width: 50%;
                }
              `}
            >
              <Text label>Premiums & Contributions</Text>
              <Select
                css={`
                  width: 50%;
                `}
                {...getProperty("PremiumIntervalsPerYear", {
                  initialValue: null,
                  transformValue: (value) => (value === null ? 0 : value),
                  transformChange: (value) =>
                    value === 0 ? null : extractNumbers(value, 12),
                })}
              >
                <MenuItem value={0}>Default Company Kit</MenuItem>
                {Object.entries(intervalMap)?.map(([key, label]) => (
                  <MenuItem value={key}>{label}</MenuItem>
                ))}
              </Select>
            </Div>
            <Div
              css={css`
                margin: 32px 0;
              `}
            >
              <Text label bold>
                Enrollment
              </Text>
              <Div
                css={css`
                  ${flex("space-between")}
                  padding: 8px 0;
                  .shared-input {
                    width: 50%;
                  }
                `}
              >
                <Text label>Start Date</Text>
                <Input {...getDateProps("EnrollmentStartDate")} />
              </Div>
              <Div
                css={css`
                  ${flex("space-between")}
                  padding: 8px 0;
                  .shared-input {
                    width: 50%;
                  }
                `}
              >
                <Text label>End Date</Text>
                <Input {...getDateProps("EnrollmentEndDate")} />
              </Div>
            </Div>

            <Div
              css={css`
                margin: 32px 0;
              `}
            >
              <Text label bold>
                Plan
              </Text>
              <Div
                css={css`
                  ${flex("space-between")}
                  padding: 8px 0;
                  .shared-input {
                    width: 50%;
                  }
                `}
              >
                <Text label>Start Date</Text>
                <Input {...getDateProps("PlanStartDate")} />
              </Div>
              <Div
                css={css`
                  ${flex("space-between")}
                  padding: 8px 0;
                  .shared-input {
                    width: 50%;
                  }
                `}
              >
                <Text label>End Date</Text>
                <Input {...getDateProps("PlanEndDate")} />
              </Div>
            </Div>
          </Div>
        </Div>
        <Div
          css={css`
            ${flex("right")}
            width: 100%;
            padding: 16px;
          `}
        >
          <Button styles="secondary mr" onClick={closeModal}>
            Cancel
          </Button>
          <Button onClick={updatePackage} disabled={!hasChanges}>
            Save
          </Button>
        </Div>
      </Modal>

      <Modal display={modal === "duplicate-package"} onClose={closeModal}>
        <Div
          css={css`
            width: 500px;
            padding: ${px.xl};
          `}
        >
          <Div
            css={css`
              ${flex("space-between")}
            `}
          >
            <Text h2>Duplicate Package</Text>
            <Button styles="icon" onClick={closeModal}>
              <X />
            </Button>
          </Div>

          <Div
            css={css`
              margin: ${px.xl} 0;
            `}
          >
            <Text
              styles="label"
              className={css`
                margin: ${px.md} 0;
              `}
            >
              Which company would you like to to duplicate this{" "}
              {selectedPkg.Name} to?
            </Text>
            <CompanySelect
              css={`
                width: 100%;
              `}
              selectedBusiness={selectedCompany}
              selectBusiness={({ option }) => setSelectedCompany(option)}
              defaultBusinessList={companies}
            />
          </Div>

          <Div
            css={css`
              ${flex("right")}
            `}
          >
            <Button styles="secondary mr" onClick={closeModal}>
              Cancel
            </Button>
            <Button onClick={() => duplicateBenefitPackage(selectedPkg)}>
              Duplicate
            </Button>
          </Div>
        </Div>
      </Modal>
    </>
  ) : (
    <div className={emptyContainer}>
      <img src={EmptyState} alt="Empty State" />
      <Text h2 bold>
        Hey, Let’s Get Some Benefits Added to Brite
      </Text>
      <Text
        className={css`
          width: 700px;
        `}
      >
        When your benefits are in Brite you can generate digital benefit guides
        in seconds. The benefit information in these guides is dynamic, which
        means that any changes or new benefits you add will automatically appear
        in the guides. This will save you time and ensure that your guides are
        always up-to-date.
      </Text>
    </div>
  );
};
