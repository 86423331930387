import React from "react";
import { Modal } from "../shared/components";
import { BriteLoader } from "../shared/components/brite-loader";

export const Callback = () => {
  return (
    <Modal full display={true}>
      <BriteLoader isLoading={true} />
    </Modal>
  );
};

export default Callback;
