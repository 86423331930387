import React, { useState } from "react";
import { css } from "@emotion/css";
import { DateRangePicker } from "react-dates";
import { IconButton, Button } from "@material-ui/core";
import moment from "moment";
import { CustomAxios } from "../redux/axios/axios";
import { toast } from "react-toastify";
import { X } from "phosphor-react";
import { Modal } from "../shared/components";

const ExportModal = (props) => {
  const [startDate, setStartDate] = useState(
    moment
      .utc()
      .subtract(7, "day")
      .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
  );
  const [endDate, setEndDate] = useState(
    moment.utc().set({ hour: 23, minute: 59, second: 59, millisecond: 999 })
  );
  const [focusedInput, setFocusedInput] = useState(null);
  const [isExporting, setIsExporting] = useState(false);

  const exportData = async () => {
    setIsExporting(true);
    try {
      await CustomAxios.post(
        `/v1/analytics/v2/export?startDate=${startDate.format()}&endDate=${endDate.format()}`
      );
    } catch (e) {
      console.log("Error doing export", e);
      toast.error("Error starting export. Please try again.");
    } finally {
      setIsExporting(false);
    }
    props.onClose();
  };

  return (
    <Modal display={true} onClose={props.onClose}>
      <div
        className={css`
          width: 700px;
          padding: 32px;
          padding-top: 24px;
        `}
      >
        <div
          className={css`
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 12px;
          `}
        >
          <div
            className={css`
              color: var(--700, #25282d);
              font-feature-settings: "clig" off, "liga" off;

              /* H2 */
              font-family: Roboto;
              font-size: 24px;
              font-style: normal;
              font-weight: 700;
              line-height: 32px; /* 133.333% */
            `}
          >
            Export Analytics Report
          </div>
          <IconButton onClick={() => props.onClose()}>
            <X />
          </IconButton>
        </div>
        <div
          className={css`
            color: var(--Gray-600, #36414c);
            font-feature-settings: "clig" off, "liga" off;
            font-family: Roboto;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px; /* 150% */
            letter-spacing: 0.15px;
            margin-bottom: 16px;
          `}
        >
          Please select a date range for your report
        </div>
        <div>
          <div
            className={css`
              display: flex;
              justify-content: center;
              border-radius: 8px;
              border: 1px solid var(--Gray-300, #d1dae3);
            `}
          >
            <div
              className={css`
                width: 290px;

                & .CalendarDay__selected_span {
                  background: #f5f7fa;
                  color: #25282d;
                  border: 1px solid #f5f7fa;
                }

                & .CalendarDay__selected_span:hover {
                  background: #25282d;
                  color: white;
                  border: 1px solid #25282d;
                }

                & .CalendarDay__selected {
                  background: #25282d;
                  color: white;
                  border: 1px solid #25282d;
                }

                & .CalendarDay__selected:hover {
                  background: #25282d;
                  color: white;
                  border: 1px solid white;
                }

                & .DateInput_input__focused {
                  border-bottom: 2px solid #25282d;
                }

                & .DayPickerKeyboardShortcuts_show__bottomRight::before {
                  border-top: 26px solid transparent;
                  border-right: 33px solid #25282d;
                  bottom: 0;
                  right: 0;
                }

                & .CalendarDay__default:hover {
                  border: 1px solid #25282d;
                  color: #fff;
                  background: #25282d;
                }
              `}
            >
              <DateRangePicker
                block
                noBorder
                disabled={isExporting}
                isOutsideRange={() => false}
                startDate={startDate} // momentPropTypes.momentObj or null,
                startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
                endDate={endDate} // momentPropTypes.momentObj or null,
                endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
                onDatesChange={({ startDate, endDate }) => {
                  if (startDate) {
                    let start = startDate.set({
                      hour: 0,
                      minute: 0,
                      second: 0,
                      millisecond: 0,
                    });
                    setStartDate(start);
                  }

                  if (endDate) {
                    let end = endDate.set({
                      hour: 23,
                      minute: 59,
                      second: 59,
                      millisecond: 999,
                    });
                    setEndDate(end);
                  }
                }} // PropTypes.func.isRequired,
                focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                onFocusChange={setFocusedInput}
              />
            </div>
          </div>

          <div
            className={css`
              color: var(--Gray-500, #66737f);
              font-feature-settings: "clig" off, "liga" off;

              /* Caption */
              font-family: Roboto;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 24px; /* 171.429% */
              letter-spacing: 0.15px;
              margin-top: 24px;
              margin-bottom: 24px;
            `}
          >
            Multiple reports will be exported and added to your documents tab
            for this company. This could take several minutes.
          </div>

          <div
            className={css`
              display: flex;
              justify-content: flex-end;
              gap: 16px;
              margin: 8px;
            `}
          >
            <Button
              variant="outlined"
              disabled={isExporting}
              onClick={props.onClose}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              disabled={isExporting}
              onClick={exportData}
            >
              Export
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ExportModal;
