import React, { useState, useEffect } from "react";
import { css, cx } from "@emotion/css";
import {
  Cards,
  Trash,
  WarningCircle,
  CopySimple,
  PlusCircle,
  StarFour,
  X,
} from "phosphor-react";
import { useMemo } from "react";
import { CarrierLogo } from "../BriteEditor/editor-components";
import { history } from "../history";
import {
  benefitsPackage,
  benefitsPackageProductsList,
  useQueryAPI,
  useRequests,
  useResource,
} from "../react-query";
import { Button, Div, Loader, NewTag, Text } from "../shared/components";
import {
  animation,
  container,
  flex,
  px,
  shadows,
} from "../shared/shared-styles";
import { colors, mediaQueryFn } from "../shared/styles";
import NoHeaderImage from "../Courses/no-header-image.png";
import { CustomAxios } from "../redux/axios/axios";
import { CircularProgress, Tooltip } from "@material-ui/core";
import { categorizeProducts, mutliNetworkCategoryMap } from "./utils";
import { useParams } from "react-router";
import { v4 as uuidv4 } from "uuid";
import { getProductNeedsAttention } from "../products/configs/form.config";
import { BriteLoader } from "../shared/components/brite-loader";
import { ACLWrapper } from "../shared/components/acl-wrapper";
import { FlagsBadge } from "../products/flags/flags-badge";
import { aiSuggestions } from "../react-query/resources/ai-suggestions";
import { needsAttention } from "../react-query/resources/needs-attention";
import { useFeatureFlagPayload } from "posthog-js/react";
import { PRODUCT_HEADERS } from "./constants";
import { Box } from "../shared/components/box";

export const titleMap = {
  medical: "Medical Plans",
  "pre-tax": "Spending Accounts",
  core: "Core Benefits",
  additional: "Additional Benefits",
  custom: "Custom Benefits",
};

const transformNeedsAttention = (data) => {
  return data?.reduce(
    (prev, item) => ({
      ...prev,
      [item?.resource_id]: [...(prev[item?.resource_id] || []), item],
    }),
    {}
  );
};

const mappingLoaderContainer = css`
  ${flex("left")}
  svg {
    margin-right: 32px;
  }
  p {
    margin-right: 16px;
  }
  background-color: ${colors.black};
  border-radius: 8px;
  padding: 24px 32px;
  margin-bottom: 32px;
`;

const animatedSkeletonLoader = (index) => css`
  background: linear-gradient(
    270deg,
    rgba(192, 81, 148, 0.2) 0%,
    rgba(169, 73, 245, 0.2) 50%,
    rgba(98, 167, 229, 0.2) 100%
  );
  background-size: 200% 100%;

  @keyframes Animation {
    0% {
      background-position: 0 0;
    }
    40% {
      background-position: 0 0;
    }
    70% {
      background-position: 100% 0;
    }
  }

  animation: Animation 2.5s linear infinite;
  background-repeat: no-repeat;
  animation-delay: ${index * 0.75}s;
  border-radius: 8px;
  height: 94px;
  margin-bottom: 16px;
`;

export const ProductList = ({
  productListResource,
  packageId,
  businessId,
  selectProduct,
  displayCourses = true,
}) => {
  const { data, refetch, isLoading } = productListResource?.query;
  const productListRequests = useRequests(productListResource);

  const { courseId } = useParams();
  const aiAutofill = useFeatureFlagPayload("ai-autofill");

  const productIdsPostgrest = data
    ?.map((product) => `${product?.ID}`)
    ?.join(",");

  const aiSuggestionsResource = useResource(aiSuggestions, {
    select: (data) => {
      return data?.reduce(
        (prev, item) => ({
          ...prev,
          [item?.product_id]: [...(prev?.[item?.product_id] || []), item],
        }),
        {}
      );
    },
    retry: 0,
    search: {
      status: "eq.pending",
      product_id: `in.(${productIdsPostgrest})`,
    },
    enabled: !!productIdsPostgrest,
  });

  const hasBeenReviewed = useMemo(() => {
    return productListResource?.query?.data?.some(
      (product) => !!product?.ReviewedAt
    );
  }, [productListResource?.query?.dataUpdatedAt]);

  const [refetchInterval, setRefetchInterval] = useState([0, 0]);

  const benefitsPackageResource = useResource(benefitsPackage, {
    refetchInterval: refetchInterval[1],
    retry: 1,
  });
  const { data: pkg } = benefitsPackageResource?.query;

  const updateRefetchInterval = () => {
    if (
      !benefitsPackageResource?.query?.data?.MappingStatus ||
      !hasBeenReviewed
    ) {
      // if MappingStatus is empty most likely the mapping is already done
      // but we landed on the page earlier than the backend could update it.
      // Otherwise the user will be stuck on an eternal loader.
      setRefetchInterval(([count]) => [
        Math.min(count + 1, 9),
        1000 * count + 1000,
      ]);
    } else if (
      benefitsPackageResource?.query?.data?.MappingStatus === "in progress"
    ) {
      setRefetchInterval(() => [0, 3000]);
    } else {
      setRefetchInterval(() => [0, 0]);
    }
  };

  useEffect(() => {
    updateRefetchInterval(benefitsPackageResource);
  }, [benefitsPackageResource?.query?.dataUpdatedAt, hasBeenReviewed]);

  useEffect(() => {
    refetch();
  }, [benefitsPackageResource?.query?.data?.ProductCount]);

  const needsAttentionResource = useResource(needsAttention, {
    search: {
      business_id: `eq.${businessId}`,
      resolved: "is.null",
      resource_type: "eq.product",
      resource_id: `in.(${productIdsPostgrest})`,
    },
    enabled: !!businessId && !!productIdsPostgrest,
    retry: 0,
    select: transformNeedsAttention,
  });

  const { data: courses, isLoading: isLoadingCourses } = useQueryAPI({
    url: `v1/benefitspackage/${packageId}/course`,
    select: ({ Data }) => Data,
    enabled: !!packageId,
    retry: 1,
  });

  const { multiNetwork, ...categorizedProducts } = useMemo(() => {
    return categorizeProducts(data);
  }, [data]);

  const getProductName = (key, product) => {
    return (
      product.ProductName ||
      (product.Type === "supplimental_life"
        ? "Life Insurance (Basic/Supplemental)"
        : product.Title) ||
      product.Name ||
      (key === "medical" ? "Medical" : PRODUCT_HEADERS[product?.Type])
    );
  };

  const reviewProduct = async (e, product) => {
    e?.stopPropagation();
    const putProduct = benefitsPackageProductsList?.utils?.getPutProduct(
      product?.ID,
      {
        ...product,
        ReviewedAt: new Date().toISOString(),
      }
    );
    await productListRequests?.put(putProduct);
  };

  const deleteProduct = async (product) => {
    const { ID, MultiNetworkID = "" } = product;
    let products = [product];
    if (MultiNetworkID) {
      products = data?.filter(
        (item) => item?.MultiNetworkID === MultiNetworkID
      );
    }
    try {
      const nextData = data?.map((item) => {
        if (item.ID === ID) {
          return { ...item, ID: "" };
        }
        return item;
      });
      productListResource?.setData(nextData);
      await Promise.allSettled(
        products.map(({ ID }) => {
          return CustomAxios.delete(
            `/v1/benefitspackage/${packageId}/product/${ID}`
          );
        })
      );
    } catch (err) {
      console.warn(err);
    } finally {
      refetch();
    }
  };

  const duplicateProduct = async (product) => {
    const { MultiNetworkID = "" } = product;
    let newMultiId = "";
    let products = [product];
    if (MultiNetworkID) {
      products = data?.filter(
        (item) => item?.MultiNetworkID === MultiNetworkID
      );
      newMultiId = uuidv4();
    }
    try {
      await Promise.allSettled(
        products.map(({ ID }) => {
          return CustomAxios.post(
            `/v1/benefitspackage/${packageId}/product/${ID}/copies${
              newMultiId ? `?multi-network-id=${newMultiId}` : ""
            }`
          );
        })
      );
    } catch (err) {
      console.warn(err);
    } finally {
      refetch();
    }
  };

  const needsAttentionList = useMemo(() => {
    return data?.reduce((prev, product) => {
      const needsAttention = getProductNeedsAttention(product);
      const id = product?.MultiNetworkID || product?.ID;
      if (!(id in prev) || prev[id] === false) {
        return {
          ...prev,
          [id]: needsAttention,
        };
      }
      return prev;
    }, {});
  }, [data]);

  const courseBuilderPath = (course) =>
    `/${businessId}/courses/${course.ID}/builder`;

  return (
    <Div
      css={css`
        width: 100%;
        margin-top: ${px.xl};
      `}
    >
      {pkg?.MappingStatus === "in progress" && aiAutofill?.value ? (
        <>
          <div className={mappingLoaderContainer}>
            <BriteLoader
              overlay={false}
              size={32}
              weight="10px"
              color="white"
            />
            <Text
              h4
              css={`
                color: white;
              `}
            >
              Searching Documents for Plan Details
            </Text>
            <Text label white>
              Hopefully this doesn't take too long
            </Text>
          </div>
          {[0, 1, 2].map((index) => (
            <div className={animatedSkeletonLoader(index)} />
          ))}
        </>
      ) : (
        <Div
          css={css`
            position: relative;
            width: 100%;
            ${mediaQueryFn("greater-than", "1400px")} {
              ${flex("space-between stretch")}
            }
          `}
        >
          <BriteLoader
            isLoading={isLoading}
            css={`
              height: calc(100vh - 180px);
              padding-bottom: 35vh;
            `}
          >
            <Text
              h2
              css={`
                margin: 32px 0;
              `}
            >
              Fetching Package...
            </Text>
          </BriteLoader>
          {!!data?.length ? (
            <Div
              css={css`
                ${flex("left grow start")}
              `}
            >
              <Div
                css={css`
                  width: 100%;
                  padding-bottom: 32px;
                `}
              >
                {Object.entries(categorizedProducts).map(
                  ([key, products]) =>
                    !!products?.length && (
                      <Div key={key}>
                        <Text h2>{titleMap[key]}</Text>
                        {products?.map((product) => {
                          const isMappingProduct =
                            aiSuggestionsResource?.query?.data?.[product?.ID]
                              ?.length;
                          return (
                            <Div
                              css={css`
                                ${flex("space-between")}
                                ${container.box}
                                ${container.hoverOutline}
                                padding: ${px.lg} ${px.xl};
                                ${needsAttentionList?.[
                                  product?.MultiNetworkID || product?.ID
                                ] && "padding-left: 16px;"} margin: ${px.xl} 0;
                                transition: 0.2s opacity ease;
                              `}
                              onClick={() => selectProduct(product)}
                              key={product?.ID + "attention"}
                            >
                              <NewTag
                                createdAt={product?.CreatedAt}
                                className={css`
                                  margin-left: -8px;
                                  margin-right: 16px;
                                `}
                              />
                              {needsAttentionList?.[
                                product?.MultiNetworkID || product?.ID
                              ] && (
                                <WarningCircle
                                  color={colors.red[100]}
                                  className={css`
                                    min-width: 32px;
                                    margin-right: 16px;
                                  `}
                                />
                              )}
                              <Div
                                css={css`
                                  flex-grow: 1;
                                `}
                              >
                                <Text h3 wrap>
                                  {getProductName(key, product)}
                                </Text>
                                <Text uppercase>
                                  {product?.Details?.PlanType || product?.Title}
                                </Text>
                              </Div>

                              {aiAutofill?.value &&
                              ((product?.ModifiedAt === product?.CreatedAt &&
                                !!product?.ProductName) ||
                                !product?.ReviewedAt) ? (
                                <div
                                  className={cx(
                                    "test",
                                    css`
                                      min-width: max-content;
                                      background-color: ${colors.gray[200]};
                                      :hover {
                                        ${shadows.sm}
                                      }
                                      padding: 4px 8px;
                                      border-radius: 30px;
                                      ${flex("left")}
                                      margin-right: 16px;
                                    `
                                  )}
                                >
                                  <StarFour
                                    size={16}
                                    color={colors.purple}
                                    weight="fill"
                                    className={css`
                                      margin-right: 8px;
                                    `}
                                  />
                                  <Text label bold>
                                    Ready for Review
                                  </Text>
                                  <X
                                    color={colors.gray[500]}
                                    className={css`
                                      margin-left: 8px;
                                    `}
                                    size={16}
                                    onClick={(e) => reviewProduct(e, product)}
                                  />
                                </div>
                              ) : (
                                <>
                                  {product?.Details?.IsMultiCarrier && (
                                    <Div
                                      css={css`
                                        width: 250px;
                                        ${mediaQueryFn("less-than", "1000px")} {
                                          display: none;
                                        }
                                      `}
                                    >
                                      <Text styles="label bold ml-sm mb-sm">
                                        Multiple Carriers
                                      </Text>
                                    </Div>
                                  )}

                                  {product?.MultiNetworkID && (
                                    <Div
                                      css={css`
                                        width: 250px;
                                        ${mediaQueryFn("less-than", "1000px")} {
                                          display: none;
                                        }
                                      `}
                                    >
                                      <Text styles="label bold ml-sm mb-sm">
                                        Networks
                                      </Text>
                                      <Div
                                        css={css`
                                          ${flex("left wrap")}
                                        `}
                                      >
                                        {Object.entries(
                                          multiNetwork || {}
                                        )?.map(([key, value]) =>
                                          value?.[product?.MultiNetworkID]?.map(
                                            (item) => (
                                              <Tooltip
                                                title={
                                                  mutliNetworkCategoryMap[key]
                                                }
                                                key={item}
                                              >
                                                <Text
                                                  ellipsis
                                                  className={css`
                                                    width: max-content;
                                                    display: inline;
                                                    font-size: 0.9em;
                                                    margin: 1px;
                                                    padding: 0 8px;
                                                    border-radius: 16px;
                                                    border: 1px solid
                                                      ${colors.gray[200]};
                                                  `}
                                                >
                                                  {item}
                                                </Text>
                                              </Tooltip>
                                            )
                                          )
                                        )}
                                      </Div>
                                    </Div>
                                  )}

                                  <FlagsBadge
                                    flags={
                                      needsAttentionResource?.query?.data?.[
                                        product?.ID
                                      ]
                                    }
                                  />
                                </>
                              )}

                              <Div
                                css={css`
                                  ${flex("right")} width: 260px;
                                  ${mediaQueryFn("less-than", "1200px")} {
                                    width: 180px;
                                  }
                                `}
                                onClick={(e) => e.stopPropagation()}
                              >
                                {product?.IsMultiCarrier ? (
                                  <div
                                    className={css`
                                      display: flex;
                                      align-items: center;
                                      gap: 16px;
                                      justify-content: space-between;
                                    `}
                                  >
                                    <CarrierLogo
                                      maxHeight="80px"
                                      maxWidth="200px"
                                      position="center right"
                                      carrierID={product?.ProviderID}
                                      planCarrierName={product?.ProviderName}
                                    />
                                    <div
                                      className={css`
                                        border-left: 1px solid #26282d;
                                        height: 50px;
                                      `}
                                    ></div>
                                    <CarrierLogo
                                      carrierID={
                                        product?.Details?.MultiCarrierID ||
                                        "00000000-0000-0000-0000-000000000000"
                                      }
                                      planCarrierName={
                                        product?.Details?.MultiCarrierName || ""
                                      }
                                      maxWidth="200px"
                                      maxHeight="80px"
                                      position="center"
                                    />
                                  </div>
                                ) : product?.ProviderID &&
                                  product?.ProviderID !==
                                    "00000000-0000-0000-0000-000000000000" ? (
                                  <CarrierLogo
                                    maxHeight="80px"
                                    maxWidth="200px"
                                    position="center right"
                                    carrierID={product?.ProviderID}
                                    planCarrierName={product?.ProviderName}
                                  />
                                ) : (
                                  <Box
                                    css={`
                                      height: 80px;
                                    `}
                                  />
                                )}

                                {product?.ID ? (
                                  isMappingProduct && aiAutofill?.value ? (
                                    <StarFour
                                      color={colors.purple}
                                      weight="fill"
                                      className={css`
                                        opacity: 1 !important;
                                      `}
                                    />
                                  ) : (
                                    <ACLWrapper
                                      acls={["write:benefits_package"]}
                                      resourceId={packageId}
                                    >
                                      <>
                                        <Tooltip
                                          title="Duplicate"
                                          placement="top"
                                        >
                                          <Button
                                            styles="icon"
                                            className={css`
                                              margin-left: ${px.lg};
                                            `}
                                            onClick={() =>
                                              duplicateProduct(product)
                                            }
                                          >
                                            <CopySimple />
                                          </Button>
                                        </Tooltip>{" "}
                                        <Tooltip title="Delete" placement="top">
                                          <Button
                                            styles="icon"
                                            className={css`
                                              margin-left: ${px.sm};
                                            `}
                                            onClick={() =>
                                              deleteProduct(product)
                                            }
                                          >
                                            <Trash />
                                          </Button>
                                        </Tooltip>
                                      </>
                                    </ACLWrapper>
                                  )
                                ) : (
                                  <Div
                                    css={css`
                                      width: 40px;
                                      height: 40px;
                                      margin-left: ${px.lg};
                                    `}
                                  >
                                    <CircularProgress />
                                  </Div>
                                )}
                              </Div>
                            </Div>
                          );
                        })}
                      </Div>
                    )
                )}
              </Div>
            </Div>
          ) : (
            <Div
              css={css`
                ${flex("center column")} width: 100%;
                border: 1px solid ${colors.gray[300]};
                ${container.hover}
                padding: 32px 16px;
                border-radius: 8px;
                svg {
                  margin-bottom: 8px;
                }
              `}
              onClick={() =>
                history.push(
                  `/${businessId}/packages/${packageId}/benefit-type`
                )
              }
            >
              <PlusCircle />
              <Text label>Add a New Benefit</Text>
            </Div>
          )}
          {!isLoadingCourses && !!courses?.length && displayCourses && (
            <Div
              className={css`
                transition: width 0.2s ease;
                position: relative;
                ${flex("aic column")}
                width: 370px;
                margin-left: ${px.xl};
                ${animation("fadeIn", ".2s ease")}
                ${mediaQueryFn("greater-than", "1400px")} {
                  height: 100%;
                  max-height: calc(100vh - 64px);
                  position: sticky;
                  top: 132px;
                }
                ${mediaQueryFn("less-than", "1400px")} {
                  width: 100%;
                  display: block;
                  margin-left: 0;
                }
              `}
            >
              <Loader type="icon" isLoading={isLoading} />
              <Div
                css={css`
                  ${flex("aic")} width: 100%;
                  margin-bottom: ${px.xl};
                  margin-left: ${px.lg};
                `}
              >
                <Cards className="bounce" />
                <Text
                  styles="h2"
                  className={css`
                    margin-left: ${px.md};
                  `}
                >
                  Attached Guides
                </Text>
              </Div>
              <Div
                css={css`
                  height: 100%;
                  overflow: auto;
                  width: 100%;
                  ${mediaQueryFn("less-than", "1400px")} {
                    ${flex("aic wrap")}
                  }
                  background-color: ${colors.gray[100]};
                  border-radius: ${px.lg};
                  padding: ${px.md};
                `}
              >
                {courses?.map((course) => (
                  <Div
                    css={css`
                      ${container.box}
                      max-width: 320px;
                      margin: ${px.lg};
                      ${mediaQueryFn("less-than", "1400px")} {
                        margin: ${px.lg};
                      }
                      ${course.ID === courseId
                        ? `border: 2px solid black;`
                        : `:hover { background-color: ${colors.gray[100]}; img { opacity: .5; } }; cursor: pointer;`}
                    `}
                    key={course.ID}
                    onClick={() => history.push(courseBuilderPath(course))}
                    disabled={course.ID === courseId}
                  >
                    <Div
                      className={css`
                        ${flex("jcc aic")}
                        height: 208px;
                      `}
                    >
                      {!course.LogoURL ? (
                        <img
                          alt="Empty Carrier Logo"
                          className={css`
                            width: 300px;
                            height: 160px;
                          `}
                          src={NoHeaderImage}
                        />
                      ) : (
                        <img
                          className={css`
                            max-height: 80px;
                            max-width: 250px;
                          `}
                          alt="Carrier Logo"
                          src={course.LogoURL}
                        />
                      )}
                    </Div>
                    <Div
                      className={css`
                        border-top: 1px solid #d1dae3;
                        margin-top: 8px;
                        padding: 16px 8px;
                      `}
                    >
                      <Text styles="h4 bold">{course.Name}</Text>
                    </Div>
                  </Div>
                ))}
              </Div>
            </Div>
          )}
        </Div>
      )}
    </Div>
  );
};
