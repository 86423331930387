import { css } from "@emotion/css";
import React, { forwardRef } from "react";
import { animation, flex } from "../shared-styles";
import { X } from "phosphor-react";
import { Button } from "./button";
import { Text } from "./text";
import { Box } from "./box";
import { Portal } from "./Portal";

export const Modal = forwardRef(
  (
    {
      display,
      onClose,
      children,
      css: cssString = "",
      full = false,
      zIndex = "1000",
      ...rest
    },
    ref
  ) => {
    if (!display) {
      return null;
    }

    return (
      <Portal>
        <Box
          ref={ref}
          onClick={(e) => {
            e.stopPropagation();
            onClose?.();
          }}
          full={full}
          {...rest}
          css={`
            position: fixed;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: ${zIndex};
            background-color: rgba(0, 0, 0, 0.4);
            ${animation("fadeIn", ".2s ease")}
          `}
          flex="center"
        >
          <Box
            css={`
              background-color: white;
              ${full
                ? `
                height: 100vh;
                width: 100vw;
            `
                : `border-radius: 16px;`}
              ${cssString}
            `}
            onClick={(e) => e.stopPropagation()}
          >
            {children}
          </Box>
        </Box>
      </Portal>
    );
  }
);

Modal.Paper = ({ width = "500px", children, css: cssString }) => (
  <div
    className={css`
      width: ${width};
      padding: 32px;
      ${cssString}
    `}
  >
    {children}
  </div>
);

Modal.Header = ({ title, onClose, css: cssString }) => {
  const headerTitle =
    typeof title === "string" ? <Text h2>{title}</Text> : title;
  return (
    <div
      className={css`
        width: 100%;
        padding-bottom: 8px;
        ${flex("space-between")}
        ${cssString}
      `}
    >
      {headerTitle}
      <Button styles="icon" onClick={onClose}>
        <X />
      </Button>
    </div>
  );
};

Modal.Body = ({ children, css: cssString }) => (
  <div
    className={css`
      width: 100%;
      padding: 8px 0;
      ${cssString}
    `}
  >
    {children}
  </div>
);

Modal.Actions = ({ children, css: cssString }) => (
  <div
    className={css`
      width: 100%;
      padding-top: 16px;
      ${flex("right")}
      button {
        margin-left: 16px;
      }
      ${cssString}
    `}
  >
    {children}
  </div>
);
