import { useState } from "react";
import { MenuItem, Tooltip } from "@material-ui/core";
import { css } from "@emotion/css";
import { DotsThree, MagnifyingGlass, Users, X } from "phosphor-react";
import { toast } from "react-toastify";
import { CustomAxios } from "../redux/axios/axios";
import {
  Button,
  Div,
  DropMenu,
  Input,
  Loader,
  Modal,
  Text,
} from "../shared/components";
import { flex, shadows } from "../shared/shared-styles";
import { colors } from "../shared/styles";
import { UnlayerPreview } from "./unlayer-preview";
import { useStateSync } from "../shared/use-state-sync";
import { MemoizedIFrame } from "./memoized-iframe";
import { TemplateModal } from "./template-modal";
import { history } from "../history";
import { useStore } from "../store-provider/use-store";
import { useTemplates } from "./use-templates";
import { TemplateFilters } from "./template-filters";
import { BriteLoader } from "../shared/components/brite-loader";
import { useAcls } from "../shared/use-acls";
import { WRITE_COURSE } from "../shared/acl-constants";
import { Box } from "../shared/components/box";
import { useModalWithData } from "../common/hooks/useModalWithData";
import { ShareResourceModal } from "../shared/components/ShareResourceModal";

export const thumbnailWidth = 800;

export const Templates = () => {
  const shareModal = useModalWithData();

  const {
    data: { parentBusiness, selectedBusiness },
  } = useStore();
  const businessId = selectedBusiness?.ID;
  const parentBusinessId = selectedBusiness?.ParentID || parentBusiness?.ID;

  const templateProps = useTemplates({
    businessId,
    parentBusinessId,
  });
  const { paginatedQuery } = templateProps;

  const {
    paginatedList: templates,
    isLoading,
    isFetching,
    refetch,
    hasMore,
    fetchNext,
  } = paginatedQuery;

  const [isDeleting, setIsDeleting] = useState(false);
  const [modalItems, setModalItems] = useState([]); // MODAL = ['modal-type', templateId]

  const canEditTemplates = useAcls([WRITE_COURSE]);

  const [selectedTemplate] = useStateSync(() => {
    if (!modalItems.length) {
      return null;
    }
    const [, id] = modalItems;
    return templates.find(({ ID }) => ID === id) || null;
  }, [modalItems.length]);

  const updateTemplate = async (changes, oldValues) => {
    try {
      await CustomAxios.put(`v2/templates/${changes?.id}`, {
        ...oldValues,
        BusinessID: changes?.businessId,
        ShareScope: changes?.shareScope,
      });
    } catch (err) {
      console.warn(err);
      toast.error(`Error updating template.`);
    } finally {
      refetch();
    }
  };

  const deleteTemplate = async (id) => {
    try {
      setIsDeleting(true);
      await CustomAxios.delete(`/v1/templates/${id}`);
      setModalItems([]);
    } catch (err) {
      console.warn(err);
      toast.error("Failed to delete template.");
    } finally {
      setIsDeleting(false);
      refetch();
    }
  };

  const detectBottomScroll = (event) => {
    const parentScrollHeight = event.target.parentElement.scrollHeight;
    const scrolledBottom =
      event.target.scrollTop + parentScrollHeight >
      event.target.scrollHeight - 150;
    if (scrolledBottom && hasMore && !isFetching) {
      fetchNext();
    }
  };

  return (
    <Box page>
      <Div
        css={css`
          width: 100%;
          ${flex("space-between")}
        `}
      >
        <Text h1>Templates</Text>
        <Input
          styles="search"
          placeholder="Search"
          value={templateProps.search}
          onChange={(e) => templateProps.handlers.setSearch(e.target.value)}
          startIcon={<MagnifyingGlass color={colors.gray[500]} />}
          css={`
            padding-left: 8px;
            min-width: 300px;
          `}
        />
      </Div>

      <TemplateFilters
        {...templateProps}
        businessId={businessId}
        parentBusinessId={parentBusinessId}
      />

      <Div>
        <Div
          css={css`
            position: relative;
            ${flex("left wrap")} padding: 16px 0;
            max-height: calc(100vh - 280px);
            overflow-y: auto;
            padding-bottom: 100px;
          `}
          onScroll={detectBottomScroll}
        >
          {(templateProps.tab !== "carrier-templates" ||
            !!templateProps.carrierId ||
            templateProps.search) &&
            templates?.map((item) => (
              <Div
                css={css`
                  margin: 8px 0;
                  margin-right: 32px;
                  margin-left: 2px;
                `}
                key={item?.ID}
              >
                <Div
                  css={css`
                    position: relative;
                    ${shadows.md} background-color: white;
                    margin-bottom: 8px;
                    border-radius: 8px;
                    width: 320px;
                    height: 200px;
                    overflow: hidden;
                    :hover {
                      outline: 2px solid ${colors.gray[400]};
                    }
                  `}
                  onClick={() => setModalItems(["view", item?.ID])}
                >
                  {item?.Type === "regular" && (
                    <UnlayerPreview content={item?.Content} />
                  )}

                  {item.Type === "brite-editor" && (
                    <Div
                      css={css`
                        ${flex("center start")}
                        position: relative;
                        width: 320px;
                        height: 200px;
                        overflow: hidden;
                      `}
                    >
                      <MemoizedIFrame
                        loading="lazy"
                        className={css`
                          background-color: white;
                          border: none;
                          outline: none;
                          position: absolute;
                          top: 0;
                          bottom: 0;
                          right: 0;
                          left: 0;
                          width: 816px;
                          height: 100vh;
                          transform-origin: 0 0;
                          transform: scale(${320 / thumbnailWidth});
                          z-index: 10;
                        `}
                        src={`https://britehr.app/brite-template-preview/${item?.ID}?lt=preview`}
                      />
                      <Div
                        css={css`
                          position: absolute;
                          top: 0;
                          bottom: 0;
                          left: 0;
                          right: 0;
                          z-index: 5000;
                          cursor: pointer;
                          background-color: transparent;
                        `}
                      />
                    </Div>
                  )}
                </Div>
                <Div
                  css={css`
                    ${flex("space-between")} height: 48px;
                    width: 320px;
                  `}
                >
                  <Text
                    h4
                    ellipsis
                    className={css`
                      flex-grow: 1;
                    `}
                  >
                    {item.Name}
                  </Text>
                  <Div
                    css={css`
                      ${flex("right")}
                    `}
                  >
                    {item?.ShareScope !== "business" && (
                      <Tooltip title="Public">
                        <Users size={24} />
                      </Tooltip>
                    )}
                    {businessId === item?.BusinessID && canEditTemplates && (
                      <DropMenu
                        button={
                          <Button styles="icon">
                            <DotsThree />
                          </Button>
                        }
                      >
                        <MenuItem
                          style={{ padding: "8px 24px", fontWeight: "bold" }}
                          onClick={() =>
                            history.push(`/${businessId}/templates/${item?.ID}`)
                          }
                        >
                          Edit Original
                        </MenuItem>
                        <MenuItem
                          style={{ padding: "8px 24px", fontWeight: "bold" }}
                          onClick={() => shareModal.show(item)}
                        >
                          Share
                        </MenuItem>
                        <MenuItem
                          style={{
                            padding: "8px 24px",
                            color: colors.red[100],
                            fontWeight: "bold",
                          }}
                          onClick={() => setModalItems(["delete", item?.ID])}
                        >
                          Delete
                        </MenuItem>
                      </DropMenu>
                    )}
                  </Div>
                </Div>
              </Div>
            ))}

          <BriteLoader
            type="icon"
            isLoading={isLoading}
            css={`
              height: 100%;
              max-height: calc(100vh - 200px);
            `}
          />

          {!isLoading && !isFetching && !templates.length ? (
            <Div
              css={css`
                width: 100%;
                ${flex("center")}
              `}
            >
              <Text h4>
                Looks like we couldn't find any matching templates.
              </Text>
            </Div>
          ) : null}
        </Div>
      </Div>

      {shareModal?.isOpen ? (
        <ShareResourceModal
          modalProps={shareModal}
          title={`Share "${shareModal?.data?.Name}"`}
          onSubmit={updateTemplate}
          getFormValues={(data) => ({
            id: data?.ID,
            name: data?.Name,
            shareScope: data?.ShareScope,
            businessId: data?.BusinessID,
          })}
        />
      ) : null}

      {modalItems?.[0] === "delete" && selectedTemplate !== null && (
        <Modal
          display={modalItems?.[0] === "delete" && selectedTemplate !== null}
          onClose={() => setModalItems([])}
        >
          <Div
            css={css`
              width: 500px;
            `}
          >
            <Div
              css={css`
                ${flex("space-between")} padding: 32px;
              `}
            >
              <Text h4>Delete "{selectedTemplate?.Name}"?</Text>
              <Button styles="icon" onClick={() => setModalItems([])}>
                <X />
              </Button>
            </Div>
            <Div
              css={css`
                padding: 0 32px;
              `}
            >
              <Text>
                Are you sure you want to permanently delete this template?
              </Text>
            </Div>
            <Div
              css={css`
                ${flex("right")} padding: 32px;
              `}
            >
              <Button
                secondary
                css={`
                  margin-right: 16px;
                `}
                onClick={() => setModalItems([])}
              >
                Cancel
              </Button>
              <Button
                css={`
                  position: relative;
                  background-color: ${colors.red[100]};
                `}
                onClick={() => deleteTemplate(selectedTemplate?.ID)}
                disabled={isDeleting}
              >
                <Loader
                  type="three-dots"
                  isLoading={isDeleting}
                  className={css`
                    ${flex("center start")} background-color: ${colors
                      .gray[300]};
                  `}
                />
                Delete
              </Button>
            </Div>
          </Div>
        </Modal>
      )}

      <TemplateModal
        display={modalItems?.[0] === "view" && selectedTemplate !== null}
        onClose={() => setModalItems([])}
        template={selectedTemplate}
        businessId={businessId}
        setModalItems={setModalItems}
      />
    </Box>
  );
};
