import { css } from "@emotion/css";
import { cloneDeep, get } from "lodash";
import { defaultComponents } from "./constants";
import * as uuid from "uuid";

export const formatString = (str, replace = "-") => {
  return str
    .split("")
    .map((letter, idx) => {
      return !/^\d+$/.test(letter) && letter.toUpperCase() === letter
        ? `${idx !== 0 ? replace : ""}${letter.toLowerCase()}`
        : letter;
    })
    .join("");
};

export const buildCss = (
  styleObject,
  appliedStyles = "",
  returnEmotion = false
) => {
  const value = Object.entries(styleObject || {}).reduce((p, [k, v]) => {
    const kebabKey = formatString(k);
    return p + ` ${kebabKey}:${v};`;
  }, appliedStyles);
  return returnEmotion
    ? css`
        ${value}
      `
    : value;
};

export const buildTextStyles = (textStyles) => {
  const appliedStyles = `width: 100%; `;
  return Object.entries(textStyles).reduce((prev, [key, value]) => {
    const kebabClass = formatString(key);
    const styles = buildCss(value, appliedStyles);
    return prev + ` ._brite-text-${kebabClass} { ${styles} }`;
  }, "");
};

function rKeys(o, path = "") {
  if (!o || typeof o !== "object") return path;
  return Object.keys(o).map((key) =>
    rKeys(o[key], path ? [path, key].join(".") : key)
  );
}
export const getObjectPaths = (o) => {
  return rKeys(o).toString().split(",");
};

export const buildTagPaths = (plans) => {
  return plans.reduce((prev, item) => {
    const paths = getObjectPaths(item).reduce(
      (p, path) => ({ ...p, [path]: get(item, path, null) }),
      {}
    );
    const type =
      item.Type === "default" || item.Type === "core" ? "medical" : item.Type;
    return { ...prev, [item.ID]: { type, paths } };
  }, {});
};

export const getComponent = (type) => {
  const data = cloneDeep(defaultComponents[type]);
  return { ...data, id: uuid.v4() };
};

export const generateColorsObject = (styles) => {
  const textColors = Object.entries(styles?.text || {}).reduce(
    (prev, [key, value]) => [
      ...prev,
      { key, value: value?.color, name: `--brite-text-${key}` },
    ],
    []
  );

  const accentIdx = styles?.general?.colorPickerPresets.findIndex(
    (item) => item === styles?.general?.accentColor
  );
  const accentColors =
    styles?.general?.colorPickerPresets?.length && accentIdx > -1
      ? styles?.general?.colorPickerPresets?.slice(accentIdx, accentIdx + 5)
      : [];

  const accentColorsList = accentColors.reduce(
    (prev, item, idx) => [
      ...prev,
      { key: `accent-${idx}`, value: item, name: `--brite-accent-${idx}` },
    ],
    []
  );

  const colors = [
    ...textColors,
    ...accentColorsList,
    {
      key: "background-color",
      value: styles?.general?.backgroundColor,
      name: "--brite-background-color",
    },
  ];

  return colors;
};
