import { useState } from "react";
import { css } from "@emotion/css";
import { SquaresFour, Trash, UploadSimple, X } from "phosphor-react";
import { Button, Div, Input, Modal, Text } from "../../shared/components";
import { colors } from "../../shared/styles";
import { ImagesModal, UploadImage } from "../toolbar-menus";
import { useStateSync } from "../../shared/use-state-sync";
import { container, flex, px } from "../../shared/shared-styles";

export const BackgroundImage = ({ root, setRoot, ...modalProps }) => {
  const [modal, setModal] = useState("");
  const [src, setSrc, isSrcSynced] = useStateSync(
    root?.backgroundImageUrl || "",
    [root?.backgroundImageUrl]
  );
  const [alt, setAlt, isAltSynced] = useStateSync(
    root?.backgroundImageAlt || "",
    [root?.backgroundImageAlt]
  );

  const hasChanges = !isSrcSynced || !isAltSynced;

  const updateImage = (type, attributes) => {
    setSrc(attributes.src);
    setAlt(attributes.alt);
  };

  const removeImage = () => {
    setSrc("");
    setAlt("");
  };

  const saveChanges = () => {
    setRoot({
      ...root,
      backgroundImageUrl: src,
      backgroundImageAlt: alt,
      containerStyle: {
        ...root.containerStyle,
        backgroundImage: src ? `url("${src}")` : "",
        backgroundSize: src ? "cover" : "",
        backgroundRepeat: src ? "no-repeat" : "",
      },
    });
    modalProps.onClose();
  };

  return (
    <>
      <Modal {...modalProps}>
        <Div
          className={css`
            padding: ${px.xl};
            width: 500px;
          `}
        >
          <Div
            css={css`
              ${flex("jcsb aic")}
            `}
          >
            <Text styles="h2">Page Background Image</Text>
            <Button styles="icon sm" onClick={modalProps.onClose}>
              <X />
            </Button>
          </Div>
          <Div
            css={css`
              padding: ${px.md};
            `}
          >
            <Div
              css={css`
                ${flex("aic")} ${container.hover} padding: ${px.md};
                margin: ${px.md} 0;
                border: 1px solid ${colors.black};
                border-radius: 8px;
              `}
              onClick={() => setModal("image-upload")}
            >
              <Div
                className={css`
                  width: 32px;
                `}
              >
                <UploadSimple />
              </Div>
              <Text styles="label bold padh">Upload Image</Text>
            </Div>

            <Div
              css={css`
                ${flex("aic")} ${container.hover} padding: ${px.md};
                margin: ${px.md} 0;
                border: 1px solid ${colors.black};
                border-radius: 8px;
              `}
              onClick={() => setModal("image-library")}
            >
              <Div
                className={css`
                  width: 32px;
                `}
              >
                <SquaresFour />
              </Div>
              <Text styles="label bold padh">Image Library</Text>
            </Div>

            <Div>
              <Text styles="label">Image Source</Text>
              <Input
                styles="mb"
                className={css`
                  width: 100%;
                `}
                value={src || ""}
                onChange={(e) => setSrc(e.target.value)}
              />

              <Text styles="label">Alternate Text</Text>
              <Input
                styles="mb"
                className={css`
                  width: 100%;
                `}
                value={alt || ""}
                onChange={(e) => setAlt(e.target.value)}
              />
            </Div>
          </Div>
          <Div
            css={css`
              ${flex("jcr aic")}
            `}
          >
            {!!root?.backgroundImageUrl && (
              <Button
                hoverLabel="Remove Background Image"
                styles="icon mr"
                onClick={removeImage}
                disabled={!isSrcSynced}
              >
                <Trash />
              </Button>
            )}
            <Button styles="secondary mr" onClick={modalProps.onClose}>
              Close
            </Button>
            <Button disabled={!hasChanges} onClick={saveChanges}>
              Save
            </Button>
          </Div>
        </Div>

        <ImagesModal
          display={modal === "image-library"}
          onClose={() => setModal("")}
          updateImage={updateImage}
        />

        <UploadImage
          display={modal === "image-upload"}
          onClose={() => setModal("")}
          updateImage={updateImage}
        />
      </Modal>
    </>
  );
};
