import { css } from "@emotion/css";

export const draftStyles = css`
  .public-DraftEditorPlaceholder-root {
    position: absolute;
    opacity: 50%;
  }

  .public-DraftStyleDefault-ol,
  .public-DraftStyleDefault-ul {
    margin: 0;
    padding: 0;
    margin-left: 1em;
  }

  .public-DraftStyleDefault-unorderedListItem {
    position: relative;
    margin-left: 16px;
  }

  .public-DraftStyleDefault-orderedListItem {
    position: relative;
    margin-left: 1em;
  }

  .public-DraftStyleDefault-unorderedListItem.public-DraftStyleDefault-depth0 {
    margin-left: 1em;
    list-style-type: disc;
  }

  .public-DraftStyleDefault-unorderedListItem.public-DraftStyleDefault-depth1 {
    margin-left: 2em;
    list-style-type: circle;
  }

  .public-DraftStyleDefault-unorderedListItem.public-DraftStyleDefault-depth2 {
    margin-left: 3em;
    list-style-type: square;
  }

  .public-DraftStyleDefault-unorderedListItem.public-DraftStyleDefault-depth3 {
    margin-left: 4em;
    list-style-type: disc;
  }

  .public-DraftStyleDefault-unorderedListItem.public-DraftStyleDefault-depth4 {
    margin-left: 5em;
    list-style-type: circle;
  }

  .public-DraftStyleDefault-unorderedListItem.public-DraftStyleDefault-depth5 {
    margin-left: 6em;
    list-style-type: square;
  }
`;
