import React from "react";
import { css } from "@emotion/css";
import { Button, Div, Modal } from "../shared/components";
import { CircularProgress, IconButton } from "@material-ui/core";
import { useStore } from "../store-provider/use-store";
import { flex } from "../shared/shared-styles";
import Slack from "./slack.png";
import Teams from "./teams.png";
import { CustomAxios } from "../redux/axios/axios";
import { X } from "phosphor-react";
import { useFeatureFlagPayload } from "posthog-js/react";

export const ConnectionsContainer = ({
  showConnectionsModal,
  closeShowConnectionsModal,
  setShowTeamsModal,
  showTeamsModal,
}) => {
  const {
    data: { kolla },
    setStore,
  } = useStore();

  const [showConnectionTeams, setShowConnectionTeams] = React.useState(false);
  const canTextMessage = useFeatureFlagPayload("text-messaging-new");

  React.useEffect(() => {
    try {
      window.kolla.subscribe("onLinkCreated", (e) => {
        if (e.connector.connector_id && e.connector.connector_id === "slack") {
          let cloned = { ...kolla };

          cloned.slack = true;
          setStore("kolla", cloned);
        }

        if (
          e.connector.connector_id &&
          e.connector.connector_id === "microsoft-teams-47756"
        ) {
          let cloned = { ...kolla };

          cloned["microsoft-teams-47756"] = true;
          setStore("kolla", cloned);

          try {
            CustomAxios.post("/v1/teams");
          } catch (e) {
            console.log("setup teams tenant failed");
          }
        }
      });
    } catch (e) {
      console.error("KOLLA ERROR onLinkCreated");
    }

    try {
      window.kolla.subscribe("onLinkDisabled", (e) => {
        if (e.connector.connector_id && e.connector.connector_id === "slack") {
          let cloned = { ...kolla };

          cloned.slack = false;
          setStore("kolla", cloned);
        }

        if (
          e.connector.connector_id &&
          e.connector.connector_id === "microsoft-teams-47756"
        ) {
          let cloned = { ...kolla };

          cloned["microsoft-teams-47756"] = false;
          setStore("kolla", cloned);
        }
      });
    } catch (e) {
      console.error("KOLLA ERROR onLinkDisabled");
    }
  }, [kolla]);

  return (
    <div>
      {!canTextMessage?.value && (
        <div>
          <div>
            {!kolla ? (
              <Div
                css={css`
                  ${flex("jcc aic")}
                `}
              >
                <CircularProgress />
              </Div>
            ) : (
              <div
                className={css`
                  display: flex;
                  gap: 32px;
                  justify-content: center;
                `}
              >
                <div
                  className={css`
                    border: 1px solid #d1dae3;
                    border-radius: 8px;
                    padding: 24px;
                    margin-top: 40px;
                    margin-bottom: 47px;
                    width: 334px;
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    flex: 1;
                    align-self: flex-start;
                    max-width: 334px;
                  `}
                >
                  <img
                    alt="Slack"
                    src={Slack}
                    className={css`
                      width: 138px;
                      height: 35px;
                      margin-bottom: 24px;
                    `}
                  />

                  <div
                    className={css`
                      font-family: "Roboto";
                      font-style: normal;
                      font-weight: 400;
                      font-size: 16px;
                      line-height: 24px;
                      /* identical to box height, or 150% */

                      letter-spacing: 0.15px;

                      color: #66737f;
                      margin-bottom: 24px;
                      text-align: center;
                    `}
                  >
                    Send Brite guides through Slack
                  </div>
                  {kolla && kolla.slack ? (
                    <div
                      className={css`
                        display: flex;
                        gap: 40px;
                        align-items: center;
                      `}
                    >
                      <div
                        onClick={() => {
                          try {
                            window.kolla.openConnector("slack");
                          } catch (e) {
                            console.error(
                              "Kolla error opening slack connector"
                            );
                          }
                        }}
                        className={css`
                          cursor: pointer;
                          background: #d8f4df;
                          border: 1px solid #d8f4df;
                          border-radius: 8px;
                          font-family: "Roboto";
                          font-style: normal;
                          font-weight: 700;
                          font-size: 14px;
                          line-height: 24px;
                          /* identical to box height, or 171% */

                          letter-spacing: 0.4px;
                          text-transform: uppercase;

                          color: #32aa4e;
                          width: 147px;
                          text-align: center;
                          padding: 8px;
                          &:hover {
                            opacity: 0.8;
                          }
                        `}
                      >
                        Connected
                      </div>
                    </div>
                  ) : (
                    <Button
                      onClick={() => {
                        try {
                          window.kolla.openConnector("slack");
                        } catch (e) {
                          console.error("Kolla error opening slack connector");
                        }
                      }}
                      styles="secondary"
                    >
                      Connect
                    </Button>
                  )}
                </div>

                <div
                  className={css`
                    border: 1px solid #d1dae3;
                    border-radius: 8px;
                    padding: 24px;
                    margin-top: 40px;
                    margin-bottom: 47px;
                    width: 334px;
                    max-width: 334px;
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    flex: 1;
                  `}
                >
                  <img
                    alt="Teams"
                    src={Teams}
                    className={css`
                      width: 138px;
                      height: 35px;
                      margin-bottom: 24px;
                    `}
                  />

                  <div
                    className={css`
                      font-family: "Roboto";
                      font-style: normal;
                      font-weight: 400;
                      font-size: 16px;
                      line-height: 24px;
                      /* identical to box height, or 150% */

                      letter-spacing: 0.15px;

                      color: #66737f;
                      margin-bottom: 24px;
                      text-align: center;
                    `}
                  >
                    Send Brite guides through Teams
                  </div>

                  {kolla && kolla["microsoft-teams-47756"] ? (
                    <div
                      className={css`
                        display: flex;
                        align-items: center;
                        gap: 24px;
                        flex-direction: column;
                      `}
                    >
                      <div
                        onClick={() => {
                          try {
                            window.kolla.openConnector("microsoft-teams-47756");
                          } catch (e) {
                            console.error(
                              "Kolla error opening teams connector"
                            );
                          }
                        }}
                        className={css`
                          cursor: pointer;
                          background: #d8f4df;
                          border: 1px solid #d8f4df;
                          border-radius: 8px;
                          font-family: "Roboto";
                          font-style: normal;
                          font-weight: 700;
                          font-size: 14px;
                          line-height: 24px;
                          /* identical to box height, or 171% */

                          letter-spacing: 0.4px;
                          text-transform: uppercase;

                          color: #32aa4e;
                          width: 147px;
                          text-align: center;
                          padding: 8px;
                          &:hover {
                            opacity: 0.8;
                          }
                        `}
                      >
                        Connected
                      </div>
                    </div>
                  ) : (
                    <Button
                      onClick={() => setShowTeamsModal(true)}
                      styles="secondary"
                    >
                      Connect
                    </Button>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      )}

      {showTeamsModal && (
        <Modal
          onClose={() => {
            setShowTeamsModal(false);
            setShowConnectionTeams(false);
          }}
          display
          css={`
            border-radius: 16px;
            padding: 32px;
          `}
        >
          <div
            className={css`
              display: flex;
              justify-content: space-between;
              align-items: baseline;
            `}
          >
            <div
              className={css`
                font-family: "Roboto";
                font-style: normal;
                font-weight: 700;
                font-size: 24px;
                line-height: 133.4%;
                /* identical to box height, or 32px */

                color: #25282d;
                padding-bottom: 16px;
              `}
            >
              Connect Microsoft Teams
            </div>
            <div>
              <IconButton
                onClick={() => {
                  setShowTeamsModal(false);
                  setShowConnectionTeams(false);
                }}
              >
                <X />
              </IconButton>
            </div>
          </div>

          {kolla && !kolla["microsoft-teams-47756"] && !showConnectionTeams && (
            <>
              <div
                className={css`
                  background: #f5f7fa;
                  border-radius: 8px;

                  margin-bottom: 24px;
                  padding: 16px;
                `}
              >
                <div
                  className={css`
                    font-family: "Roboto";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    /* or 150% */

                    letter-spacing: 0.15px;

                    color: #66737f;
                  `}
                >
                  {" "}
                  A Microsoft Teams Admin will be needed to complete the next
                  step of this process.
                </div>
              </div>
              <h3>Step 1:</h3>
              <Button
                onClick={() => {
                  setShowConnectionTeams(true);
                }}
              >
                <a
                  className={css`
                    text-decoration: none;
                    color: #fff;
                  `}
                  href={
                    "https://storage.cloud.google.com/brite_downloadables/Brite_MicrosoftTeamsApp.zip"
                  }
                  download="brite_bot.zip"
                >
                  Download Application File
                </a>
              </Button>
              <Button
                styles="secondary"
                onClick={() => {
                  setShowConnectionTeams(true);
                }}
              >
                Skip this step
              </Button>
            </>
          )}

          {showConnectionTeams && (
            <div>
              <div
                className={css`
                  background: #f5f7fa;
                  border-radius: 8px;

                  margin-bottom: 24px;
                  padding: 16px;
                `}
              >
                <div
                  className={css`
                    font-family: "Roboto";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    /* or 150% */

                    letter-spacing: 0.15px;

                    color: #66737f;
                  `}
                >
                  {" "}
                  <h3>Step 2:</h3>
                  Have a Microsoft Teams Admin upload that file for your
                  organization in the{" "}
                  <a
                    href={
                      "https://admin.teams.microsoft.com/policies/manage-apps"
                    }
                    target="_blank"
                  >
                    Teams Admin Portal
                  </a>
                  <br />
                  <br />
                  For more details, see this{" "}
                  <a
                    href={
                      "https://be-brite-5901597.hs-sites.com/knowledge/setting-up-your-microsoft-teams-integration"
                    }
                    target="_blank"
                  >
                    help center article.
                  </a>
                  .
                </div>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Button
                  onClick={() => {
                    setShowConnectionTeams(false);
                  }}
                  styles="secondary"
                >
                  Go back
                </Button>
                <Button
                  onClick={() => {
                    try {
                      window.kolla.openConnector("microsoft-teams-47756");
                      window?.ChurnZero?.push([
                        "setAttribute",
                        "account",
                        "integration-microsoft-teams",
                        true,
                      ]);
                      setShowTeamsModal(false);
                      setShowConnectionTeams(false);
                    } catch (e) {
                      console.error("Teams connector kolla failure");
                      setShowTeamsModal(false);
                      setShowConnectionTeams(false);
                    }
                  }}
                  styles="Primary"
                >
                  That is done, Connect.
                </Button>
              </div>
            </div>
          )}
        </Modal>
      )}

      {showConnectionsModal && (
        <Modal
          onClose={() => closeShowConnectionsModal()}
          display
          css={`
            border-radius: 16px;
            padding: 32px;
          `}
        >
          <div
            className={css`
              display: flex;
              justify-content: space-between;
              align-items: center;
              gap: 168px;
            `}
          >
            <div
              className={css`
                font-family: "Roboto";
                font-style: normal;
                font-weight: 700;
                font-size: 24px;
                line-height: 133.4%;
                /* identical to box height, or 32px */

                color: #25282d;
              `}
            >
              Messaging Integrations
            </div>
            <div>
              <IconButton onClick={() => closeShowConnectionsModal()}>
                <X />
              </IconButton>
            </div>
          </div>

          <div>
            <div
              className={css`
                border: 1px solid #d1dae3;
                border-radius: 8px;
                padding: 24px;
                margin-top: 24px;
                margin-bottom: 24px;

                display: flex;
                align-items: center;
                justify-content: space-between;

                flex: 1;
              `}
            >
              <img
                alt="Slack"
                src={Slack}
                className={css`
                  width: 138px;
                  height: 35px;
                `}
              />

              {kolla && kolla.slack ? (
                <div
                  className={css`
                    display: flex;
                    gap: 40px;
                    align-items: center;
                  `}
                >
                  <div
                    onClick={() => {
                      try {
                        window.kolla.openConnector("slack");
                      } catch (e) {
                        console.error("Kolla failure with slack connector");
                      }
                    }}
                    className={css`
                      cursor: pointer;
                      background: #d8f4df;
                      border: 1px solid #d8f4df;
                      border-radius: 8px;
                      font-family: "Roboto";
                      font-style: normal;
                      font-weight: 700;
                      font-size: 14px;
                      line-height: 24px;
                      /* identical to box height, or 171% */

                      letter-spacing: 0.4px;
                      text-transform: uppercase;

                      color: #32aa4e;
                      width: 147px;
                      text-align: center;
                      padding: 8px;
                      &:hover {
                        opacity: 0.8;
                      }
                    `}
                  >
                    Connected
                  </div>
                </div>
              ) : (
                <Button
                  onClick={() => {
                    try {
                      window.kolla.openConnector("slack");
                    } catch (e) {
                      console.error("Kolla error with slack connections");
                    }
                  }}
                  styles="secondary"
                >
                  Connect
                </Button>
              )}
            </div>

            <div
              className={css`
                border: 1px solid #d1dae3;
                border-radius: 8px;
                padding: 24px;
                margin-top: 24px;
                margin-bottom: 24px;
              `}
            >
              <div
                className={css`
                  display: flex;
                  align-items: center;
                  justify-content: space-between;

                  flex: 1;
                `}
              >
                <img
                  alt="Teams"
                  src={Teams}
                  className={css`
                    width: 138px;
                    height: 35px;
                  `}
                />

                {kolla && kolla["microsoft-teams-47756"] ? (
                  <div
                    className={css`
                      display: flex;
                      align-items: center;
                      gap: 24px;
                      flex-direction: column;
                    `}
                  >
                    <div
                      onClick={() => {
                        try {
                          window.kolla.openConnector("microsoft-teams-47756");
                        } catch (e) {
                          console.error("Kolla error with teams connections");
                        }
                      }}
                      className={css`
                        cursor: pointer;
                        background: #d8f4df;
                        border: 1px solid #d8f4df;
                        border-radius: 8px;
                        font-family: "Roboto";
                        font-style: normal;
                        font-weight: 700;
                        font-size: 14px;
                        line-height: 24px;
                        /* identical to box height, or 171% */

                        letter-spacing: 0.4px;
                        text-transform: uppercase;

                        color: #32aa4e;
                        width: 147px;
                        text-align: center;
                        padding: 8px;
                        &:hover {
                          opacity: 0.8;
                        }
                      `}
                    >
                      Connected
                    </div>
                  </div>
                ) : (
                  <Button
                    onClick={() => {
                      setShowTeamsModal(true);
                      setShowConnectionTeams(false);
                    }}
                    styles="secondary"
                  >
                    Connect
                  </Button>
                )}
              </div>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};
