import { css } from "@emotion/css";
import { ChromePicker } from "react-color";
import {
  Button,
  Div,
  DropMenu,
  Input,
  Select,
  Text,
} from "../../shared/components";
import { flex, px } from "../../shared/shared-styles";
import { colorStyle } from "./edit-style";
import { MenuItem } from "@material-ui/core";

export const EditLine = ({ current, updateCurrent }) => {
  const line = current?.Theme?.Line || {};
  const colorKey = "Line.Color";
  const color = line?.Color || "#000000";
  const weight = "Weight" in line ? line?.Weight : 2;
  const style = line?.Style || "solid";

  const handleStyle = (e) => {
    if (e.target.value !== "solid") {
      updateCurrent("Line", {
        ...line,
        Style: e.target.value,
        BorderRadius: 0,
      });
    } else {
      updateCurrent("Line.Style", e.target.value);
    }
  };

  const handleBorderRadius = (e) => {
    if (e.target.value === 30) {
      updateCurrent("Line", {
        ...line,
        Style: "solid",
        BorderRadius: e.target.value,
      });
    } else {
      updateCurrent("Line.BorderRadius", e.target.value);
    }
  };

  return (
    <Div
      className={css`
        margin-top: 32px;
      `}
    >
      <Div
        className={css`
          height: 40px;
        `}
      >
        <Text styles="h2 nopad">Line Color</Text>
      </Div>

      <Div
        css={css`
          margin: ${px.xl} 0;
        `}
      >
        <Text styles="mb-sm" label bold>
          Color
        </Text>
        <Div
          css={css`
            ${flex("space-between")} position: relative;
          `}
        >
          <Input
            className={css`
              width: 100%;
            `}
            value={color}
            onChange={(e) => updateCurrent(colorKey, e.target.value)}
          />
          <DropMenu
            button={<Button styles="naked" className={colorStyle(color)} />}
            transformOrigin={{ horizontal: "right" }}
          >
            <Div onClick={(e) => e.stopPropagation()}>
              <ChromePicker
                color={color}
                onChange={(color) => updateCurrent(colorKey, color.hex)}
                disableAlpha
              />
            </Div>
          </DropMenu>
        </Div>
      </Div>

      <Div
        css={css`
          margin: ${px.xl} 0;
        `}
      >
        <Text styles="mb-sm" label bold>
          Weight
        </Text>
        <Input
          type="number"
          value={weight}
          onChange={(e) => updateCurrent("Line.Weight", Number(e.target.value))}
        />
      </Div>

      <Div
        css={css`
          margin: ${px.xl} 0;
        `}
      >
        <Text styles="mb-sm" label bold>
          Style
        </Text>
        <Select value={style} onChange={handleStyle}>
          <MenuItem value="solid">Solid</MenuItem>
          <MenuItem value="dotted">Dotted</MenuItem>
          <MenuItem value="dashed">Dashed</MenuItem>
        </Select>
      </Div>

      <Div
        css={css`
          margin: ${px.xl} 0;
        `}
      >
        <Text styles="mb-sm" label bold>
          End Caps
        </Text>
        <Select value={line?.BorderRadius || 0} onChange={handleBorderRadius}>
          <MenuItem value={0}>Straight</MenuItem>
          <MenuItem value={30}>Round</MenuItem>
        </Select>
      </Div>
    </Div>
  );
};
