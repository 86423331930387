import { Tooltip } from "@material-ui/core";
import { css } from "@emotion/css";
import { BookmarkSimple } from "phosphor-react";
import { useMemo } from "react";
import { Text } from "../../shared/components";
import { flex } from "../../shared/shared-styles";
import { colors } from "../../shared/styles";

export const FlagsBadge = ({ flags, properties = [], orphanedFlags = [] }) => {
  const flagCount = useMemo(() => {
    if (!properties?.length) {
      return flags?.length;
    }

    let count = 0;

    const flaggedProperties = flags?.map(
      ({ metadata }) => metadata.property_chain
    );

    for (let item of properties) {
      const propertiesCount =
        flaggedProperties?.filter((property) => property === item)?.length || 0;
      count = count + propertiesCount;
    }

    if (orphanedFlags?.length) {
      return count + orphanedFlags?.length;
    }
    return count;
  }, [flags?.length, properties?.length]);

  if (!flagCount) {
    return null;
  }

  return (
    <Tooltip
      title={`${flagCount} field${flagCount === 1 ? "" : "s"} need${
        flagCount === 1 ? "s" : ""
      } attention`}
    >
      <div
        className={css`
          position: relative;
          margin-right: 16px;
        `}
      >
        <BookmarkSimple />
        <Text
          css={`
            position: absolute;
            top: -8px;
            left: 16px;
            color: white;
            font-weight: bold;
            background-color: ${colors.purple};
            ${flex("center")}
            min-width: 24px;
            padding: 0 8px;
            height: 24px;
            border-radius: 30px;
          `}
        >
          {flagCount}
        </Text>
      </div>
    </Tooltip>
  );
};
