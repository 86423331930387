import { forwardRef, useEffect } from "react";
import { useThemedProps } from "../useThemedProps";
import { container, scrollbar } from "../shared-styles";
import { colors } from "../styles";

const boxTheme = {
  radius: `border-radius: 8px;`,
  hover: container.hover,
  hoverOutline: container.hoverOutline,
  hideScrollbar: scrollbar.hide,
  scrollbar: scrollbar.style,
  padSm: `padding: 8px;`,
  padMd: `padding: 16px;`,
  padLg: `padding: 24px;`,
  padXl: `padding: 32px;`,
  border: `1px solid ${colors.gray[300]};`,
  page: `
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
  `,
};

export const Box = forwardRef(
  ({ children, onInitial = null, onCleanup = null, ...props }, ref) => {
    const themedProps = useThemedProps(props, boxTheme);
    useEffect(() => {
      if (onInitial) {
        onInitial();
      }
      return () => {
        if (onCleanup) {
          onCleanup();
        }
      };
    }, []);
    return (
      <div ref={ref} {...themedProps}>
        {children}
      </div>
    );
  }
);
