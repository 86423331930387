import { MenuItem } from "@material-ui/core";
import { css } from "@emotion/css";
import { PencilSimple, X } from "phosphor-react";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router";
import { mutliNetworkCategoryMap } from "../../../benefit-package/utils";
import { ALL_TYPES, PRODUCT_HEADERS } from "../../../benefit-package/constants";
import { history } from "../../../history";
import { ProductSummary } from "../../../products/product-summary";
import { Button, Div, Modal, Select, Text } from "../../../shared/components";
import { flex, scrollbar } from "../../../shared/shared-styles";
import { colors } from "../../../shared/styles";
import { useForm } from "../../../shared/use-form";
import { useSearchParams } from "../../../shared/use-search-params";
import { useEditorResource } from "../../use-editor-resource";
import { SmartFieldInput } from "../../utility-components/smart-field-input";
import { CostSummary } from "./summaries/cost-summary";
import { NetworkSearch } from "./summaries/network-search";
import { useProductData } from "../../../products/configs/use-product-data";
import { BriteLoader } from "../../../shared/components/brite-loader";
import { modifyContent } from "../../provider/utils";
import { useStore } from "../../../store-provider/use-store";
import { CustomAxios } from "../../../redux/axios/axios";
import { useQueryAPI } from "../../../react-query";

export const BenefitSummary = (props) => {
  const { businessId } = useParams();
  const {
    data: { devMode },
  } = useStore();
  const { editor, element, location, packageId } = props;
  const {
    state: { course },
  } = editor;
  const { editorType, viewMode } = editor?.state;
  const [loading, setLoading] = useState(false);

  const onUpdate = (updates) => modifyContent.merge(editor, location, updates);

  const {
    params: { modalToolbar: modal },
    updateParams,
    removeParam,
  } = useSearchParams();

  const setModal = (modalToolbar) => {
    if (loading) return;
    if (!modalToolbar) {
      removeParam("modalToolbar");
    } else {
      updateParams({ modalToolbar });
    }
  };

  const { data: styles } = useEditorResource("styles");
  const { data: smartFields } = useEditorResource("liveSmartFields");
  const { data: programData } = useQueryAPI({
    url: `public/v1/business/${businessId}/programs`,
    defaultValue: [],
  });

  const productId = useMemo(() => {
    if (
      smartFields?.[element?.data?.id]?.Success &&
      smartFields?.[element?.data?.id]?.Value
    ) {
      return smartFields?.[element?.data?.id]?.Value;
    }
    return element?.data?.id;
  }, [element?.data?.id, smartFields]);

  const state = useProductData({
    devMode,
    productId,
    businessId,
    editorType,
    productType: element?.type,
    packageId,
  });

  const { values, setValues, getProperty, hasChanges } = useForm(
    { ...element?.data, productId, type: element?.type },
    [element?.data, element?.type, modal, productId]
  );

  useEffect(() => {
    if (state?.product?.ID && state?.product?.ID === element?.data?.id) {
      setModal("");
    }
  }, [state?.product?.ID !== element?.data?.id]);

  const updateSummarySettings = () => {
    const {
      id,
      type,
      smartFieldDisplay = "",
      relatedProductId,
      selectedId,
    } = values;
    if (devMode) {
      onUpdate({ data: { id, smartFieldDisplay }, type });
      setModal("");
    } else if (editorType === "page") {
      onUpdate({
        data: { id, relatedProductId, selectedId },
        type,
      });
      setModal("");
    } else {
      onUpdate({ type });
      setModal("");
    }
  };

  const routeToProductForm = () => {
    history.push(
      `/${businessId}/packages/${packageId}/${element?.type}/${productId}`
    );
  };

  const handleSmartField = ({ data }) => {
    setValues((form) => ({
      ...form,
      id: data?.value,
      smartFieldDisplay: data?.name,
    }));
  };

  const loadProductTags = async (productId) => {
    if (!productId || !element?.type || !course.IsPublished) {
      // return no product-tags, hardcoded values will be used and the smart component will not be portable to other benefits packages.
      return {};
    }
    try {
      setLoading(true);
      const prodType =
        element?.type === "hsa_buyup" ? "insurance_plan" : element?.type;
      const resp = await CustomAxios.get(
        `/v1/product/types/${prodType}/tags?productID=${productId}&benefitsPackageID=${props.packageId}&use-product-layout=true&direct-ref=true`
      );
      return resp.data;
    } catch (error) {
      console.warn(
        "error loading product tags for smart component, will use hard-coded values instead",
        error
      );
      return null;
    } finally {
      setLoading(false);
    }
  };

  const tagsDrillDown = (mergeTags, searchNames) => {
    if (!mergeTags || !searchNames.length) {
      return null;
    }

    for (let i = 0; i < mergeTags.length; i++) {
      if (mergeTags[i].name?.toLowerCase() !== searchNames[0].toLowerCase()) {
        continue;
      }

      if (mergeTags[i].mergeTags) {
        return tagsDrillDown(mergeTags[i].mergeTags, searchNames.slice(1));
      }

      return mergeTags[i].value;
    }

    return null;
  };

  const handleProductIdentifierTag = async (e) => {
    const tags = await loadProductTags(e.target.value);
    const idTag = tags?.DataSource?.IdentifierTag;
    if (values.type === "hsa_buyup") {
      const relatedProductIdTag = tagsDrillDown(tags?.mergeTags, [
        "Included Plan Programs",
        "Health Savings Account",
        "Related Product Id",
      ]);
      setValues((form) => ({
        ...form,
        id: idTag ? idTag : e.target.value,
        relatedProductId: relatedProductIdTag,
        selectedId: e.target.value,
      }));
      return;
    }

    setValues((form) => ({
      ...form,
      id: idTag ? idTag : e.target.value,
      selectedId: e.target.value,
    }));
  };

  const removeSmartField = () => {
    setValues((form) => ({
      ...form,
      id: "",
    }));
  };

  const filteredProducts = useMemo(() => {
    if (element?.type === "hsa_buyup") {
      const productMap = props?.products?.reduce(
        (prev, item) => ({
          ...prev,
          [item?.ID]: item,
        }),
        {}
      );
      return programData?.reduce((prev, { ProgramType, PlanID }) => {
        if (ProgramType === "health_savings_account" && PlanID in productMap) {
          const product = productMap?.[PlanID];
          return [...prev, product];
        }
        return prev;
      }, []);
    }

    return props?.products?.filter((p) => {
      return p.Type === element?.type;
    });
  }, [element?.type, props?.products, programData]);

  return (
    <>
      <Div
        css={css`
          ${flex("center")} position: relative;
          width: 100%;
          transition: height 0.2s ease;
        `}
        onDoubleClick={() => setModal("benefits-options")}
      >
        {element?.component === "benefits" ? (
          <ProductSummary state={state} styles={styles} />
        ) : element?.component === "pricing" ? (
          <CostSummary
            type={element?.type}
            state={state}
            styles={styles}
            summary={element?.data}
            viewMode={viewMode}
          />
        ) : element?.component === "network" ? (
          <NetworkSearch state={state} styles={styles} />
        ) : null}
      </Div>

      {/* DELETE THIS IN EMPLOYEE-VIEW */}
      {props.isSelected && modal === "benefits-options" && (
        <Modal display={true} onClose={() => setModal("")}>
          <Div
            css={css`
              width: 500px;
              padding: 8px 0;
            `}
          >
            <Div
              css={css`
                ${flex("space-between")} padding: 16px 32px;
                border-bottom: 1px solid ${colors.gray[300]};
              `}
            >
              <Text h2>Edit</Text>
              <Button styles="icon" onClick={() => setModal("")}>
                <X />
              </Button>
            </Div>

            <Div
              css={css`
                position: relative;
                width: 100%;
                padding: 16px 32px;
                ${scrollbar.hide}
              `}
            >
              {devMode && (
                <>
                  <Text label bold>
                    ID
                  </Text>
                  <SmartFieldInput
                    value={values?.id}
                    smartFieldDisplay={values?.smartFieldDisplay || ""}
                    handleSmartField={handleSmartField}
                    removeSmartField={removeSmartField}
                    inputProps={getProperty("id")}
                  />
                </>
              )}

              {editorType === "page" && !devMode ? (
                <>
                  <Div
                    css={css`
                      ${flex("space-between")}
                    `}
                  >
                    <Text label bold>
                      Edit Benefit
                    </Text>
                    <Button
                      hoverLabel="Go To Benefit Form"
                      styles="icon"
                      className={css`
                        margin-left: 4px;
                      `}
                      onClick={routeToProductForm}
                    >
                      <PencilSimple />
                    </Button>
                  </Div>
                  <Text
                    className={css`
                      flex-grow: 1;
                      margin-top: 16px;
                      margin-bottom: 8px;
                    `}
                    label
                    bold
                  >
                    Selected Benefit
                  </Text>
                  <Div
                    css={css`
                      ${flex("space-between")} margin-bottom: 32px;
                    `}
                  >
                    <Select
                      className={css`
                        flex-grow: 1;
                      `}
                      {...getProperty("id")}
                      value={values?.selectedId || values?.id}
                      onChange={handleProductIdentifierTag}
                    >
                      {filteredProducts?.map((item) => (
                        <MenuItem key={item.ID} value={item.ID}>
                          <Div
                            css={css`
                              width: 100%;
                              ${flex("space-between")}
                            `}
                          >
                            <Text label>
                              {item?.ProductName ||
                                item?.Title ||
                                PRODUCT_HEADERS[item?.Type]}
                            </Text>
                            {item?.MultiNetworkCategory && (
                              <Text>
                                {
                                  mutliNetworkCategoryMap?.[
                                    item?.MultiNetworkCategory
                                  ]
                                }
                              </Text>
                            )}
                          </Div>
                        </MenuItem>
                      ))}
                    </Select>
                  </Div>
                </>
              ) : (
                <>
                  <Text
                    className={css`
                      flex-grow: 1;
                      margin-top: 16px;
                      margin-bottom: 8px;
                    `}
                    label
                    bold
                  >
                    Selected Benefit Type
                  </Text>
                  <Div
                    css={css`
                      ${flex("space-between")} margin-bottom: 32px;
                    `}
                  >
                    <Select
                      {...getProperty("type")}
                      className={css`
                        flex-grow: 1;
                      `}
                    >
                      {ALL_TYPES?.map(
                        (item) =>
                          PRODUCT_HEADERS[item] && (
                            <MenuItem key={item} value={item}>
                              <Div
                                css={css`
                                  width: 100%;
                                  ${flex("space-between")}
                                `}
                              >
                                <Text label>{PRODUCT_HEADERS[item]}</Text>
                              </Div>
                            </MenuItem>
                          )
                      )}
                    </Select>
                  </Div>
                </>
              )}

              <BriteLoader
                isLoading={
                  editorType === "page" &&
                  !devMode &&
                  state?.product?.ID !== productId
                }
                css={`
                  height: 100%;
                `}
              />
            </Div>
            <Div
              css={css`
                ${flex("right")}
                width: 100%;
                height: 100px;
                padding: 32px;
                border-top: 1px solid ${colors.gray[300]};
              `}
            >
              <Button
                disabled={loading}
                className={css`
                  margin-right: 16px;
                `}
                onClick={() => setModal("")}
                styles="secondary"
              >
                Close
              </Button>
              <Button
                disabled={!hasChanges || loading}
                onClick={updateSummarySettings}
              >
                Update
              </Button>
            </Div>
          </Div>
        </Modal>
      )}
      {/* DELETE THIS IN EMPLOYEE-VIEW  ^^^*/}
    </>
  );
};
