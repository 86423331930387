import { MenuItem } from "@material-ui/core";
import { css } from "@emotion/css";
import { Trash, X } from "phosphor-react";
import { useQueryAPI } from "../../../react-query";
import {
  Button,
  Div,
  Modal,
  Text,
  Select,
  Input,
} from "../../../shared/components";
import { flex, px } from "../../../shared/shared-styles";
import { actions } from "../../constants";
import { useEditor } from "../../provider/use-editor-store";
import { editorUtils } from "../../provider/utils";
import { SmartFieldInput } from "../../utility-components/smart-field-input";

export const ActionsModal = ({ onUpdate, ...modalProps }) => {
  const editor = useEditor();
  const selectedContent = editorUtils.getSelection(editor);

  const { data: pages } = useQueryAPI({
    cacheKey: ["pre-fetch-editor-resource"],
    url: `v2/course/${editor?.state?.course?.ID}/pages`,
    enabled: modalProps.display,
  });

  const handleActionType = (event) => {
    const value = event.target.value;
    onUpdate({
      action: {
        type: value,
        data: { ...actions[value] },
      },
    });
  };

  // handle converting from old image link to new action
  if (!selectedContent?.action && selectedContent?.link) {
    onUpdate({
      link: null,
      action: {
        type: "open-website",
        data: {
          url: selectedContent?.link,
          tabType:
            selectedContent?.attributes.target === "_self"
              ? "same-tab"
              : "new-tab",
        },
      },
    });
  }

  const value = selectedContent?.action?.data?.url;
  const emailValue = selectedContent?.action?.data?.sendToEmail;
  const phoneValue = selectedContent?.action?.data?.callNumber;
  const contentValue = selectedContent?.content;
  const smsValue = selectedContent?.action?.data?.sendToNumber;
  const smartFieldDisplay = selectedContent?.action?.smartFieldDisplay;
  const buttonTextSmartFieldDisplay =
    selectedContent?.action?.buttonTextSmartFieldDisplay;
  const handleSmartField = ({ data }) => {
    onUpdate({
      action: {
        smartFieldDisplay: data.name,
        data: { url: data.value },
      },
    });
  };
  const removeSmartField = () => {
    onUpdate({
      action: {
        data: { url: "" },
      },
    });
  };
  const handleButtonTextSmartField = ({ data }) => {
    onUpdate({
      content: data.value,
      action: {
        buttonTextSmartFieldDisplay: data.name,
      },
    });
  };
  const removeButtonTextSmartField = () => {
    onUpdate({
      content: "",
      action: {
        buttonTextSmartFieldDisplay: "",
      },
    });
  };
  const handleEmailSmartField = ({ data }) => {
    onUpdate({
      action: {
        smartFieldDisplay: data.name,
        data: { sendToEmail: data.value },
      },
    });
  };
  const removeEmailSmartField = () => {
    onUpdate({
      action: {
        data: { sendToEmail: "" },
      },
    });
  };
  const handlePhoneSmartField = ({ data }) => {
    onUpdate({
      action: {
        smartFieldDisplay: data.name,
        data: { callNumber: data.value },
      },
    });
  };
  const removePhoneSmartField = () => {
    onUpdate({
      action: {
        data: { callNumber: "" },
      },
    });
  };
  const handleSMSSmartField = ({ data }) => {
    onUpdate({
      action: {
        smartFieldDisplay: data.name,
        data: { sendToNumber: data.value },
      },
    });
  };
  const removeSMSSmartField = () => {
    onUpdate({
      action: {
        data: { sendToNumber: "" },
      },
    });
  };

  return (
    <Modal {...modalProps}>
      <Div
        css={css`
          padding: 32px;
        `}
        onMouseDown={(e) => e.stopPropagation()}
      >
        <Div
          css={css`
            width: 500px;
          `}
        >
          <Div
            css={css`
              ${flex("jcsb aic")} margin-bottom: ${px.md};
            `}
          >
            <Text styles="h2">Button Settings</Text>
            <Button styles="icon" onClick={modalProps.onClose}>
              <X />
            </Button>
          </Div>

          {!modalProps.hideBtnText && (
            <Div>
              <Text styles="label bold">Button Text</Text>
              <SmartFieldInput
                value={contentValue}
                smartFieldDisplay={buttonTextSmartFieldDisplay}
                handleSmartField={handleButtonTextSmartField}
                removeSmartField={removeButtonTextSmartField}
                inputProps={{
                  value: selectedContent?.content,
                  onChange: (e) => onUpdate({ content: e.target.value }),
                }}
              />
            </Div>
          )}

          <Div
            css={css`
              margin-top: ${px.md};
            `}
          >
            <Text styles="label bold">Action</Text>
            <Select
              className={css`
                width: 100%;
              `}
              value={selectedContent?.action?.type}
              onChange={handleActionType}
            >
              {Object.entries(actions).map(([key, { displayName }]) => (
                <MenuItem value={key} key={key}>
                  {displayName}
                </MenuItem>
              ))}
            </Select>
          </Div>

          {selectedContent?.action?.type === "open-website" && (
            <Div
              css={css`
                margin-top: ${px.md};
              `}
            >
              <Text
                styles="label bold"
                css={`
                  margin-top: 16px;
                `}
              >
                URL
              </Text>
              <SmartFieldInput
                value={value}
                smartFieldDisplay={smartFieldDisplay}
                handleSmartField={handleSmartField}
                removeSmartField={removeSmartField}
                inputProps={{
                  value: selectedContent?.action?.data?.url,
                  onChange: (e) =>
                    onUpdate({ action: { data: { url: e.target.value } } }),
                }}
                nameFilter="URL"
              />
              <Text
                styles="label bold"
                css={`
                  margin-top: 16px;
                `}
              >
                Open website in
              </Text>
              <Select
                css={`
                  width: 100%;
                `}
                value={selectedContent?.action?.data?.tabType || "new-tab"}
                onChange={(e) =>
                  onUpdate({ action: { data: { tabType: e.target.value } } })
                }
              >
                <MenuItem value="new-tab">New Tab</MenuItem>
                <MenuItem value="same-tab">Same Tab</MenuItem>
              </Select>
            </Div>
          )}

          {selectedContent?.action?.type === "route-to-page" && (
            <Div
              css={css`
                margin-top: ${px.md};
              `}
            >
              <Text styles="label bold">Page</Text>
              <Select
                css={`
                  width: 100%;
                `}
                value={selectedContent?.action?.data?.pageId}
                onChange={(e) =>
                  onUpdate({ action: { data: { pageId: e.target.value } } })
                }
              >
                {pages?.map(({ ID, Name }) => (
                  <MenuItem value={ID} key={ID}>
                    {Name}
                  </MenuItem>
                ))}
              </Select>
              <Text
                styles="label bold"
                css={`
                  margin-top: 16px;
                `}
              >
                Open page in
              </Text>
              <Select
                css={`
                  width: 100%;
                `}
                value={selectedContent?.action?.data?.tabType || "same-tab"}
                onChange={(e) =>
                  onUpdate({ action: { data: { tabType: e.target.value } } })
                }
              >
                <MenuItem value="new-tab">New Tab</MenuItem>
                <MenuItem value="same-tab">Same Tab</MenuItem>
              </Select>
            </Div>
          )}

          {selectedContent?.action?.type === "open-email" && (
            <Div>
              <Div
                css={css`
                  margin-top: ${px.md};
                `}
              >
                <Text styles="label bold">Send To Email</Text>
                <SmartFieldInput
                  value={emailValue}
                  smartFieldDisplay={smartFieldDisplay}
                  handleSmartField={handleEmailSmartField}
                  removeSmartField={removeEmailSmartField}
                  inputProps={{
                    value: selectedContent?.action?.data?.sendToEmail,
                    onChange: (e) =>
                      onUpdate({
                        action: { data: { sendToEmail: e.target.value } },
                      }),
                  }}
                  nameFilter="email"
                />
              </Div>
              <Div
                css={css`
                  margin-top: ${px.md};
                `}
              >
                <Text styles="label bold">Subject</Text>
                <Input
                  className={css`
                    width: 100%;
                  `}
                  value={selectedContent?.action?.data?.subject}
                  onChange={(e) =>
                    onUpdate({ action: { data: { subject: e.target.value } } })
                  }
                />
              </Div>
              <Div
                css={css`
                  margin-top: ${px.md};
                `}
              >
                <Text styles="label bold">Body</Text>
                <Input
                  multiline
                  className={css`
                    width: 100%;
                  `}
                  value={selectedContent?.action?.data?.body}
                  onChange={(e) =>
                    onUpdate({ action: { data: { body: e.target.value } } })
                  }
                  rows={4}
                  rowsMax={4}
                />
              </Div>
            </Div>
          )}

          {selectedContent?.action?.type === "open-sms" && (
            <Div>
              <Div
                css={css`
                  margin-top: ${px.md};
                `}
              >
                <Text styles="label bold">Send To Number</Text>
                <SmartFieldInput
                  value={smsValue}
                  smartFieldDisplay={smartFieldDisplay}
                  handleSmartField={handleSMSSmartField}
                  removeSmartField={removeSMSSmartField}
                  inputProps={{
                    format: "phone",
                    value: selectedContent?.action?.data?.sendToNumber,
                    onChange: (e, value) => {
                      onUpdate({
                        action: { data: { sendToNumber: value } },
                      });
                    },
                  }}
                  nameFilter="phone"
                />
                <Text styles="label bold">Message</Text>
                <Input
                  rows={3}
                  className={css`
                    width: 100%;
                  `}
                  value={selectedContent?.action?.data?.message}
                  onChange={(e) =>
                    onUpdate({ action: { data: { message: e.target.value } } })
                  }
                />
              </Div>
            </Div>
          )}

          {selectedContent?.action?.type === "call-phone" && (
            <Div
              css={css`
                margin-top: ${px.md};
              `}
            >
              <Text styles="label bold">Call Number</Text>
              <SmartFieldInput
                value={phoneValue}
                smartFieldDisplay={smartFieldDisplay}
                handleSmartField={handlePhoneSmartField}
                removeSmartField={removePhoneSmartField}
                inputProps={{
                  format: "phone",
                  value: selectedContent?.action?.data?.callNumber,
                  onChange: (e, value) =>
                    onUpdate({
                      action: { data: { callNumber: value } },
                    }),
                }}
                nameFilter="phone"
              />
            </Div>
          )}
        </Div>

        <Div
          css={css`
            ${modalProps.allowClear && selectedContent.action
              ? flex("jcsb aic")
              : flex("jcr aic")} margin-top: ${px.xl};
          `}
        >
          {modalProps.allowClear && selectedContent.action && (
            <Button
              type="button"
              styles="icon"
              onClick={() => {
                onUpdate({ action: null });
                modalProps.onClose();
              }}
              hoverLabel="Remove"
            >
              <Trash />
            </Button>
          )}
          <Div
            css={css`
              ${flex("aic")}
            `}
          >
            <Button styles="secondary" onClick={modalProps.onClose}>
              Cancel
            </Button>
            <Button styles="ml" onClick={modalProps.onClose}>
              Done
            </Button>
          </Div>
        </Div>
      </Div>
    </Modal>
  );
};
