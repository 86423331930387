import { css } from "@emotion/css";
import { useEffect, useState } from "react";
import { Product } from "../products/product";
import { benefitsPackageProductsList, useResource } from "../react-query";
import { Div } from "../shared/components";
import { flex } from "../shared/shared-styles";
import { useStore } from "../store-provider/use-store";
import { CreationFlow } from "./creation-flow/creation-flow";
import { ProductList } from "./product-list";

export const BenefitPackageModal = ({ pkg }) => {
  const {
    data: { selectedBusiness },
  } = useStore();
  const businessId = selectedBusiness.ID || "";
  const [product, setProduct] = useState(null);
  const [step, setStep] = useState("");

  const productListResource = useResource(benefitsPackageProductsList, {
    enabled: !!pkg?.ID && !step,
  });

  useEffect(() => {
    if (!pkg?.ID) {
      setProduct(null);
    }
  }, [pkg?.ID]);

  return (
    <Div
      css={css`
        ${flex("center start")} width: 100%;
      `}
    >
      {!product?.ID ? (
        !!step ? (
          <CreationFlow
            modalProps={{
              step,
              setStep,
              packageId: pkg?.ID,
              exitFlow: () => setStep(""),
            }}
          />
        ) : (
          <Div
            css={css`
              width: 100%;
            `}
          >
            <ProductList
              productListResource={productListResource}
              businessId={businessId}
              packageId={pkg?.ID}
              selectProduct={setProduct}
              displayCourses={false}
            />
          </Div>
        )
      ) : (
        <Product
          businessId={businessId}
          packageId={pkg?.ID}
          planId={product?.ID}
          planType={product?.Type}
          goBack={() => setProduct({})}
        />
      )}
    </Div>
  );
};
