import React from "react";
import { useAuth0 } from "./Auth/react-auth0-wrapper";
import { css } from "@emotion/css";
import { Button, Modal } from "./shared/components";

export const SignUp = ({ component: Component, path, ...rest }) => {
  const { loginWithRedirect } = useAuth0();

  const signUpPage = async () => {
    await loginWithRedirect({
      redirect_uri: window.location.origin,
      appState: { targetUrl: path },
      screen_hint: "signup",
      prompt: "login",
    });
  };

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const email = urlParams.get("email");

  return (
    <Modal
      display
      full
      css={`
        font-weight: 400;
        overflow: auto;
      `}
    >
      <div
        style={{
          position: "absolute",
          height: "100vh",
          width: "100vw",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: "white",
        }}
      >
        <div
          className={css`
            display: flex;
            justify-content: center;
            margin: 30px;
          `}
        >
          <img
            src="https://assets-global.website-files.com/657c7902abeae51dbdbdd438/65b80ed0f974753d072a61a2_Asset%201%402x.png"
            width="83px"
            height="35px"
            alt="Sign Up Brite"
          />
        </div>

        <div
          className={css`
            text-align: center;
            margin-bottom: 18px;
            font-weight: bold;
          `}
        >
          You've been invited to create a new account with Brite
        </div>
        <div
          className={css`
            text-align: center;
            margin-bottom: 18px;
          `}
        >
          Only sign up using the email address ({email}) that got invited to
          join.{" "}
        </div>

        <div
          className={css`
            display: flex;
            justify-content: center;
            margin: 30px;
          `}
        >
          <Button
            css={`
              padding: 12px;
              border-radius: 0px;
              color: white;
              margin: 10px;
            `}
            onClick={() => {
              signUpPage();
            }}
          >
            Create Account
          </Button>
        </div>
      </div>
    </Modal>
  );
};
