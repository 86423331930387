import { MenuItem, Radio } from "@material-ui/core";
import { css } from "@emotion/css";
import { Bug, X } from "phosphor-react";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { history } from "../history";
import { CustomAxios } from "../redux/axios/axios";
import {
  Button,
  Div,
  Input,
  Loader,
  Modal,
  Select,
  Text,
} from "../shared/components";
import { flex, px } from "../shared/shared-styles";
import { colors } from "../shared/styles";
import { useSearchParams } from "../shared/use-search-params";

export const BugReport = ({ ...modalProps }) => {
  const { params } = useSearchParams();
  const [linearData, setLinearData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const submitLinearTicket = async () => {
    try {
      setIsLoading(true);
      const ticket =
        linearData?.TicketType === "urgent" ? "bug" : linearData?.TicketType;
      let data = {
        ...linearData,
        TicketType: ticket,
        UserTitle:
          linearData?.TicketType.toUpperCase() + " | " + linearData?.UserTitle,
      };
      if (data.TicketType === "feature") {
        data.BrowserContext = {};
      }
      await CustomAxios.post(`v1/app-feedback/tickets`, data);
      toast.success(`Successfully sent ticket!`);
      modalProps?.onClose();
    } catch (err) {
      toast.error("Error sending ticket...");
    } finally {
      setIsLoading(false);
    }
  };

  const updateLinearTicket = (event) => {
    const { name, value } = event.target || {};
    setLinearData((data) => ({ ...data, [name]: value }));
  };

  const resetTicket = () => {
    let logOutput = console?.allLogs?.toReversed().reduce((acc, item) => {
      if (!item || !item?.value || !item?.value?.[0]) {
        return acc;
      }

      let msg = item.value[0];

      // if item is an object, capture the value in a string
      if (typeof item.value[0] !== "string") {
        if (item.value[0].message) {
          msg = item.value[0].message;
        }

        if (item.type === "error" && item.value[0].stack) {
          msg += "\n" + item.value[0].stack.replace("\n", "\t\n");
        }
      }
      msg = msg.replace("\n", "\t\t\n");

      // eslint-disable-next-line
      return acc + `${item.datetime}  ${item.type}  ${msg}` + "\n";
    }, "Logs (descending):\n\n");

    const requestErrors = [...console.requestErrors].map((item) => {
      const url = item?.config?.url;
      const { Authorization, ...headers } = item?.config?.headers || {};
      return {
        message: item?.message,
        method: item.config?.method,
        headers,
        url,
        response: item?.response?.data,
      };
    });

    const BrowserContext = {
      timestamp: new Date().toISOString(),
      pathname: `https://app.be-brite.com${history.location.pathname}`,
      requestErrors,
    };
    const { pathname } = history.location;
    let UserTitle = "";
    if (
      pathname.includes("courses/") &&
      params?.courseModal === "design-styles"
    ) {
      UserTitle = "Design Styles";
    } else if (pathname.includes("courses/")) {
      UserTitle = "Editor Tools";
    } else if (pathname.includes("courses")) {
      UserTitle = "Guides";
    } else if (pathname.includes("analytics")) {
      UserTitle = "Analytics";
    } else if (pathname.includes("design-styles")) {
      UserTitle = "Design Styles";
    } else if (pathname.includes("messaging")) {
      UserTitle = "Messaging";
    } else if (pathname.includes("packages")) {
      UserTitle = "Benefits";
    } else if (pathname.includes("templates")) {
      UserTitle = "Templates";
    }
    setLinearData((value) => ({
      ...value,
      BrowserContext,
      TicketType: "urgent",
      UserTitle,
      UserDescription: "",
      LogOutput: logOutput,
    }));
  };

  useEffect(() => {
    if (modalProps?.display) {
      resetTicket();
    }
  }, [modalProps?.display, params, history.location.pathname]);

  return (
    <Modal {...modalProps}>
      <Div
        className={css`
          margin: ${px.xl};
          width: 500px;
        `}
      >
        <Div
          css={css`
            ${flex("jcsb aic")}
          `}
        >
          <Div
            css={css`
              ${flex("left")}
            `}
          >
            <Bug />
            <Text
              h3
              css={`
                margin-left: 16px;
              `}
            >
              Bug Report
            </Text>
          </Div>
          <Button styles="icon" onClick={modalProps.onClose}>
            <X />
          </Button>
        </Div>

        <Select
          css={`
            margin-top: 16px;
          `}
          placeholder="Problem"
          value={linearData?.UserTitle}
          onChange={updateLinearTicket}
          name="UserTitle"
        >
          <MenuItem value="Guides">Guides</MenuItem>
          <MenuItem value="Editor Tools">Editor Tools</MenuItem>
          <MenuItem value="Benefits">Benefits</MenuItem>
          <MenuItem value="Descision Tool">Decision Tool</MenuItem>
          <MenuItem value="Analytics">Analytics</MenuItem>
          <MenuItem value="Design Styles">Design Styles</MenuItem>
          <MenuItem value="Messaging">Messaging</MenuItem>
          <MenuItem value="Templates">Templates</MenuItem>
          <MenuItem value="Other">Other</MenuItem>
        </Select>

        <Input
          css={`
            margin-top: 16px;
          `}
          rows={3}
          placeholder="Description"
          value={linearData?.UserDescription}
          name="UserDescription"
          onChange={updateLinearTicket}
        />

        <Div
          css={css`
            ${flex("left")} margin-top: 16px;
            cursor: pointer;
          `}
          onClick={() =>
            setLinearData((data) => ({ ...data, TicketType: "urgent" }))
          }
        >
          <Radio
            checked={linearData?.TicketType === "urgent"}
            color="primary"
          />
          <Text label>This effects me now</Text>
        </Div>

        <Div
          css={css`
            ${flex("left")} cursor: pointer;
          `}
          onClick={() =>
            setLinearData((data) => ({ ...data, TicketType: "bug" }))
          }
        >
          <Radio checked={linearData?.TicketType === "bug"} color="primary" />
          <Text label>This effects me, but I can get by for now</Text>
        </Div>

        <Div
          css={css`
            ${flex("left")} cursor: pointer;
          `}
          onClick={() =>
            setLinearData((data) => ({ ...data, TicketType: "feature" }))
          }
        >
          <Radio
            checked={linearData?.TicketType === "feature"}
            color="primary"
          />
          <Text label>This could be useful in the future</Text>
        </Div>

        <Div
          css={css`
            ${flex("jcr aic")} margin-top: ${px.xl};
          `}
        >
          <Button
            secondary
            onClick={modalProps.onClose}
            css={`
              margin-right: 16px;
            `}
          >
            Cancel
          </Button>
          <Button
            onClick={submitLinearTicket}
            disabled={!linearData?.UserTitle || isLoading}
            css={`
              position: relative;
            `}
          >
            <Loader
              type="three-dots"
              isLoading={isLoading}
              className={css`
                background-color: ${colors.gray[300]};
                height: calc(100% - 6px);
                padding-bottom: 20px;
                ${flex("center")}
              `}
            />
            Submit
          </Button>
        </Div>
      </Div>
    </Modal>
  );
};
