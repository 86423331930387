import { css } from "@emotion/css";
import { useContext, useEffect, useRef } from "react";
import { Button, Text } from "../../shared/components";
import { useStateSync } from "../../shared/use-state-sync";
import { EditorDetailsContext } from "../provider/editor-detail-provider";
import { useStore } from "../../store-provider/use-store";
import { useParams } from "react-router";
import { useSearchParams } from "../../shared/use-search-params";
import { container } from "../../shared/shared-styles";
import { needsAttention, useRequests, useResource } from "../../react-query";
import { SlateComment } from "../../shared/components/slate-text/SlateComment";
import {
  convertSlateToComment,
  subscribeMentions,
} from "../../shared/components/slate-text/utils";
import { colors } from "../../shared/styles";
import { useSlateComment } from "../../shared/components/slate-text/useSlateComment";

export const CreateComment = () => {
  const { data } = useStore();
  const { businessId, courseId } = useParams();
  const {
    params: { pageId },
  } = useSearchParams();

  const needsAttentionResource = useResource(needsAttention, {
    search: {
      business_id: `eq.${businessId}`,
      resource_id: `eq.${courseId}`,
    },
  });
  const needsAttentionRequests = useRequests(needsAttentionResource);

  const {
    editorDetails: { commenting },
  } = useContext(EditorDetailsContext);

  const formRef = useRef();
  const inputRef = useRef();

  const [display, setDisplay] = useStateSync(
    commenting?.selectType === "component",
    [commenting?.selectedAt]
  );

  useEffect(() => {
    if (display) {
      formRef?.current?.scrollIntoView();
      inputRef?.current?.focus();
    }
  }, [display]);

  const onSubmit = async (e, slateContent) => {
    setDisplay(false);
    e?.preventDefault();
    // Get comment content
    const commentData = convertSlateToComment(slateContent);
    try {
      subscribeMentions("guide", courseId, commentData?.mentions);
      const postConfig = needsAttention?.utils?.getPost(
        {
          created_by_user_id: data?.user?.ID,
          business_id: businessId,
          resource_id: courseId,
          resource_type: "guide",
          note: commentData?.preview,
          metadata: {
            pageId,
            componentId: commenting?.componentId,
            username: data?.user?.FirstName || data?.user?.Email,
            contentSerializer: commentData?.serializer,
            content: commentData?.content,
          },
        },
        needsAttentionResource?.params
      );
      await needsAttentionRequests?.post(postConfig);
    } catch (err) {
      console.log(err);
    }
  };

  const slateComment = useSlateComment({ onSubmit });

  if (!display) {
    return null;
  }

  return (
    <form
      ref={formRef}
      className={css`
        scroll-margin-top: 15vh;
        position: absolute;
        top: calc(100% + 8px);
        left: calc(50% - 250px);
        z-index: 10000;
        width: 500px;
        ${container.box}
        padding: 16px;
      `}
      onClick={(e) => e.stopPropagation()}
    >
      <Text label bold>
        New Comment
      </Text>
      <SlateComment
        css={`
          border-top: 1px solid ${colors.gray[300]};
          padding: 0;
          margin-top: 8px;
          .slate-editable {
            padding: 0;
            margin-bottom: 16px;
            margin-top: 8px;
          }
        `}
        placeholder="Write a comment"
        slateComment={slateComment}
        mention={{ width: 400 }}
      />
      <Button
        css={`
          padding: 4px 8px;
          font-size: 0.95em;
          text-transform: none;
        `}
        type="submit"
        onClick={slateComment?.onSubmit}
      >
        Post
      </Button>
    </form>
  );
};
