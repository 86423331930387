import { css, cx } from "@emotion/css";
import { useContext, useRef } from "react";
import { Button, Div, Text } from "../../shared/components";
import {
  animation,
  container,
  flex,
  shadows,
} from "../../shared/shared-styles";
import { colors } from "../../shared/styles";
import { useStore } from "../../store-provider/use-store";
import { editorUtils } from "../provider/utils";
import { useEditorResource } from "../use-editor-resource";
import { ComponentIconMap } from "./component-icon-map";
import {
  CaretLeft,
  CaretRight,
  ClockCounterClockwise,
  Cube,
} from "phosphor-react";
import { EditorDetailsContext } from "../provider/editor-detail-provider";
import { History } from "./history";
import { useFeatureFlagPayload } from "posthog-js/react";

export const componentLabels = {
  textV2: "Text",
  text: "Text",
  button: "Button",
  image: "Image",
  line: "Line",
  space: "Space",
  document: "Document",
  video: "Video",
  website: "Website",
  benefits: "Benefits",
  pricing: "Pricing",
  network: "Network",
  "content-block": "Blocks",
  "dev-block": "Dev Blocks",
  accordion: "Accordion",
};

const tabStyle = css`
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border: 1px solid ${colors.gray[300]};
  background-color: white;
  ${container.hover}
  button {
    background: none;
    :hover {
      background-color: none;
      background: none;
    }
  }
  margin-bottom: 16px;
`;

export const EditorComponents = ({ editor, disabled: componentsDisabled }) => {
  const accordionFF = useFeatureFlagPayload("editor-component-accordion");

  const {
    data: { devMode },
  } = useStore();

  const {
    editorDetails: { componentToolbar, toolbarType = "components" },
    setProperties,
  } = useContext(EditorDetailsContext);

  const editorToolbarRef = useRef();

  const {
    state: { drag, course, editorType },
  } = editor;

  const { data: contentBlocks } = useEditorResource("blocks");

  const handleDragStart = (e, type) => {
    e.preventDefault();
    e.stopPropagation();
    editorUtils?.setProperty(editor, "selection", null);
    editorUtils?.setProperty(editor, "drag", {
      type,
      action: "create",
      isDragging: true,
    });
  };

  const Component = ({ type, label = "", disabled }) => {
    const style = css`
      width: 250px;
      height: 48px;
      min-height: 48px;
      border: 1px solid ${colors.gray[300]};
      border-radius: 8px;
      color: white;
      background-color: white;
      margin: 16px 0;
      user-select: none;
      transition: all 0.3s ease;
      ${!disabled &&
      `
        cursor: ${drag?.isDragging ? "grabbing" : "grab"};
        :hover {
          ${shadows.md}
          border: 1px solid transparent;
        }
        :active {
          cursor: grabbing;
        }
      `}
    `;

    const color = disabled ? colors.gray[400] : colors.black;

    return (
      <Div
        className={cx(
          css`
            ${flex("aic")}
          `,
          style
        )}
        disabled={disabled}
        draggable={!disabled}
        onDragStart={(e) => !disabled && handleDragStart(e, type)}
      >
        <Div
          className={css`
            width: 32px;
            margin: 0 16px;
            margin-right: 8px;
          `}
        >
          <ComponentIconMap type={type} size={24} color={color} />
        </Div>
        <Text color={color} styles="label">
          {label || componentLabels[type]}
        </Text>
      </Div>
    );
  };

  return (
    <Div
      flex="center start"
      className={css`
        height: 100%;
        background-color: ${colors.gray[100]};
        padding: 0;
        position: relative;
        ${animation("fadeIn", ".2s ease")}
        ${componentToolbar
          ? `
            min-width: 300px;
            max-width: 300px;
          `
          : `
            min-width: 0;
            max-width: 0;
            width: 0;
        `}
        transition: max-width .2s ease;
        transition: min-width 0.2s ease;
      `}
      ref={editorToolbarRef}
    >
      <Div
        className={css`
          position: absolute;
          right: 100%;
          top: 32px;
          .selected {
            background-color: ${colors.purple};
            svg {
              color: white;
            }
            :hover {
              background-color: none;
            }
          }
        `}
      >
        {devMode ? (
          <>
            <Div
              css={tabStyle}
              className={toolbarType === "components" ? "selected" : ""}
            >
              <Button
                hoverLabel={
                  <Text
                    css={`
                      color: white;
                      strong {
                        margin-left: 16px;
                      }
                    `}
                  >
                    Tools <strong>T</strong>
                  </Text>
                }
                hoverPlacement="right"
                styles="icon sm"
                onClick={() => setProperties({ toolbarType: "components" })}
              >
                <Cube
                  size={24}
                  color={toolbarType === "components" ? "white" : colors.black}
                />
              </Button>
            </Div>
            <Div
              css={tabStyle}
              className={toolbarType === "history" ? "selected" : ""}
            >
              <Button
                hoverLabel={
                  <Text
                    css={`
                      color: white;
                      strong {
                        margin-left: 16px;
                      }
                    `}
                  >
                    History <strong>G</strong>
                  </Text>
                }
                hoverPlacement="right"
                styles="icon sm"
                onClick={() => setProperties({ toolbarType: "history" })}
              >
                <ClockCounterClockwise
                  size={24}
                  color={toolbarType === "history" ? "white" : colors.black}
                />
              </Button>
            </Div>
          </>
        ) : null}
      </Div>
      <Div
        className={css`
          position: absolute;
          right: calc(100%);
          bottom: 34px;
        `}
      >
        <Div
          className={css`
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
            background-color: white;
            border: 1px solid ${colors.gray[300]};
            height: 40px;
            ${flex("center")}
            ::after {
              content: "";
              position: absolute;
              right: calc(100% + 6px);
              top: 8px;
            }
            ${container.hover}
            z-index: 100000;
            button {
              :hover {
                background: none;
              }
            }
            :not(:hover) {
              .hover-label {
                display: none;
                ${animation("fadeIn", ".3s ease")}
              }
            }
          `}
        >
          <Div
            className="hover-label"
            css={css`
              position: absolute;
              right: calc(100% + 8px);
              background-color: ${colors.black};
              border-radius: 8px;
              padding: 8px;
              p {
                color: white;
                min-width: max-content;
              }
              strong {
                margin-left: 8px;
              }
            `}
          >
            <Text>
              Toggle Toolbar <strong>T</strong>
            </Text>
          </Div>
          <Button
            styles="icon sm"
            onClick={() =>
              setProperties({
                componentToolbar: !componentToolbar,
              })
            }
          >
            {componentToolbar ? (
              <CaretRight size={24} />
            ) : (
              <CaretLeft size={24} />
            )}
          </Button>
        </Div>
      </Div>
      <Div
        className={css`
          box-sizing: border-box;
          height: 100%;
          overflow-y: auto;
          overflow-x: hidden;
          max-height: 100vh;
          padding: 0 16px;
        `}
      >
        {devMode && toolbarType === "history" ? (
          <History editor={editor} />
        ) : (
          <>
            <Text
              label
              bold
              css={`
                margin-top: 16px;
              `}
            >
              Basic
            </Text>
            <Component type="textV2" />
            <Component type="button" />
            <Component type="image" />
            <Component type="line" />
            <Component type="space" />
            <Component type="document" />
            <Component type="video" />
            <Component type="website" />
            {accordionFF?.value ? <Component type="accordion" /> : null}
            <Component type="content-block" disabled={!contentBlocks?.length} />
            <Text
              label
              bold
              css={`
                margin-top: 32px;
                margin-bottom: 0;
              `}
            >
              Smart Components
            </Text>
            <Component
              type="benefits"
              disabled={
                !devMode &&
                editorType === "page" &&
                (!course?.BenefitsPackageID ||
                  course?.BenefitsPackageID ===
                    "00000000-0000-0000-0000-000000000000")
              }
            />
            <Component
              type="pricing"
              disabled={
                !devMode &&
                editorType === "page" &&
                (!course?.BenefitsPackageID ||
                  course?.BenefitsPackageID ===
                    "00000000-0000-0000-0000-000000000000")
              }
            />
            <Component
              type="network"
              disabled={
                !devMode &&
                editorType === "page" &&
                (!course?.BenefitsPackageID ||
                  course?.BenefitsPackageID ===
                    "00000000-0000-0000-0000-000000000000")
              }
            />
            {devMode && (
              <>
                <Text h4>Dev</Text>
                <Component type="dev-block" />
                <Component type="text" label="Text V1" />
              </>
            )}
          </>
        )}
      </Div>
      <Div
        css={css`
          transition: all 1s ease;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: rgba(255, 255, 255);
          z-index: 1000;
          ${componentsDisabled
            ? `
                  opacity: .5;
                  backdrop-filter: blur(8px); `
            : `
                  pointer-events: none;
                  opacity: 0;
                `}
        `}
      />
    </Div>
  );
};
