import { css } from "@emotion/css";
import { useEffect, useMemo } from "react";
import { Div, Text } from "../../../shared/components";
import { ImageSquare } from "phosphor-react";
import { colors } from "../../../shared/styles";
import { CroppedImage } from "./copped-image";
import { flex } from "../../../shared/shared-styles";
import { useStateSync } from "../../../shared/use-state-sync";
import { useEditorResource } from "../../use-editor-resource";
import { ReactComponent as SmartImage } from "../../../images/smart-image.svg";

const isSmartText = (string) =>
  string?.startsWith("{{") && string?.endsWith("}}");

export const Image = ({ element }) => {
  const { attributes, crop = null } = element;
  const [error, setError] = useStateSync(false, [attributes]);

  const { data: styles } = useEditorResource("styles");
  const { data: smartFields } = useEditorResource("liveSmartFields");

  const combinedStyles = {
    ...styles?.image,
    ...attributes?.style,
  };

  const isSmartField = isSmartText(attributes?.src);

  useEffect(() => {
    setError(false);
  }, []);

  const sourceLink = useMemo(() => {
    const { src = "" } = attributes;

    if (isSmartField && smartFields?.[src]?.Success) {
      return smartFields?.[src]?.Value !== "<no value>"
        ? smartFields?.[src]?.Value
        : "";
    }
    return src;
  }, [
    element,
    isSmartField,
    attributes?.src,
    smartFields?.[attributes?.src]?.Value,
  ]);

  const validUrl = useMemo(() => {
    try {
      new URL(sourceLink);
      return true;
    } catch (err) {
      return !!sourceLink ? false : null;
    }
  }, [sourceLink]);

  const brokenSmartImage =
    isSmartField &&
    attributes?.src in smartFields &&
    !smartFields?.[attributes?.src]?.Success;

  const handleDoubleClick = (e) => {
    if (brokenSmartImage) {
      e.stopPropagation();
    }
  };

  return (
    <>
      {validUrl === false || error || !sourceLink ? (
        <Div
          data-component={true}
          className={css`
            ${flex("aic column")}
            background-color: ${colors.gray[100]};
            border-radius: 8px;
            width: 100%;
            min-height: 150px;
            padding-top: 32px;
            ${brokenSmartImage ? `outline: 2px solid ${colors.red[100]};` : ""}
            * {
              pointer-events: none;
            }
            .image-hover {
              padding: 8px 0;
              padding-bottom: 16px;
              text-align: center;
            }
            :not(:hover) {
              .image-hover {
                visibility: hidden;
              }
            }
          `}
          onDoubleClick={handleDoubleClick}
        >
          {(!brokenSmartImage && isSmartField) || (!!sourceLink && !error) ? (
            <SmartImage
              className={css`
                width: 32px;
                height: 32px;
              `}
            />
          ) : (
            <ImageSquare
              size={48}
              color={error && !!sourceLink ? colors.red[100] : colors.black}
            />
          )}

          {brokenSmartImage ? (
            <Text
              css={`
                padding-top: 8px;
                text-align: center;
                color: ${colors.red[100]};
              `}
            >
              Smart Image <br />
              needs attention
            </Text>
          ) : !sourceLink && isSmartField ? (
            <Text
              css={`
                padding: 8px;
                text-align: center;
                padding-top: 16px;
                color: ${colors.black};
              `}
            >
              Smart Image is unavailable
            </Text>
          ) : !validUrl && !!attributes.src ? (
            <Text color={colors.red[100]}>Invalid Image URL</Text>
          ) : error && !!sourceLink ? (
            <Text
              css={`
                color: ${colors.red[100]};
              `}
            >
              Image failed to load
            </Text>
          ) : (
            <Text styles="bold label">Image</Text>
          )}
          {!isSmartField ? (
            <Text label className="image-hover">
              Double Click for more options.
            </Text>
          ) : null}
        </Div>
      ) : crop === null ? (
        <img
          src={sourceLink}
          alt={attributes.alt}
          data-component={true}
          style={combinedStyles}
          onError={() => setError(true)}
        />
      ) : (
        <CroppedImage
          style={attributes.style}
          width={combinedStyles.width}
          crop={crop}
          imageProps={{
            src: sourceLink,
            alt: attributes.alt,
            "data-component": true,
          }}
        />
      )}
    </>
  );
};
