import { css } from "@emotion/css";
import { useState } from "react";
import { toast } from "react-toastify";
import { CustomAxios } from "./redux/axios/axios";
import { Button, Div, Input, Modal, Text } from "./shared/components";
import { flex } from "./shared/shared-styles";
import { useForm } from "./shared/use-form";
import { useStore } from "./store-provider/use-store";
import { Box } from "./shared/components/box";
import { colors } from "./shared/styles";
import { UserAvatar } from "./shared/components/UserAvatar";
import { useUploadMedia } from "./shared/useUploadMedia";
import { fileTypesMap } from "./Documents/FullscreenUploader";

export const UserSettings = ({ ...modalProps }) => {
  const {
    data: { user },
    setStore,
  } = useStore();

  const [loading, setLoading] = useState(false);
  const form = useForm(user, [user?.ModifiedAt, modalProps?.display]);

  const submitUpdates = async () => {
    const old = { ...user };
    try {
      setLoading(true);
      const updates = { ...user, ...form?.values };
      setStore("user", updates);
      await CustomAxios.put(`v1/users/${user.ID}`, updates);
      modalProps.onClose();
    } catch (err) {
      setStore("user", old);
      toast.error(`Failed to update user.`);
    } finally {
      setLoading(false);
    }
  };

  const removeImage = () => {
    form.merge({
      Preferences: { ImageURL: "" },
    });
  };

  const onUploadSuccess = (list) => {
    const item = list[0];
    form.merge({
      Preferences: { ImageURL: item?.PublicURL },
    });
  };

  const { getRootProps, getInputProps } = useUploadMedia({
    setLoading,
    onSuccess: onUploadSuccess,
    accept: [fileTypesMap.images],
    maxFiles: 1,
  });

  return (
    <Modal {...modalProps}>
      <Modal.Paper>
        <Modal.Header title="User Settings" onClose={modalProps.onClose} />
        <Modal.Body>
          <Text
            label
            bold
            css={`
              margin-bottom: 8px;
            `}
          >
            Upload Your Profile Image
          </Text>
          <Box flex="left">
            <UserAvatar user={form?.values} size={64} />
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <Button
                secondary
                css={`
                  margin-left: 16px;
                  padding: 4px 16px;
                `}
              >
                {!!form?.values?.Preferences?.ImageURL ? "Change" : "Upload"}
              </Button>
            </div>
            {!!form?.values?.Preferences?.ImageURL ? (
              <Button
                secondaryLight
                css={`
                  margin-left: 16px;
                  padding: 4px 16px;
                `}
                onClick={removeImage}
              >
                Remove
              </Button>
            ) : null}
          </Box>
          <Div
            css={css`
              ${flex("space-between")}
              margin: 16px 0;
            `}
          >
            <Div
              css={css`
                flex-grow: 1;
              `}
            >
              <Text
                label
                bold
                css={`
                  margin-bottom: 8px;
                `}
              >
                First Name
              </Text>
              <Input
                css={`
                  margin-bottom: 8px;
                `}
                {...form?.getProperty("FirstName")}
              />
            </Div>
            <Div
              css={css`
                flex-grow: 1;
                margin-left: 8px;
              `}
            >
              <Text
                label
                bold
                css={`
                  margin-bottom: 8px;
                `}
              >
                Last Name
              </Text>
              <Input
                css={`
                  margin-bottom: 8px;
                `}
                {...form?.getProperty("LastName")}
              />
            </Div>
          </Div>
          <Text
            label
            bold
            css={`
              margin-bottom: 8px;
            `}
          >
            Choose Your Color
          </Text>
          <Box flex="left">
            {Object.values(colors.lite).map((color) => (
              <Box
                css={`
                  border-radius: 50%;
                  background-color: ${color};
                  width: 32px;
                  height: 32px;
                  border: 2px solid white;
                  margin-right: 8px;
                  cursor: pointer;
                  ${form?.values?.Preferences?.Color === color
                    ? `
                    outline: 2px solid ${colors.black};
                    `
                    : ""}
                `}
                onClick={() => form?.merge({ Preferences: { Color: color } })}
              />
            ))}
          </Box>
        </Modal.Body>
        <Modal.Actions>
          <Button secondary onClick={modalProps.onClose}>
            Cancel
          </Button>
          <Button
            disabled={!form?.hasChanges || loading}
            onClick={submitUpdates}
          >
            Save
          </Button>
        </Modal.Actions>
      </Modal.Paper>
    </Modal>
  );
};
