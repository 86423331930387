import { css } from "@emotion/css";
import { colors } from "../styles";

export const Divider = () => {
  return (
    <div
      className={css`
        height: 1px;
        align-self: stretch;
        background: ${colors.gray[300]};
      `}
    />
  );
};
