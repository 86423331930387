import { useState, useMemo, useEffect } from "react";
import { css } from "@emotion/css";
import { Div, Modal, Text, Button } from "../shared/components";
import { BriteLoader } from "../shared/components/brite-loader";
import { isEmpty } from "lodash";

import { colors } from "../shared/styles";
import { history } from "../history";
import { flex, px, animation } from "../shared/shared-styles";
import {
  ArrowLeft,
  CaretUp,
  CaretDown,
  X,
  Check,
  Info,
  CalendarBlank,
  WarningCircle,
  UploadSimple,
} from "phosphor-react";
import { cloneDeep } from "lodash";

import { DateRangePicker } from "react-dates";

import DonutChart from "react-donut-chart";

import TextField from "@material-ui/core/TextField";

import moment from "moment";

import { Dropdown } from "../shared/components/dropdown";
import {
  IconButton,
  Tooltip,
  Button as ButtonMUI,
  MenuItem,
  Select,
} from "@material-ui/core";
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
  ZoomableGroup,
} from "react-simple-maps";

import { EnhancedTable } from "./EnhancedTable";
import { LineChart, ColumnChart } from "react-chartkick";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import { groupBy } from "lodash";
import ShareModal from "./ShareModal";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  formControl: {
    "label + &": {
      display: "none",
    },
    minWidth: 223,
    color: "#25282D",

    fontFamily: "Roboto",
    fontSize: 16,
    fontWeight: 400,
    height: 48,
    letterSpacing: "0.15px",
    borderRadius: 8,

    "> .MuiOutlinedInput-notchedOutline": {
      "> .PrivateNotchedOutline-legend-20": {
        width: "unset",
      },
    },
    "& legend": { display: "none" },
    "& fieldset": { top: 0 },
  },

  menuStyle: {
    color: "#25282D",
    marginLeft: "8px",
    marginRight: "8px",
    fontFamily: "Roboto",
    fontSize: 16,
    fontWeight: 400,

    letterSpacing: "0.15px",
    paddingLeft: "8px",
    paddingRight: "8px",

    "&:hover": {
      borderRadius: "8px",
      backgroundColor: "#F5F7FA",
    },
    "&.Mui-selected": {
      borderRadius: "8px",
      backgroundColor: "#F5F7FA",
      "&:hover": {
        borderRadius: "8px",
        backgroundColor: "#F5F7FA",
      },
    },
  },
}));

const useOutlinedInputStyles = makeStyles((theme) => ({
  root: {
    "label + &": {
      display: "none",
    },
    "& $notchedOutline": {
      borderColor: "#D1DAE3",
    },
    "&:hover $notchedOutline": {
      borderColor: "#25282D",
    },
    "&$focused $notchedOutline": {
      borderColor: "#25282D",
    },
    "&$focused": {
      backgroundColor: "white",
    },

    "> .MuiOutlinedInput-notchedOutline": {
      "> .PrivateNotchedOutline-legend-20": {
        width: "unset",
      },
    },
    "& legend": { display: "none" },
    "& fieldset": { top: 0 },
  },
  focused: {
    backgroundColor: "transparent",
  },
  notchedOutline: {},
}));

const graphColors = [
  "#C613DB", // purple/pink
  "#F900D1", // pink
  "#FF2567", // pink/orange
  "#FF5C45", // orange
  "#FF8424", // orange/yellow
  "#FFA600", // yellow
];

const chartLegendOptions = {
  position: "top",
  align: "end",
  display: true,
  labels: {
    usePointStyle: true,
    pointStyle: "circle",
    boxHeight: 7,
    filter: function (item, chart) {
      // Remove legend items for datasets that have no data
      let hasEntries = false;
      let dataset = chart.datasets[item.datasetIndex];
      for (let j = 0; j < dataset.data.length; j++) {
        if (dataset.data[j] > 0) {
          hasEntries = true;
        }
      }
      return hasEntries;
    },
  },
};

const EmptyStateSmall = () => {
  return (
    <div
      className={css`
        margin-top: 24px;
        margin-bottom: 24px;
      `}
    >
      <div
        className={css`
          color: var(--500, #66737f);
          text-align: center;
          font-feature-settings: "clig" off, "liga" off;
          /* Caption */
          font-family: Roboto;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px; /* 171.429% */
          letter-spacing: 0.15px;
        `}
      >
        No Data to Display
      </div>
    </div>
  );
};

const EmptyState = () => {
  return (
    <div
      className={css`
        margin: 32px;

        display: flex;

        justify-content: center;
        align-items: center;
      `}
    >
      <div
        className={css`
          text-align: center;

          max-width: 300px;
        `}
      >
        <div
          className={css`
            color: var(--700, #25282d);
            text-align: center;
            font-feature-settings: "clig" off, "liga" off;
            /* H4 */
            font-family: Roboto;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: 28px; /* 155.556% */
            letter-spacing: 0.15px;
            margin-bottom: 16px;
          `}
        >
          No Data to Display
        </div>
        <div
          className={css`
            color: var(--500, #66737f);
            text-align: center;
            font-feature-settings: "clig" off, "liga" off;
            /* Body - Regular */
            font-family: Roboto;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px; /* 150% */
            letter-spacing: 0.15px;
          `}
        >
          Try setting another date range or sharing your guide to get more
          analytics data.
        </div>
      </div>
    </div>
  );
};

const graphStyle = (extraStyling) => {
  let styling =
    `
    border-radius: 8px;
    border: 1px solid ${colors.gray[300]};
    margin-top: 12px;
    margin-bottom: 12px;
    padding: 24px;
    color: #25282d;
    font-feature-settings: "clig" off, "liga" off;
  
    /* H4 */
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 133.333% */
    letter-spacing: 0.15px;
  ` + extraStyling;

  return css`
    ${styling}
  `;
};

const TRAFFIC_TAB = "traffic";
const ENGAGEMENT_TAB = "engagement";
const CONVERSION_TAB = "conversion";

export const GuideAnalytics = (props) => {
  const classes = useStyles();
  const outlinedInputClasses = useOutlinedInputStyles();
  const [filter, setFilter] = useState("");
  const [tab, setTab] = useState(TRAFFIC_TAB);
  const [learnMore, showLearnMore] = useState("");
  const [shareModalOpen, setShareModalOpen] = useState(false);

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const filteredList = useMemo(() => {
    if (filter) {
      return props.guides.filter(({ Name }) =>
        Name.toLowerCase().includes(filter.toLowerCase())
      );
    }
    return props.guides;
  }, [filter, props.guides.length]);

  const timeOptions = useMemo(() => {
    let options = [
      "Last 7 days",
      "Last 30 days",
      "Last 90 days",
      "Last Year",
      "Custom",
    ];
    if (props?.guide?.Type === "openenrollment") {
      options.unshift("Open Enrollment");
    }
    return options;
  }, [props.guide]);

  useEffect(() => {
    if (props?.guide?.Type === "openenrollment") {
      props.setTimeframe("Open Enrollment");
    }
  }, [props?.guide?.Type]);

  //We started collecting unique user info on Jan 19. don't show the card if we don't have data i.e. before Jan 19. Same for page view data and March 30th
  const verifyAfterDate = (d) => {
    return props.startDate > d;
  };

  const getMinDate = (format) => {
    let now = moment();
    return now.subtract(7, "day").format(format);
  };

  const getMaxDate = (format) => {
    return moment().format(format);
  };

  const calculateCourseScore = () => {
    let score = 0;
    let runningTotal = 0;
    for (let question of props.pageViewsStatistics?.engagementOverview
      ?.Questions) {
      runningTotal += Math.round(
        (question.AnsweredCorrect / question.Total) * 100
      );
    }
    if (props.pageViewsStatistics?.engagementOverview?.Questions.length > 0) {
      score = Math.round(
        runningTotal /
          props.pageViewsStatistics?.engagementOverview?.Questions.length
      );
    }
    return score;
  };

  const countTotalResponses = () => {
    let runningTotal = 0;
    for (let question of props.pageViewsStatistics?.engagementOverview
      ?.Questions) {
      runningTotal += question.Total;
    }

    return runningTotal;
  };

  const calculateCourseTime = (timeInSeconds) => {
    let h = Math.floor(timeInSeconds / 3600);
    let m = Math.floor((timeInSeconds % 3600) / 60);
    let s = Math.floor((timeInSeconds % 3600) % 60);

    return (
      <div
        className={css`
          display: flex;
          align-items: center;
          gap: 12px;
        `}
      >
        {h > 0 && (
          <div
            className={css`
              display: flex;
              align-items: center;
              gap: 4px;
            `}
          >
            <div
              className={css`
                font-family: "Roboto";
                font-style: normal;
                font-weight: 700;
                font-size: 34px;
                line-height: 48px;
                /* identical to box height, or 141% */

                letter-spacing: 0.25px;

                color: #25282d;
              `}
            >
              {h}
            </div>
            <div
              className={css`
                color: var(--500, #66737f);
                font-feature-settings: "clig" off, "liga" off;
                /* Body - Regular */
                font-family: Roboto;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;

                letter-spacing: 0.15px;
              `}
            >
              Hrs
            </div>
          </div>
        )}
        <div
          className={css`
            display: flex;
            align-items: center;
            gap: 4px;
          `}
        >
          <div
            className={css`
              font-family: "Roboto";
              font-style: normal;
              font-weight: 700;
              font-size: 34px;
              line-height: 48px;
              /* identical to box height, or 141% */

              letter-spacing: 0.25px;

              color: #25282d;
            `}
          >
            {m}
          </div>
          <div
            className={css`
              color: var(--500, #66737f);
              font-feature-settings: "clig" off, "liga" off;
              /* Body - Regular */
              font-family: Roboto;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;

              letter-spacing: 0.15px;
            `}
          >
            Min
          </div>
        </div>
        {h <= 0 && (
          <div
            className={css`
              display: flex;
              align-items: center;
              gap: 4px;
            `}
          >
            <div
              className={css`
                font-family: "Roboto";
                font-style: normal;
                font-weight: 700;
                font-size: 34px;
                line-height: 48px;
                /* identical to box height, or 141% */

                letter-spacing: 0.25px;

                color: #25282d;
              `}
            >
              {s}
            </div>
            <div
              className={css`
                color: var(--500, #66737f);
                font-feature-settings: "clig" off, "liga" off;
                /* Body - Regular */
                font-family: Roboto;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;

                letter-spacing: 0.15px;
              `}
            >
              Secs
            </div>
          </div>
        )}
      </div>
    );
  };

  const getuniqueUsersPercentage = (users) => {
    if (props.eligibleEmployee) {
      return Math.round((users / props.eligibleEmployee) * 100);
    } else {
      return 100;
    }
  };

  const getEnrollButtonClickedPercentage = (clicks) => {
    if (
      props.analytics.Activity &&
      props.analytics.Activity.length &&
      props.analytics.Activity.length > 0
    ) {
      return Math.round((clicks / props.analytics.Activity.length) * 100);
    } else {
      return 0;
    }
  };

  const getDecisionToolCompletionsPercentage = (completions) => {
    if (
      props.analytics.Activity &&
      props.analytics.Activity.length &&
      props.analytics.Activity.length > 0
    ) {
      return Math.round((completions / props.analytics.Activity.length) * 100);
    } else {
      return 0;
    }
  };

  const getDeviceCount = () => {
    return (
      props?.analytics?.TotalMobileVisits + props?.analytics?.TotalDesktopVisits
    );
  };

  const getDeviceData = () => {
    let mobile = 0;
    let desktop = 0;
    if (props.analytics.UniqueUsers && props.analytics.TotalMobileVisits) {
      mobile = Math.round(
        (props.analytics.TotalMobileVisits / props.analytics.UniqueUsers) * 100
      );
    }

    if (props.analytics.UniqueUsers && props.analytics.TotalDesktopVisits) {
      desktop = Math.round(
        (props.analytics.TotalDesktopVisits / props.analytics.UniqueUsers) * 100
      );
    }

    return [
      {
        label: "Mobile",
        value: mobile,
      },
      {
        label: "Desktop",
        value: desktop,
      },
    ];
  };

  const getMaxLocationCount = () =>
    props.analytics.Locations
      ? props.analytics.Locations.sort((a, b) => b.Count - a.Count)[0].Count
      : 0;

  const getMinLocationCount = () =>
    props.analytics.Locations
      ? props.analytics.Locations.sort((a, b) => a.Count - b.Count)[0].Count
      : 0;

  const getDotRadius = (count) => {
    let min = getMinLocationCount();
    let max = getMaxLocationCount();

    if (min === max) {
      max = min + 1;
    }
    return ((count - min) / (max - min)) * (50 - 8) + 8;
  };

  const getDevicePercentage = (device) => {
    if (device === "desktop") {
      let desktop = 0;
      if (props.analytics.TotalDesktopVisits) {
        desktop = Math.round(
          (props.analytics.TotalDesktopVisits /
            (props.analytics.TotalDesktopVisits +
              props.analytics.TotalMobileVisits)) *
            100
        );
      }
      return `${desktop}%`;
    } else {
      let mobile = 0;
      if (props.analytics.TotalMobileVisits) {
        mobile = Math.round(
          (props.analytics.TotalMobileVisits /
            (props.analytics.TotalDesktopVisits +
              props.analytics.TotalMobileVisits)) *
            100
        );
      }

      return `${mobile}%`;
    }
  };
  const countActivityByDay = () => {
    var groups = groupBy(props.analytics.Activity, function (date) {
      return moment(date.DateStarted).startOf("day").format("YYYY-MM-DD");
    });
    let obj = {};

    const keys = Object.keys(groups);
    for (const key of keys) {
      obj[key] = groups[key].length;
    }
    return obj;
  };

  const countEnrollmentClicksByDay = () => {
    var groups = groupBy(
      props.analytics.conversion.EnrollmentClicks,
      function (date) {
        return moment(date.Timestamp).startOf("day").format("YYYY-MM-DD");
      }
    );
    let obj = {};

    const keys = Object.keys(groups);
    for (const key of keys) {
      obj[key] = groups[key].length;
    }
    return obj;
  };

  const countUniqueActivityByDay = () => {
    let userTracker = {};
    let uniqueDailyActivity = [];
    props.analytics.Activity.forEach((activity) => {
      let uniqueKey =
        activity.UserIdentifier +
        String(
          moment(activity.DateStarted).startOf("day").format("YYYY-MM-DD")
        );
      if (!userTracker[uniqueKey]) {
        userTracker[uniqueKey] = true;
        uniqueDailyActivity.push(activity);
      }
    });

    var groups = groupBy(uniqueDailyActivity, function (activity) {
      return moment(activity.DateStarted).startOf("day").format("YYYY-MM-DD");
    });
    let obj = {};

    const keys = Object.keys(groups);
    for (const key of keys) {
      obj[key] = groups[key].length;
    }
    return obj;
  };

  const countUniqueEnrollmentClicksByDay = () => {
    let userTracker = {};
    let uniqueDailyActivity = [];
    props.analytics.conversion.EnrollmentClicks.forEach((activity) => {
      let uniqueKey =
        activity.UserID +
        String(moment(activity.Timestamp).startOf("day").format("YYYY-MM-DD"));
      if (!userTracker[uniqueKey]) {
        userTracker[uniqueKey] = true;
        uniqueDailyActivity.push(activity);
      }
    });

    var groups = groupBy(uniqueDailyActivity, function (activity) {
      return moment(activity.Timestamp).startOf("day").format("YYYY-MM-DD");
    });
    let obj = {};

    const keys = Object.keys(groups);
    for (const key of keys) {
      obj[key] = groups[key].length;
    }
    return obj;
  };

  const countActivityByDayAndMedium = (medium) => {
    var groups = groupBy(props.analytics.Activity, function (activity) {
      return moment(activity.DateStarted).startOf("day").format("YYYY-MM-DD");
    });
    let activityCountsByDay = {};
    let totalActivity = 0;

    const keys = Object.keys(groups);
    for (const key of keys) {
      // loop over the array of activities for that day and only count the ones that match the medium
      let filtered = groups[key].filter((activity) => {
        return (
          activity.CommunicationMedium === medium ||
          activity.LinkTracker === medium
        );
      });
      totalActivity += filtered?.length;
      activityCountsByDay[key] = filtered?.length;
    }

    return [activityCountsByDay, totalActivity];
  };
  const getTotalCount = (listKey) => {
    let count = props.analytics?.conversion?.DecisionToolCompletions || 0;
    return count;
  };

  const bartooltip = (tooltipItems) => {
    let pageName = "No Page Name";
    if (tooltipItems && tooltipItems.length > 0) {
      let pageOrder = tooltipItems[0].label;
      for (let page of props.pageViewsStatistics.data) {
        if (pageOrder === page.PageOrder) {
          pageName = page.PageName;
        }
      }
    }

    return pageName;
  };

  const afterBodytooltip = (tooltipItems) => {
    let visits = 0;
    if (tooltipItems) {
      visits = tooltipItems.formattedValue;
    }
    return `${visits} Visits`;
  };

  const getGraphData = () => {
    return [
      {
        name: "Total Visitors",
        data: countActivityByDay(),
        color: colors.black,
      },
      {
        name: "Unique Visitors",
        data: countUniqueActivityByDay(),
        color: colors.purple,
      },
    ];
  };

  const getEnrollmentClicksData = () => {
    return [
      {
        name: "Total",
        data: countEnrollmentClicksByDay(),
        color: colors.black,
      },
      {
        name: "Unique Visitors",
        data: countUniqueEnrollmentClicksByDay(),
        color: colors.purple,
      },
    ];
  };

  const getSourcesGraphData = () => {
    let [otherData, otherCount] = countActivityByDayAndMedium(""); // undefined medium is "other"
    let [emailData, emailCount] = countActivityByDayAndMedium("email");
    let [slackData, slackCount] = countActivityByDayAndMedium("slack");
    let [textData, textCount] = countActivityByDayAndMedium("text");
    let [teamsData, teamsCount] = countActivityByDayAndMedium("teams");
    let [pdfQRCodeData, pdfQRCodeCount] =
      countActivityByDayAndMedium("pdf-qr-code");
    let [pdfQRCodeManualData, pdfQRCodeManualCount] =
      countActivityByDayAndMedium("pdf-qr-code-manual");

    let dataWithSources = [
      {
        name: "Email",
        data: emailData,
        count: emailCount,
      },
      {
        name: "Texting",
        data: textData,
        count: textCount,
      },
      {
        name: "Slack",
        data: slackData,
        count: slackCount,
      },
      {
        name: "Teams",
        data: teamsData,
        count: teamsCount,
      },
      {
        name: "Brite Handout",
        data: pdfQRCodeData,
        count: pdfQRCodeCount,
      },
      {
        name: "QR Code",
        data: pdfQRCodeManualData,
        count: pdfQRCodeManualCount,
      },
    ].filter(({ count }) => count > 0);

    const displayColors = [
      "#6D44FB", // purple
      "#C613DB", // purple/pink
      "#F900D1", // pink
      "#FF2567", // pink/orange
      "#FF5C45", // orange
      "#FF8424", // orange/yellow
      "#FFA600", // yellow
    ];

    let totalCount = 0;

    // Assign colors based on most frequent entries.
    let colorCodedData = dataWithSources.sort((a, b) => b.count - a.count);
    for (let i = 0; i < colorCodedData.length; i++) {
      colorCodedData[i].color = displayColors[i];
      totalCount += colorCodedData[i].count;
    }

    // Add new entry to sortedData for "Other" sources (black, to give comms features more pop)
    colorCodedData.push({
      name: "Other",
      data: otherData,
      count: otherCount,
      color: "#25282D",
    });
    totalCount += otherCount;
    colorCodedData = colorCodedData.sort((a, b) => b.count - a.count);

    return { colorCodedData, totalCount };
  };

  const getRecommendationPercentage = (recommendation, listKey) => {
    let totalCount = getTotalCount(listKey);
    if (listKey === "Buyup") {
      return recommendation.Count / totalCount;
    }
    return recommendation.SuggestedCount / totalCount;
  };

  const calculateEnrollmentConfidence = () => {
    let count = 0;
    for (let confidence of props.analytics.enrollmentConfidence) {
      if (confidence.confident) {
        count += 1;
      }
    }
    if (props.analytics.enrollmentConfidence.length === 0) {
      return 0;
    }
    return Math.floor(
      (count / props.analytics.enrollmentConfidence.length) * 100
    );
  };

  if (shareModalOpen) {
    return (
      <ShareModal
        guideId={props.guide.ID}
        onClose={() => {
          setShareModalOpen(false);
        }}
      />
    );
  }

  return (
    <Modal full display={true}>
      <div
        className={css`
          display: flex;
          flex-direction: column;
          height: 100%;
          width: 100%;
        `}
      >
        <Div
          css={css`
            ${flex("space-between")} width: 100%;

            height: 96px;
            padding: 32px;
            border-bottom: 1px solid var(--300, #d1dae3);
            background-color: white;
            z-index: 100000000;
          `}
        >
          <Div
            css={css`
              ${flex("left")}
            `}
          >
            {props.close && (
              <Button styles="icon" onClick={props.close}>
                <ArrowLeft />
              </Button>
            )}

            <Dropdown
              button={({ open }) => (
                <Div
                  css={css`
                    ${flex("space-between")}
                    margin-left: 16px;
                  `}
                >
                  <Text
                    h2
                    className={css`
                      margin-right: 16px;
                      max-width: 450px;
                    `}
                    ellipsis
                  >
                    {props.guideLoaded ? props.guide?.Name : "Loading..."}
                  </Text>
                  {props.selectCourse && (open ? <CaretUp /> : <CaretDown />)}
                </Div>
              )}
              listWidth="450px"
            >
              {props.selectCourse && (
                <div
                  className={css`
                    display: flex;
                    flex-direction: column;
                    height: 100%;
                    width: 100%;
                  `}
                >
                  <div
                    className={css`
                      width: 95%;
                      margin-top: 12px;
                      margin-bottom: 12px;
                      margin-left: 12px;
                    `}
                  >
                    <TextField
                      size={"small"}
                      variant="outlined"
                      placeholder="Search for a guide"
                      style={{
                        width: "100%",
                      }}
                      value={filter}
                      onChange={(e) => setFilter(e.target.value)}
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    />
                  </div>

                  {filteredList.map((guide) => (
                    <Div
                      key={guide.ID}
                      css={css`
                        border-bottom: 1px solid ${colors.gray[300]};
                      `}
                    >
                      <Div
                        css={css`
                          padding: 16px;

                          cursor: pointer;
                          :hover {
                            background-color: ${colors.gray[100]};
                          }
                          transition: background-color 0.2s ease;
                        `}
                        onClick={() => {
                          props.selectCourse(guide);
                        }}
                      >
                        <Text label>{guide.Name}</Text>
                      </Div>
                    </Div>
                  ))}
                </div>
              )}
            </Dropdown>
          </Div>
          <Div
            css={css`
              ${flex("aic")}
            `}
          >
            {props.guideLoaded && (
              <Div
                css={css`
                  ${flex("right")}
                  transition: all .2s ease;
                `}
              >
                <div
                  className={css`
                    margin-right: ${px.lg};

                    padding: 5px 16px;
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    height: 44px;
                  `}
                >
                  <div
                    className={css`
                      color: var(--700, #25282d);
                      font-feature-settings: "clig" off, "liga" off;
                      font-family: Roboto;
                      font-size: 24px;
                      font-style: normal;
                      font-weight: 700;
                      border-bottom: 2px solid var(--700, #25282d);
                      line-height: 24px; /* 100% */
                      cursor: pointer;
                    `}
                    onClick={() => {
                      props.setEditEmployeeCount(true);
                    }}
                  >
                    {!props.eligibleEmployee
                      ? 0
                      : props.eligibleEmployee.toLocaleString()}
                  </div>

                  <div
                    className={css`
                      color: var(--700, #25282d);
                      font-feature-settings: "clig" off, "liga" off;
                      font-family: Roboto;
                      font-size: 16px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: 32px; /* 200% */
                      letter-spacing: 0.15px;
                    `}
                  >
                    Employees
                  </div>

                  <Tooltip title="The amount of people this guide was distributed to. Usually the total amount of employees for this company. This number is used in calcuations.">
                    <Info size={24} color={"#9AA7B5"} />
                  </Tooltip>
                </div>
              </Div>
            )}

            {props.guide && props.shareable && (
              <div
                className={css`
                  margin-right: 8px;
                `}
              >
                <Tooltip title="Share Analytics">
                  <IconButton
                    onClick={() => {
                      setShareModalOpen(true);
                    }}
                  >
                    <UploadSimple />
                  </IconButton>
                </Tooltip>
              </div>
            )}

            <Div
              css={css`
                ${flex("right")}
                transition: all .2s ease;
              `}
            >
              {props.timeFrame !== "Custom" && (
                <div
                  className={css`
                    margin-right: ${px.lg};
                  `}
                >
                  {timeOptions && (
                    <Select
                      className={classes.formControl}
                      id="timeframe-select"
                      variant="outlined"
                      value={props.timeFrame}
                      onChange={(e) => {
                        props.setTimeframe(e.target.value);
                      }}
                      open={open}
                      onClose={handleClose}
                      onOpen={handleOpen}
                      input={
                        <OutlinedInput
                          labelWidth={223}
                          classes={outlinedInputClasses}
                        />
                      }
                    >
                      {timeOptions.map((opt) => {
                        if (opt === "Open Enrollment" && open) {
                          return (
                            <MenuItem className={classes.menuStyle} value={opt}>
                              <div
                                className={css`
                                  display: flex;
                                  align-items: center;
                                  justify-content: space-between;
                                  width: 100%;
                                `}
                              >
                                <div>{opt}</div>
                                <div
                                  className={css`
                                    color: var(--Purple, #6d44fb);
                                    font-feature-settings: "clig" off,
                                      "liga" off;
                                    /* Body - Dark */
                                    font-family: Roboto;
                                    font-size: 16px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: 24px; /* 150% */
                                    letter-spacing: 0.15px;
                                    cursor: pointer;
                                    &:hover {
                                      color: #25282d;
                                    }
                                  `}
                                  onClick={(e) => {
                                    props.setOpenEnrollmentCollection(true);
                                  }}
                                >
                                  Edit
                                </div>
                              </div>
                            </MenuItem>
                          );
                        }
                        return (
                          <MenuItem className={classes.menuStyle} value={opt}>
                            {opt}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  )}
                </div>
              )}

              {props.timeFrame === "Custom" && (
                <div
                  className={css`
                    display: flex;
                    align-items: center;
                    border-radius: 8px;
                    border: 1px solid var(--Gray-300, #d1dae3);
                    height: 44px;
                    margin-right: 16px;
                  `}
                >
                  <div
                    className={css`
                      & .CalendarDay__selected_span {
                        background: #f5f7fa;
                        color: #25282d;
                        border: 1px solid #f5f7fa;
                      }

                      & .CalendarDay__selected_span:hover {
                        background: #25282d;
                        color: white;
                        border: 1px solid #25282d;
                      }

                      & .CalendarDay__selected {
                        background: #25282d;
                        color: white;
                        border: 1px solid #25282d;
                      }

                      & .CalendarDay__selected:hover {
                        background: #25282d;
                        color: white;
                        border: 1px solid white;
                      }

                      & .DateInput_input__focused {
                        border-bottom: 2px solid #25282d;
                      }

                      & .DayPickerKeyboardShortcuts_show__bottomRight::before {
                        border-top: 26px solid transparent;
                        border-right: 33px solid #25282d;
                        bottom: 0;
                        right: 0;
                      }

                      & .CalendarDay__default:hover {
                        border: 1px solid #25282d;
                        color: #fff;
                        background: #25282d;
                      }
                    `}
                  >
                    <DateRangePicker
                      small
                      noBorder
                      isOutsideRange={() => false}
                      startDate={props.startDate} // momentPropTypes.momentObj or null,
                      startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
                      endDate={props.endDate} // momentPropTypes.momentObj or null,
                      endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
                      onDatesChange={({ startDate, endDate }) => {
                        if (startDate) {
                          let start = startDate.set({
                            hour: 0,
                            minute: 0,
                            second: 0,
                            millisecond: 0,
                          });
                          props.setStartDate(start);
                        }

                        if (endDate) {
                          let end = endDate.set({
                            hour: 23,
                            minute: 59,
                            second: 59,
                            millisecond: 999,
                          });
                          props.setEndDate(end);
                        }
                      }} // PropTypes.func.isRequired,
                      focusedInput={props.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                      onFocusChange={(focusedInput) =>
                        props.setFocusedInput(focusedInput)
                      } // PropTypes.func.isRequired,
                    />
                  </div>
                  <div
                    className={css`
                      display: flex;
                      align-items: center;
                      border-left: 1px solid #d1dae3;
                      height: 44px;
                    `}
                  >
                    <Tooltip title="Apply selected range">
                      <IconButton
                        onClick={() => {
                          props.refresh();
                        }}
                      >
                        <Check />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Close custom range">
                      <IconButton
                        onClick={() => props.setTimeframe("Last 30 days")}
                      >
                        <X />
                      </IconButton>
                    </Tooltip>
                  </div>
                </div>
              )}
            </Div>

            <Div
              css={css`
                ${flex("right")}
                transition: all .2s ease;
                overflow: hidden;
                max-width: 250px;
              `}
            >
              {props.close && (
                <Button
                  className={css`
                    margin-right: ${px.lg};
                    ${animation("fadeIn", ".2s ease")}
                    padding-left: 24px;
                    padding-right: 24px;
                    padding-top: 10px;
                    padding-bottom: 10px;
                  `}
                  styles="secondary"
                  onClick={props.close}
                >
                  Close
                </Button>
              )}
            </Div>
          </Div>
        </Div>
        <div
          className={css`
            flex: 1;

            width: 100%;
            height: 100%;
            overflow: auto;
          `}
        >
          {(props.loading || props.coursesLoading) && (
            <div
              className={css`
                display: flex;
                justify-content: center;
                margin-top: 80px;
              `}
            >
              <BriteLoader />
            </div>
          )}
          {props.guideLoaded && (
            <div
              className={css`
                max-width: 1200px;
                margin: 56px auto;
              `}
            >
              <div
                className={css`
                  display: flex;
                  align-items: center;
                  border-bottom: 1px solid #d1dae3;
                  padding-bottom: 18px;
                  width: 100%;
                  margin-top: 32px;
                  margin-bottom: 32px;
                `}
              >
                <div
                  className={css`
                    cursor: pointer;
                    font-weight: 700;
                    font-size: 18px;
                    line-height: 175%;
                    color: ${tab === TRAFFIC_TAB ? "#25282D" : "#66737f"};
                    border-bottom: ${tab === TRAFFIC_TAB
                      ? "4px solid #25282D"
                      : "4px solid transparent"};
                    padding-bottom: 13px;
                    margin-bottom: -19px;
                    margin-right: 56px;
                    &:hover {
                      color: #25282d;
                    }
                  `}
                  onClick={() => setTab(TRAFFIC_TAB)}
                >
                  Traffic
                </div>
                <div
                  className={css`
                    cursor: pointer;
                    font-weight: 700;
                    font-size: 18px;
                    line-height: 175%;
                    color: ${tab === ENGAGEMENT_TAB ? "#25282D" : "#66737f"};
                    border-bottom: ${tab === ENGAGEMENT_TAB
                      ? "4px solid #25282D"
                      : "4px solid transparent"};
                    padding-bottom: 13px;
                    margin-bottom: -19px;
                    margin-right: 56px;
                    &:hover {
                      color: #25282d;
                    }
                  `}
                  onClick={() => setTab(ENGAGEMENT_TAB)}
                >
                  Engagement
                </div>

                <div
                  className={css`
                    cursor: pointer;
                    font-weight: 700;
                    font-size: 18px;
                    line-height: 175%;
                    color: ${tab === CONVERSION_TAB ? "#25282D" : "#66737f"};
                    border-bottom: ${tab === CONVERSION_TAB
                      ? "4px solid #25282D"
                      : "4px solid transparent"};
                    padding-bottom: 13px;
                    margin-bottom: -19px;
                    margin-right: 56px;
                    &:hover {
                      color: #25282d;
                    }
                  `}
                  onClick={() => setTab(CONVERSION_TAB)}
                >
                  Conversion
                </div>
              </div>

              {tab === TRAFFIC_TAB && (
                <>
                  <div
                    className={css`
                      display: flex;
                      margin-top: 40px;
                      align-items: center;
                    `}
                  >
                    <Tooltip
                      title="The total number of visits in a selected time"
                      placement={"top"}
                    >
                      <div
                        className={css`
                          flex: 1;
                          background: #ffffff;
                          border: 1px solid #d1dae3;
                          border-radius: 8px;
                          padding: 16px 24px;
                          margin-right: 24px;
                        `}
                      >
                        {props.analytics.Activity &&
                        props.analytics.Activity.length > 0 ? (
                          <>
                            <div
                              className={css`
                                font-family: "Roboto";
                                font-style: normal;
                                font-weight: 400;
                                font-size: 16px;
                                line-height: 24px;
                                /* identical to box height, or 150% */

                                letter-spacing: 0.15px;

                                color: #66737f;
                              `}
                            >
                              Total Guide Visits
                            </div>
                            <div
                              className={css`
                                font-family: "Roboto";
                                font-style: normal;
                                font-weight: 700;
                                font-size: 34px;
                                line-height: 48px;
                                /* identical to box height, or 141% */

                                letter-spacing: 0.25px;

                                color: #25282d;
                              `}
                            >
                              {props.analytics.Activity
                                ? props.analytics.Activity.length.toLocaleString()
                                : 0}
                            </div>
                          </>
                        ) : (
                          <EmptyStateSmall />
                        )}
                      </div>
                    </Tooltip>
                    <Tooltip
                      title="An estimate of the total number of actual visitors who accessed your guide"
                      placement={"top"}
                    >
                      <div
                        className={css`
                          flex: 1;
                          background: #ffffff;
                          border: 1px solid #d1dae3;
                          border-radius: 8px;
                          padding: 16px 24px;
                          margin-right: 24px;
                        `}
                      >
                        {props.analytics.UniqueUsers > 0 ? (
                          <>
                            <div
                              className={css`
                                font-family: "Roboto";
                                font-style: normal;
                                font-weight: 400;
                                font-size: 16px;
                                line-height: 24px;
                                /* identical to box height, or 150% */

                                letter-spacing: 0.15px;

                                color: #66737f;
                              `}
                            >
                              Unique Visitors
                            </div>
                            <div
                              className={css`
                                font-family: "Roboto";
                                font-style: normal;
                                font-weight: 700;
                                font-size: 34px;
                                line-height: 48px;
                                /* identical to box height, or 141% */

                                letter-spacing: 0.25px;

                                color: #25282d;
                              `}
                            >
                              {props.analytics.UniqueUsers?.toLocaleString()}
                            </div>
                          </>
                        ) : (
                          <EmptyStateSmall />
                        )}
                      </div>
                    </Tooltip>

                    <Tooltip
                      title="Percentage of employees that have visited this guide"
                      placement={"top"}
                    >
                      <div
                        className={css`
                          flex: 1;
                          background: #ffffff;
                          border: 1px solid #d1dae3;
                          border-radius: 8px;
                          padding: 16px 24px;
                        `}
                      >
                        {props.analytics.UniqueUsers > 0 ? (
                          <>
                            <div
                              className={css`
                                font-family: "Roboto";
                                font-style: normal;
                                font-weight: 400;
                                font-size: 16px;
                                line-height: 24px;
                                /* identical to box height, or 150% */

                                letter-spacing: 0.15px;

                                color: #66737f;
                              `}
                            >
                              Percent of Employees
                            </div>
                            <div
                              className={css`
                                display: flex;
                                align-items: center;
                              `}
                            >
                              <div
                                className={css`
                                  font-family: "Roboto";
                                  font-style: normal;
                                  font-weight: 700;
                                  font-size: 34px;
                                  line-height: 48px;
                                  /* identical to box height, or 141% */

                                  letter-spacing: 0.25px;

                                  color: #25282d;
                                `}
                              >
                                {getuniqueUsersPercentage(
                                  props.analytics.UniqueUsers
                                )}
                                %
                              </div>
                              <div
                                className={css`
                                  color: var(--500, #66737f);
                                  font-feature-settings: "clig" off, "liga" off;
                                  /* Body - Regular */
                                  font-family: Roboto;
                                  font-size: 16px;
                                  font-style: normal;
                                  font-weight: 400;
                                  line-height: 24px; /* 150% */
                                  letter-spacing: 0.15px;
                                  padding-left: 8px;
                                `}
                              >
                                (
                                {`${props.analytics.UniqueUsers.toLocaleString()}/${props.eligibleEmployee.toLocaleString()}`}
                                )
                              </div>
                            </div>
                          </>
                        ) : (
                          <EmptyStateSmall />
                        )}
                      </div>
                    </Tooltip>
                  </div>
                  <div
                    className={css`
                      font-family: "Roboto";
                      font-style: normal;
                      font-weight: 700;
                      font-size: 24px;
                      line-height: 32px;
                      /* identical to box height, or 133% */

                      color: #25282d;
                      margin-top: 40px;
                    `}
                  >
                    Guide Visitors
                  </div>
                  <div
                    className={css`
                      border-radius: 8px;
                      border: 1px solid var(--300, #d1dae3);
                      padding: 24px;
                      margin-top: 16px;
                    `}
                  >
                    {!isEmpty(countActivityByDay()) ||
                    !isEmpty(countUniqueActivityByDay()) ? (
                      <LineChart
                        adapter="chartjs"
                        messages={{ empty: `No Data to Display` }}
                        xmin={
                          props.startDate
                            ? props.startDate.format("YYYY-MM-DD")
                            : getMinDate("YYYY-MM-DD")
                        }
                        xmax={
                          props.endDate
                            ? props.endDate.format("YYYY-MM-DD")
                            : getMaxDate("YYYY-MM-DD")
                        }
                        min={0}
                        max={countActivityByDay().length}
                        scales
                        library={{
                          plugins: {
                            legend: chartLegendOptions,
                          },
                        }}
                        data={getGraphData()}
                      />
                    ) : (
                      <EmptyState />
                    )}
                  </div>
                  <div>
                    <div
                      className={css`
                        font-family: "Roboto";
                        font-style: normal;
                        font-weight: 700;
                        font-size: 24px;
                        line-height: 32px;
                        /* identical to box height, or 133% */

                        color: #25282d;
                        margin-top: 40px;
                      `}
                    >
                      Visits By Source
                    </div>

                    <div
                      className={css`
                        border-radius: 8px;
                        background: var(--100, #f5f7fa);
                        display: flex;
                        padding: 16px 24px;
                        justify-content: space-between;
                        align-items: center;
                        margin-top: 16px;
                        margin-right: 12px;
                      `}
                    >
                      <div
                        className={css`
                          color: var(--700, #25282d);
                          font-feature-settings: "clig" off, "liga" off;
                          /* Body - Dark */
                          font-family: Roboto;
                          font-size: 16px;
                          font-style: normal;
                          font-weight: 400;
                          line-height: 24px; /* 150% */
                          letter-spacing: 0.15px;
                        `}
                      >
                        To track visitor sources, send your guide with Brite’s
                        Communication Tools
                      </div>
                      <div
                        className={css`
                          display: flex;
                          gap: 16px;
                          align-items: center;
                        `}
                      >
                        <ButtonMUI
                          size="small"
                          style={{ textTransform: "none" }}
                          onClick={() => showLearnMore("Communication-Tools")}
                        >
                          Learn More
                        </ButtonMUI>
                      </div>
                    </div>

                    <div>
                      <div
                        className={css`
                          display: flex;
                          padding-top: 24px;
                          padding-bottom: 24px;
                          gap: 24px;
                        `}
                      >
                        {getSourcesGraphData().totalCount > 0 ? (
                          <>
                            <div className={graphStyle("flex-basis: 33.33%;")}>
                              {getSourcesGraphData().colorCodedData.map(
                                (source, index) => {
                                  return (
                                    <div
                                      className={css`
                                        color: #25282d;
                                        font-feature-settings: "clig" off,
                                          "liga" off;

                                        /* Label */
                                        font-family: Roboto;
                                        font-size: 14px;
                                        font-style: normal;
                                        font-weight: 700;
                                        line-height: 24px; /* 171.429% */
                                        letter-spacing: 0.15px;
                                      `}
                                    >
                                      <div
                                        className={css`
                                          display: flex;
                                          justify-content: space-between;
                                        `}
                                      >
                                        <div>{source.name}</div>
                                        <div
                                          className={css`
                                            color: var(--500, #66737f);
                                            font-feature-settings: "clig" off,
                                              "liga" off;
                                            font-family: Roboto;
                                            font-size: 14px;
                                            font-style: normal;
                                            font-weight: 400;
                                            line-height: 24px; /* 171.429% */
                                            letter-spacing: 0.15px;
                                          `}
                                        >
                                          {(source.count /
                                            getSourcesGraphData().totalCount) *
                                            100 >
                                          1
                                            ? Math.floor(
                                                (source.count /
                                                  getSourcesGraphData()
                                                    .totalCount) *
                                                  100
                                              )
                                            : (
                                                (source.count /
                                                  getSourcesGraphData()
                                                    .totalCount) *
                                                100
                                              ).toFixed(2)}
                                          %
                                        </div>
                                      </div>
                                      <div
                                        className={css`
                                          display: flex;
                                          flex-direction: column;
                                          justify-content: center;
                                          align-items: flex-start;
                                          align-self: stretch;
                                          border-radius: 8px;
                                          background: var(--200, #e8edf3);
                                          margin-top: 4px;
                                          margin-bottom: 8px;
                                        `}
                                      >
                                        <div
                                          className={css`
                                            width: ${source.count
                                              ? (source.count /
                                                  getSourcesGraphData()
                                                    .totalCount) *
                                                100
                                              : 0}%;
                                            height: 8px;
                                            border-radius: 8px;
                                            background: ${source.color};
                                          `}
                                        ></div>
                                      </div>
                                    </div>
                                  );
                                }
                              )}
                            </div>
                            <div className={graphStyle("flex-basis: 63.33%;")}>
                              <LineChart
                                adapter="chartjs"
                                messages={{ empty: "No data" }}
                                xmin={
                                  props.startDate
                                    ? props.startDate.format("YYYY-MM-DD")
                                    : getMinDate("YYYY-MM-DD")
                                }
                                xmax={
                                  props.endDate
                                    ? props.endDate.format("YYYY-MM-DD")
                                    : getMaxDate("YYYY-MM-DD")
                                }
                                min={1}
                                max={countActivityByDay().length}
                                scales
                                library={{
                                  plugins: {
                                    legend: chartLegendOptions,
                                    annotation: {
                                      annotations:
                                        props.communicationAnnotations,
                                    },
                                  },
                                }}
                                data={getSourcesGraphData().colorCodedData}
                              />
                            </div>
                          </>
                        ) : (
                          <div
                            className={css`
                              border-radius: 8px;
                              border: 1px solid ${colors.gray[300]};
                              margin-top: 12px;
                              margin-bottom: 12px;
                              padding: 24px;
                              width: 100%;
                            `}
                          >
                            <EmptyState />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div
                    className={css`
                      display: flex;
                    `}
                  >
                    <div
                      className={css`
                        flex: 1;
                        margin-right: 40px;
                      `}
                    >
                      {" "}
                      <div
                        className={css`
                          font-family: "Roboto";
                          font-style: normal;
                          font-weight: 700;
                          font-size: 24px;
                          line-height: 32px;
                          /* identical to box height, or 133% */

                          color: #25282d;
                          margin-top: 40px;
                          margin-bottom: 24px;
                        `}
                      >
                        Visitor Locations
                      </div>
                      <div>
                        {props.analytics.Locations ? (
                          <div
                            className={css`
                              border: 1px solid #d1dae3;
                              border-radius: 8px;
                              padding: 32px;
                            `}
                          >
                            <div
                              className={css`
                                display: flex;
                                align-items: center;
                              `}
                            >
                              {props.analytics.Locations.sort(
                                (a, b) => b.Count - a.Count
                              ).map((l, index) => {
                                if (index > 2) {
                                  return null;
                                }
                                return (
                                  <div
                                    className={css`
                                      padding: 16px;
                                      padding-left: 24px;
                                      padding-right: 24px;
                                      margin-right: 24px;
                                      margin-bottom: 24px;
                                      width: 230px;
                                    `}
                                  >
                                    <div
                                      className={css`
                                        font-family: "Roboto";
                                        font-style: normal;
                                        font-weight: 400;
                                        font-size: 16px;
                                        line-height: 24px;
                                        /* identical to box height, or 150% */

                                        letter-spacing: 0.15px;

                                        color: #66737f;
                                      `}
                                    >
                                      {l.CityName}
                                    </div>
                                    <div
                                      className={css`
                                        font-family: "Roboto";
                                        font-style: normal;
                                        font-weight: 700;
                                        font-size: 34px;
                                        line-height: 48px;
                                        /* identical to box height, or 141% */

                                        letter-spacing: 0.25px;

                                        color: #25282d;
                                      `}
                                    >
                                      {l.Count}
                                    </div>
                                  </div>
                                );
                              })}
                            </div>

                            <div
                              className={css`
                                font-size: 18px;
                              `}
                            >
                              <ComposableMap projection="geoAlbers">
                                <ZoomableGroup zoom={1} maxZoom={1}>
                                  <Geographies
                                    geography={
                                      "https://cdn.jsdelivr.net/npm/world-atlas@2/countries-50m.json"
                                    }
                                  >
                                    {({ geographies }) =>
                                      geographies.map((geo) => (
                                        <Geography
                                          key={geo.rsmKey}
                                          geography={geo}
                                          fill="#DDD"
                                          stroke="#FFF"
                                        />
                                      ))
                                    }
                                  </Geographies>
                                  {props.analytics.Locations.map((l) => (
                                    <Marker
                                      key={l.Long + l.Lat}
                                      coordinates={[l.Long, l.Lat]}
                                    >
                                      <circle
                                        r={getDotRadius(l.Count)}
                                        fill="#25282D"
                                      />
                                    </Marker>
                                  ))}
                                </ZoomableGroup>
                              </ComposableMap>
                            </div>
                          </div>
                        ) : (
                          <div
                            className={css`
                              border: 1px solid #d1dae3;
                              border-radius: 8px;
                              padding: 32px;
                            `}
                          >
                            <EmptyState />
                          </div>
                        )}
                      </div>
                    </div>
                    <div
                      className={css`
                        width: 320px;
                      `}
                    >
                      <div
                        className={css`
                          font-family: "Roboto";
                          font-style: normal;
                          font-weight: 700;
                          font-size: 24px;
                          line-height: 32px;

                          color: #25282d;
                          margin-top: 40px;
                          margin-bottom: 24px;
                        `}
                      >
                        Visitor Devices
                      </div>

                      <div
                        className={css`
                          border: 1px solid #d1dae3;
                          border-radius: 8px;
                          padding-top: 32px;
                          padding-left: 32px;
                          padding-right: 32px;
                          padding-bottom: 56px;
                        `}
                      >
                        {getDeviceCount() > 0 ? (
                          <>
                            <div
                              className={css`
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                              `}
                            >
                              <div
                                className={css`
                                  display: flex;

                                  align-items: center;
                                `}
                              >
                                <div
                                  className={css`
                                    width: 16px;
                                    height: 16px;
                                    border-radius: 50%;
                                    background: #9aa7b5;
                                    margin-right: 16px;
                                  `}
                                ></div>
                                <div>Desktop</div>
                              </div>
                              <div>{getDevicePercentage("desktop")}</div>
                            </div>

                            <div
                              className={css`
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                margin-top: 16px;
                              `}
                            >
                              <div
                                className={css`
                                  display: flex;

                                  align-items: center;
                                `}
                              >
                                <div
                                  className={css`
                                    width: 16px;
                                    height: 16px;
                                    border-radius: 50%;
                                    background: #25282d;
                                    margin-right: 16px;
                                  `}
                                ></div>
                                <div>Mobile</div>
                              </div>
                              <div>{getDevicePercentage("mobile")}</div>
                            </div>
                            <div
                              className={css`
                                padding: 20px;
                                align-items: center;
                              `}
                            >
                              <DonutChart
                                interactive={false}
                                clickToggle={false}
                                selectedOffset={0}
                                height="216"
                                strokeColor={"transparent"}
                                legend={false}
                                width="216"
                                colors={["#25282D", "#9AA7B5"]} // mobile then desktop
                                data={getDeviceData()}
                              />
                            </div>
                            <div
                              className={css`
                                position: relative;
                                top: -166px;
                                margin-bottom: -89px;
                              `}
                            >
                              <div
                                className={css`
                                  font-family: "Roboto";
                                  font-style: normal;
                                  font-weight: 700;
                                  font-size: 34px;
                                  line-height: 48px;
                                  /* identical to box height, or 141% */
                                  text-align: center;
                                  letter-spacing: 0.25px;

                                  color: #25282d;
                                `}
                              >
                                {getDeviceCount() || "0"}
                              </div>
                              <div
                                className={css`
                                  font-family: "Roboto";
                                  font-style: normal;
                                  font-weight: 400;
                                  font-size: 16px;
                                  line-height: 24px;
                                  text-align: center;
                                  /* identical to box height, or 150% */

                                  letter-spacing: 0.15px;

                                  color: #66737f;
                                `}
                              >
                                Known Devices
                              </div>
                            </div>
                          </>
                        ) : (
                          <EmptyState />
                        )}
                      </div>
                    </div>
                  </div>
                </>
              )}
              {tab === ENGAGEMENT_TAB && (
                <>
                  {props.loadingPageViewsData && (
                    <>
                      <div
                        className={css`
                          display: flex;
                          justify-content: center;
                          margin-top: 80px;
                        `}
                      >
                        <BriteLoader />
                      </div>
                    </>
                  )}
                  {!props.loadingPageViewsData &&
                    props.pageViewsStatistics &&
                    props.pageViewsStatistics.data &&
                    props.pageViewsStatistics.data.length &&
                    verifyAfterDate(new Date(2022, 2, 30)) && (
                      <>
                        <div
                          className={css`
                            display: flex;
                            margin-top: 40px;
                            align-items: center;
                          `}
                        >
                          <Tooltip
                            title="The total amount of page visits across your entire guide."
                            placement={"top"}
                          >
                            <div
                              className={css`
                                flex: 1;
                                background: #ffffff;
                                border: 1px solid #d1dae3;
                                border-radius: 8px;
                                padding: 16px 24px;
                                margin-right: 24px;
                              `}
                            >
                              {props.pageViewsStatistics?.engagementOverview
                                ?.TotalPageVisits > 0 ? (
                                <>
                                  <div
                                    className={css`
                                      font-family: "Roboto";
                                      font-style: normal;
                                      font-weight: 400;
                                      font-size: 16px;
                                      line-height: 24px;
                                      /* identical to box height, or 150% */

                                      letter-spacing: 0.15px;

                                      color: #66737f;
                                    `}
                                  >
                                    Total Page Visits
                                  </div>
                                  <div
                                    className={css`
                                      font-family: "Roboto";
                                      font-style: normal;
                                      font-weight: 700;
                                      font-size: 34px;
                                      line-height: 48px;
                                      /* identical to box height, or 141% */

                                      letter-spacing: 0.25px;

                                      color: #25282d;
                                    `}
                                  >
                                    {props.pageViewsStatistics?.engagementOverview?.TotalPageVisits.toLocaleString()}
                                  </div>
                                </>
                              ) : (
                                <EmptyStateSmall />
                              )}
                            </div>
                          </Tooltip>
                          <Tooltip
                            title="The average number of pages a visitor sees across your entire guide"
                            placement={"top"}
                          >
                            <div
                              className={css`
                                flex: 1;
                                background: #ffffff;
                                border: 1px solid #d1dae3;
                                border-radius: 8px;
                                padding: 16px 24px;
                                margin-right: 24px;
                              `}
                            >
                              {props.pageViewsStatistics?.engagementOverview
                                ?.AveragePagesPerVisitor > 0 ? (
                                <>
                                  <div
                                    className={css`
                                      font-family: "Roboto";
                                      font-style: normal;
                                      font-weight: 400;
                                      font-size: 16px;
                                      line-height: 24px;
                                      /* identical to box height, or 150% */

                                      letter-spacing: 0.15px;

                                      color: #66737f;
                                    `}
                                  >
                                    Average Pages per Visitor
                                  </div>
                                  <div
                                    className={css`
                                      font-family: "Roboto";
                                      font-style: normal;
                                      font-weight: 700;
                                      font-size: 34px;
                                      line-height: 48px;
                                      /* identical to box height, or 141% */

                                      letter-spacing: 0.25px;

                                      color: #25282d;
                                    `}
                                  >
                                    {Math.round(
                                      props.pageViewsStatistics
                                        ?.engagementOverview
                                        ?.AveragePagesPerVisitor * 10
                                    ) / 10}
                                  </div>
                                </>
                              ) : (
                                <EmptyStateSmall />
                              )}
                            </div>
                          </Tooltip>
                          <Tooltip
                            title="The total amount of time spent on your guide by all visitors"
                            placement={"top"}
                          >
                            <div
                              className={css`
                                flex: 1;
                                background: #ffffff;
                                border: 1px solid #d1dae3;
                                border-radius: 8px;
                                padding: 16px 24px;
                                margin-right: 24px;
                              `}
                            >
                              {props.pageViewsStatistics?.engagementOverview
                                ?.TotalVisitTimeSeconds > 0 ? (
                                <>
                                  <div
                                    className={css`
                                      font-family: "Roboto";
                                      font-style: normal;
                                      font-weight: 400;
                                      font-size: 16px;
                                      line-height: 24px;
                                      /* identical to box height, or 150% */

                                      letter-spacing: 0.15px;

                                      color: #66737f;
                                    `}
                                  >
                                    Total Visit Time
                                  </div>
                                  {calculateCourseTime(
                                    props.pageViewsStatistics
                                      ?.engagementOverview
                                      ?.TotalVisitTimeSeconds
                                  )}
                                </>
                              ) : (
                                <EmptyStateSmall />
                              )}
                            </div>
                          </Tooltip>
                          <Tooltip
                            title="The average amount of time a visitor spends on your guide"
                            placement={"top"}
                          >
                            <div
                              className={css`
                                flex: 1;
                                background: #ffffff;
                                border: 1px solid #d1dae3;
                                border-radius: 8px;
                                padding: 16px 24px;
                              `}
                            >
                              {props.pageViewsStatistics?.engagementOverview
                                ?.AverageTimePerVisitorSeconds > 0 ? (
                                <>
                                  <div
                                    className={css`
                                      font-family: "Roboto";
                                      font-style: normal;
                                      font-weight: 400;
                                      font-size: 16px;
                                      line-height: 24px;
                                      /* identical to box height, or 150% */

                                      letter-spacing: 0.15px;

                                      color: #66737f;
                                    `}
                                  >
                                    Average Time per Visitor
                                  </div>
                                  {calculateCourseTime(
                                    props.pageViewsStatistics
                                      ?.engagementOverview
                                      ?.AverageTimePerVisitorSeconds
                                  )}
                                </>
                              ) : (
                                <EmptyStateSmall />
                              )}
                            </div>
                          </Tooltip>
                        </div>

                        <div
                          className={css`
                            font-family: "Roboto";
                            font-style: normal;
                            font-weight: 700;
                            font-size: 24px;
                            line-height: 32px;
                            /* identical to box height, or 133% */

                            color: #25282d;
                            margin-top: 40px;
                            margin-bottom: 24px;
                          `}
                        >
                          Visits by Page
                        </div>
                        <div
                          className={css`
                            margin-bottom: 40px;
                            border-radius: 8px;
                            border: 1px solid var(--300, #d1dae3);
                            padding: 24px;
                          `}
                        >
                          {props.pageViewsStatistics?.data?.columnRows?.length >
                          0 ? (
                            <ColumnChart
                              dataset={{
                                borderWidth: 0,
                                borderColor: "#6D44FB",
                                backgroundColor: "#6D44FB",
                              }}
                              min={0}
                              max={
                                props.pageViewsStatistics.largestPageVisits < 10
                                  ? props.pageViewsStatistics
                                      .largestPageVisits + 10
                                  : ""
                              }
                              library={{
                                interaction: {
                                  intersect: false,
                                  mode: "index",
                                },
                                plugins: {
                                  tooltip: {
                                    backgroundColor: "#fff",
                                    borderWidth: 2,
                                    padding: 16,
                                    borderColor: "#6D44FB",
                                    titleColor: "#25282D",
                                    bodyColor: "#66737F",
                                    titleFont: {
                                      weight: 700,
                                      family: "Roboto",
                                      size: 14,
                                    },

                                    callbacks: {
                                      title: bartooltip,
                                      label: afterBodytooltip,
                                    },
                                  },
                                },
                              }}
                              colors={["#6D44FB"]}
                              data={props.pageViewsStatistics.data.columnRows}
                            />
                          ) : (
                            <EmptyState />
                          )}
                        </div>

                        <div>
                          <EnhancedTable rows={props.pageDataRows} />
                        </div>

                        <div
                          className={css`
                            margin-top: 40px;
                          `}
                        >
                          <div
                            className={css`
                              color: #25282d;

                              font-feature-settings: "clig" off, "liga" off;
                              font-family: Roboto;
                              font-size: 24px;
                              font-style: normal;
                              font-weight: 700;
                              line-height: 32px;
                            `}
                          >
                            Your Custom Questions
                          </div>
                          <div
                            className={css`
                              display: flex;
                              gap: 24px;
                              padding-top: 24px;
                            `}
                          >
                            {props.pageViewsStatistics?.engagementOverview
                              ?.Questions.length !== 0 ? (
                              <>
                                <div
                                  className={css`
                                    border-radius: 8px;
                                    border: 1px solid var(--300, #d1dae3);
                                    width: 263px;
                                    padding: 24px;
                                  `}
                                >
                                  <div
                                    className={css`
                                      color: var(--500, #66737f);
                                      font-feature-settings: "clig" off,
                                        "liga" off;
                                      /* Body - Dark */
                                      font-family: Roboto;
                                      font-size: 16px;
                                      font-style: normal;
                                      font-weight: 400;
                                      line-height: 24px; /* 150% */
                                      letter-spacing: 0.15px;
                                    `}
                                  >
                                    Total Responses
                                  </div>
                                  <div
                                    className={css`
                                      color: var(--700, #25282d);
                                      font-feature-settings: "clig" off,
                                        "liga" off;
                                      /* H1 */
                                      font-family: Roboto;
                                      font-size: 34px;
                                      font-style: normal;
                                      font-weight: 700;
                                      line-height: 48px; /* 141.176% */
                                      letter-spacing: 0.25px;
                                      margin-bottom: 16px;
                                    `}
                                  >
                                    {props.pageViewsStatistics
                                      ?.engagementOverview?.Questions.length !==
                                    0
                                      ? `${countTotalResponses()}%`
                                      : "No data"}
                                  </div>
                                  <div
                                    className={css`
                                      color: var(--500, #66737f);
                                      font-feature-settings: "clig" off,
                                        "liga" off;
                                      /* Body - Dark */
                                      font-family: Roboto;
                                      font-size: 16px;
                                      font-style: normal;
                                      font-weight: 400;
                                      line-height: 24px; /* 150% */
                                      letter-spacing: 0.15px;
                                    `}
                                  >
                                    Average Score
                                  </div>
                                  <div
                                    className={css`
                                      color: var(--700, #25282d);
                                      font-feature-settings: "clig" off,
                                        "liga" off;
                                      /* H1 */
                                      font-family: Roboto;
                                      font-size: 34px;
                                      font-style: normal;
                                      font-weight: 700;
                                      line-height: 48px; /* 141.176% */
                                      letter-spacing: 0.25px;
                                    `}
                                  >
                                    {props.pageViewsStatistics
                                      ?.engagementOverview?.Questions.length !==
                                    0
                                      ? `${calculateCourseScore()}%`
                                      : "No data"}
                                  </div>
                                </div>
                                <div
                                  className={css`
                                    flex: 1;
                                  `}
                                >
                                  {props.pageViewsStatistics?.engagementOverview?.Questions.map(
                                    (question, index) => {
                                      return (
                                        <div
                                          key={index}
                                          className={css`
                                            border-radius: 8px;
                                            border: 1px solid
                                              var(--Gray-300, #d1dae3);
                                            padding-top: 16px;
                                            padding-bottom: 16px;
                                            padding-left: 24px;
                                            padding-right: 40px;
                                            display: flex;
                                            margin-bottom: 16px;
                                          `}
                                        >
                                          <div
                                            className={css`
                                              flex: 1;
                                              padding-right: 40px;
                                            `}
                                          >
                                            <div
                                              className={css`
                                                color: var(--Gray-700, #25282d);
                                                font-feature-settings: "clig"
                                                    off,
                                                  "liga" off;
                                                /* Body - Regular */
                                                font-family: Roboto;
                                                font-size: 16px;
                                                font-style: normal;
                                                font-weight: 400;
                                                line-height: 24px; /* 150% */
                                                letter-spacing: 0.15px;
                                              `}
                                            >
                                              {question.QuestionText}
                                            </div>
                                            <div
                                              className={css`
                                                color: var(--Gray-500, #66737f);
                                                font-feature-settings: "clig"
                                                    off,
                                                  "liga" off;
                                                /* Caption */
                                                font-family: Roboto;
                                                font-size: 14px;
                                                font-style: normal;
                                                font-weight: 400;
                                                line-height: 24px; /* 171.429% */
                                                letter-spacing: 0.15px;
                                                padding-top: 8px;
                                              `}
                                            >
                                              {question.Total} Responses
                                            </div>
                                          </div>
                                          <div
                                            className={css`
                                              border-left: 1px solid #d1dae3;
                                              padding-left: 40px;
                                              width: 100px;
                                            `}
                                          >
                                            <div
                                              className={css`
                                                color: var(--700, #25282d);
                                                text-align: center;
                                                font-feature-settings: "clig"
                                                    off,
                                                  "liga" off;
                                                /* H2 */
                                                font-family: Roboto;
                                                font-size: 24px;
                                                font-style: normal;
                                                font-weight: 700;
                                                line-height: 32px;
                                              `}
                                            >
                                              {Math.round(
                                                (question.AnsweredCorrect /
                                                  question.Total) *
                                                  100
                                              )}
                                              %
                                            </div>
                                            <div
                                              className={css`
                                                color: var(--500, #66737f);
                                                text-align: center;
                                                font-feature-settings: "clig"
                                                    off,
                                                  "liga" off;
                                                /* Caption */
                                                font-family: Roboto;
                                                font-size: 14px;
                                                font-style: normal;
                                                font-weight: 400;
                                                line-height: 24px; /* 171.429% */
                                                letter-spacing: 0.15px;
                                              `}
                                            >
                                              Correct
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                              </>
                            ) : (
                              <div
                                className={css`
                                  border-radius: 8px;
                                  border: 1px solid var(--300, #d1dae3);
                                  width: 100%;
                                  padding: 24px;
                                `}
                              >
                                {" "}
                                <EmptyState />{" "}
                              </div>
                            )}
                          </div>
                        </div>
                      </>
                    )}
                </>
              )}
              {tab === CONVERSION_TAB && (
                <>
                  <div
                    className={css`
                      display: flex;
                      margin-top: 40px;
                      align-items: center;
                    `}
                  >
                    <Tooltip
                      title={`The total percentage of visitors that responded "Yes" in your guide when asked if they were confident to enroll in their benefits.`}
                      placement={"top"}
                    >
                      <div
                        className={css`
                          flex: 1;
                          background: #ffffff;
                          border: 1px solid #d1dae3;
                          border-radius: 8px;
                          padding: 16px 24px;
                          margin-right: 24px;
                        `}
                      >
                        {!props.analytics.enrollmentConfidence.error &&
                        props.analytics.enrollmentConfidence.length > 0 ? (
                          <>
                            <div
                              className={css`
                                font-family: "Roboto";
                                font-style: normal;
                                font-weight: 400;
                                font-size: 16px;
                                line-height: 24px;
                                /* identical to box height, or 150% */

                                letter-spacing: 0.15px;

                                color: #66737f;
                              `}
                            >
                              Enrollment Confidence
                            </div>
                            <div
                              className={css`
                                font-family: "Roboto";
                                font-style: normal;
                                font-weight: 700;
                                font-size: 34px;
                                line-height: 48px;
                                /* identical to box height, or 141% */

                                letter-spacing: 0.25px;

                                color: #25282d;
                                display: flex;
                                align-items: center;
                                gap: 12px;
                              `}
                            >
                              <div>
                                {props.analytics.enrollmentConfidence.error
                                  ? 0
                                  : calculateEnrollmentConfidence()}
                                %
                              </div>

                              <div
                                className={css`
                                  color: var(--500, #66737f);
                                  font-family: Roboto;
                                  font-size: 16px;
                                  font-style: normal;
                                  font-weight: 400;
                                  line-height: 24px;
                                  letter-spacing: 0.15px;
                                `}
                              >
                                (
                                {`${
                                  props.analytics.enrollmentConfidence.error
                                    ? 0
                                    : props.analytics.enrollmentConfidence
                                        .length
                                } ${
                                  props.analytics.enrollmentConfidence
                                    .length === 1
                                    ? "Response"
                                    : "Responses"
                                }`}
                                )
                              </div>
                            </div>
                          </>
                        ) : (
                          <EmptyStateSmall />
                        )}
                      </div>
                    </Tooltip>

                    <Tooltip
                      title="The total number of visitors that clicked an enrollment button on your guide"
                      placement={"top"}
                    >
                      <div
                        className={css`
                          flex: 1;
                          background: #ffffff;
                          border: 1px solid #d1dae3;
                          border-radius: 8px;
                          padding: 16px 24px;
                          margin-right: 24px;
                        `}
                      >
                        {" "}
                        {props.analytics?.conversion?.EnrollmentClicks?.length >
                        0 ? (
                          <>
                            <div
                              className={css`
                                font-family: "Roboto";
                                font-style: normal;
                                font-weight: 400;
                                font-size: 16px;
                                line-height: 24px;
                                /* identical to box height, or 150% */

                                letter-spacing: 0.15px;

                                color: #66737f;
                              `}
                            >
                              Enrollment Button Clicks
                            </div>
                            <div
                              className={css`
                                font-family: "Roboto";
                                font-style: normal;
                                font-weight: 700;
                                font-size: 34px;
                                line-height: 48px;
                                /* identical to box height, or 141% */

                                letter-spacing: 0.25px;

                                color: #25282d;
                                display: flex;
                                align-items: center;
                                gap: 12px;
                              `}
                            >
                              <div>
                                {props.analytics?.conversion?.EnrollmentClicks?.length.toLocaleString() ||
                                  0}
                              </div>

                              <div
                                className={css`
                                  color: var(--500, #66737f);
                                  font-family: Roboto;
                                  font-size: 16px;
                                  font-style: normal;
                                  font-weight: 400;
                                  line-height: 24px;
                                  letter-spacing: 0.15px;
                                `}
                              >
                                (
                                {getEnrollButtonClickedPercentage(
                                  props.analytics?.conversion?.EnrollmentClicks
                                    ?.length || 0
                                )}
                                %)
                              </div>
                            </div>
                          </>
                        ) : (
                          <EmptyStateSmall />
                        )}
                      </div>
                    </Tooltip>

                    <Tooltip
                      title="The total number of visitors that completed the decision tool at any point on your guide"
                      placement={"top"}
                    >
                      <div
                        className={css`
                          flex: 1;
                          background: #ffffff;
                          border: 1px solid #d1dae3;
                          border-radius: 8px;
                          padding: 16px 24px;
                        `}
                      >
                        {props.analytics?.conversion?.DecisionToolCompletions >
                        0 ? (
                          <>
                            <div
                              className={css`
                                font-family: "Roboto";
                                font-style: normal;
                                font-weight: 400;
                                font-size: 16px;
                                line-height: 24px;
                                /* identical to box height, or 150% */

                                letter-spacing: 0.15px;

                                color: #66737f;
                              `}
                            >
                              Decision Tool Completions
                            </div>
                            <div
                              className={css`
                                font-family: "Roboto";
                                font-style: normal;
                                font-weight: 700;
                                font-size: 34px;
                                line-height: 48px;
                                /* identical to box height, or 141% */

                                letter-spacing: 0.25px;

                                color: #25282d;
                                display: flex;
                                align-items: center;
                                gap: 12px;
                              `}
                            >
                              <div>
                                {props.analytics?.conversion?.DecisionToolCompletions.toLocaleString() ||
                                  0}
                              </div>

                              <div
                                className={css`
                                  color: var(--500, #66737f);
                                  font-family: Roboto;
                                  font-size: 16px;
                                  font-style: normal;
                                  font-weight: 400;
                                  line-height: 24px;
                                  letter-spacing: 0.15px;
                                `}
                              >
                                (
                                {getDecisionToolCompletionsPercentage(
                                  props.analytics?.conversion
                                    ?.DecisionToolCompletions || 0
                                )}
                                %)
                              </div>
                            </div>
                          </>
                        ) : (
                          <EmptyStateSmall />
                        )}
                      </div>
                    </Tooltip>
                  </div>

                  <div
                    className={css`
                      font-family: "Roboto";
                      font-style: normal;
                      font-weight: 700;
                      font-size: 24px;
                      line-height: 32px;
                      /* identical to box height, or 133% */

                      color: #25282d;
                      margin-top: 40px;
                    `}
                  >
                    Enrollment Clicks by Day
                  </div>
                  <div
                    className={css`
                      border-radius: 8px;
                      border: 1px solid var(--300, #d1dae3);
                      padding: 24px;
                      margin-top: 16px;
                    `}
                  >
                    {!isEmpty(countEnrollmentClicksByDay()) ||
                    !isEmpty(countUniqueEnrollmentClicksByDay()) ? (
                      <LineChart
                        adapter="chartjs"
                        messages={{ empty: "No data" }}
                        xmin={
                          props.startDate
                            ? props.startDate.format("YYYY-MM-DD")
                            : getMinDate("YYYY-MM-DD")
                        }
                        xmax={
                          props.endDate
                            ? props.endDate.format("YYYY-MM-DD")
                            : getMaxDate("YYYY-MM-DD")
                        }
                        min={0}
                        max={countEnrollmentClicksByDay().length}
                        scales
                        library={{
                          plugins: {
                            legend: chartLegendOptions,
                          },
                        }}
                        data={getEnrollmentClicksData()}
                      />
                    ) : (
                      <EmptyState />
                    )}
                  </div>

                  <div
                    className={css`
                      font-family: "Roboto";
                      font-style: normal;
                      font-weight: 700;
                      font-size: 24px;
                      line-height: 32px;
                      /* identical to box height, or 133% */

                      color: #25282d;
                      margin-top: 40px;
                      margin-bottom: 24px;
                    `}
                  >
                    Products Recommended
                  </div>
                  <div
                    className={css`
                      border: 1px solid #d1dae3;
                      border-radius: 8px;
                      padding: 32px;
                    `}
                  >
                    {(props.analytics.conversion.MedicalRecommendations &&
                      props.analytics.conversion.MedicalRecommendations.length >
                        0) ||
                    (props.analytics.conversion.NonMedicalRecommendations &&
                      props.analytics.conversion.NonMedicalRecommendations
                        .length > 0) ? (
                      <>
                        {props.analytics.conversion.MedicalRecommendations &&
                          props.analytics.conversion.MedicalRecommendations
                            .length > 0 && (
                            <div
                              className={css`
                                color: var(--700, #25282d);
                                font-feature-settings: "clig" off, "liga" off;
                                /* H4 */
                                font-family: Roboto;
                                font-size: 18px;
                                font-style: normal;
                                font-weight: 700;
                                line-height: 24px; /* 133.333% */
                                letter-spacing: 0.15px;
                                margin-bottom: 16px;
                              `}
                            >
                              Medical Plans
                            </div>
                          )}

                        {props.analytics.conversion?.MedicalRecommendations?.sort(
                          (a, b) => b.SuggestedCount - a.SuggestedCount
                        ).map((recommendation, index) => {
                          return (
                            <div
                              className={css`
                                color: #25282d;
                                font-feature-settings: "clig" off, "liga" off;

                                /* Label */
                                font-family: Roboto;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 700;
                                line-height: 24px; /* 171.429% */
                                letter-spacing: 0.15px;
                                margin-bottom: 24px;
                              `}
                            >
                              <div
                                className={css`
                                  display: flex;
                                  justify-content: space-between;
                                `}
                              >
                                <div>{recommendation.PlanName}</div>
                                <div
                                  className={css`
                                    color: var(--500, #66737f);
                                    font-feature-settings: "clig" off,
                                      "liga" off;
                                    font-family: Roboto;
                                    font-size: 14px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: 24px; /* 171.429% */
                                    letter-spacing: 0.15px;
                                  `}
                                >
                                  {Math.floor(
                                    getRecommendationPercentage(
                                      recommendation,
                                      "Recommendation"
                                    ) * 100
                                  )}
                                  %
                                </div>
                              </div>
                              <Tooltip title={recommendation.SuggestedCount}>
                                <div
                                  className={css`
                                    display: flex;
                                    flex-direction: column;
                                    justify-content: center;
                                    align-items: flex-start;
                                    align-self: stretch;
                                    border-radius: 8px;
                                    background: var(--200, #e8edf3);
                                    margin-top: 4px;
                                    margin-bottom: 8px;
                                  `}
                                >
                                  <div
                                    className={css`
                                      width: ${getRecommendationPercentage(
                                        recommendation,
                                        "Recommendation"
                                      ) * 100}%;
                                      height: 8px;
                                      border-radius: 8px;
                                      background: #6d44fb;
                                    `}
                                  ></div>
                                </div>
                              </Tooltip>
                            </div>
                          );
                        })}

                        {props.analytics.conversion.NonMedicalRecommendations &&
                          props.analytics.conversion.NonMedicalRecommendations
                            .length > 0 && (
                            <div
                              className={css`
                                color: var(--700, #25282d);
                                font-feature-settings: "clig" off, "liga" off;
                                /* H4 */
                                font-family: Roboto;
                                font-size: 18px;
                                font-style: normal;
                                font-weight: 700;
                                line-height: 24px; /* 133.333% */
                                letter-spacing: 0.15px;
                                margin-bottom: 16px;
                                padding-top: 24px;
                                margin-top: 24px;
                                border-top: 1px solid #d1dae3;
                              `}
                            >
                              Other
                            </div>
                          )}

                        {props.analytics.conversion.NonMedicalRecommendations.sort(
                          (a, b) => b.Count - a.Count
                        ).map((recommendation, index) => {
                          return (
                            <div
                              className={css`
                                color: #25282d;
                                font-feature-settings: "clig" off, "liga" off;

                                /* Label */
                                font-family: Roboto;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 700;
                                line-height: 24px; /* 171.429% */
                                letter-spacing: 0.15px;
                                margin-bottom: 24px;
                              `}
                            >
                              <div
                                className={css`
                                  display: flex;
                                  justify-content: space-between;
                                `}
                              >
                                <div>{recommendation.DisplayValue}</div>
                                <div
                                  className={css`
                                    color: var(--500, #66737f);
                                    font-feature-settings: "clig" off,
                                      "liga" off;
                                    font-family: Roboto;
                                    font-size: 14px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: 24px; /* 171.429% */
                                    letter-spacing: 0.15px;
                                  `}
                                >
                                  {Math.floor(
                                    getRecommendationPercentage(
                                      recommendation,
                                      "Buyup"
                                    ) * 100
                                  )}
                                  %
                                </div>
                              </div>
                              <Tooltip title={recommendation.Count}>
                                <div
                                  className={css`
                                    display: flex;
                                    flex-direction: column;
                                    justify-content: center;
                                    align-items: flex-start;
                                    align-self: stretch;
                                    border-radius: 8px;
                                    background: var(--200, #e8edf3);
                                    margin-top: 4px;
                                    margin-bottom: 8px;
                                  `}
                                >
                                  <div
                                    className={css`
                                      width: ${getRecommendationPercentage(
                                        recommendation,
                                        "Buyup"
                                      ) * 100}%;
                                      height: 8px;
                                      border-radius: 8px;
                                      background: ${index < 5
                                        ? graphColors[index]
                                        : "#25282d"};
                                    `}
                                  ></div>
                                </div>
                              </Tooltip>
                            </div>
                          );
                        })}
                      </>
                    ) : (
                      <EmptyState />
                    )}
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      </div>
      {props.openEnrollmentCollection && (
        <Modal
          onClose={() => {
            props.setOpenEnrollmentCollection(false);
            props.setTimeframe("Last 30 days");
          }}
          display={true}
        >
          <div
            className={css`
              padding: 32px;
            `}
          >
            <div
              className={css`
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 16px;
              `}
            >
              <CalendarBlank size={72} />
              <div
                className={css`
                  color: var(--700, #25282d);
                  text-align: center;
                  font-feature-settings: "clig" off, "liga" off;
                  font-family: Roboto;
                  font-size: 24px;
                  font-style: normal;
                  font-weight: 700;
                  line-height: 32px; /* 133.333% */
                  text-transform: capitalize;
                  margin-top: 8px;
                `}
              >
                Let's set your open enrollment dates
              </div>
              <div
                className={css`
                  color: var(--Gray-500, #66737f);
                  text-align: center;
                  font-feature-settings: "clig" off, "liga" off;
                  font-family: Roboto;
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 24px; /* 150% */
                  letter-spacing: 0.15px;
                `}
              >
                To get the most precise insights on your analytics, we suggest
                adding your open enrollment dates.
              </div>
              <div
                className={css`
                  display: flex;
                  align-items: center;
                  border-radius: 8px;
                  border: 1px solid var(--Gray-300, #d1dae3);
                  height: 44px;
                `}
              >
                <div
                  className={css`
                    & .CalendarDay__selected_span {
                      background: #f5f7fa;
                      color: #25282d;
                      border: 1px solid #f5f7fa;
                    }

                    & .CalendarDay__selected_span:hover {
                      background: #25282d;
                      color: white;
                      border: 1px solid #25282d;
                    }

                    & .CalendarDay__selected {
                      background: #25282d;
                      color: white;
                      border: 1px solid #25282d;
                    }

                    & .CalendarDay__selected:hover {
                      background: #25282d;
                      color: white;
                      border: 1px solid white;
                    }

                    & .DateInput_input__focused {
                      border-bottom: 2px solid #25282d;
                    }

                    & .DayPickerKeyboardShortcuts_show__bottomRight::before {
                      border-top: 26px solid transparent;
                      border-right: 33px solid #25282d;
                      bottom: 0;
                      right: 0;
                    }

                    & .CalendarDay__default:hover {
                      border: 1px solid #25282d;
                      color: #fff;
                      background: #25282d;
                    }
                  `}
                >
                  <DateRangePicker
                    small
                    openDirection={"up"}
                    noBorder
                    isOutsideRange={() => false}
                    startDate={
                      props?.guide?.TargetingStart
                        ? moment.utc(props?.guide?.TargetingStart).set({
                            hour: 0,
                            minute: 0,
                            second: 0,
                            millisecond: 0,
                          })
                        : null
                    } // momentPropTypes.momentObj or null,
                    startDateId="oe_start_date_id" // PropTypes.string.isRequired,
                    endDate={
                      props?.guide?.TargetingEnd
                        ? moment.utc(props.guide.TargetingEnd).set({
                            hour: 23,
                            minute: 59,
                            second: 59,
                            millisecond: 999,
                          })
                        : null
                    } // momentPropTypes.momentObj or null,
                    endDateId="oe_end_date_id" // PropTypes.string.isRequired,
                    onDatesChange={({ startDate, endDate }) => {
                      let course = cloneDeep(props.guide);

                      course.TargetingStart = startDate;
                      course.TargetingEnd = endDate;

                      props.setSelectedCourseData(course);
                    }} // PropTypes.func.isRequired,
                    focusedInput={props.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                    onFocusChange={(focusedInput) =>
                      props.setFocusedInput(focusedInput)
                    } // PropTypes.func.isRequired,
                  />
                </div>
              </div>
            </div>
            <div
              className={css`
                display: flex;
                justify-content: flex-end;
                gap: 16px;
                margin-top: 24px;
              `}
            >
              <div>
                <Button
                  styles="secondary"
                  className={css`
                    width: 124px;
                  `}
                  onClick={() => {
                    props.setOpenEnrollmentCollection(false);
                    props.setTimeframe("Last 30 days");
                  }}
                >
                  Skip
                </Button>
              </div>
              <div>
                <Button
                  className={css`
                    width: 124px;
                  `}
                  disabled={
                    !props?.guide?.TargetingStart || !props?.guide?.TargetingEnd
                  }
                  onClick={() => {
                    props.setOpenEnrollmentCollection(false);
                    props.updateOEDates();
                    props.setTimeframe("Open Enrollment");
                  }}
                >
                  Set
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      )}
      {learnMore && (
        <Modal display={true} onClose={() => showLearnMore("")}>
          <div
            className={css`
              width: 600px;
            `}
          >
            <div
              className={css`
                display: flex;
                justify-content: space-between;
                padding: 32px;
                align-items: center;
              `}
            >
              {learnMore === "Communication-Tools" && (
                <div
                  className={css`
                  color: var(--700, #25282D);
                  font-feature-settings: 'clig' off, 'liga' off;
                  font-family: Roboto;
                  font-size: 24px;
                  font-style: normal;
                  font-weight: 700;
                  line-height: 32px; /
                  `}
                >
                  Brite Communication Tools
                </div>
              )}
              <IconButton size="medium" onClick={() => showLearnMore("")}>
                <X />
              </IconButton>
            </div>
            {learnMore === "Communication-Tools" && (
              <div
                className={css`
                  color: var(--500, #66737f);
                  font-feature-settings: "clig" off, "liga" off;
                  /* Body - Dark */
                  font-family: Roboto;
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 24px; /* 150% */
                  letter-spacing: 0.15px;
                  padding: 32px;
                  padding-top: 0px;
                `}
              >
                Brite offers various options for distributing guides. You can
                send them via text, Slack, email, or distribute them using a QR
                code. By leveraging our communication tools located in the
                "Messaging" tab, we can improve analytics accuracy.
              </div>
            )}

            <div
              className={css`
                display: flex;
                justify-content: flex-end;
                gap: 16px;

                padding: 32px;
                padding-top: 0px;
              `}
            >
              <div>
                <Button
                  styles="secondary"
                  className={css`
                    width: 124px;
                  `}
                  onClick={() => showLearnMore("")}
                >
                  Back
                </Button>
              </div>
              <div>
                <Button
                  className={css`
                    width: 124px;
                  `}
                  onClick={() => {
                    history.push(`/${props?.selectedBusiness?.ID}/messaging`);
                  }}
                >
                  Explore
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      )}

      {props.editEmployeeCount && (
        <Modal display={true} onClose={() => props.setEditEmployeeCount(false)}>
          <div
            className={css`
              width: 600px;
            `}
          >
            <div
              className={css`
                display: flex;
                justify-content: space-between;
                padding: 32px;
                padding-bottom: 24px;
                align-items: center;
              `}
            >
              <div
                className={css`
                  color: var(--700, #25282D);
                  font-feature-settings: 'clig' off, 'liga' off;
                  font-family: Roboto;
                  font-size: 24px;
                  font-style: normal;
                  font-weight: 700;
                  line-height: 32px; /
                  `}
              >
                Number of Employees
              </div>

              <IconButton
                size="medium"
                onClick={() => props.setEditEmployeeCount(false)}
              >
                <X />
              </IconButton>
            </div>

            <div
              className={css`
                border-radius: 8px;
                background: var(--100, #f5f7fa);
                margin: 32px;
                margin-top: 0px;
                padding: 8px;
                display: flex;
                gap: 16px;
              `}
            >
              <div>
                <WarningCircle weight="fill" color="#F8A74C" />
              </div>
              <div
                className={css`
                  color: var(--500, #66737f);
                  font-feature-settings: "clig" off, "liga" off;
                  /* Body - Dark */
                  font-family: Roboto;
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 24px; /* 150% */
                  letter-spacing: 0.15px;
                `}
              >
                Changing the number of employees can significantly impact the
                analytics data displayed. This change will only affect the
                current guide.
              </div>
            </div>
            <div
              className={css`
                margin-left: 32px;
                margin-right: 32px;
                margin-bottom: 24px;
              `}
            >
              <TextField
                autoFocus
                className={css`
                  width: 100%;
                `}
                id="outlined-basic"
                variant="outlined"
                label="Number of Employees"
                type="number"
                value={props.eligibleEmployee}
                onChange={(e) => {
                  if (e.target.value <= 9999999) {
                    props.setEligibleEmployee(e.target.value);
                    props.updateEligibleEmployees(e.target.value);
                  }
                }}
              />
            </div>

            <div
              className={css`
                display: flex;
                justify-content: flex-end;
                gap: 16px;

                padding: 32px;
                padding-top: 0px;
              `}
            >
              <div>
                <Button
                  styles="secondary"
                  className={css`
                    width: 124px;
                  `}
                  onClick={() => props.setEditEmployeeCount(false)}
                >
                  Cancel
                </Button>
              </div>
              <div>
                <Button
                  className={css`
                    width: 124px;
                  `}
                  onClick={() => props.setEditEmployeeCount(false)}
                >
                  Update
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </Modal>
  );
};
