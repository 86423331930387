import { Editor, Transforms } from "slate";
import { Mention } from "./Mention";
import * as uuid from "uuid";
import { slateToDomConfig, slateToHtml } from "slate-serializers";
import { Element } from "domhandler";
import { CustomAxios } from "../../../redux/axios/axios";

const inlineElements = ["mention"];

export function withInlines(editor) {
  const { isInline } = editor;
  editor.isInline = (element) =>
    inlineElements.includes(element.type) || isInline(element);
  return editor;
}

export const setNodes = (editor, updates, matchFn) => {
  Transforms.setNodes(editor, updates, { match: matchFn });
};

export const removeNode = (editor, matchFn) => {
  const [block] = Editor.nodes(editor, {
    match: matchFn,
  });
  const [, blockPath] = block || [];
  const node = block?.[0];
  if (matchFn(node)) {
    Transforms.delete(editor, {
      at: blockPath,
    });
  }
  return node;
};

export const insertMention = (editor, mention) => {
  const mentionNode = {
    children: [{ text: "@" }],
    type: "mention",
    data: {
      mentionId: uuid.v4(),
    },
  };
  Transforms.insertNodes(editor, mentionNode);
  Transforms.move(editor);
};

export const slatePreviewElements = {
  p: ({ children }) => {
    return children?.reduce((prev, item) => {
      if (item?.type === "mention") {
        const subChildren = item?.children?.reduce(
          (p, item) => p + item.text,
          ""
        );
        return prev + subChildren;
      }
      return prev + item.text;
    }, "");
  },
};

export const intialSlateValue = [
  {
    type: "p",
    children: [{ text: "" }],
  },
];

export const DefaultElement = (props) => {
  return <p {...props.atttributes}>{props.children}</p>;
};

export const elementMap = {
  mention: Mention,
};

export const getMentionName = (user) => {
  if (user?.FirstName && user?.LastName) {
    return `${user?.FirstName} ${user?.LastName}`;
  } else if (user?.FirstName) {
    return user?.FirstName;
  } else if (user?.LastName) {
    return user?.LastName;
  } else {
    return user?.Email;
  }
};

export const convertSlateToComment = (slateContent) => {
  let mentions = [];

  const content = slateToHtml(slateContent, {
    ...slateToDomConfig,
    elementTransforms: {
      ...slateToDomConfig?.elementTransforms,
      mention: ({ children = [], node }) => {
        mentions.push(node?.data?.user);
        return new Element("strong", {}, children);
      },
    },
  });

  const preview = slateContent?.reduce((prev, item, idx) => {
    const data = slatePreviewElements?.[item?.type](item);
    const newline = idx > 0 ? "\n" : "";
    return prev + newline + data;
  }, "");

  return {
    content,
    mentions,
    preview,
    serializer: "slate-to-html",
  };
};

export const subscribeMentions = async (resourceType, resourceId, users) => {
  return Promise.all(
    users?.map((user) => {
      return CustomAxios.post(
        `v1/notifications/${resourceType}/${resourceId}/subscriptions/${user?.UserID}`
      );
    })
  );
};

// const Leaf = ({ attributes, children, leaf }) => {
//   return <span {...attributes}>{children}</span>;
// };
