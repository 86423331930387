import { useState } from "react";
import { css } from "@emotion/css";
import MenuItem from "@material-ui/core/MenuItem";
import Tooltip from "@material-ui/core/Tooltip";
import { Button } from "../shared/components/button";
import { Info } from "phosphor-react";
import ScratchIcon from "./scratch-icon.png";
import FirstAidKit from "./FirstAidKit.png";
import Stack from "./Stack.png";
import { Div, Loader, Modal, Select, Text } from "../shared/components";
import { container, flex } from "../shared/shared-styles";
import { colors } from "../shared/styles";
import { useAcls } from "../shared/use-acls";

export function AddCourseMenu(props) {
  const canWrite = useAcls(["write:brite"]);

  const [newGuideModalOpen, setNewGuideModalOpen] = useState(false);

  const [selectedGuideStartingPoint, setSelectedGuideStartingPoint] =
    useState("generate");

  const handleClick = (event) => {
    event.stopPropagation();
    setNewGuideModalOpen(true);
  };

  const handleClose = async (event) => {
    event.stopPropagation();
    setNewGuideModalOpen(false);
  };

  let modalProps = { display: newGuideModalOpen, onClose: handleClose };

  return (
    <>
      <Tooltip
        title="Add a new guide. Select where you'd like to start"
        placement="top"
      >
        <Button onClick={handleClick} color="primary" disabled={!canWrite}>
          New Guide
        </Button>
      </Tooltip>
      {newGuideModalOpen && (
        <Modal {...modalProps}>
          <Modal.Paper width="600px">
            <Modal.Header
              title="Add a New Guide"
              onClose={modalProps.onClose}
            />
            <Modal.Body>
              <div
                className={css`
                  color: #25282d;
                  font-family: Roboto;
                  font-size: 18px;
                  font-style: normal;
                  font-weight: 700;
                  line-height: 24px;
                  margin-bottom: 16px;
                `}
              >
                What is the main objective of this guide?
              </div>
              <div>
                {" "}
                <Select
                  css={`
                    width: 100%;
                  `}
                  placeholder="Select a Type"
                  value={props.selectedGuideType}
                  onChange={(e) => props.setSelectedGuideType(e.target.value)}
                >
                  <MenuItem value={"openenrollment"}>Open Enrollment</MenuItem>
                  <MenuItem value={"newhire"}>New Hire</MenuItem>
                  <MenuItem value={"recruiting"}>Recruiting</MenuItem>
                  <MenuItem value={"other"}>Other</MenuItem>
                </Select>
              </div>
              <div
                className={css`
                  color: #25282d;
                  font-family: Roboto;
                  font-size: 18px;
                  font-style: normal;
                  font-weight: 700;
                  line-height: 24px;
                  padding: 16px 0;
                  padding-top: 24px;
                  margin-top: 24px;
                  border-top: 1px solid ${colors.gray[300]};
                `}
              >
                How would you like to get started?
              </div>
              <div
                className={css`
                  border-radius: 8px;
                  border: 1px solid #d1dae3;
                  ${selectedGuideStartingPoint === "generate"
                    ? `
                    background: ${colors.gray[100]};
                    outline: 2px solid ${colors.black};
                  `
                    : `
                    background: ;#fff;
                  `};
                  padding: 16px;
                  display: flex;
                  align-items: center;
                  gap: 32px;
                  cursor: pointer;
                  margin-top: 12px;
                  margin-bottom: 12px;
                  ${container.hover}
                `}
                onClick={() => setSelectedGuideStartingPoint("generate")}
              >
                <div>
                  <img
                    src={FirstAidKit}
                    alt="generate-package-icon"
                    width="40px"
                    height="40px"
                  />
                </div>
                <Div
                  css={css`
                    ${flex("space-between")}
                    width: 100%;
                  `}
                >
                  <Text h4>Generate From a Benefit Package</Text>
                  <Tooltip
                    title="Choose a benefits package, pick a style, and we'll generate a
                    course."
                  >
                    <Info size={24} />
                  </Tooltip>
                </Div>
              </div>

              <div
                className={css`
                  border-radius: 8px;
                  border: 1px solid #d1dae3;
                  ${selectedGuideStartingPoint === "template"
                    ? `
                    background: ${colors.gray[100]};
                    outline: 2px solid ${colors.black};
                  `
                    : `
                    background: ;#fff;
                  `};
                  padding: 16px;
                  display: flex;
                  align-items: center;
                  gap: 32px;
                  cursor: pointer;
                  margin-top: 12px;
                  margin-bottom: 12px;
                  ${container.hover}
                `}
                onClick={() => setSelectedGuideStartingPoint("template")}
              >
                <div>
                  {" "}
                  <img
                    src={Stack}
                    alt="use-template-icon"
                    width="40px"
                    height="40px"
                  />
                </div>
                <Div
                  css={css`
                    ${flex("space-between")}
                    width: 100%;
                  `}
                >
                  <Text h4>Use a Template</Text>
                  <Tooltip title="Start building with a saved template or Brite template.">
                    <Info size={24} />
                  </Tooltip>
                </Div>
              </div>

              <div
                className={css`
                  border-radius: 8px;
                  border: 1px solid #d1dae3;
                  ${selectedGuideStartingPoint === "scratch"
                    ? `
                    background: ${colors.gray[100]};
                    outline: 2px solid ${colors.black};
                  `
                    : `
                    background: ;#fff;
                  `};
                  padding: 16px;
                  display: flex;
                  align-items: center;
                  gap: 32px;
                  cursor: pointer;
                  margin-top: 12px;
                  margin-bottom: 12px;
                  ${container.hover}
                `}
                onClick={() => setSelectedGuideStartingPoint("scratch")}
              >
                <div>
                  <img
                    src={ScratchIcon}
                    alt="start-from-scratch-icon"
                    width="40px"
                    height="40px"
                  />
                </div>
                <Div
                  css={css`
                    ${flex("space-between")}
                    width: 100%;
                  `}
                >
                  <Text h4>Start From Scratch</Text>
                  <Tooltip title="Dive right into the builder and start with a blank canvas.">
                    <Info size={24} />
                  </Tooltip>
                </Div>
              </div>
            </Modal.Body>
            <Modal.Actions>
              <Button
                secondary
                css={`
                  margin-right: 16px;
                `}
                onClick={modalProps.onClose}
              >
                Close
              </Button>
              <Button
                disabled={false}
                onClick={(e) => {
                  if (selectedGuideStartingPoint === "generate") {
                    props.generateCourse(e);

                    handleClose(e);
                  } else if (selectedGuideStartingPoint === "template") {
                    props.pickTemplate(e);

                    handleClose(e);
                  } else {
                    props.startFromScratch(e);

                    handleClose(e);
                  }
                }}
                css={`
                  position: relative;
                `}
              >
                <Loader
                  type="three-dots"
                  isLoading={false}
                  className={css`
                    ${flex("center start")} background-color: ${colors
                      .gray[300]};
                  `}
                />
                Create Guide
              </Button>
            </Modal.Actions>
          </Modal.Paper>
        </Modal>
      )}
    </>
  );
}
