import { CircularProgress, MenuItem, Tooltip } from "@material-ui/core";
import { css } from "@emotion/css";
import { useQueryAPI } from "../react-query";
import { CustomAxios } from "../redux/axios/axios";
import {
  Button,
  ConfirmationModal,
  Div,
  DropMenu,
  Text,
} from "../shared/components";
import { useStore } from "../store-provider/use-store";
import { useAcls } from "../shared/use-acls";
import { WRITE_BUSINESS_ACCESS } from "../shared/acl-constants";
import { CaretDown, DotsThree, Info } from "phosphor-react";
import { useState } from "react";
import { toast } from "react-toastify";
import { AddMember } from "../Dashboard/add-member";
import { EditMember } from "./edit-member";
import { container, flex } from "../shared/shared-styles";
import { colors } from "../shared/styles";

const memberStyle = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 81px;
  padding: 16px;
  border-bottom: 1px solid #d1dae3;
`;

export const fetchBusinessMembers = async (businessId) => {
  let users = [];
  try {
    const {
      data: { Data: members },
    } = await CustomAxios.get(
      `v2/business/${businessId}/users?dni_children=true`
    );
    const filtered = members.filter(({ Roles }) =>
      Roles.some(({ ResourceID }) => ResourceID === businessId)
    );
    const promises = await Promise.allSettled(
      filtered.map((item) => CustomAxios.get(`v1/users/${item.UserID}`))
    );
    for (let idx = 0; idx < promises.length; idx++) {
      if (promises[idx].status === "fulfilled") {
        const member = filtered[idx];
        const user = promises[idx].value.data;
        const role = member.Roles.find(
          ({ ResourceID }) => ResourceID === businessId
        );
        users.push({ role, ...user });
      }
    }
  } catch (err) {
    console.warn(err);
    return [];
  }
  return users.sort((a, b) => a.Email.localeCompare(b.Email));
};

const memberRoleMapping = {
  admin: {
    display: "Admin",
    tooltip: "No restrictions",
  },
  editor: {
    display: "Editor",
    tooltip: "Can edit everything except users",
  },
  guest: {
    display: "HR Team",
    tooltip:
      "Can view anything, and can create new guides and edit only those guides they've created",
  },
  viewer: {
    display: "View Only",
    tooltip: "Can't make edits to anything, view only",
  },
};

export const BusinessMembers = () => {
  const {
    data: { selectedBusiness, user },
  } = useStore();
  const hasWriteAccess = useAcls([WRITE_BUSINESS_ACCESS]);

  const [displayMemberModal, setDisplayMemberModal] = useState(false);
  const [displayEdit, setDisplayEdit] = useState(false);
  const [displayRemoveId, setDisplayRemoveId] = useState("");

  const { data, cache, refetch, isLoading } = useQueryAPI({
    urlKey: `business-members/${selectedBusiness.ID}`,
    queryFn: () => fetchBusinessMembers(selectedBusiness.ID),
    enabled: !!selectedBusiness.ID,
    defaultValue: [],
  });

  const handlePermission = async (roleId, member) => {
    const userId = member.ID;
    const businessId = selectedBusiness.ID;

    const old = [...data];
    const updates = data.map((item) =>
      item.ID === userId
        ? { ...item, role: { ...item.role, RoleID: roleId } }
        : item
    );

    try {
      cache.setData(updates);
      await CustomAxios.delete(`v1/user/${userId}/access/${businessId}`);
      await CustomAxios.post(
        `v1/user/${userId}/access/${businessId}/role/${roleId}`
      );
      window?.ChurnZero?.push([
        "trackEvent",
        "Settings",
        `Permissions updated`,
        1,
      ]);
    } catch (err) {
      toast.error("Failed to update the users permission");
      cache.setData(old);
      console.warn(err);
    } finally {
      refetch();
    }
  };

  const removeAccess = async () => {
    const userId = displayRemoveId;
    const businessId = selectedBusiness.ID;
    const old = [...data];
    const updates = data.filter((item) => item.ID !== userId);
    setDisplayRemoveId("");
    try {
      cache.setData(updates);
      await CustomAxios.delete(`v1/user/${userId}/access/${businessId}`);
    } catch (err) {
      cache.setData(old);
      toast.error("Failed to remove users access.");
      console.warn(err);
    } finally {
      refetch();
    }
  };

  const handleCloseMemberModal = () => {
    refetch();
    setDisplayMemberModal(false);
  };

  return (
    <Div>
      <Div
        css={css`
          ${container.header}
        `}
      >
        <Text styles="h1">Members</Text>
        <Div
          css={css`
            display: flex;
          `}
        >
          {!hasWriteAccess ? (
            <Button
              disabled="true"
              hoverLabel="Must be an admin to invite members"
            >
              <Text bold>Invite Member</Text>
            </Button>
          ) : (
            <Button onClick={() => setDisplayMemberModal(true)}>
              Invite Member
            </Button>
          )}
        </Div>
      </Div>

      <div
        className={css`
          margin: 32px 0;
          max-width: 1000px;
          padding-bottom: 100px;
        `}
      >
        <Div
          css={css`
            ${flex("space-between")} margin: 0 8px;
          `}
        >
          <Text styles="fg">Name</Text>
          <Text styles="fg">Email</Text>
          <Text
            className={css`
              width: 150px;
            `}
          >
            Permissions
          </Text>
          <Text
            styles="right"
            className={css`
              width: 150px;
            `}
          >
            Options
          </Text>
        </Div>

        {isLoading ? (
          <div
            className={css`
              display: flex;
              justify-content: center;
              align-items: center;
              margin: 32px;
            `}
          >
            <CircularProgress />
          </div>
        ) : !!data?.length ? (
          data?.map((member) => (
            <div key={member.ID} className={memberStyle}>
              <Div
                css={css`
                  ${flex("grow")}
                  max-width: 15%;
                `}
              >
                <Text>
                  {member.FirstName} {member.LastName}
                </Text>
              </Div>
              <Div
                css={css`
                  ${flex("grow")}
                  max-width: 25%;
                `}
              >
                <Tooltip title={member.Email} placement="top">
                  <Text
                    css={`
                      max-width: 100%;
                      overflow: hidden;
                    `}
                    ellipsis
                  >
                    {member.Email}
                  </Text>
                </Tooltip>
              </Div>
              <Div
                className={css`
                  width: 150px;
                `}
              >
                <DropMenu
                  button={
                    <div
                      className={css`
                        ${flex("space-between")} border-radius: 8px;
                        border: 1px solid ${colors.gray[300]};
                        padding: 8px;
                        ${container?.hover}
                      `}
                    >
                      <Text label>
                        {memberRoleMapping[member?.role?.RoleID]?.display}
                      </Text>
                      <CaretDown size={24} />
                    </div>
                  }
                >
                  {Object.entries(memberRoleMapping)?.map(([key, role]) => (
                    <MenuItem
                      value={key}
                      item={member}
                      onClick={() => handlePermission(key, member)}
                      style={{
                        width: "180px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Text label>{role?.display}</Text>
                      <Tooltip title={role?.tooltip} placement="right">
                        <Info size={24} />
                      </Tooltip>
                    </MenuItem>
                  ))}
                </DropMenu>
              </Div>
              <Div
                css={css`
                  ${flex("jcr")} width: 150px;
                `}
              >
                {hasWriteAccess ? (
                  <DropMenu
                    title="Options"
                    button={
                      <Button styles="icon">
                        <DotsThree />
                      </Button>
                    }
                  >
                    {member.ID === user.ID && (
                      <MenuItem onClick={() => setDisplayEdit(true)}>
                        Edit
                      </MenuItem>
                    )}

                    {
                      // can't remove yourself if you're the last member
                      (member.ID !== user.ID || data?.length > 1) && (
                        <MenuItem onClick={() => setDisplayRemoveId(member.ID)}>
                          Remove Access
                        </MenuItem>
                      )
                    }
                  </DropMenu>
                ) : (
                  <Button styles="icon" disabled={true}>
                    <DotsThree />
                  </Button>
                )}
              </Div>
            </div>
          ))
        ) : (
          <Text styles="label mt-xl">No members found.</Text>
        )}
      </div>

      <AddMember
        display={displayMemberModal}
        onClose={handleCloseMemberModal}
        defaultBusiness={selectedBusiness}
        hideBusinessSelection={true}
      />

      <EditMember
        display={displayEdit}
        onClose={() => {
          setDisplayEdit(false);
          refetch();
        }}
      />

      <ConfirmationModal
        title="Are you sure you want to remove access for this user?"
        display={!!displayRemoveId}
        onClose={() => setDisplayRemoveId("")}
        onConfirm={removeAccess}
        isDeleteAlert
        confirmText="Remove Access"
      />
    </Div>
  );
};
