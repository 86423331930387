import { Collapse, Tooltip } from "@material-ui/core";
import { css } from "@emotion/css";
import { set } from "lodash";
import {
  CaretDown,
  CaretUp,
  PencilSimple,
  Percent,
  WarningCircle,
} from "phosphor-react";
import { useEffect, useMemo, useState } from "react";
import { surestPlanType } from "../Content/decision-tool-constants";
import { Button, Div, Text } from "../shared/components";
import { flex } from "../shared/shared-styles";
import { colors } from "../shared/styles";
import { ZipCodes } from "./applicable-zip-codes";
import { CustomQuestion } from "./sections/custom-question";
import { FieldInput } from "./field-components/field-input";
import { Field, fieldContainerStyle } from "./field-components/field";
import { PlanTypeIcons } from "../benefit-package/plan-type-icons";
import {
  formFieldConfigs,
  formSectionConfigs,
  getSectionNeedsAttention,
} from "./configs/form.config";
import { getFieldConfigs, getSectionConfig } from "./configs/config-utils";
import { productModifier } from "./configs/product-utils";
import { Disclaimers } from "./field-components/disclaimers";
import { CustomDetails } from "./field-components/custom-details";
import { FlagsBadge } from "./flags/flags-badge";
import { Flag } from "./flags/flag";

export const sectionContainer = (open) => css`
  border-radius: 16px;
  border: 1px solid ${colors.gray[300]};
  padding: 32px;
  width: 100%;
  margin-bottom: 16px;
  ${!open
    ? `
  :hover {
    cursor: pointer;
    background-color: ${colors.gray[100]};
  }`
    : ""}
  transition: background .4s ease;
`;

export const ProductSection = ({
  state,
  section,
  needsAttention,
  openSections,
  index,
}) => {
  const { product, network, fieldsObject } = state;

  const core = network.getProductByCategory("core");
  const { Type } = product;

  const [open, setOpen] = useState(!!openSections && index === 0);
  const [modal, setModal] = useState("");

  useEffect(() => {
    setOpen(!!openSections);
  }, [openSections]);

  const sectionConfig = getSectionConfig(formSectionConfigs, section);

  const SectionIcon =
    sectionConfig?.Icon || (index === 0 ? PlanTypeIcons : null);

  const onUpdate = (property, value) => {
    let nextValue = value;
    if (property === "Details.InNetworkPlanDesign.Coinsurance") {
      nextValue /= 100;
    }
    productModifier.setProduct(
      state,
      (data) => {
        let nextData = { ...data };
        set(nextData, property, nextValue);
        return nextData;
      },
      { category: network?.category }
    );
  };

  const sectionNeedsAttention = getSectionNeedsAttention(
    product,
    section?.Fields
  );

  const isMedicalDetails =
    section?.DisplayValue === "Plan Details" && Type === "insurance_plan";

  const fieldsConfig = getFieldConfigs(formFieldConfigs, {
    product,
    fieldsObject,
  });

  const propertiesList = Object.keys(fieldsObject || {});

  const orphanedFlags = useMemo(() => {
    if (index === 0) {
      const expandedLater = ["Cost"];
      return state?.flags?.query?.data?.reduce((prev, flag) => {
        const hasProperty = propertiesList.includes(
          flag?.metadata?.property_chain
        );
        const isHidden = fieldsConfig?.hideField;
        const isExpandedLater = expandedLater.some((item) =>
          flag?.metadata?.property_chain.includes(item)
        );
        if (!hasProperty && !isHidden && !isExpandedLater) {
          return [...prev, flag];
        }
        return prev;
      }, []);
    }
    return [];
  }, [state?.flags?.query?.dataUpdatedAt, propertiesList?.length]);

  const filteredFlags = useMemo(() => {
    if (section?.DisplayValue === "Premiums & Contributions") {
      return state?.flags?.query?.data
        ?.map(({ metadata }) => metadata?.property_chain)
        ?.filter((property) => property.includes("Cost."));
    }
    return section?.Fields?.map(({ PropertyChain }) => PropertyChain);
  }, [section.Fields.length]);

  return (
    <>
      <Div css={sectionContainer(open)} onClick={() => !open && setOpen(!open)}>
        {section?.DisplayValue && (
          <Div
            css={css`
              ${flex("jcsb aic")} cursor: pointer;
              ${open &&
              `padding-bottom: 24px; margin-bottom: 24px; border-bottom: 1px solid ${colors.gray[300]};`}
            `}
            onClick={() => setOpen(!open)}
          >
            <Div
              css={css`
                ${flex("ais")}
              `}
            >
              <Div
                css={css`
                  ${flex("aic")} min-width: 32px;
                  margin-right: 8px;
                `}
              >
                {SectionIcon ? <SectionIcon type={Type} /> : SectionIcon}
              </Div>
              <Text styles="h3">{section?.DisplayValue}</Text>
            </Div>

            <Div
              css={css`
                ${flex("right")}
              `}
            >
              <FlagsBadge
                flags={state?.flags?.query?.data}
                properties={filteredFlags}
                orphanedFlags={orphanedFlags}
              />
              {sectionNeedsAttention && (
                <Tooltip title="This section needs attention">
                  <WarningCircle
                    color={colors.red[100]}
                    className={css`
                      min-width: 32px;
                    `}
                  />
                </Tooltip>
              )}
              <Button
                styles="icon"
                className={css`
                  pointer-events: none;
                `}
              >
                {open ? <CaretUp /> : <CaretDown />}
              </Button>
            </Div>
          </Div>
        )}
        <Collapse in={open}>
          {isMedicalDetails &&
            core?.Details?.PlanType.toUpperCase() === surestPlanType && (
              <Div css={fieldContainerStyle()}>
                <Text
                  label
                  bold
                  css={`
                    width: 50%;
                  `}
                >
                  Insert Surest expected change in member claims cost
                </Text>
                <Div
                  css={css`
                    width: 50%;
                  `}
                >
                  <FieldInput
                    field={{
                      Type: "percent",
                      PropertyChain: `Details.SurestProfessionalServices.ActuarailCost`,
                      productId: product?.ID,
                    }}
                    config={{
                      storeValueAs: "number",
                    }}
                    startIcon={
                      <Text
                        styles="label"
                        css={`
                          padding: 0 16px;
                        `}
                      >
                        -
                      </Text>
                    }
                    endIcon={<Percent />}
                    value={
                      product?.Details?.SurestProfessionalServices
                        ?.ActuarailCost
                    }
                    updateProperty={(property, value) => {
                      debugger;
                      onUpdate(property, value);
                    }}
                  />
                </Div>
              </Div>
            )}

          {section?.Type === "custom-question" ? (
            <CustomQuestion
              state={state}
              needsAttention={needsAttention?.["Question.Text"]}
            />
          ) : section?.Type === "disclaimers" ? (
            <Disclaimers state={state} />
          ) : section?.Type === "custom-details" ? (
            <CustomDetails state={state} />
          ) : null}

          {section.Fields.map((item) => (
            <>
              <Field
                state={state}
                field={item}
                config={fieldsConfig[item?.PropertyChain]}
              />
            </>
          ))}

          {index === 0
            ? orphanedFlags?.map((item) => (
                <Flag flag={item} property={item?.metadata?.property_chain} />
              ))
            : null}

          {section?.DisplayValue === "Network Information" &&
            product?.Type === "insurance_plan" &&
            network?.category === "narrow" && (
              <Div
                css={css`
                  ${flex("space-between")}
                  margin: 16px;
                `}
              >
                <Text label>Manage Applicable Zip Codes</Text>
                <Div
                  css={css`
                    ${flex("right")}
                  `}
                >
                  <Text label bold>
                    {product?.ApplicableZipCodes?.length} Zip Codes
                  </Text>
                  <Button styles="icon" onClick={() => setModal("zip-codes")}>
                    <PencilSimple />
                  </Button>
                </Div>
              </Div>
            )}
        </Collapse>
      </Div>

      {modal === "zip-codes" && (
        <ZipCodes
          product={product}
          onUpdate={onUpdate}
          display={modal === "zip-codes"}
          onClose={() => setModal("")}
        />
      )}
    </>
  );
};
