import { MenuItem } from "@material-ui/core";
import { css } from "@emotion/css";
import { BracketsCurly } from "phosphor-react";
import { Button, Div, DropMenu, Input } from "../../shared/components";
import { flex } from "../../shared/shared-styles";
import { colors } from "../../shared/styles";
import { useStore } from "../../store-provider/use-store";
import { modifyContent } from "../provider/utils";

export const DevBlock = ({ editor, element, location }) => {
  const { data, type } = element;

  const {
    data: { devMode },
  } = useStore();

  if (!devMode) {
    return null;
  }

  return (
    <Div
      css={css`
        width: 100%;
        ${flex("left")} padding: 16px;
      `}
    >
      <DropMenu
        button={
          <Button
            styles="icon"
            hoverLabel={type}
            className={css`
              background-color: ${colors.purple};
              :hover {
                background-color: ${colors.gray[500]};
              }
            `}
          >
            <BracketsCurly color="white" />
          </Button>
        }
      >
        <MenuItem
          onClick={() =>
            modifyContent.merge(editor, location, { type: "code" })
          }
        >
          <BracketsCurly
            className={css`
              margin-right: 8px;
            `}
          />{" "}
          Code
        </MenuItem>
      </DropMenu>

      <Input
        rows={2}
        css={`
          flex-grow: 1;
          margin-left: 16px;
        `}
        value={data?.content}
        onChange={(e) =>
          modifyContent.merge(editor, location, {
            data: { content: e.target.value },
          })
        }
      />
    </Div>
  );
};
