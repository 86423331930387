import { css } from "@emotion/css";
import {
  Cpu,
  CurrencyDollarSimple,
  File,
  FirstAidKit,
  Image,
  MapPin,
  Newspaper,
  PlusCircle,
  Rectangle,
  Rows,
  SquaresFour,
  Tabs,
  TextT,
  VideoCamera,
} from "phosphor-react";
import { Icon } from "../../shared/components/icon";
import { colors } from "../../shared/styles";
import { ReactComponent as ListPlus } from "../../images/svg/list-plus.svg";

export const ComponentIconMap = ({ type, ...rest }) => {
  if (type === "text" || type === "textV2") {
    return <TextT {...rest} />;
  } else if (type === "image") {
    return <Image {...rest} />;
  } else if (type === "space") {
    const size = rest.size ? rest.size - 8 : "24";
    const color = rest.color || colors.black;
    return (
      <div
        className={css`
          width: ${size}px;
          height: ${size}px;
          margin: 4px;
          border: 2px dashed ${color};
        `}
      />
    );
  } else if (type === "line") {
    const size = rest.size ? rest.size - 8 : "24";
    const color = rest.color || colors.black;
    return (
      <div
        className={css`
          width: ${size}px;
          height: ${size}px;
          margin: 4px;
          border: 1px solid transparent;
        `}
      >
        <div
          className={css`
            margin-top: 10px;
            height: 0;
            border-top: 2px solid ${color};
          `}
        />
      </div>
    );
  } else if (type === "empty-space") {
    const size = rest.size ? rest.size - 8 : "24";
    const color = rest.color || colors.black;
    return (
      <div
        className={css`
          width: ${size}px;
          height: ${size}px;
          margin: 4px;
          border: 2px dashed ${color};
        `}
      />
    );
  } else if (type === "button") {
    return <Rectangle {...rest} />;
  } else if (type === "accordion") {
    return <PlusCircle {...rest} />;
  } else if (type === "video") {
    return <VideoCamera {...rest} />;
  } else if (type === "website") {
    return <Newspaper {...rest} />;
  } else if (type === "tab") {
    return <Tabs {...rest} />;
  } else if (type === "benefits") {
    return <FirstAidKit {...rest} />;
  } else if (type === "pricing") {
    return <CurrencyDollarSimple {...rest} />;
  } else if (type === "network") {
    return <MapPin {...rest} />;
  } else if (type === "document") {
    return <File {...rest} />;
  } else if (type === "content-block") {
    return <Icon SVG={ListPlus} {...rest} />;
  } else if (type === "row") {
    return <Rows {...rest} />;
  } else if (type === "group") {
    return <SquaresFour {...rest} />;
  } else if (type === "dev-block") {
    return <Cpu {...rest} />;
  } else {
    return null;
  }
};
