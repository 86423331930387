import { Tooltip } from "@material-ui/core";
import { css } from "@emotion/css";
import { flex } from "../../shared/shared-styles";
import { useEditorResource } from "../use-editor-resource";

export const Space = ({ isSelected, element }) => {
  const { component, container, attributes } = element;
  const { data: styles } = useEditorResource("styles");

  const combinedStyles = {
    ...styles?.[component],
    ...attributes?.style,
  };

  const containerStyle = container?.attributes?.style;

  const componentStyles =
    component === "space"
      ? {
          ...combinedStyles,
          borderTopRightRadius: containerStyle?.borderTopRightRadius || "",
          borderBottomRightRadius:
            containerStyle?.borderBottomRightRadius || "",
          borderTopLeftRadius: containerStyle?.borderTopLeftRadius || "",
          borderBottomLeftRadius: containerStyle?.borderBottomLeftRadius || "",
        }
      : combinedStyles;
  return (
    <div
      className={css`
        position: relative;
        overflow: hidden;
        ${flex("center")}
      `}
      style={componentStyles}
      data-component={true}
    >
      {isSelected ? (
        <Tooltip title={`Min Height ${combinedStyles.minHeight}`}>
          <div
            className={css`
              outline: 1px dotted rgba(200, 200, 200, 0.7);
              height: 100%;
              width: 100%;
              max-height: ${combinedStyles.minHeight};
              :hover {
                background-color: rgba(150, 150, 150, 0.1);
              }
            `}
          />
        </Tooltip>
      ) : null}
    </div>
  );
};
