import * as uuid from "uuid";
import { getGenericQueryProps, getUrl } from ".";

const url = "/v1/bp/needs_attention";
export const needsAttention = {
  url,
  key: "needs-attention",
  getResourceOptions: (resourceProps) => {
    const resourceUrl = getUrl(url, resourceProps);
    return {
      url: resourceUrl,
      queryProps: {
        initialData: [],
        ...getGenericQueryProps(resourceUrl, resourceProps, {
          headers: {
            "Accept-Profile": "brite",
          },
        }),
      },
    };
  },
  utils: {
    getPatch: (id, body, resourceParams) => ({
      url: `/v1/bp/needs_attention?id=eq.${id}&business_id=eq.${resourceParams?.businessId}`,
      body,
      config: {
        headers: {
          "Accept-Profile": "brite",
          "Content-Profile": "brite",
        },
      },
      optimistic: (old) => old?.map((item) => (item?.id === id ? body : item)),
    }),
    getPost: (body, resourceParams) => {
      const postBody = {
        id: uuid.v4(),
        business_id: resourceParams?.businessId,
        ...body,
      };
      return {
        url: `/v1/bp/needs_attention?business_id=eq.${resourceParams?.businessId}`,
        body: postBody,
        config: {
          headers: {
            "Accept-Profile": "brite",
            "Content-Profile": "brite",
            Prefer: "return=representation",
          },
        },
        optimistic: (old) => [...old, postBody],
      };
    },
    getDelete: (id, resourceParams) => ({
      url: `/v1/bp/needs_attention?id=eq.${id}&business_id=eq.${resourceParams?.businessId}`,
      config: {
        headers: {
          "Accept-Profile": "brite",
          "Content-Profile": "brite",
        },
      },
      optimistic: (old) => old?.filter((item) => item?.id !== id),
    }),
    getFlagByPropertyChain: (flags, property) => {
      return (
        flags?.query?.data?.filter(
          (flag) => flag?.metadata?.property_chain === property
        )?.[0] || null
      );
    },
  },
};
