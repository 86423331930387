import { useEffect, useMemo, useRef, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { CustomAxios } from "../redux/axios/axios";
import { useStore } from "../store-provider/use-store";

const getQueryParams = (url, cacheKey, params, deps) => {
  let query = "";
  let urlWithPathParams = url;
  if (params) {
    const optionEntries = params ? Object.entries(params) : [];
    urlWithPathParams = optionEntries?.reduce(
      (prev, [key, value]) => prev?.replace(`:${key}`, value),
      url
    );

    if ("query" in params) {
      query = "";
      if (
        typeof params.query === "object" &&
        Object.keys(params.query || {})?.length
      ) {
        query =
          "?" +
          Object.entries(params.query || {})
            .reduce((prev, [key, value]) => {
              if (value.includes(",")) {
                const split = value.split(",");
                const queries = split.map((item) => `${key}=${item}`);
                return [...prev, ...queries];
              }
              return [...prev, `${key}=${value}`];
            }, [])
            .join("&");
      } else if (params.query && typeof params.query === "string") {
        query = params.query;
      }
    }
  }

  const path = urlWithPathParams + query;

  const queryKey = [...cacheKey, url, ...Object.values(params), ...deps];

  const queryFn = async () => {
    const axiosOptions = params ? params.axios || {} : {};
    const { data } = await CustomAxios.get(path, axiosOptions);
    return data;
  };

  return {
    path,
    queryKey,
    queryFn,
  };
};

export const useCache = (queryKey, cacheKey, params = {}) => {
  const {
    data: { selectedBusiness },
  } = useStore();
  const businessId = selectedBusiness && (selectedBusiness.ID || "");
  const { queryKey: key } = getQueryParams(queryKey, cacheKey, params, [
    businessId,
  ]);
  const queryClient = useQueryClient();
  const data = queryClient.getQueryData(key);
  const setData = (value) => queryClient.setQueryData(key, value);
  return { data, setData };
};

export const useQueryAPI = (
  {
    url,
    defaultValue,
    urlKey,
    params = {},
    onMount = null,
    cacheKey = [],
    ...queryOpts
  },
  deps = []
) => {
  const isInitial = useRef(true);
  const [isSettled, setIsSettled] = useState(false);

  const {
    data: { selectedBusiness },
  } = useStore();
  const businessId = selectedBusiness && (selectedBusiness.ID || "");
  const { path, queryKey, queryFn } = getQueryParams(
    url || urlKey,
    cacheKey,
    params,
    [businessId]
  );
  const query = useMemo(
    () => [
      queryKey,
      queryOpts.queryFn || queryFn,
      {
        ...queryOpts,
        onSuccess: queryOpts.onSuccess
          ? (data) => queryOpts?.onSuccess(data, path)
          : null,
      },
    ],
    [path, queryKey]
  );

  const cache = useCache(url || urlKey, cacheKey);

  let queryReturn = useQuery(...query);
  if (queryReturn.data === undefined) {
    queryReturn = {
      ...queryReturn,
      data: defaultValue,
    };
  }

  useEffect(() => {
    if (!isInitial.current) {
      queryReturn.refetch();
    }
    isInitial.current = false;
  }, deps);

  useEffect(() => {
    if (!queryReturn.isLoading) {
      setIsSettled(true);
      if (onMount) {
        if (
          ("enabled" in queryOpts && queryOpts.enabled) ||
          !("enabled" in queryOpts)
        ) {
          onMount(queryReturn.data, path);
        }
      }
    }
  }, [queryReturn.dataUpdatedAt, path]);

  return {
    ...queryReturn,
    isSettled,
    queryKey,
    cache,
  };
};

