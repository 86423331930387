import React, {
  useMemo,
  useState,
  useEffect,
  useCallback,
  useRef,
} from "react";
import { css } from "@emotion/css";
import { Button as MUIButton, Tooltip } from "@material-ui/core";
import { toast } from "react-toastify";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Typography } from "@material-ui/core";
import { ExtensionIconMapper } from "./ExtensionIconMapper";
import { FullScreenUploader } from "./FullscreenUploader";
import EmptyState from "../images/document-empty-state.png";
import { NewTag } from "../shared/components/new-tag";
import { Button, Div, Input, Loader, Text } from "../shared/components";
import { colors } from "../shared/styles";
import { container, flex } from "../shared/shared-styles";
import { Eye, File, MagnifyingGlass, Users } from "phosphor-react";
import { useRequests, useResource } from "../react-query/use-resource";
import { DocumentOptions } from "../BriteEditor/toolbar-menus/menu-components/documents-library";
import { media } from "../react-query/resources/media";
import { Box } from "../shared/components/box";

const documentContainer = css`
  display: flex;
  align-items: center;
  padding: 16px;
  width: 100%;
  cursor: pointer;
  z-index: 1;
  p {
    flex-grow: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  button {
    margin-right: 8px;
  }
  > svg {
    margin-right: 8px;
  }
`;

const emptyStateContainer = css`
  padding: 0;
  margin: auto;
  margin-top: 20%;
  width: max-content;
  text-align: center;
  > * {
    margin-bottom: 32px;
    max-width: 500px;
  }
`;

export const Documents = () => {
  const fileUploadRef = useRef(null);

  const [showShare, setShowShare] = useState(false);
  const [loadingDocuments, setLoadingDocuments] = useState(false);
  const [editingDocument, setEditingDocument] = useState(null);
  const [filter, setFilter] = useState("");

  useEffect(() => {
    if (!showShare) {
      setEditingDocument(null);
    }
  }, [showShare]);

  useEffect(() => {
    if (!editingDocument) {
      setShowShare(false);
    }
  }, [editingDocument]);

  const mediaResource = useResource(media);
  const mediaRequests = useRequests(mediaResource);

  const { data: documents, isLoading } = mediaResource?.query;

  const onDrop = useCallback(async (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      setLoadingDocuments(true);
      try {
        const fileObject = media.utils.extractFileObject(acceptedFiles[0]);
        const mediaPostMeta = media.utils.getPost(fileObject);
        await mediaRequests.post(mediaPostMeta);
        window?.ChurnZero?.push([
          "trackEvent",
          "Documents",
          "Documents tab was accessed AND a document was uploaded",
          1,
        ]);
      } catch (err) {
        console.warn(err);
      } finally {
        setLoadingDocuments(false);
      }
    } else {
      toast.error("Invalid file or too many files");
    }
  }, []);

  const handleUploadButton = (event) => {
    const files = Array.from(event.target.files);
    onDrop(files);
  };

  const documentDeps = documents.map(({ Name }) => Name).toString();
  const filteredDocs = useMemo(() => {
    if (!filter) {
      return documents;
    }
    return documents.filter(({ Name }) =>
      Name.toUpperCase().includes(filter.toUpperCase())
    );
  }, [filter, documentDeps, documents]);

  return (
    <Box
      page
      css={css`
        position: relative;
        width: 100%;
      `}
    >
      <FullScreenUploader onDrop={onDrop} accept="pdf, csv, txt, images" />
      <div
        className={css`
          position: relative;
          display: flex;
          justify-content: space-between;
          align-items: stretch;
          margin-bottom: 24px;
        `}
      >
        <Text h1>Documents</Text>

        <MUIButton
          type="file"
          color="primary"
          variant="contained"
          component="label"
          className={css`
            z-index: 1;
          `}
        >
          Upload Document
          <input
            type="file"
            hidden
            ref={fileUploadRef}
            onChange={handleUploadButton}
          />
        </MUIButton>
      </div>
      <Div
        css={css`
          position: relative;
          ${flex("center start")}
        `}
      >
        <Loader
          isLoading={loadingDocuments || isLoading}
          type="icon"
          className={css`
            width: 100%;
            height: 100%;
            min-height: 50vh;
          `}
        >
          <File size={64} className="bounce" />
        </Loader>
        {!loadingDocuments && !isLoading ? (
          <section
            className={css`
              width: 100%;
              max-width: 700px;
            `}
          >
            {!documents.length ? (
              <div className={emptyStateContainer}>
                <img src={EmptyState} alt="Empty State" />
                <Typography style={{ fontWeight: "bold", fontSize: "22px" }}>
                  Hey, welcome to documents.
                </Typography>
                <br />
                <Typography>
                  Drag n' drop your documents here so you can easily access them
                  from the guide builder.
                </Typography>
                <Typography
                  style={{ marginTop: "82px", color: "#66737F" }}
                  variant="body2"
                >
                  (supports .pdf, .csv, and .txt files)
                </Typography>
              </div>
            ) : (
              <>
                <Input
                  styles="search"
                  placeholder="Search"
                  startIcon={<MagnifyingGlass />}
                  value={filter}
                  onChange={(e) => setFilter(e.target.value)}
                  css={`
                    margin-bottom: 16px;
                  `}
                />
                <Div
                  css={css`
                    height: 100%
                    overflow-y: auto;
                  `}
                >
                  {filteredDocs.map((document) => (
                    <Div
                      css={css`
                        ${container.hover}
                        border-radius: 16px;
                        margin: 8px 0;
                        border: 1px solid ${colors.gray[300]};
                        p {
                          flex-grow: 1;
                        }
                      `}
                      key={document.ID}
                      onClick={() => window.open(document.PublicURL)}
                    >
                      <div className={documentContainer}>
                        <ExtensionIconMapper fileType={document.FileType} />
                        <NewTag createdAt={document.CreatedAt} />
                        <Text label>
                          {document.Name.replace(/\.[^/.]+$/, "")}
                        </Text>

                        {document?.ShareScope !== "business" && (
                          <Tooltip title="Public">
                            <Users size={24} />
                          </Tooltip>
                        )}
                        <Button
                          styles="icon sm"
                          hoverLabel="Preview"
                          onClick={() => window.open(document.PublicURL)}
                        >
                          <Eye />
                        </Button>
                        {!document?.ID ? (
                          <CircularProgress
                            size={32}
                            className={css`
                              margin-right: 8px;
                            `}
                          />
                        ) : (
                          <DocumentOptions
                            document={document}
                            mediaResource={mediaResource}
                          />
                        )}
                      </div>
                    </Div>
                  ))}
                </Div>
              </>
            )}
          </section>
        ) : null}
      </Div>
    </Box>
  );
};
