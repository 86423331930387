import { css } from "@emotion/css";
import { X } from "phosphor-react";
import { useState } from "react";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import { CustomAxios } from "../../../redux/axios/axios";
import {
  Modal,
  Div,
  Text,
  Button,
  Input,
  Loader,
} from "../../../shared/components";
import { flex } from "../../../shared/shared-styles";
import { colors } from "../../../shared/styles";
import { useForm } from "../../../shared/use-form";

export const SavePageTemplate = ({ display, page, close }) => {
  const { businessId } = useParams();

  const { values, getProperty, hasChanges } = useForm({ name: "" }, [display]);
  const [isLoading, setIsLoading] = useState(false);

  const saveTemplate = async (body) => {
    try {
      setIsLoading(true);
      await CustomAxios.post(`/v2/templates`, body);
      toast.success("Successfully saved page as template!");
      close();
    } catch (e) {
      toast.error("Could not save page as a template");
    } finally {
      setIsLoading(false);
    }
  };

  const savePageAsTemplate = async () => {
    let body = null;
    if (page.Type === "regular") {
      window.unlayer.exportHtml((data) => {
        const { design } = data;
        body = {
          Name: values.name,
          Content: design,
          BusinessID: businessId,
          Type: "regular",
          ShareScope: "business",
        };
        saveTemplate(body);
      });
    } else if (page.Type === "brite-editor") {
      body = {
        Name: values.name,
        Content: page.Content,
        BusinessID: businessId,
        Type: "brite-editor",
        ShareScope: "business",
      };
      saveTemplate(body);
    }
  };

  if (!display) {
    return null;
  }

  return (
    <Modal display={display} onClose={close}>
      <Div
        css={css`
          width: 500px;
        `}
      >
        <Div
          css={css`
            ${flex("space-between")} padding: 32px;
          `}
        >
          <Text h2>Save Page As Template</Text>
          <Button styles="icon" onClick={close}>
            <X />
          </Button>
        </Div>

        <Div
          css={css`
            padding: 0 32px;
          `}
        >
          <Text label>Template Name</Text>
          <Input {...getProperty("name")} />
        </Div>

        <Div
          css={css`
            ${flex("right")} padding: 32px;
          `}
        >
          <Button
            secondary
            css={`
              margin-right: 16px;
            `}
            onClick={close}
          >
            Cancel
          </Button>
          <Button
            disabled={!hasChanges || isLoading}
            onClick={savePageAsTemplate}
            css={`
              position: relative;
            `}
          >
            <Loader
              type="three-dots"
              isLoading={isLoading}
              className={css`
                background-color: ${colors.gray[300]};
                ${flex("center start")}
              `}
            />
            Save
          </Button>
        </Div>
      </Div>
    </Modal>
  );
};
