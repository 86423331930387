import { CustomAxios } from "../../redux/axios/axios";

export const formatDocumentsForEditor = async (businessId, parentId) => {
  try {
    let response = await CustomAxios.get("/v2/media");

    let docs = response.data;
    docs.sort(function (a, b) {
      return new Date(b.CreatedAt) - new Date(a.CreatedAt);
    });

    let mappedDocs = docs.map((doc) => {
      return {
        label: doc.Name,
        value: `${doc.Name}&&&${doc.PublicURL}`,
      };
    });

    if (businessId !== parentId) {
      let parentResponse = await CustomAxios.get("/v2/media", {
        headers: {
          BusinessID: parentId,
        },
      });
      let parentDocs = parentResponse.data;
      parentDocs.sort(function (a, b) {
        return new Date(b.CreatedAt) - new Date(a.CreatedAt);
      });

      let mappedParentDocs = parentDocs.map((doc) => {
        return {
          label: doc.Name,
          value: `${doc.Name}&&&${doc.PublicURL}`,
        };
      });
      mappedDocs = mappedParentDocs.concat(mappedDocs);
    }

    return mappedDocs;
  } catch (e) {
    console.log("error getting documents", e);
    return [
      {
        label: "No documents",
        value: "No documents&&&https://getbritehr.com",
      },
    ];
  }
};
