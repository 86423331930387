import { css } from "@emotion/css";
import { ArrowLeft, Palette, MagnifyingGlass } from "phosphor-react";
import { useEffect, useState } from "react";
import { courseNameStyle } from "../../design-styles/design-styles-page";
import { DesignStylesViewer } from "../../design-styles/design-styles-viewer";
import { EditStyle } from "../../design-styles/edit-style/edit-style";
import { NewDesignStylesModal } from "../../design-styles/new-design-styles-modal";
import { useDesignStyles } from "../../design-styles/use-design-styles";
import {
  Button,
  Div,
  Input,
  Loader,
  Modal,
  NewTag,
  Text,
} from "../../shared/components";
import { container, flex } from "../../shared/shared-styles";
import { colors } from "../../shared/styles";

export const SelectDesignStyle = ({ designStyleId, setDesignStyleId }) => {
  const designStyle = useDesignStyles();
  const [modal, setModal] = useState("");
  const [search, setSearch] = useState("");

  useEffect(() => {
    if (!modal) {
      designStyle.setCurrent(null);
    }
  }, [modal]);

  return (
    <>
      <Text
        h2
        css={`
          text-align: center;
        `}
      >
        Select a Design Style to sync with your guide
      </Text>
      <Div
        css={css`
          ${flex("left")}
          margin-top: 56px;
          margin-bottom: 24px;
        `}
      >
        <Input
          startIcon={<MagnifyingGlass />}
          placeholder="Search for a Design Style"
          css={`
            flex-grow: 1;
            margin-right: 16px;
          `}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        <Button secondary onClick={() => setModal("add-new")}>
          Add New Design Style
        </Button>
      </Div>

      <Div
        css={css`
          position: relative;
        `}
      >
        <Loader
          type="icon"
          isLoading={designStyle?.designStylesQuery?.isLoading}
          className={css`
            ${flex("space-between")}
            padding-top: 10vh;
            height: calc(100vh - 50px);
          `}
        >
          <Palette className="bounce" size={64} />
        </Loader>
        {designStyle?.designStylesQuery?.data?.map((item) => (
          <Div
            css={css`
              width: 100%;
              border: 1px solid ${colors.gray[300]};
              ${item?.ID === designStyleId
                ? `outline: 2px solid ${colors.black}; background-color: ${colors.gray[100]};`
                : ``}
              border-radius: 8px;
              padding: 12px;
              margin: 24px 0;
              ${flex("space-between")}
              ${container.hover}
            `}
            onClick={() => setDesignStyleId(item?.ID)}
          >
            <Div
              css={css`
                ${flex("left")}
              `}
            >
              <Div
                css={css`
                  width: 56px;
                  height: 56px;
                  border-radius: 8px;
                  margin-right: 16px;
                  ${item?.ID === designStyleId
                    ? `
                      background-color: ${colors.black};
                      color: white;
                    `
                    : `
                      color: ${colors.gray[500]};
                      border: 1px solid ${colors.gray[300]};
                    `}

                  ${flex("center")}
                `}
              >
                <Palette />
              </Div>
              <Text h4>{item?.Name}</Text>
            </Div>
            <Div
              css={css`
                ${flex("right")}
              `}
            >
              <NewTag createdAt={item?.CreatedAt} />
              <Button
                secondary
                onClick={(event) => {
                  event?.stopPropagation();
                  designStyle?.setCurrent(item);
                  setModal("edit");
                }}
              >
                Edit
              </Button>
            </Div>
          </Div>
        ))}
      </Div>

      {modal === "add-new" ? (
        <NewDesignStylesModal
          createNewDesignStyle={designStyle?.createNewDesignStyle}
          display={modal === "add-new"}
          onClose={() => setModal("")}
        />
      ) : modal === "edit" ? (
        <Modal full display={modal === "edit"} onClose={() => setModal("")}>
          <Div
            css={css`
              width: 100vw;
              height: 100vh;
            `}
          >
            <Div
              css={css`
                ${flex("space-between")}
                width: 100vw;
                height: 100px;
                padding: 32px;
                border-bottom: 1px solid ${colors.gray[300]};
              `}
            >
              <>
                <Div
                  css={css`
                    ${flex("left")}
                  `}
                >
                  <Button
                    styles="icon"
                    onClick={() => setModal("")}
                    css={`
                      margin-right: 16px;
                    `}
                  >
                    <ArrowLeft />
                  </Button>
                  <input
                    className={courseNameStyle}
                    value={designStyle?.current?.Name || ""}
                    onChange={(e) => {
                      const Name = e.target.value;
                      designStyle?.setCurrent({
                        ...designStyle?.current,
                        Name,
                      });
                    }}
                  />
                </Div>
                <Div
                  css={css`
                    ${flex("right")}
                  `}
                >
                  <Button
                    secondary
                    onClick={() => setModal("")}
                    css={`
                      margin-right: 16px;
                    `}
                  >
                    Back
                  </Button>
                  <Button
                    onClick={designStyle?.saveCurrent}
                    disabled={!designStyle?.hasChanges || designStyle?.isSaving}
                    css={`
                      position: relative;
                    `}
                  >
                    <Loader
                      isLoading={designStyle?.isSaving}
                      type="three-dots"
                      className={css`
                        ${flex("center start")}
                        background-color: ${colors.gray[300]};
                      `}
                    />
                    Save
                  </Button>
                </Div>
              </>
            </Div>

            <Div
              css={css`
                ${flex("center start")}
                width: 100%;
                max-width: 1200px;
                margin: 0 auto;
                padding-top: 16px;
                height: 100px;
              `}
            >
              <DesignStylesViewer designStyleProps={designStyle} />
              <EditStyle designStyleProps={designStyle} />
            </Div>
          </Div>
        </Modal>
      ) : null}
    </>
  );
};
