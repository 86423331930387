import { matchPath } from "react-router";
import { useParams } from "react-router-dom";
import { history } from "../history";
import { productFormRoute } from "../Router/AppRouter";
import { Product } from "./product";

export const ProductPage = () => {
  const params = useParams();
  const routeToProducts = () => {
    history.goBack();
    // Sometimes there isn't previous history to goBack to.
    // This solution doesn't work for some reason: https://stackoverflow.com/questions/37385570/how-to-know-if-react-router-can-go-back-to-display-back-button-in-react-app
    // react-router could be updated to v6 which has useNavigator but that
    // could become messy.
    setTimeout(() => {
      const isMatch = matchPath(history?.location?.pathname, {
        path: productFormRoute,
      });
      if (isMatch) {
        history.push(`/${params.businessId}/packages/${params.packageId}`);
      }
    }, 10);
  };
  return <Product {...params} goBack={routeToProducts} />;
};
