import posthog from "posthog-js";
import { getBusinessAcls } from "../Auth/login-utils";
import { CustomAxios } from "../redux/axios/axios";
import { useStore } from "../store-provider/use-store";

export const useBusinessAuth = () => {
  const { data, setProperties } = useStore();
  const { companies, acls, user } = data;

  const refreshAcls = async (resourceId) => {
    try {
      const response = await CustomAxios.get(`v1/users/me/acls`);
      const data = response.data.Data;
      acls[resourceId] = data.find((item) => item.ResourceID === resourceId);
    } catch (err) {
      console.warn(err);
      return;
    }

    setProperties({ acls });
    return;
  };

  const fetchBusinesses = async () => {
    const { companies, acls } = await getBusinessAcls();
    setProperties({ companies, acls });
    return { companies, acls };
  };

  // Override is only needed during the login flow because some values may not have been updated yet.
  const setBusiness = async (data, override = {}) => {
    const { acls: aclList = acls, companies: companyList = companies } =
      override;

    const business = {
      ...data,
      EmployeeCount: data.EmployeeCount || 1,
      ID: data.ID || "",
      Name: data.Name || "",
      IsActive: data.IsActive || false,
      Type: data.Type || "single",
    };

    CustomAxios.setHeader("BusinessID", business.ID);
    CustomAxios.setHeader("ParentID", business.ParentID);

    posthog.register({
      currentBusinessID: business.ID,
      currentBusinessName: business.Name,
      selfService: business.ID === business.ParentID, // if working on assigned business or not (deprecated in favor of serviceType property)
      serviceType:
        business.ID === business.ParentID ? "self-service" : user?.Type,
    });

    posthog.setPersonProperties({
      currentParentID: business.ParentID,
      currentBusinessID: business.ID,
      currentBusinessName: business.Name,
    });

    const parent =
      companyList.find(({ ID }) => ID === business.ParentID) || business;

    setProperties({
      selectedBusiness: business,
      parentBusiness: parent,
      selectedAcl: aclList[parent.ID],
    });

    window?.ChurnZero?.push([
      "setContact",
      business.CRMID || business.ID,
      user.Email,
    ]);
    window?.ChurnZero?.push([
      "setAttribute",
      "account",
      "BusinessID",
      business?.ID,
    ]);
    window?.ChurnZero?.push([
      "setAttribute",
      "account",
      "ParentBusinessID",
      parent?.ID,
    ]);
    window?.ChurnZero?.push([
      "setAttribute",
      "account",
      "Companies",
      companyList.map(({ Name }) => Name),
    ]);
  };

  return {
    refreshAcls,
    setBusiness,
    fetchBusinesses,
  };
};
