import { css } from "@emotion/css";
import { useMemo, useState } from "react";
import { toast } from "react-toastify";
import { useRequests, useResource } from "../react-query";
import { guides } from "../react-query/resources/guides";
import { Button, Div, Input, Modal, Text, Toggle } from "../shared/components";
import { flex } from "../shared/shared-styles";
import { useStore } from "../store-provider/use-store";
import { CompanySelect } from "../Sidebar/company-select";

export const DuplicateGuideModal = ({ display, hideDuplicateGuide, guide }) => {
  const guidesResource = useResource(guides, { staleTime: 5 * 60 * 1000 });
  const guidesRequests = useRequests(guidesResource);
  const {
    data: { selectedBusiness, companies, acls },
  } = useStore();

  const [selected, setSelected] = useState(selectedBusiness);
  const [guideName, setGuideName] = useState(`${guide.Name} (Copy)`);
  const [convertToBriteEditor, setConvertToBriteEditor] = useState(true);
  const [loading, setLoading] = useState(false);

  const accessibleCompanies = useMemo(() => {
    const list = companies.filter(({ ID, ParentID }) => {
      const id = ID in acls ? ID : ParentID;
      return acls[id].Acls.includes("write:course");
    });
    return list;
  }, [companies, acls]);

  const handleMove = async () => {
    try {
      setLoading(true);

      const duplicateGuide = guides.utils.getDuplicatePost(
        guide.ID,
        selected?.ID,
        {
          Name: guideName,
          ConvertToBriteEditor: convertToBriteEditor,
          CourseFolderID: null,
        }
      );
      await guidesRequests.post(duplicateGuide);
      const company = companies.find(({ ID }) => ID === selected.ID);
      if (company && selected?.ID !== selectedBusiness?.ID) {
        toast.success(`Successfully duplicated to ${company.Name}.`);
      } else {
        toast.success(`Successfully duplicated ${guideName}.`);
      }
    } catch (err) {
      console.error(err);
      toast.error(
        `We're sorry, it looks like we couldn't duplicate the guide. Please try again.`
      );
    } finally {
      hideDuplicateGuide();
      setLoading(false);
    }
  };

  const modalProps = {
    display,
    onClose: () => hideDuplicateGuide(),
  };

  return (
    <Modal {...modalProps}>
      <Modal.Paper>
        <Modal.Header title="Duplicate" onClose={modalProps.onClose} />
        <Modal.Body>
          <Div
            css={css`
              ${flex("space-between")}
              padding: 8px 0;
            `}
          >
            <Text label>Save duplicate to</Text>
            <CompanySelect
              selectedBusiness={selected}
              selectBusiness={({ option }) => setSelected(option)}
              defaultBusinessList={accessibleCompanies}
              css={`
                height: 48px;
                padding: 0 8px;
                width: 65%;
                font-size: 0.8em;
              `}
            />
          </Div>
          <Div
            css={css`
              ${flex("space-between")}
              padding: 8px 0;
            `}
          >
            <Text label>Guide Name</Text>
            <Input
              css={`
                width: 65%;
              `}
              placeholder="Guide Name"
              value={guideName}
              onChange={(event) => setGuideName(event.target.value)}
            />
          </Div>
          <Div
            css={css`
              ${flex("space-between")}
              padding-top: 32px;
            `}
          >
            <Text>Convert to Brite Editor (Recommended)</Text>
            <Toggle
              checked={convertToBriteEditor}
              onChange={(e) => setConvertToBriteEditor(e.target.checked)}
            />
          </Div>
        </Modal.Body>
        <Modal.Actions>
          <Button
            secondary
            css={`
              margin-right: 16px;
            `}
            onClick={modalProps.onClose}
          >
            Cancel
          </Button>
          <Button onClick={handleMove} disabled={loading}>
            {loading ? "Saving" : "Submit"}
          </Button>
        </Modal.Actions>
      </Modal.Paper>
    </Modal>
  );
};
