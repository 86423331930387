import { Collapse } from "@material-ui/core";
import { css } from "@emotion/css";
import { CaretDown, CaretUp, Info, Percent, Plus, X } from "phosphor-react";
import { useState } from "react";
import { Button, Div, Input, Text } from "../shared/components";
import { flex } from "../shared/shared-styles";
import { productModifier } from "./configs/product-utils";
import { sectionContainer } from "./product-section";

export const ConditionPercentages = ({ state }) => {
  const {
    product: { Details },
  } = state;

  const conditions = Details.ConditionPercentages;

  const [open, setOpen] = useState(false);

  const setConditions = (conditionPercentages) => {
    productModifier.setProduct(state, {
      ...state.product,
      Details: {
        ...state.product.Details,
        ConditionPercentages: conditionPercentages,
      },
    });
  };

  const addCondition = () => {
    const condition = {
      Name: "",
      Percentage: "",
    };
    setConditions([...(conditions || []), condition]);
  };

  const updateCondition = (idx, property, value) => {
    let list = [...conditions];
    list.splice(idx, 1, { ...conditions[idx], [property]: value });
    setConditions([...list]);
  };

  const removeCondition = (idx) => {
    let list = [...conditions];
    list.splice(idx, 1);
    setConditions([...list]);
  };

  return (
    <Div css={sectionContainer(open)} onClick={() => !open && setOpen(!open)}>
      <Div
        css={css`
          ${flex("jcsb aic")} cursor: pointer;
          ${open && `padding-bottom: 16px;`}
        `}
        onClick={() => setOpen(!open)}
      >
        <Div
          css={css`
            ${flex("ais")}
          `}
        >
          <Div
            css={css`
              ${flex("aic")} min-width: 32px;
              margin-right: 8px;
            `}
          >
            <Info />
          </Div>
          <Text styles="h3">Listed Conditions</Text>
        </Div>
        <Div
          css={css`
            ${flex("jcr aic")}
          `}
        >
          <Button
            styles="icon"
            className={css`
              pointer-events: none;
            `}
          >
            {open ? <CaretUp /> : <CaretDown />}
          </Button>
        </Div>
      </Div>
      <Collapse in={open}>
        {conditions?.length ? (
          <>
            <Div
              css={css`
                ${flex("left")}
                margin-bottom: 8px;
              `}
            >
              <Text
                label
                css={`
                  flex-grow: 1;
                  margin-right: 64px;
                `}
              >
                Condition
              </Text>
              <Text
                label
                css={`
                  flex-grow: 1;
                `}
                endIcon={<Percent />}
                type="number"
              >
                Payable benefit percentage
              </Text>
            </Div>

            {conditions?.map((item, idx) => (
              <Div
                css={css`
                  ${flex("left")}
                  margin-bottom: 8px;
                `}
              >
                <Input
                  css={`
                    flex-grow: 1;
                  `}
                  value={item.Name}
                  onChange={(e) => updateCondition(idx, "Name", e.target.value)}
                />
                <Input
                  css={`
                    margin-left: 8px;
                    flex-grow: 1;
                  `}
                  endIcon={<Percent />}
                  value={item.Percentage}
                  onChange={(e) =>
                    updateCondition(idx, "Percentage", e.target.value)
                  }
                />
                <Button
                  onClick={removeCondition.bind(this, idx)}
                  styles="icon sm"
                  css={`
                    margin-left: 8px;
                  `}
                >
                  <X />
                </Button>
              </Div>
            ))}
          </>
        ) : (
          <Text>Click 'Add Condition' to get started.</Text>
        )}
        <Button
          secondary
          css={`
            margin-top: 16px;
          `}
          onClick={addCondition}
        >
          <Plus size={16} />
          Add Condition
        </Button>
      </Collapse>
    </Div>
  );
};
