import { css } from "@emotion/css";
import { Warning } from "phosphor-react";
import { Div, Text } from "../shared/components";
import { flex } from "../shared/shared-styles";
import { colors } from "../shared/styles";

export const SupplementalLifeAlerts = ({ state }) => {
  const { Details } = state?.product;
  if (
    Details?.EmployerPaidCoverage ||
    Details?.SupplementalLifeCoverageAvailable
  ) {
    const allToggled =
      Details?.EmployerPaidCoverage &&
      Details?.SupplementalLifeCoverageAvailable;
    return (
      <Div
        css={css`
          padding: 8px;
          border-radius: 8px;
          background-color: ${colors.gray[100]};
          ${flex(`space-between ${allToggled ? "start" : ""}`)}
        `}
      >
        <Div
          css={css`
            width: 32px;
            margin-right: 8px;
          `}
        >
          <Warning />
        </Div>
        <Div>
          {Details?.EmployerPaidCoverage && (
            <Text styles="label bold">
              Your employer covers ${Details?.EmployerPaidCoverageAmount} of
              life insurance at no cost to you!
            </Text>
          )}
          {Details?.SupplementalLifeCoverageAvailable && (
            <Text styles="label">
              There is additional life insurance you should consider purchasing.
            </Text>
          )}
        </Div>
      </Div>
    );
  }

  return null;
};
