import { css } from "@emotion/css";
import { X } from "phosphor-react";
import { Button, Div, Input, Modal, Text } from "../../shared/components";
import { flex } from "../../shared/shared-styles";
import { useForm } from "../../shared/use-form";

export const EditFieldLayout = ({
  display,
  field,
  updateFieldLayout,
  onClose,
}) => {
  const { values, getProperty, hasChanges } = useForm(
    {
      ...field,
      RecDisplayValue: field?.RecDisplayValue || field?.DisplayValue,
    },
    [field, display]
  );

  const onSave = () => {
    updateFieldLayout(values);
    onClose();
  };

  return (
    <Modal display={display} onClose={onClose}>
      <Div
        css={css`
          width: 500px;
          padding: 32px;
        `}
      >
        <Div
          css={css`
            ${flex("space-between")}
          `}
        >
          <Text h2>{values?.DisplayValue}</Text>
          <Button styles="icon" onClick={onClose}>
            <X />
          </Button>
        </Div>

        <Div
          css={css`
            margin: 32px 0;
          `}
        >
          <Text label bold>
            Labels
          </Text>
          <Div
            css={css`
              ${flex("space-between")}
              margin: 8px 0;
            `}
          >
            <Text label>Prepend</Text>
            <Input
              css={`
                width: 50%;
              `}
              {...getProperty("Pre")}
            />
          </Div>
          <Div
            css={css`
              ${flex("space-between")}
              margin: 8px 0;
            `}
          >
            <Text label>External</Text>
            <Input
              css={`
                width: 50%;
              `}
              {...getProperty("RecDisplayValue")}
            />
          </Div>
          <Div
            css={css`
              ${flex("space-between")}
              margin: 8px 0;
            `}
          >
            <Text label>Append</Text>
            <Input
              css={`
                width: 50%;
              `}
              {...getProperty("Post")}
            />
          </Div>
        </Div>
        <Div
          css={css`
            ${flex("right")}
          `}
        >
          <Button secondary onClick={onClose}>
            Cancel
          </Button>
          <Button
            css={`
              margin-left: 16px;
            `}
            onClick={onSave}
            disabled={!hasChanges}
          >
            Update
          </Button>
        </Div>
      </Div>
    </Modal>
  );
};
