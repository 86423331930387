import { css } from "@emotion/css";
import { useCallback, useMemo } from "react";
import { colors } from "../../shared/styles";
import { useEditor } from "../provider/use-editor-store";
import { editorUtils } from "../provider/utils";

export const droppableContainer = (drag) =>
  drag?.isDragging
    ? css`
        .droppable {
          position: absolute;
          border-radius: 0;
        }

        .droppable.drop-left {
          top: 0px;
          bottom: 0px;
          left: 0px;
          right: 80%;
          z-index: 50;
          :hover {
            ::after {
              content: "";
              position: absolute;
              top: 0;
              bottom: 0;
              left: -5px;
              border-left: 9px solid ${colors.purple}EE;
              pointer-events: none;
            }
          }
        }

        .droppable.drop-right {
          top: 0px;
          bottom: 0px;
          left: 80%;
          right: 0px;
          z-index: 50;
          :hover {
            ::after {
              content: "";
              position: absolute;
              top: 0;
              bottom: 0;
              right: -4px;
              border-right: 9px solid ${colors.purple}EE;
              pointer-events: none;
            }
          }
        }

        .droppable.drop-top {
          top: 0;
          bottom: 50%;
          left: 20%;
          right: 20%;
          z-index: 50;
          :hover {
            ::after {
              content: "";
              position: absolute;
              left: -33%;
              right: -33%;
              top: -2px;
              border-top: 9px solid ${colors.purple};
              pointer-events: none;
            }
          }
        }

        .droppable.drop-bottom {
          top: 50%;
          bottom: 0;
          left: 20%;
          right: 20%;
          z-index: 50;
          :hover {
            ::after {
              content: "";
              position: absolute;
              left: -33%;
              right: -33%;
              bottom: -1px;
              border-bottom: 9px solid ${colors.purple};
              pointer-events: none;
            }
          }
        }

        .droppable.drag-over {
          top: 0px;
          bottom: 0px;
          right: -2px;
          left: -2px;
          pointer-events: none;
          z-index: 10;
        }
      `
    : "";

export const Droppable = ({ type, indices }) => {
  const editor = useEditor();
  const {
    state: { drag = {} },
  } = editor;

  const dragOver = useCallback(
    (position, location = []) => {
      if (position) {
        editorUtils?.setProperty(editor, "drag", {
          ...drag,
          destination: { location, position },
        });
      } else {
        editorUtils?.setProperty(editor, "drag", {
          ...drag,
          destination: { location: [], position },
        });
      }
    },
    [editor, indices]
  );

  return useMemo(
    () =>
      drag?.isDragging ? (
        <div
          className={`droppable drop-${type}`}
          onMouseUp={() => dragOver(type, indices)}
        />
      ) : null,
    [type, indices]
  );
};
