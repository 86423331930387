import { Checkbox } from "@material-ui/core";
import { css } from "@emotion/css";
import { X } from "phosphor-react";
import { useState } from "react";
import { Button, Div, Modal, Text } from "../../../shared/components";
import { container, flex } from "../../../shared/shared-styles";
import { colors } from "../../../shared/styles";

export const BulkDelete = ({ pages, handleDelete, ...modalProps }) => {
  const [selection, setSelection] = useState([]);

  const handleSelection = (ID) => {
    if (selection.includes(ID)) {
      const next = selection.filter((id) => id !== ID);
      setSelection(next);
    } else {
      setSelection([...selection, ID]);
    }
  };

  const onDelete = () => {
    try {
      handleDelete(selection);
    } catch (err) {
      console.warn(err);
    } finally {
      modalProps.onClose();
    }
  };

  return (
    <Modal {...modalProps}>
      <Div
        css={css`
          width: 500px;
          padding: 32px;
        `}
      >
        <Div
          css={css`
            ${flex("space-between")}
          `}
        >
          <Text h2>Bulk Delete</Text>
          <Button styles="icon" onClick={modalProps.onClose}>
            <X />
          </Button>
        </Div>
        <Text
          label
          bold
          css={`
            margin: 16px 0;
          `}
        >
          Select the pages you want to delete
        </Text>
        <Div
          css={css`
            overflow-y: auto;
            max-height: 30vh;
            border-radius: 8px;
            border: 1px solid ${colors.gray[300]};
          `}
        >
          {pages?.map(({ ID, Name }) => (
            <Div
              key={ID}
              css={css`
                ${flex("space-between")}
                ${container.hover}
                padding: 8px;
                border-radius: 8px;
              `}
              onClick={() => handleSelection(ID)}
            >
              <Text label>{Name}</Text>
              <Checkbox
                checked={selection.includes(ID)}
                onChange={() => handleSelection(ID)}
              />
            </Div>
          ))}
        </Div>
        <Div
          css={css`
            ${flex("right")}
            padding-top: 32px;
          `}
        >
          <Button
            secondary
            onClick={modalProps.onClose}
            css={`
              margin-right: 16px;
            `}
          >
            Close
          </Button>
          <Button
            bgColor={colors.red[100]}
            disabled={!selection.length}
            onClick={onDelete}
          >
            Delete
          </Button>
        </Div>
      </Div>
    </Modal>
  );
};
