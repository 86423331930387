import { css } from "@emotion/css";
import { ChromePicker } from "react-color";
import { Button, Div, DropMenu, Input, Text } from "../../shared/components";
import { flex, px } from "../../shared/shared-styles";
import { colorStyle } from "./edit-style";

export const EditAccent = ({ current, updateCurrent }) => {
  const colorKey = "Body.AccentColor";
  const color = current?.Theme?.Body?.AccentColor || "#000000";

  const updateColor = () => {
    const value = `#${color?.replace("#", "")?.toUpperCase()}`;
    updateCurrent(colorKey, value);
  };

  return (
    <Div
      className={css`
        margin-top: 32px;
      `}
    >
      <Div
        className={css`
          height: 40px;
        `}
      >
        <Text styles="h2 nopad">Accent Color</Text>
      </Div>

      <Div
        css={css`
          margin: ${px.xl} 0;
        `}
      >
        <Text styles="mb-sm" label bold>
          Color
        </Text>
        <Div
          css={css`
            ${flex("jcsb aic")} position: relative;
          `}
        >
          <Input
            className={css`
              width: 100%;
            `}
            value={color}
            onChange={(e) => updateCurrent(colorKey, e.target.value)}
            onBlur={updateColor}
          />
          <DropMenu
            button={<Button styles="naked" className={colorStyle(color)} />}
            transformOrigin={{ horizontal: "right" }}
          >
            <Div onClick={(e) => e.stopPropagation()}>
              <ChromePicker
                color={color}
                onChange={(color) => updateCurrent(colorKey, color.hex)}
                disableAlpha
              />
            </Div>
          </DropMenu>
        </Div>
      </Div>
    </Div>
  );
};
