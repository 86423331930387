// EMPLOYEE-VIEW
import { css } from "@emotion/css";
import { useFeatureFlagPayload } from "posthog-js/react";
import {
  getTierStructure,
  useDisplaySettings,
} from "../../BriteEditor/editor-components/benefits/plan-comparisons/use-display-settings";
import { Div, Text } from "../../shared/components";
import { flex } from "../../shared/shared-styles";
import { getProductPremium } from "./field-utils";

export const CostSummary = ({
  state,
  markHidden = false,
  comparisonView = false,
}) => {
  const costTiersFF = useFeatureFlagPayload("tier-structure");
  const { product, businessId, packageId } = state;
  const cost = product?.Cost;
  const { displaySettings } = useDisplaySettings(businessId, packageId);
  const tiers = getTierStructure({
    featureFlag: costTiersFF?.value,
    displaySettings,
    cost,
  });

  return (
    <Div
      css={css`
        width: 100%;
      `}
    >
      <Text
        h4
        css={`
          padding: 16px 32px;
        `}
      >
        Cost{" "}
        {displaySettings?.premiumInterval === 12
          ? "per month"
          : "per pay period"}
      </Text>
      {tiers?.keys?.map((tier) => (
        <Div
          className="alternating-item"
          css={css`
            ${comparisonView ? "" : flex("space-between")} width: 100%;
            padding: 16px 32px;
          `}
        >
          <Text label>{tiers?.labels?.[tier]}</Text>
          <Text label bold>
            {markHidden
              ? "-"
              : getProductPremium({
                  cost,
                  key: tier,
                  interval: displaySettings?.premiumInterval,
                })}
          </Text>
        </Div>
      ))}
    </Div>
  );
};
