import React, { useContext, useEffect } from "react";
import { css } from "@emotion/css";
import { Auth0Context } from "./Auth/react-auth0-wrapper";
import { history } from "./history";
import Button from "@material-ui/core/Button";
import { useStore } from "./store-provider/use-store";

const Home = () => {
  const { isAuthenticated, loading, ...context } = useContext(Auth0Context);

  const {
    data: { selectedBusiness },
  } = useStore();
  const businessId = selectedBusiness.ID || "";

  useEffect(() => {
    if (!loading && isAuthenticated && businessId) {
      history.push(`/${businessId}/courses`);
    }
  }, [isAuthenticated, businessId, loading]);

  return (
    <div
      className={css`
        display: flex;
        flex-direction: column;
        height: calc(100vh - 34px);
      `}
    >
      <div
        className={css`
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 40px;
          margin-top: 14px;
          flex-wrap: wrap;
          flex: 1;
        `}
      >
        <div>
          <div
            className={css`
              font-size: 20px;
              width: 300px;
              line-height: 28px;
            `}
          >
            You are about to enter the{" "}
            <span
              className={css`
                font-weight: bold;
              `}
            >
              benefits experience platform
            </span>{" "}
            for brokers, employers, and vendors.
          </div>
          <div
            className={css`
              margin-bottom: 18px;
              margin-top: 12px;
            `}
          ></div>
          <div
            className={css`
              display: flex;
            `}
          >
            <div
              className={css`
                margin-right: 14px;
              `}
            >
              <Button
                variant="outlined"
                onClick={() => {
                  context.loginWithRedirect({
                    redirect_uri: window.location.origin,
                    appState: { targetUrl: `/${businessId}/courses` },
                  });
                }}
              >
                Login
              </Button>
            </div>
            <div>
              <Button
                variant="outlined"
                onClick={() => {
                  context.loginWithRedirect({
                    redirect_uri: window.location.origin,
                    appState: { targetUrl: `/${businessId}/courses` },
                    screen_hint: "signup",
                    prompt: "login",
                  });
                }}
              >
                Sign Up
              </Button>
            </div>
          </div>
        </div>
        <div
          className={css`
            margin-top: 18px;
          `}
        >
          <img
            src="https://storage.googleapis.com/brite-carrier-logos/logos/content-builder-login-page.gif"
            alt="brite builder"
            width="300px"
          />
        </div>
      </div>

      <div
        className={css`
          text-align: center;
        `}
      >
        <div
          className={css`
            margin-top: 24px;
            font-size: 12px;
            padding-bottom: 24px;
          `}
        >
          <span
            className={css`
              font-weight: bold;
            `}
          >
            Need assistance?
          </span>{" "}
          sales@britebenefits.com
        </div>
        <a
          className={css`
            color: black;
            text-decoration: none;
            &:hover {
              color: black;
              text-decoration: none;
            }
            &:visited {
              color: black;
              text-decoration: none;
            }
          `}
          href="https://share.hsforms.com/1UQbu6cvVR5-AJ09KpbxSjA3ihp9"
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy Policy{" "}
        </a>{" "}
        |{" "}
        <a
          className={css`
            color: black;
            text-decoration: none;
            &:hover {
              color: black;
              text-decoration: none;
            }
            &:visited {
              color: black;
              text-decoration: none;
            }
          `}
          href="https://share.hsforms.com/18ZIPbaK4QQGU3xaJ_0N2MA3ihp9"
          target="_blank"
          rel="noopener noreferrer"
        >
          Terms of Use{" "}
        </a>
        |{" "}
        <a
          className={css`
            color: black;
            text-decoration: none;
            &:hover {
              color: black;
              text-decoration: none;
            }
            &:visited {
              color: black;
              text-decoration: none;
            }
          `}
          href="https://share.hsforms.com/1vbQfUlKjSeSaZumLLoW67A3ihp9"
          target="_blank"
          rel="noopener noreferrer"
        >
          Sub Processors
        </a>{" "}
        <div
          className={css`
            margin-top: 24px;
            font-size: 12px;
            padding-bottom: 24px;
          `}
        >
          Brite Benefits, Inc.
        </div>
      </div>
    </div>
  );
};

export default Home;
