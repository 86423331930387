import { css } from "@emotion/css";
import { X } from "phosphor-react";
import { useContext, useMemo } from "react";
import { Button } from "../../../../shared/components";
import { colors } from "../../../../shared/styles";
import { EditorDetailsContext } from "../../../provider/editor-detail-provider";
import { patterns } from "../text-utils";

export const Tag = ({ children, ...rest }) => {
  const { editorDetails, dispatch } = useContext(EditorDetailsContext);

  const range = [rest?.blockKey, rest?.start, rest?.end];

  const deleteTag = () => {
    dispatch({
      type: "SET",
      key: "deleteTag",
      payload: range,
    });
  };

  const tag = useMemo(() => {
    const text = rest?.decoratedText.replace(patterns?.tags, "$1");
    return editorDetails?.tags?.find(({ value }) => value === text);
  });

  return (
    <div
      data-offset-key={rest.offsetKey}
      tabIndex={-1}
      className={css`
        display: inline-flex;
        align-items: start;
        position: relative;
        background-color: ${colors.gray[100]};
        color: ${colors.black};
        ${editorDetails?.selection?.join("-") === range?.join("-")
          ? `background-color: ${colors.purple}; color: white;`
          : ""}
        outline-offset: -2px;
        border-radius: 8px;
        padding: 4px;
        cursor: pointer;
        transition: background-color 0.15s ease;
        margin: 1px;
        margin-left: 0.15em;
        margin-right: 0.1em;
        caret-color: black;
        svg {
          font-weight: 1em;
        }
      `}
      contentEditable={false}
    >
      {tag?.sample}
      <Button
        css={`
          background-color: ${colors.gray[300]};
          padding: 4px;
          margin-left: 4px;
        `}
        onClick={deleteTag}
      >
        <X />
      </Button>
    </div>
  );
};
