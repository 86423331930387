import React, { useEffect, useRef, useState } from "react";
import { Menu, Tooltip } from "@material-ui/core";
import { noop } from "lodash";
import { css, cx } from "@emotion/css";
import { container } from "../shared-styles";

const clearButton = css`
  border: none;
  background: none;
  outline: none;
  appearance: none;
  margin: 0;
`;

export const DropMenu = ({
  title = "",
  listDirection = "row",
  className = "drop-menu-styles",
  button,
  children,
  listStyle = {},
  onClick = noop,
  onClose = noop,
  setTrackDisplay = false,
  transformOrigin = {},
  displayMenu = true,
  ...rest
}) => {
  const ref = useRef(null);
  const [display, setDisplay] = useState(false);

  useEffect(() => {
    if (setTrackDisplay) {
      setTrackDisplay(display);
    }
  }, [display]);

  const handleClick = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setDisplay(true);
    onClick(event);
  };

  const handleClose = (event) => {
    event.stopPropagation();
    setDisplay(false);
    onClose();
  };

  const directionStyle = listDirection === "row" ? {} : { display: "flex" };

  const originDefault = {
    vertical: -32,
    horizontal: "left",
    ...transformOrigin,
  };

  return (
    <div
      className={cx(className, css``)}
      ref={ref}
      onClick={(e) => e.stopPropagation()}
    >
      <Tooltip title={title} placement="top">
        <div className={clearButton} onClick={handleClick}>
          {button}
        </div>
      </Tooltip>
      <Menu
        id="more-menu"
        className="brite-menu"
        anchorEl={ref.current}
        getContentAnchorEl={null}
        keepMounted
        open={display && displayMenu}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        transformOrigin={originDefault}
        transitionDuration={100}
        PaperProps={{
          style: {
            borderRadius: 8,
          },
        }}
        MenuListProps={{
          style: {
            margin: 8,
            ...directionStyle,
          },
        }}
        {...directionStyle}
        {...rest}
      >
        {display && displayMenu && (
          <div
            onClick={handleClose}
            style={listStyle}
            className={css`
              li {
                border-radius: 8px;
                ${container.hover}
              }
            `}
          >
            {children}
          </div>
        )}
      </Menu>
    </div>
  );
};
