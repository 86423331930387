import { useMemo, useState } from "react";
import { BriteLoader } from "../shared/components/brite-loader";
import { flex } from "../shared/shared-styles";

export const MemoizedIFrame = (props) => {
  const [loading, setLoading] = useState(true);
  return useMemo(() => {
    return (
      <>
        <iframe
          title="Template"
          {...props}
          onLoad={() => setTimeout(() => setLoading(false), 1000)}
        />
        <BriteLoader
          isLoading={loading}
          css={`
            ${flex("center")} height: 100%;
          `}
        />
      </>
    );
  }, [props.src, loading]);
};
