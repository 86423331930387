import { useState } from "react";
import { css } from "@emotion/css";
import { ArrowLeft, CaretRight } from "phosphor-react";
import { GenerateGuide } from "../benefit-package/generate-guide/generate-guide";
import { useModal } from "../common/hooks/useModal";
import { CustomAxios } from "../redux/axios/axios";
import { Button, Div, Text } from "../shared/components";
import { container } from "../shared/shared-styles";
import { AddCourseMenu } from "./AddCourseMenu";
import { UseTemplateModal } from "./UseTemplateModal";

export const Header = ({
  currentFolder,
  setCurrentFolder,
  toggleNewFolder,
  routeToBuilder,
}) => {
  const generateModal = useModal();
  const useTemplateModal = useModal();

  const [selectedGuideType, setSelectedGuideType] = useState("openenrollment");

  const handlePosts = async (e) => {
    e.stopPropagation();
    const newCourseDefault = {
      Name: "Untitled Guide",
      Type: selectedGuideType,
      Views: 0,
      IsPublished: false,
      ViewTotalsMonthly: true,
      Pages: [
        {
          Type: "brite-editor",
          Content: null,
          Name: "Welcome",
          Order: 1,
          FrontendID: "_" + Math.random().toString(36).substr(2, 9),
          InsurancePlans: [],
          Question: null,
          IsLocked: false,
          TemplateID: "00000000-0000-0000-0000-000000000000",
        },
      ],
      CourseFolderID: currentFolder ? currentFolder.ID : null,
    };

    const response = await CustomAxios.post(`/v1/course`, newCourseDefault);
    window?.ChurnZero?.push([
      "trackEvent",
      "Guide Created",
      `Name: ${newCourseDefault?.Name}`,
      1,
    ]);
    routeToBuilder(response.data.ID);
  };

  return (
    <>
      <Div
        css={css`
          ${container.header}
        `}
      >
        <Div
          css={css`
            display: flex;
            align-items: center;
            gap: 8px;
          `}
        >
          {currentFolder ? (
            <FolderBreadcrumbs
              currentFolder={currentFolder}
              setCurrentFolder={setCurrentFolder}
            />
          ) : (
            <Text h1>Brite Guides</Text>
          )}
        </Div>
        <Div
          css={css`
            display: flex;
            justify-content: flex-end;
            align-items: center;
            > div {
              display: flex;
              align-items: center;
              margin-top: 5px;
              margin-right: 14px;
            }
          `}
        >
          {!currentFolder && (
            <Div>
              <Button secondary onClick={() => toggleNewFolder()}>
                New Folder
              </Button>
            </Div>
          )}
          <Div id="addnewcourse">
            <AddCourseMenu
              selectedGuideType={selectedGuideType}
              setSelectedGuideType={setSelectedGuideType}
              generateCourse={(e) => {
                e.stopPropagation();
                generateModal.toggle();
              }}
              pickTemplate={(e) => {
                e.stopPropagation();
                useTemplateModal.toggle();
              }}
              startFromScratch={handlePosts}
            />
          </Div>
        </Div>
      </Div>
      {generateModal.isOpen && (
        <GenerateGuide
          display={generateModal.isOpen}
          onClose={() => generateModal.hide()}
          currentFolder={currentFolder}
          selectedGuideType={selectedGuideType}
        />
      )}
      {useTemplateModal.isOpen && (
        <UseTemplateModal
          hideUseTemplate={useTemplateModal.hide}
          currentFolder={currentFolder}
          selectedGuideType={selectedGuideType}
        />
      )}
    </>
  );
};

const FolderBreadcrumbs = ({ currentFolder, setCurrentFolder }) => {
  const breadcrumbText = css`
    color: #25282d;
    font-feature-settings: "clig" off, "liga" off;
    font-family: Roboto;
    font-size: 34px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px; /* 141.176% */
    letter-spacing: 0.25px;
  `;
  return (
    <>
      <Button
        styles="icon"
        onClick={() => {
          setCurrentFolder(null);
        }}
      >
        <ArrowLeft />
      </Button>
      <div className={breadcrumbText}>Guides</div>
      <CaretRight color={"#9AA7B5"} />
      <div className={breadcrumbText}>{currentFolder.Name}</div>
    </>
  );
};
