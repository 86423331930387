import React, { useEffect } from "react";
import { Route, useLocation } from "react-router-dom";
import { useAuth0 } from "../Auth/react-auth0-wrapper";
import { UnAuthedLoader } from "./UnAuthedLoader";

export const PrivateRoute = ({ path, children, ...rest }) => {
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const location = useLocation();
  useEffect(() => {
    const fn = async () => {
      if (!isAuthenticated) {
        await loginWithRedirect({
          redirect_uri: window.location.origin,
          appState: { targetUrl: location.pathname },
        });
      }
    };
    fn();
  }, [isAuthenticated, loginWithRedirect, location.pathname]);

  return isAuthenticated ? (
    <Route path={path} children={children} exact {...rest} />
  ) : (
    <Route path={path} children={<UnAuthedLoader />} exact {...rest} />
  );
};
