import React, { useState, useEffect } from "react";
import { css } from "@emotion/css";
import { CustomAxios } from "../redux/axios/axios";
import CircularProgress from "@material-ui/core/CircularProgress";
import { toast } from "react-toastify";
import Button from "@material-ui/core/Button";
import { CourseSelector } from "./CourseSelector";
import { sortBy } from "lodash";
import { history } from "../history";

import { CarrierSelect } from "../Content/carrier-select";
import { useStore } from "../store-provider/use-store";
import { Text, Box, Modal } from "../shared/components";

const fetchAmount = 21;

export const VendorLibrary = ({ currentFolder, selectedGuideType }) => {
  const {
    data: { selectedBusiness },
  } = useStore();
  const { ID: businessId = "" } = selectedBusiness;
  const [loading, setLoading] = useState(false);
  const [courses, setCourses] = useState([]);
  const [hasNextPage, setHasNextPage] = useState(false);
  // brite templates library
  const [selectedCarrierID, setSelectedCarrierID] = useState(
    "9ac52e0d-99ca-4502-b85d-e9193dfc4b4c"
  );
  const [selectedCourseID, setSelectedCourseID] = useState(
    "00000000-0000-0000-0000-000000000000"
  );
  const [importCourse, setImportCourse] = useState(null);

  const [page, setPage] = useState(0);

  const getVendorLibraryCourses = async () => {
    try {
      setLoading(true);

      let url = `/v1/course?type=vendor&limit=${fetchAmount}&skip=${
        page * (fetchAmount - 1)
      }`;

      if (
        selectedCarrierID &&
        selectedCarrierID !== "00000000-0000-0000-0000-000000000000"
      ) {
        url += `&business-id=${selectedCarrierID}`;
      }

      const response = await CustomAxios.get(url);

      let tempcourses = response.data;

      if (tempcourses.length === fetchAmount) {
        setHasNextPage(true);
        tempcourses.pop();
      } else {
        setHasNextPage(false);
      }

      setCourses(tempcourses);

      setLoading(false);
    } catch (e) {
      setLoading(false);
      toast.error("Could not retrieve vendor library guides");
    }
  };

  useEffect(() => {
    getVendorLibraryCourses();
  }, [selectedCarrierID, page]);

  const routeToBuilder = (id) => {
    history.push(`/${businessId}/courses/${id}`);
  };

  const importVendorCourse = async () => {
    setLoading(true);
    try {
      const r = await CustomAxios.get(`/v1/course/${importCourse.ID}`);
      let importFromThisCourse = r.data;
      if (importFromThisCourse.Pages?.length > 0) {
        importFromThisCourse.Pages = sortBy(importFromThisCourse.Pages, [
          "Order",
        ]);
      }
      let course;
      if (
        !selectedCarrierID ||
        selectedCourseID === "00000000-0000-0000-0000-000000000000"
      ) {
        const newCourseDefault = {
          Name: "Untitled Guide",
          Type: selectedGuideType,
          ViewTotalsMonthly: true,
          Views: 0,
          IsPublished: false,
          Pages: [],
          CourseFolderID: currentFolder ? currentFolder.ID : null,
        };

        const response = await CustomAxios.post(`/v1/course`, newCourseDefault);
        course = response.data;
      } else {
        const response = await CustomAxios.get(
          `/v1/course/${selectedCourseID}`
        );
        course = response.data;
      }

      if (course.Pages?.length > 0) {
        course.Pages = sortBy(course.Pages, ["Order"]);
      }

      for (let i = 0; i < importFromThisCourse.Pages?.length; i++) {
        let content = null;
        let htmlContent = "";
        if (!importFromThisCourse.Pages[i].IsLocked) {
          content = importFromThisCourse.Pages[i].Content;
          htmlContent = importFromThisCourse.Pages[i].HTMLContent;
        }
        await CustomAxios.post(`/v2/course/${course.ID}/pages`, {
          Type: importFromThisCourse.Pages[i].Type,
          Content: content,
          HTMLContent: htmlContent,
          FrontendID: "_" + Math.random().toString(36).substr(2, 9),
          InsurancePlans: [],
          Question: null,
          IsLocked: importFromThisCourse.Pages[i].IsLocked,
          TemplateID: importFromThisCourse.Pages[i].ID,

          CourseID: course.ID,
          Name: importFromThisCourse.Pages[i].Name,
        });
      }

      setImportCourse(null);
      setLoading(false);
      routeToBuilder(course.ID);
      window?.ChurnZero?.push([
        "trackEvent",
        "Guide Created",
        `Name: ${course?.Name}`,
        1,
      ]);
      toast.success("Imported successfully!");
    } catch (error) {
      console.log(error);
      setLoading(false);
      toast.error("Failed to import.");
    }
  };

  if (loading) {
    return (
      <div
        className={css`
          display: flex;
          justify-content: center;
          margin: 48px;
        `}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <>
      <div
        className={css`
          display: flex;
          justify-content: space-between;
          align-items: center;
        `}
      >
        <div
          className={css`
            font-weight: 400;
            color: #797979;
            margin-right: 10px;
            margin-left: 12px;
          `}
        >
          View and import templates already created for you.
        </div>
        <div>
          <CarrierSelect
            handleSelect={(val) => {
              setPage(0);
              setSelectedCarrierID(val);
            }}
            carrierId={selectedCarrierID}
            hideCustom
            hideCarrierText
            customLabel="Vendor"
          />
        </div>
      </div>

      {courses.length === 0 && (
        <div
          className={css`
            text-align: center;
            font-size: 16px;
            margin: 24px;
          `}
        >
          No template library guides.
        </div>
      )}

      <div
        className={css`
          display: flex;
          justify-content: flex-start;
          flex-wrap: wrap;
        `}
      >
        {courses.map((course) => {
          return (
            <Box
              css={`
                margin: 16px;
                width: 230px;
              `}
              key={course.ID}
            >
              <Box
                flex="center"
                css={`
                  margin: 0px auto;
                `}
              >
                {course.LogoURL && (
                  <img
                    alt="Carrier Logo"
                    className={css`
                      max-height: 40px;
                      max-width: 175px;
                    `}
                    src={course.LogoURL}
                  />
                )}
              </Box>

              <div
                className={css`
                  padding: 10px;
                  overflow: auto;
                  height: 64px;
                `}
                title={course.Name}
              >
                <Text
                  css={`
                    font-weight: 500;
                  `}
                >
                  {course.Name}
                </Text>
              </div>

              <div
                className={css`
                  display: flex;
                  border-top: 1px solid #eee;
                  justify-content: space-between;
                  padding-left: 8px;
                  padding-top: 2px;
                  padding-bottom: 2px;
                `}
              >
                <div>
                  <Button
                    color="primary"
                    onClick={() => {
                      window.open(
                        `https://britehr.app/${course.VanityURL}`,
                        "_blank"
                      );
                    }}
                  >
                    View
                  </Button>
                </div>

                <div
                  className={css`
                    margin-right: 8px;
                  `}
                >
                  <Button
                    color="primary"
                    onClick={() => {
                      setImportCourse(course);
                    }}
                  >
                    Import
                  </Button>
                </div>
              </div>
            </Box>
          );
        })}
      </div>

      <div
        className={css`
          display: flex;
          justify-content: space-between;
          padding: 24px;
          border: 1px solid #ededed;
          margin-top: 24px;
          margin-bottom: 24px;
          border-radius: 10px;
        `}
      >
        <div
          className={css`
            margin-right: 8px;
          `}
        >
          <Button
            disabled={page === 0}
            variant="contained"
            color="primary"
            onClick={() => {
              setPage((page) => page - 1);
            }}
          >
            Previous Page
          </Button>
        </div>

        <div
          className={css`
            margin-right: 8px;
          `}
        >
          <Button
            disabled={!hasNextPage}
            variant="contained"
            color="primary"
            onClick={() => {
              setPage((page) => page + 1);
            }}
          >
            Next Page
          </Button>
        </div>
      </div>

      {!!importCourse && (
        <Modal
          css={`
            margin: 8px;
          `}
          onClose={() => {
            setImportCourse(null);
          }}
          display
        >
          <div>
            <div
              className={css`
                font-weight: 400;
                font-size: 22px;
                line-height: 28px;
                color: #797979;
                margin: 20px;
                margin-bottom: 5px;
                border-bottom: 1px solid #cbc9c9;
                padding-bottom: 8px;
              `}
            >
              Import guide pages from {importCourse.Name}
            </div>
            <div
              className={css`
                margin: 24px;
              `}
            >
              <div>
                Select one of your guides to import this vendor guide into. The
                pages from the vendor guide will be added to the end of the
                selected guide.
              </div>

              <div
                className={css`
                  margin-top: 32px;
                `}
              >
                <CourseSelector
                  courseId={selectedCourseID}
                  handleSelect={setSelectedCourseID}
                />
              </div>

              <div
                className={css`
                  display: flex;
                  justify-content: center;
                  margin-top: 44px;
                `}
              >
                <div
                  className={css`
                    margin-right: 24px;
                  `}
                >
                  <Button
                    onClick={() => {
                      setImportCourse(null);
                    }}
                  >
                    Cancel
                  </Button>
                </div>
                <div>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      importVendorCourse();
                    }}
                  >
                    Import
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};
