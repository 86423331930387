import { Checkbox } from "@material-ui/core";
import { css } from "@emotion/css";
import { merge, set } from "lodash";
import {
  ArrowLeft,
  File,
  Folder,
  Gear,
  SquaresFour,
  Swap,
  Upload,
  X,
} from "phosphor-react";
import { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { ExtensionIconMapper } from "../Documents/ExtensionIconMapper";
import { CustomAxios } from "../redux/axios/axios";
import { Button, Div, Input, Loader, Modal, Text } from "../shared/components";
import { container, flex } from "../shared/shared-styles";
import { colors } from "../shared/styles";
import { getLayoutSelection, getLocationPath } from "./provider/utils";
import {
  DocumentsLibrary,
  ImagesModal,
  UploadDocuments,
  UploadImage,
} from "./toolbar-menus";
import { componentLabels } from "./toolbar-menus/editor-components";
import { ImageSettings } from "./toolbar-menus/menu-components/image-settings";
import {
  convertFromRaw,
  convertToRaw,
  Modifier,
  SelectionState,
} from "draft-js";
import { getHtmlContent } from "./editor-components/text/text-utils";
import { useDebounceValue } from "../shared/use-debounce-value";
import { useInput } from "../shared/use-input";
import { TypographyDropdown } from "./toolbar-menus/text-toolbar";

const getCaseValue = (value, matchCase) =>
  matchCase ? value : value.toLowerCase();

export const matchingComponents = {
  document: (selectedData, data) =>
    selectedData?.document?.ID === data?.document?.ID ? 1 : 0,
  image: (selectedData, data) =>
    selectedData?.attributes?.src === data?.attributes?.src ? 1 : 0,
  // TODO: Add text-v2 to replace
  // text: (selectedData, data, { searchTerm = "", filters = {} } = {}) => {
  //   if (!searchTerm) {
  //     return false;
  //   } else {
  //     if (filters.textType !== "all" && filters.textType !== data?.type) {
  //       return false;
  //     }

  //     const contentState = convertFromRaw(data?.rawState);
  //     const content = getCaseValue(
  //       contentState?.getPlainText(),
  //       filters.matchCase
  //     );
  //     const term = getCaseValue(searchTerm, filters.matchCase);
  //     const reg = new RegExp(term, "g");
  //     const count = content?.match(reg)?.length;
  //     return count;
  //   }
  // },
};

const getCompiledPage = (
  prev,
  { pageId, location, selectedData, data, options }
) => {
  if (data.component === selectedData.component) {
    const count = matchingComponents[data.component](
      selectedData,
      data,
      options
    );
    if (count > 0) {
      return {
        ...prev,
        [pageId]: {
          locations: [...(prev?.[pageId]?.locations || []), location],
          count: (prev?.[pageId]?.count || 0) + count,
        },
      };
    }
  }
  return prev;
};

const replaceText = ({ rawState, from, to, filters, inlineColorMap }) => {
  let contentState = convertFromRaw(rawState);
  for (const entry of contentState.getBlockMap()?.entries()) {
    let [key, block] = entry;

    let { text } = block;
    const length = from.length;

    const term = getCaseValue(from, filters.matchCase);
    const reg = new RegExp(term, "g");
    let list = [];
    let startIndex = 0;
    while (reg.test(getCaseValue(text, filters.matchCase))) {
      const index = getCaseValue(text, filters.matchCase).indexOf(
        term,
        startIndex
      );
      if (index < 0) {
        break;
      }
      list.push(index);
      const entityKey = block.getEntityAt(index);
      const inlineStyle = block.getInlineStyleAt(index);
      contentState = Modifier.replaceText(
        contentState,
        new SelectionState({
          anchorKey: key,
          anchorOffset: index,
          focusKey: key,
          focusOffset: index + length,
        }),
        to,
        inlineStyle,
        entityKey
      );
      startIndex = index + to?.length;
      block = contentState.getBlockForKey(key);
      text = block.text;
    }
  }

  const raw = convertToRaw(contentState);
  const content = getHtmlContent(contentState, inlineColorMap);
  return { rawState: raw, content };
};

export const FindAndReplace = ({
  styles,
  pagesQuery,
  refetchAll,
  element,
  courseId,
  inlineColorMap,
  ...modalProps
}) => {
  const { component: type, ...data } = element;

  const [view, setView] = useState("replace");
  const [modal, setModal] = useState("");
  const [changes, setChanges] = useState({});
  const [selectedPages, setSelectedPages] = useState([]);
  const [currentUpdateId, setCurrentUpdateId] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const [filters, setFilters] = useState({ matchCase: false, textType: "all" });
  const [searchTerm] = useInput("");
  const [replaceTerm] = useInput("");

  const refresh = async () => {
    try {
      setIsLoading(true);
      refetchAll();
    } catch (err) {
      toast.error(`Failed to load...`);
      modalProps.onClose();
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    refresh();
  }, []);

  const pageDetail = pagesQuery?.data?.reduce(
    (prev, item) => ({ ...prev, [item?.ID]: item }),
    {}
  );

  const [debounced_searchTerm] = useDebounceValue(searchTerm.value, 10);

  const pages = useMemo(() => {
    if (!pagesQuery?.isLoading && !isLoading) {
      const { data } = pagesQuery;
      const compiledPages = data.reduce((prev, page, idx) => {
        return page?.Content?.rows
          ? page?.Content?.rows?.reduce((prevContent, row, rowIdx) => {
              return row.columns.reduce((prevRows, column, colIdx) => {
                if (column.type === "list") {
                  return column.list.reduce((prevColumn, data, listIdx) => {
                    return getCompiledPage(prevColumn, {
                      data,
                      selectedData: element,
                      pageId: page?.ID,
                      location: [rowIdx, colIdx, listIdx],
                      options: { searchTerm: searchTerm.value, filters },
                    });
                  }, prevRows);
                } else {
                  return getCompiledPage(prevRows, {
                    data: column,
                    selectedData: element,
                    pageId: page?.ID,
                    location: [rowIdx, colIdx],
                    options: { searchTerm: searchTerm.value, filters },
                  });
                }
              }, prevContent);
            }, prev)
          : prev;
      }, {});
      return compiledPages;
    }
    return {};
  }, [pagesQuery?.dataUpdatedAt, isLoading, debounced_searchTerm, filters]);

  const pageIds = Object.keys(pages);

  useEffect(() => {
    setSelectedPages(pageIds);
  }, [pageIds.toString()]);

  const totalComponents = pageIds.reduce(
    (prev, item) => prev + pages?.[item]?.count,
    0
  );
  const totalSelectedComponents = selectedPages.reduce(
    (prev, item) => prev + pages?.[item]?.count,
    0
  );
  const selectAll = pageIds.length === selectedPages.length;

  const toggleSelectedPage = (id) => {
    if (selectedPages.includes(id)) {
      setSelectedPages((p) => p.filter((item) => item !== id));
    } else {
      setSelectedPages((p) => [...p, id]);
    }
  };

  const replaceMatches = async () => {
    const updates = selectedPages.reduce((prev, pageId) => {
      const content = pages[pageId].locations.reduce(
        (content, location, idx) => {
          const component = getLayoutSelection(content, location);
          let changingData = changes;
          if (type === "text") {
            changingData = replaceText({
              rawState: component.rawState,
              from: searchTerm.value,
              to: replaceTerm.value,
              inlineColorMap,
              filters,
            });
          }
          const next = merge({}, component, changingData);
          const locationPath = getLocationPath(location);
          const data = set(content, locationPath, next);
          return data;
        },
        pageDetail[pageId].Content
      );
      return { ...prev, [pageId]: content };
    }, {});
    setIsLoading(true);
    const list = Object.entries(updates);
    let error = false;
    for (const entry of list) {
      const [pageId, Content] = entry;
      setCurrentUpdateId(pageId);
      try {
        const { Order, ...data } = pageDetail[pageId];
        const body = { ...data, Content };
        await CustomAxios.put(`v2/course/${courseId}/pages/${pageId}`, body);
      } catch (err) {
        error = true;
        console.warn(err);
        toast.error(`Unable to update ${pageDetail[pageId]?.Name}`);
      }
    }
    if (!error) {
      toast.success(
        `Successfully replaced in ${totalSelectedComponents} place${
          totalSelectedComponents !== 1 ? "s" : ""
        }.`
      );
      setCurrentUpdateId("");
      modalProps.onClose();
      await refetchAll(true);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      modalProps.onClose();
    }
  };

  const validTextReplacement =
    type === "text" && !!searchTerm.value && !!replaceTerm.value;

  return (
    <Modal {...modalProps}>
      <Div
        css={css`
          width: 500px;
        `}
        onClick={(e) => e.stopPropagation()}
      >
        <Div
          css={css`
            padding: 32px;
            ${flex("space-between")} border-bottom: 1px solid ${colors
              .gray[300]};
          `}
        >
          <Text h2>{isLoading ? "Replacing" : "Find & Replace"}</Text>
          <Button styles="icon" onClick={modalProps.onClose}>
            <X />
          </Button>
        </Div>
        {isLoading ? (
          <Div
            css={css`
              ${flex("center")} position: relative;
              padding: 64px;
              margin-bottom: 32px;
            `}
          >
            <Loader
              type="icon"
              isLoading={true}
              className={css`
                height: 100%;
                min-width: 400px;
              `}
            >
              <Div
                css={css`
                  ${flex("left")} padding: 64px;
                  svg {
                    margin-right: 16px;
                    min-width: 64px;
                  }
                `}
              >
                <Swap size={64} className="bounce" />
                {currentUpdateId === "" ? null : (
                  <Text label>
                    Replacing{" "}
                    <strong>
                      {pages?.[currentUpdateId]?.count} {componentLabels[type]}
                    </strong>{" "}
                    component{pages?.[currentUpdateId]?.count !== 1 ? "s" : ""}{" "}
                    in <strong>{pageDetail?.[currentUpdateId]?.Name}</strong>{" "}
                    page...
                  </Text>
                )}
              </Div>
            </Loader>
          </Div>
        ) : (
          <>
            <Div
              css={css`
                padding: 16px 32px;
                max-height: 40vh;
                overflow: auto;
              `}
            >
              {view === "replace" ? (
                <>
                  <Div
                    css={css`
                      ${flex("space-between")} margin: 16px 0;
                      p {
                        margin-right: 8px;
                      }
                    `}
                  >
                    <Text h4>Replace {componentLabels[type]}</Text>
                    <Text link bold onClick={() => setView("matches")}>
                      {totalSelectedComponents} Match
                      {totalSelectedComponents !== 1 ? "es" : ""} found
                    </Text>
                  </Div>

                  <Div>
                    {type === "text" ? (
                      <>
                        <Div
                          css={css`
                            background-color: ${colors.gray[100]};
                            padding: 16px;
                            border-radius: 16px;
                            p {
                              margin-left: 16px;
                            }
                          `}
                        >
                          <Input
                            {...searchTerm}
                            css={`
                              width: 100%;
                            `}
                          />
                          <Div
                            css={css`
                              ${flex("space-between")} padding: 8px 0;
                            `}
                          >
                            <TypographyDropdown
                              type={filters.textType}
                              changeType={(textType) =>
                                setFilters({ ...filters, textType })
                              }
                              styles={styles}
                              showAll={true}
                              width="200px"
                            />
                            <Div
                              css={css`
                                ${flex("left")}
                              `}
                            >
                              <Text label>Match Case</Text>
                              <Checkbox
                                color="primary"
                                onChange={(e) =>
                                  setFilters({
                                    ...filters,
                                    matchCase: e.target.checked,
                                  })
                                }
                                checked={filters.matchCase}
                              />
                            </Div>
                          </Div>
                        </Div>
                        <Div
                          css={css`
                            margin: 16px 0;
                          `}
                        >
                          <Text
                            h4
                            css={`
                              margin-bottom: 8px;
                            `}
                          >
                            Replace with
                          </Text>
                        </Div>
                        <Div
                          css={css`
                            ${flex("left")} background-color: ${colors
                              .gray[100]};
                            padding: 16px;
                            border-radius: 16px;
                            p {
                              margin-left: 16px;
                            }
                          `}
                        >
                          <Input
                            {...replaceTerm}
                            css={`
                              width: 100%;
                            `}
                          />
                        </Div>
                      </>
                    ) : type === "document" ? (
                      <>
                        <Div
                          css={css`
                            ${flex("left")} background-color: ${colors
                              .gray[100]};
                            padding: 16px;
                            border-radius: 16px;
                            p {
                              margin-left: 16px;
                            }
                          `}
                        >
                          <ExtensionIconMapper
                            fileType={data?.document?.FileType}
                            className={css`
                              pointer-events: none;
                            `}
                          />
                          <Text
                            label
                            css={`
                              margin-left: 16px;
                            `}
                          >
                            {data?.document?.Name}
                          </Text>
                        </Div>
                        <Div
                          css={css`
                            margin: 40px 0;
                          `}
                        >
                          <Text
                            h4
                            css={`
                              margin-bottom: 8px;
                            `}
                          >
                            Replace with
                          </Text>
                          <Div
                            css={css`
                              ${flex("space-around")} padding: 16px 0;
                            `}
                          >
                            <Div
                              css={css`
                                ${flex("left")} padding: 16px;
                                ${container.hover} border-radius: 16px;
                                border: 1px solid ${colors.gray[300]};
                              `}
                              onClick={() => setModal("document-library")}
                            >
                              <Folder
                                className={css`
                                  pointer-events: none;
                                `}
                              />
                              <Text
                                label
                                css={`
                                  margin-left: 16px;
                                `}
                              >
                                Document library
                              </Text>
                            </Div>
                            <Div
                              css={css`
                                ${flex("left")} padding: 16px;
                                ${container.hover} border-radius: 16px;
                                border: 1px solid ${colors.gray[300]};
                              `}
                              onClick={() => setModal("document-upload")}
                            >
                              <Upload
                                className={css`
                                  pointer-events: none;
                                `}
                              />
                              <Text
                                label
                                css={`
                                  margin-left: 16px;
                                `}
                              >
                                Upload document
                              </Text>
                            </Div>
                          </Div>
                          {changes !== null ? (
                            <Div
                              css={css`
                                flex-grow: 1;
                              `}
                              onClick={() => setModal("document-library")}
                            >
                              <Div
                                css={css`
                                  ${flex("left")} background-color: ${colors
                                    .gray[100]};
                                  padding: 16px;
                                  border-radius: 16px;
                                `}
                              >
                                <ExtensionIconMapper
                                  fileType={changes?.document?.FileType}
                                  className={css`
                                    pointer-events: none;
                                  `}
                                />
                                <Text
                                  label
                                  css={`
                                    margin-left: 16px;
                                  `}
                                >
                                  {changes?.document?.Name}
                                </Text>
                              </Div>
                            </Div>
                          ) : (
                            <Div
                              css={css`
                                flex-grow: 1;
                                ${container?.hover}
                              `}
                              onClick={() => setModal("document-library")}
                            >
                              <Div
                                css={css`
                                  ${flex("left")} border: 1px solid ${colors
                                    .gray[300]};
                                  padding: 16px;
                                  border-radius: 16px;
                                `}
                              >
                                <File
                                  className={css`
                                    pointer-events: none;
                                  `}
                                />
                                <Text
                                  label
                                  css={`
                                    margin-left: 16px;
                                  `}
                                >
                                  Select A Document
                                </Text>
                              </Div>
                            </Div>
                          )}
                        </Div>

                        <DocumentsLibrary
                          element={changes?.document}
                          display={modal === "document-library"}
                          onClose={() => setModal("")}
                          onUpdate={setChanges}
                        />

                        <UploadDocuments
                          display={modal === "document-upload"}
                          onClose={() => setModal("")}
                          onUpdate={setChanges}
                        />
                      </>
                    ) : type === "image" ? (
                      <>
                        <Div
                          css={css`
                            ${flex("space-around")} background-color: ${colors
                              .gray[100]};
                            border-radius: 16px;
                            padding: 16px;
                          `}
                        >
                          <Div
                            css={css`
                              ${flex("center")} width: 50%;
                              img {
                                width: 100%;
                                height: 100%;
                                object-fit: contain;
                              }
                            `}
                          >
                            <img
                              src={element?.attributes?.src}
                              alt="Find And Replace"
                            />
                          </Div>
                        </Div>

                        <Div
                          css={css`
                            margin: 40px 0;
                          `}
                        >
                          <Text
                            h4
                            css={`
                              margin-bottom: 8px;
                            `}
                          >
                            Replace with
                          </Text>
                          <Div
                            css={css`
                              ${flex("space-around")} padding: 16px 0;
                            `}
                          >
                            <Div
                              css={css`
                                ${flex("left")} padding: 16px;
                                ${container.hover} border-radius: 16px;
                                border: 1px solid ${colors.gray[300]};
                              `}
                              onClick={() => setModal("image-library")}
                            >
                              <SquaresFour
                                className={css`
                                  pointer-events: none;
                                `}
                              />
                              <Text
                                label
                                css={`
                                  margin-left: 16px;
                                `}
                              >
                                Library
                              </Text>
                            </Div>
                            <Div
                              css={css`
                                ${flex("left")} padding: 16px;
                                ${container.hover} border-radius: 16px;
                                border: 1px solid ${colors.gray[300]};
                              `}
                              onClick={() => setModal("image-upload")}
                            >
                              <Upload
                                color={colors.black}
                                size={32}
                                weight="bold"
                                className={css`
                                  pointer-events: none;
                                `}
                              />
                              <Text
                                label
                                css={`
                                  margin-left: 16px;
                                `}
                              >
                                Upload
                              </Text>
                            </Div>
                            <Div
                              css={css`
                                ${flex("left")} padding: 16px;
                                ${container.hover} border-radius: 16px;
                                border: 1px solid ${colors.gray[300]};
                              `}
                              onClick={() => setModal("image-settings")}
                            >
                              <Gear
                                className={css`
                                  pointer-events: none;
                                `}
                              />
                              <Text
                                label
                                css={`
                                  margin-left: 16px;
                                `}
                              >
                                Source
                              </Text>
                            </Div>
                          </Div>
                          <Div
                            css={css`
                              ${flex("space-around")} background-color: ${colors
                                .gray[100]};
                              border-radius: 16px;
                              padding: 16px;
                            `}
                          >
                            <Div
                              css={css`
                                ${flex("center")} width: 50%;
                                img {
                                  width: 100%;
                                  height: 100%;
                                  object-fit: contain;
                                }
                              `}
                            >
                              {changes !== null && changes?.attributes?.src ? (
                                <img
                                  src={changes?.attributes?.src}
                                  alt="Change"
                                />
                              ) : (
                                <Text>Select An Image</Text>
                              )}
                            </Div>
                          </Div>

                          {/* TODO: Figure out this bulk update
                          <Div
                            css={css`${flex('left')} padding: 16px; margin-top: 16px; ${container.hover} border-radius: 16px; border: 1px solid ${colors.gray[300]};`}
                            onClick={() => setModal('image-link')}
                          >
                            <LinkSimple color={colors.black} size={32} weight="bold" className={css`pointer-events: none;`} />
                            <Text link bold css={`margin-left: 16px;`}>Image Link</Text>
                          </Div> */}
                        </Div>

                        <UploadImage
                          display={modal === "image-upload"}
                          onClose={() => setModal("")}
                          updateImage={(_, attributes) =>
                            setChanges((changes) => ({
                              ...changes,
                              attributes,
                            }))
                          }
                        />

                        <ImagesModal
                          display={modal === "image-library"}
                          onClose={() => setModal("")}
                          updateImage={(_, attributes) =>
                            setChanges((changes) => ({
                              ...changes,
                              attributes,
                            }))
                          }
                        />

                        <ImageSettings
                          display={modal === "image-settings"}
                          onClose={() => setModal("")}
                          onUpdate={(data) =>
                            setChanges((changes) => merge({}, changes, data))
                          }
                          item={changes}
                        />
                      </>
                    ) : null}
                  </Div>
                </>
              ) : view === "matches" ? (
                <>
                  <Div
                    css={css`
                      ${flex("space-between")} margin: 8px 16px;
                      p {
                        margin-left: 8px;
                      }
                    `}
                  >
                    <Button styles="icon" onClick={() => setView("replace")}>
                      <ArrowLeft />
                    </Button>
                    <Div
                      css={css`
                        ${flex(
                          "space-between grow"
                        )} ${container.hover} border-radius: 16px;
                      `}
                      onClick={() => setSelectedPages(selectAll ? [] : pageIds)}
                    >
                      <Text h4>
                        {totalSelectedComponents}/{totalComponents} Match
                        {totalComponents !== 1 ? "es" : ""} selected
                      </Text>
                      <Checkbox color={"primary"} checked={selectAll} />
                    </Div>
                  </Div>
                  {Object.entries(pages).map(([key, value]) => (
                    <Div
                      key={key}
                      css={css`
                        ${flex("space-between")} padding: 16px;
                        cursor: pointer;
                        ${container.hover} border-radius: 16px;
                      `}
                      onClick={() => toggleSelectedPage(key)}
                    >
                      <Div>
                        <Text h4>{pageDetail[key]?.Name}</Text>
                        <Text>
                          {value?.count} matching {type}
                          {value?.count !== 1 ? "s" : ""} found
                        </Text>
                      </Div>
                      <Checkbox
                        color={"primary"}
                        checked={selectedPages.includes(key)}
                      />
                    </Div>
                  ))}
                </>
              ) : view === "review" ? null : null}
            </Div>

            <Div
              css={css`
                padding: 32px;
                ${flex("right")} border-top: 1px solid ${colors.gray[300]};
              `}
            >
              <Button secondary onClick={modalProps.onClose}>
                Close
              </Button>
              <Button
                css={`
                  margin-left: 16px;
                `}
                disabled={
                  (!validTextReplacement && changes === null) ||
                  totalSelectedComponents < 1
                }
                onClick={replaceMatches}
              >
                Replace
              </Button>
            </Div>
          </>
        )}
      </Div>
    </Modal>
  );
};
