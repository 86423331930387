import { Checkbox, Collapse, Drawer } from "@material-ui/core";
import { css } from "@emotion/css";
import { CaretDown, CaretUp, FirstAidKit, X } from "phosphor-react";
import { useEffect, useMemo, useState } from "react";
import parse from "html-react-parser";
import { CarrierLogo } from "./carrier-logo";
import { getPremium } from "../BriteEditor/editor-components/benefits/constants";
import { useDisplaySettings } from "../BriteEditor/editor-components/benefits/plan-comparisons/use-display-settings";
import { Button, Div, Modal, Text } from "../shared/components";
import { container, flex, px, shadows } from "../shared/shared-styles";
import { colors } from "../shared/styles";
import { SupplementalLifeAlerts } from "./product-alerts";
import { ProductSummary } from "./product-summary";
import { FieldComponent } from "./field-components/field-component";
import { getFieldConfigs, getSectionConfig } from "./configs/config-utils";
import {
  summaryFieldConfigs,
  summarySectionConfigs,
} from "./configs/product-summary.config";
import { useEvent } from "../shared/use-event";

const previewContainer = (isDrawer) => css`
  position: relative;
  max-width: 600px;
  min-width: 500px;
  ${isDrawer ? `height: 100%;` : `height: calc(100vh - 100px);`}
  overflow: auto;
  padding: ${px.xl};
  background-color: ${colors.gray[100]};
`;

const parseDisclaimer = (text) => {
  var exp = /(?:^|[^"'])((ftp|http|https|file):\/\/[\S]+(\b|$))/gi;
  const disclaimer =
    text?.replace(exp, " <a href='$1' target='_blank'>$1</a>") || "";
  return parse(disclaimer);
};

const fieldContainer = (field = {}) => {
  return css`
    ${field?.Type !== "text-area" && flex("jcsb aic")}
    border-radius: 8px;
    padding: ${px.md};
  `;
};

const Container = ({ children }) => {
  const [open, setOpen] = useState(true);
  const [isDrawer, setIsDrawer] = useState(window.innerWidth < 1300);

  useEffect(() => {
    setOpen(!isDrawer);
  }, [isDrawer]);

  const checkDrawerWidth = () => setIsDrawer(window.innerWidth < 1300);
  useEvent("resize", checkDrawerWidth);

  return isDrawer ? (
    <Div
      css={css`
        position: relative;
        height: 100%;
      `}
    >
      <Button
        hoverLabel="Preview Plan Summary"
        styles="icon"
        css={`
          position: absolute;
          top: 16px;
          right: 16px;
          background-color: white;
          ${shadows.md}
        `}
        onClick={() => setOpen(true)}
      >
        <FirstAidKit />
      </Button>
      <Drawer open={open} anchor="right">
        <Div css={previewContainer(isDrawer)}>
          <Div
            css={css`
              ${flex("space-between")}
              margin-bottom: 16px;
            `}
          >
            <Text styles="h2">Preview</Text>
            <Button styles="icon" onClick={() => setOpen(false)}>
              <X />
            </Button>
          </Div>
          {children}
        </Div>
      </Drawer>
    </Div>
  ) : (
    <Div css={previewContainer(isDrawer)}>
      <Text
        styles="h2"
        className={css`
          margin-bottom: 16px;
        `}
      >
        Preview
      </Text>
      {children}
    </Div>
  );
};

export const ProductPreview = ({ state }) => {
  const { layout, product, businessId, packageId, fieldsObject } = state;

  const { displaySettings } = useDisplaySettings(businessId, packageId);
  const { premiumInterval } = displaySettings;

  const [tab] = useState("plan-summary");
  const [modal, setModal] = useState("");
  const [open, setOpen] = useState(false);
  const [costType] = useState("EmployeeOnly");

  const { sections, hasCost } = useMemo(() => {
    let sections = [...(layout?.Layout?.Sections || [])];
    const hasCostIndex = sections.findIndex(
      ({ DisplayValue }) => DisplayValue === "Premiums & Contributions"
    );
    const hasCost = hasCostIndex > -1;
    if (hasCost) {
      sections.splice(hasCostIndex, 1);
    }
    return { sections, hasCost };
  }, [layout]);

  return (
    <Container>
      <Div
        css={css`
          ${flex("jcsb aic")}
        `}
      >
        {/* <Div css={css`${flex('aic grow')} max-width: 60%;`}>
            {hasCost && (
              <>
                <Button styles="icon" onClick={() => setModal('cost-type-info')} hoverLabel="Cost Type">
                  <HandWaving color={colors.gray[500]} />
                </Button>
                <Select className={css`flex-grow: 1;`} value={costType} onChange={(e) => setCostType(e.target.value)}>
                  {Object.entries(displaySettings)?.map(([key, value]) => (
                    <MenuItem key={key} value={key}>{value}</MenuItem>
                  ))}
                  <MenuItem value="Family">Family</MenuItem>
                </Select>
              </>
            )}
          </Div> */}
      </Div>
      {/* <Div css={css`${flex('space-around')} width: 100%; border-bottom: 1px solid ${colors.gray[300]}; margin-bottom: 32px;`}>
          <Button styles={tab === 'plan-summary' ? "tab-active" : "tab-inactive"} onClick={() => setTab('plan-summary')}>Plan Summaries</Button>
          <Button styles={tab === 'recommendations' ? "tab-active" : "tab-inactive"} onClick={() => setTab('recommendations')}>Recommendations</Button>
        </Div> */}

      {false ? ( // TODO: product
        <Text>No Preview available.</Text>
      ) : (
        <>
          {tab === "recommendations" ? (
            <Div
              css={css`
                width: 100%;
                background-color: white;
                ${shadows.md} margin: ${px.lg} 0;
                padding: ${px.lg};
                border-radius: 16px;
              `}
            >
              <Div
                css={css`
                  ${flex("jcsb ais")} padding: ${px.md} 0;
                `}
              >
                <Div
                  css={css`
                    flex-grow: 1;
                  `}
                >
                  <Text styles="h4">{layout?.Title}</Text>
                  <Div
                    css={css`
                      ${flex("ais")} margin-top: ${px.md};
                    `}
                  >
                    <Checkbox style={{ padding: 0 }} />
                    <Text
                      styles="label"
                      className={css`
                        padding-left: ${px.md};
                      `}
                    >
                      {layout?.Subtitle}
                    </Text>
                  </Div>
                </Div>
                <Div
                  css={css`
                    min-width: 150px;
                    width: 20%;
                  `}
                >
                  <CarrierLogo
                    planCarrierName={product?.ProviderName}
                    carrierID={product?.ProviderID}
                    maxWidth="200px"
                    maxHeight="80px"
                  />
                </Div>
              </Div>

              {product?.Type === "supplimental_life" && (
                <SupplementalLifeAlerts state={state} />
              )}

              {hasCost && (
                <Div
                  css={css`
                    ${flex("jcsb aic")} border-radius: 8px;
                    margin: ${px.md} 0;
                    padding: ${px.md};
                    background-color: ${colors.gray[100]};
                  `}
                >
                  <Text styles="h4">
                    {premiumInterval === 12
                      ? "Your Monthly Cost"
                      : "Your Cost Per Pay Period"}
                  </Text>
                  <Text styles="h4">
                    {getPremium({
                      plan: product?.Cost,
                      key: costType,
                      type: product?.Type,
                      interval: premiumInterval,
                    })}
                  </Text>
                </Div>
              )}

              <Collapse in={open}>
                <Div
                  css={css`
                    border-top: 1px solid ${colors.gray[300]};
                    margin-top: ${px.md};
                    padding-top: ${px.lg};
                  `}
                >
                  {product?.ProductName && (
                    <Div
                      css={css`
                        ${flex("jcsb aic")} border-radius: 8px;
                        margin: ${px.md} 0;
                        padding: ${px.md};
                        background-color: ${colors.gray[100]};
                      `}
                    >
                      <Text styles="h4 normal">Plan Name</Text>
                      <Text styles="h4 normal">{product?.ProductName}</Text>
                    </Div>
                  )}
                  <Text
                    styles="label"
                    className={css`
                      margin: 0 ${px.md};
                    `}
                  >
                    {layout.Description}
                  </Text>
                  {sections?.map((section, sectionIndex) => {
                    const fieldConfigs = getFieldConfigs(summaryFieldConfigs, {
                      product: product,
                      fieldsObject,
                    });

                    return getSectionConfig(summarySectionConfigs, section)
                      ?.hideSection ? null : (
                      <Div
                        css={css`
                          margin: ${px.lg} 0;
                        `}
                      >
                        <Text styles="h4 mv">
                          {sectionIndex > 0
                            ? section.DisplayValue
                            : `Here's what's available`}
                        </Text>
                        <Div
                          css={css`
                            ${container.list}
                          `}
                        >
                          {section?.Fields?.map((field) => {
                            const fieldConfig =
                              fieldConfigs?.[field?.PropretyChain];
                            if (
                              field.State === "hide" ||
                              fieldConfig?.hideField
                            ) {
                              return null;
                            }

                            return (
                              <Div
                                className="alt-item"
                                css={fieldContainer(field)}
                              >
                                <Text styles="label">
                                  {field.RecDisplayValue || field.DisplayValue}
                                </Text>
                                <FieldComponent
                                  field={field}
                                  product={product}
                                />
                              </Div>
                            );
                          })}

                          {product?.TitleDescriptions?.map(
                            ({ Title, Description, Autogenerated }) =>
                              !!(Title && Description && !Autogenerated) && (
                                <Div
                                  css={fieldContainer()}
                                  className="alt-item"
                                >
                                  <Text styles="label">{Title}</Text>
                                  <Text styles="label bold">{Description}</Text>
                                </Div>
                              )
                          )}
                        </Div>
                      </Div>
                    );
                  })}

                  <Text
                    className={css`
                      margin: 0 ${px.md};
                    `}
                  >
                    *There are other aspects of this benefit to consider,
                    including pricing, coverage limitations, exclusions,
                    reductions and other ways this benefit will or will not pay.
                    Please refer to your plan documents for full coverage.
                  </Text>

                  {product?.Disclaimer && (
                    <Div
                      css={css`
                        margin: ${px.lg} 0;
                      `}
                    >
                      <Text>{parseDisclaimer(product?.Disclaimer)}</Text>
                    </Div>
                  )}
                </Div>
              </Collapse>

              <Div
                css={css`
                  ${flex("jcc aic")} width: 100%;
                `}
                onClick={() => setOpen(!open)}
              >
                <Button styles="icon">
                  {open ? <CaretUp /> : <CaretDown />}
                </Button>
              </Div>
            </Div>
          ) : tab === "plan-summary" ? (
            <Div
              css={css`
                width: 500px;
              `}
            >
              <ProductSummary state={state} />
            </Div>
          ) : null}

          <Modal
            display={modal === "cost-type-info"}
            onClose={() => setModal("")}
          >
            <Div
              css={css`
                width: 500px;
                padding: ${px.xl};
              `}
            >
              <Div
                css={css`
                  ${flex("jcsb aic")}
                `}
              >
                <Text styles="h2">Cost Type</Text>
                <Button styles="icon" onClick={() => setModal("")}>
                  <X />
                </Button>
              </Div>

              <Div
                css={css`
                  padding: ${px.lg} 0;
                `}
              >
                <Text styles="label">
                  This represents how you preview the cost of Premiums &
                  Contributitions.
                </Text>
                <Text
                  className={css`
                    margin-top: ${px.lg};
                  `}
                >
                  {displaySettings.EmployeeOnly},{" "}
                  {displaySettings.EmployeeSpouse},{" "}
                  {displaySettings.EmployeeChildren},{" "}
                  {displaySettings.Family || "Family"}.
                </Text>
              </Div>

              <Div
                css={css`
                  ${flex("jcr aic")} padding-top: ${px.lg};
                `}
              >
                <Button onClick={() => setModal("")}>Close</Button>
              </Div>
            </Div>
          </Modal>
        </>
      )}
    </Container>
  );
};
