import { css } from "@emotion/css";
import { Div, Input, Text } from "../../shared/components";
import { flex, px } from "../../shared/shared-styles";
import { productModifier } from "../configs/product-utils";
import { useAcls } from "../../shared/use-acls";
import { WRITE_BENEFITS_PACKAGE } from "../../shared/acl-constants";
import { ApplicableStates } from "./applicable-states";
import { useStateSync } from "../../shared/use-state-sync";

export const Disclaimers = ({ state }) => {
  const { product } = state;
  const { ApplicableStates: states = [] } = product || {};
  const [disclaimer, setDisclaimer] = useStateSync(product?.Disclaimer || "", [
    product?.Disclaimer,
  ]);

  const hasApplicableStatesInLayout = "ApplicableStates" in state?.fieldsObject;

  const canEdit = useAcls([WRITE_BENEFITS_PACKAGE]);

  const updateStates = (value) => {
    productModifier.setProduct(state, (data) => ({
      ...data,
      ApplicableStates: value,
    }));
  };

  const updateDisclaimer = () => {
    productModifier.setProduct(state, (data) => ({
      ...data,
      Disclaimer: disclaimer,
    }));
  };

  return (
    <>
      {hasApplicableStatesInLayout ? null : (
        <Div
          css={css`
            ${flex("jcsb aic")} margin: ${px.lg} 0;
          `}
        >
          <Text styles="label">Applicable States</Text>
          <ApplicableStates
            value={states}
            onUpdate={updateStates}
            css={`
              width: 50%;
            `}
          />
        </Div>
      )}

      <Div
        css={css`
          margin-top: ${px.xl};
          margin-bottom: ${px.lg};
          ${flex("space-between start")}
        `}
      >
        <Text styles="label">Add Disclaimer</Text>
        <Input
          css={`
            width: 50%;
          `}
          disabled={!canEdit}
          rows={3}
          value={disclaimer}
          onChange={(e) => setDisclaimer(e.target.value)}
          onBlur={updateDisclaimer}
        />
      </Div>
    </>
  );
};
