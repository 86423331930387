import React, { useEffect, useState, useMemo, useCallback } from "react";
import { css } from "@emotion/css";
import { useTheme } from "@material-ui/core/styles";

import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";

import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import { states, industries } from "../constants";
import { cloneDeep } from "lodash";
import Chip from "@material-ui/core/Chip";
import Input from "@material-ui/core/Input";
import { CustomAxios } from "../redux/axios/axios";
import { useAcls } from "../shared/use-acls";
import { WRITE_BUSINESS_ACCESS } from "../shared/acl-constants";
import { useForm } from "../shared/use-form";
import { handleUpdateBusiness } from "../redux/replace-redux-actions";

const validate = (values) => {
  const errors = {};

  if (!values.EmployeeCount) {
    errors.EmployeeCount = "Required";
  }
  if (!values.Name) {
    errors.Name = "Required";
  }

  if (!values.Industry) {
    errors.Industry = "Required";
  }

  if (!values.State) {
    errors.State = "Required";
  }

  if (!values.Domain) {
    errors.Domain = "Required";
  }

  return errors;
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 1,
  borderRadius: 8,
  borderColor: "#25282D",
  borderStyle: "dashed",
  backgroundColor: "#ffffff",
  color: "#25282D",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#2196f3",
};

const rejectStyle = {
  borderColor: "#2196f3",
};

export const CompanySettings = (props) => {
  const theme = useTheme();

  const form = useForm(
    {
      EmployeeCount: props.EmployeeCount,
      Name: props.Name,
      Industry: props.Industry,
      State: props.State,
      Domain: props.Domain,
      Description: props.Description,
      Categories: props.Categories || [],
      LogoURL: props.LogoURL,
      ParentID: props.ParentID,
      ID: props.ID,
      BrokerContactName: props.BrokerContactName,
      BrokerContactPhone: props.BrokerContactPhone,
      BrokerContactEmail: props.BrokerContactEmail,
      BrokerContactTitle: props.BrokerContactTitle,
      HRContactEmail: props.HRContactEmail,
      HRContactName: props.HRContactName,
      HRContactPhone: props.HRContactPhone,
      HRContactTitle: props.HRContactTitle,
    },
    []
  );

  const { values } = form;
  const errors = validate(values);
  const handleChange = (e) => form?.merge({ [e.target.name]: e.target.value });

  const handleSubmit = (event) => {
    event.preventDefault();
    window?.ChurnZero?.push([
      "trackEvent",
      "Business Details",
      "This page was clicked on and any amount of information was input",
      1,
    ]);
    let cloned = cloneDeep(values);

    cloned.Type = props.Type;

    props.setStore("selectedBusiness", { PaymentSource: "none", ...cloned });
    handleUpdateBusiness({ PaymentSource: "none", ...cloned });
  };

  const [imageUploading, setImageUploading] = useState(false);
  const [categories, setCategories] = useState([]);
  const canAdminBusiness = useAcls(WRITE_BUSINESS_ACCESS);

  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      setImageUploading(true);
      const url = `/v2/media/${
        acceptedFiles[0].name
          ? encodeURIComponent(acceptedFiles[0].name)
          : "no-name"
      }/${
        acceptedFiles[0].type
          ? encodeURIComponent(acceptedFiles[0].type.replace("/", "."))
          : "image.png"
      }`;
      const formData = new FormData();
      formData.append("file", acceptedFiles[0]);
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      CustomAxios.post(url, formData, config)
        .then((res) => {
          setImageUploading(false);
          form?.merge({ LogoURL: res.data.PublicURL });
        })
        .catch(() => {
          setImageUploading(false);
          toast.error("Error uploading logo. Try again.");
        });
    } else {
      setImageUploading(false);
      toast.error("Invalid file or too many files");
    }
  }, []);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    maxFiles: 1,
    accept: "image/*",
    noDragEventsBubbling: true,
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  const fetchAllCategories = async () => {
    try {
      const response = await CustomAxios.get(`/v1/categories`);
      setCategories(response.data.sort());
    } catch (e) {}
  };

  useEffect(() => {
    fetchAllCategories();
  }, []);

  function getStyles(name, personName, theme) {
    return {
      fontWeight:
        personName?.indexOf(name) === -1
          ? theme?.typography?.fontWeightRegular
          : theme?.typography?.fontWeightMedium,
    };
  }

  if (props.loading) {
    return (
      <div
        className={css`
          margin: 0 auto;
        `}
      >
        <CircularProgress />
      </div>
    );
  }
  return (
    <div>
      <div
        className={css`
          font-family: "Roboto";
          font-style: normal;
          font-weight: 700;
          font-size: 34px;
          line-height: 123.5%;
          /* identical to box height, or 42px */

          letter-spacing: 0.25px;

          color: #25282d;
          margin-bottom: 40px;
        `}
      >
        Business Details
      </div>

      <form
        onSubmit={handleSubmit}
        className={css`
          max-width: 800px;
        `}
      >
        <div
          className={css`
            display: flex;
            gap: 56px;
          `}
        >
          <div
            className={css`
              font-family: "Roboto";
              font-style: normal;
              font-weight: 700;
              font-size: 18px;
              line-height: 24px;
              /* identical to box height, or 133% */

              letter-spacing: 0.15px;

              color: #25282d;
              min-width: 125px;
            `}
          >
            Company Logo
          </div>
          <div>
            {props.Type === "carrier" && (
              <div
                className={css`
                  margin-bottom: 8px;
                `}
              >
                As a carrier, this logo is shown to brokers on Brite and on the
                employee recommendations page. Check out the Preview tab
              </div>
            )}

            {imageUploading && (
              <div
                className={css`
                  margin-bottom: 18px;
                `}
              >
                Uploading logo...
              </div>
            )}

            {!imageUploading && (
              <div
                className={css`
                  margin-bottom: 18px;
                  display: flex;
                  align-items: center;
                `}
              >
                <section
                  className={css`
                    margin-left: 24px;
                  `}
                >
                  <div
                    className={css`
                      &:hover {
                        border-color: blue !important;
                      }
                    `}
                    {...getRootProps({ style })}
                  >
                    <input {...getInputProps()} />
                    {values.LogoURL && (
                      <div
                        className={css`
                          min-width: 200px;
                        `}
                      >
                        <img
                          alt="Carrier Logo"
                          src={
                            values.LogoURL ||
                            "https://storage.googleapis.com/brite-carrier-logos/logos/logo-placeholder.png"
                          }
                          className={css`
                            max-height: 80px;
                            max-width: 200px;
                          `}
                        />
                      </div>
                    )}

                    {!values.LogoURL && <p>Drag 'n' drop your logo here</p>}
                  </div>
                </section>
              </div>
            )}
          </div>
        </div>

        <hr
          className={css`
            border: 0;
            height: 1px;
            background: #d1dae3;

            margin-bottom: 40px;
          `}
        />

        <div
          className={css`
            display: flex;
            gap: 56px;
          `}
        >
          <div
            className={css`
              font-family: "Roboto";
              font-style: normal;
              font-weight: 700;
              font-size: 18px;
              line-height: 24px;
              /* identical to box height, or 133% */

              letter-spacing: 0.15px;

              color: #25282d;
            `}
          >
            Company Information
          </div>
          <div
            className={css`
              flex: 1;
            `}
          >
            <div
              className={css`
                margin-bottom: 18px;
              `}
            >
              <TextField
                fullWidth
                error={errors.Name}
                label="Company name"
                variant="outlined"
                onChange={handleChange}
                value={values.Name}
                name="Name"
              />
            </div>

            {props.Type === "carrier" && (
              <>
                <div
                  className={css`
                    margin-bottom: 18px;
                  `}
                >
                  <div
                    className={css`
                      margin-bottom: 8px;
                    `}
                  >
                    Your company description will be shown to brokers as they
                    browse carriers and products through Brite.
                  </div>
                  <TextField
                    fullWidth
                    error={errors.Description}
                    label="Company Description"
                    variant="outlined"
                    onChange={handleChange}
                    value={values.Description}
                    name="Description"
                    multiline
                    rowsMax={4}
                  />
                </div>

                <div
                  className={css`
                    margin-bottom: 8px;
                  `}
                >
                  What type of benefit products does your company offer? You can
                  select more than one.
                </div>

                <div
                  className={css`
                    margin-bottom: 24px;
                    border: 1px solid #ccc;
                    border-radius: 4px;
                    padding: 20px 0px 0px;
                  `}
                >
                  <FormControl variant="outlined" fullWidth>
                    <Select
                      labelId="categories-label"
                      id="select-category"
                      multiple
                      variant="outlined"
                      value={values.Categories}
                      onChange={(e) => {
                        if (e.target.value && Array.isArray(e.target.value)) {
                          const ids = e.target.value.map((o) => o.ID);
                          const filtered = e.target.value.filter(
                            ({ ID }, index) => !ids.includes(ID, index + 1)
                          );
                          form?.merge({ Categories: filtered });
                        } else {
                          form?.merge({ Categories: [] });
                        }
                      }}
                      input={
                        <Input id="select-multiple-chip" variant="outlined" />
                      }
                      renderValue={(selected) => (
                        <div
                          className={css`
                            margin: 2px;
                          `}
                        >
                          {selected.map((value) => (
                            <Chip
                              key={value.ID}
                              label={value.Name}
                              style={{ margin: "2px" }}
                              onDelete={() => {
                                let filtered = values.Categories.filter(
                                  function (el) {
                                    return el.ID !== value.ID;
                                  }
                                );
                                form?.merge({ Categories: filtered });
                              }}
                              onMouseDown={(event) => {
                                event.stopPropagation();
                              }}
                            />
                          ))}
                        </div>
                      )}
                      MenuProps={MenuProps}
                    >
                      {categories.map((category) => (
                        <MenuItem
                          key={category.ID}
                          value={category}
                          style={getStyles(
                            category.Name,
                            values.Categories,
                            theme
                          )}
                        >
                          {category.Name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </>
            )}

            <div
              className={css`
                margin-bottom: 18px;
              `}
            >
              <TextField
                fullWidth
                error={errors.EmployeeCount}
                label="# of employees"
                variant="outlined"
                onChange={handleChange}
                value={values.EmployeeCount}
                type="number"
                name="EmployeeCount"
                disabled={!canAdminBusiness}
              />
            </div>

            <div
              className={css`
                margin-bottom: 18px;
              `}
            >
              <TextField
                fullWidth
                error={errors.Domain}
                label="Website URL"
                variant="outlined"
                onChange={handleChange}
                value={values.Domain}
                name="Domain"
              />
            </div>

            <div
              className={css`
                margin-bottom: 18px;
              `}
            >
              <FormControl variant="outlined" error={errors.State} fullWidth>
                <InputLabel id="state">State</InputLabel>
                <Select
                  labelId="state"
                  id="select-state"
                  value={values.State}
                  onChange={(e) => {
                    form?.merge({ State: e.target.value });
                  }}
                  name="State"
                  input={<OutlinedInput label="State" />}
                >
                  {states.map((state) => (
                    <MenuItem key={state.id} value={state.id}>
                      {state.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>

            <div
              className={css`
                margin-bottom: 18px;
              `}
            >
              <FormControl variant="outlined" error={errors.Industry} fullWidth>
                <InputLabel id="industry">Industry</InputLabel>
                <Select
                  labelId="industry"
                  id="select-industry"
                  value={values.Industry}
                  name="Industry"
                  onChange={(e) => {
                    form?.merge({ Industry: e.target.value });
                  }}
                  input={<OutlinedInput label="Industry" />}
                >
                  {industries.map((industry) => (
                    <MenuItem key={industry.name} value={industry.name}>
                      {industry.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>

            <div
              className={css`
                font-family: "Roboto";
                font-style: normal;
                font-weight: 700;
                font-size: 18px;
                line-height: 24px;
                /* identical to box height, or 133% */

                letter-spacing: 0.15px;

                color: #25282d;
                margin-bottom: 18px;
              `}
            >
              Broker Contact Information
            </div>

            <div
              className={css`
                margin-bottom: 18px;
              `}
            >
              <TextField
                fullWidth
                error={errors.BrokerContactName}
                label="Broker Contact Name"
                variant="outlined"
                onChange={handleChange}
                value={values.BrokerContactName}
                name="BrokerContactName"
              />
            </div>
            <div
              className={css`
                margin-bottom: 18px;
              `}
            >
              <TextField
                fullWidth
                error={errors.BrokerContactEmail}
                label="Broker Contact Email"
                variant="outlined"
                type="email"
                onChange={handleChange}
                value={values.BrokerContactEmail}
                name="BrokerContactEmail"
              />
            </div>
            <div
              className={css`
                margin-bottom: 18px;
              `}
            >
              <TextField
                fullWidth
                error={errors.BrokerContactPhone}
                label="Broker Contact Phone"
                variant="outlined"
                type="tel"
                onChange={handleChange}
                value={values.BrokerContactPhone}
                name="BrokerContactPhone"
              />
            </div>
            <div
              className={css`
                margin-bottom: 18px;
              `}
            >
              <TextField
                fullWidth
                error={errors.BrokerContactTitle}
                label="Broker Contact Title"
                variant="outlined"
                onChange={handleChange}
                value={values.BrokerContactTitle}
                name="BrokerContactTitle"
              />
            </div>

            <div
              className={css`
                font-family: "Roboto";
                font-style: normal;
                font-weight: 700;
                font-size: 18px;
                line-height: 24px;
                /* identical to box height, or 133% */

                letter-spacing: 0.15px;

                color: #25282d;
                margin-bottom: 18px;
              `}
            >
              HR Contact Information
            </div>

            <div
              className={css`
                margin-bottom: 18px;
              `}
            >
              <TextField
                fullWidth
                error={errors.HRContactName}
                label="HR Contact Name"
                variant="outlined"
                onChange={handleChange}
                value={values.HRContactName}
                name="HRContactName"
              />
            </div>
            <div
              className={css`
                margin-bottom: 18px;
              `}
            >
              <TextField
                fullWidth
                error={errors.HRContactEmail}
                label="HR Contact Email"
                variant="outlined"
                type="email"
                onChange={handleChange}
                value={values.HRContactEmail}
                name="HRContactEmail"
              />
            </div>
            <div
              className={css`
                margin-bottom: 18px;
              `}
            >
              <TextField
                fullWidth
                error={errors.HRContactPhone}
                label="HR Contact Phone"
                variant="outlined"
                type="tel"
                onChange={handleChange}
                value={values.HRContactPhone}
                name="HRContactPhone"
              />
            </div>
            <div
              className={css`
                margin-bottom: 18px;
              `}
            >
              <TextField
                fullWidth
                error={errors.HRContactTitle}
                label="HR Contact Title"
                variant="outlined"
                onChange={handleChange}
                value={values.HRContactTitle}
                name="HRContactTitle"
              />
            </div>

            <div
              className={css`
                display: flex;
                justify-content: flex-end;
              `}
            >
              <Button variant="contained" color="primary" type="submit">
                Save Changes
              </Button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
