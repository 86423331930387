import { css } from "@emotion/css";
import { ArrowLeft } from "phosphor-react";
import { useState } from "react";
import { ConfirmationPrompt } from "../../../design-styles/confirmation-prompt";
import { DesignStyles } from "../../../design-styles/design-styles";
import { courseNameStyle } from "../../../design-styles/design-styles-page";
import { useDesignStyles } from "../../../design-styles/use-design-styles";
import {
  Button,
  ConfirmationModal,
  Div,
  Modal,
  Text,
} from "../../../shared/components";
import { flex, px } from "../../../shared/shared-styles";
import { colors } from "../../../shared/styles";
import { useStateSync } from "../../../shared/use-state-sync";

export const DesignStylesModalV2 = (props) => {
  const { course, saveCourse, refetch, ...modalProps } = props;

  const [themeId, setThemeId] = useStateSync(course?.ThemeID, [
    course?.ThemeID,
  ]);

  const designStyleProps = useDesignStyles(setThemeId, [modalProps.display]);

  const { current, setCurrent } = designStyleProps;

  const [savePrompt, setSavePrompt] = useState(null);
  const [cancelChanges, setCancelChanges] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const themeHasChanged = themeId !== course?.ThemeID;

  const handleSaveEdits = async () => {
    try {
      setIsSaving(true);
      await designStyleProps.saveCurrent();

      setCurrent({});
    } catch {
    } finally {
      setIsSaving(false);
    }
  };

  const handleSaveThemeId = async () => {
    try {
      setIsSaving(true);
      await saveCourse({ ThemeID: themeId });
      modalProps.onClose();
    } catch {
    } finally {
      setIsSaving(false);
    }
  };

  const handleSave = () => {
    if (themeHasChanged) {
      handleSaveThemeId();
    }
    if (designStyleProps?.hasChanges) {
      handleSaveEdits();
    }
  };

  const handleClose = () => {
    designStyleProps.resetCurrent();
    modalProps.onClose();
  };

  const handleCancelEdits = () => {
    if (designStyleProps?.hasChanges) {
      setCancelChanges(true);
    } else {
      setCurrent({});
    }
  };

  return (
    <Modal {...modalProps} full>
      <Div
        css={css`
          ${flex("jcsb aic")} padding: ${px.xl};
        `}
        className={css`
          width: 100%;
          height: 100px;
          border-bottom: 1px solid ${colors?.gray[300]};
        `}
      >
        <Div
          css={css`
            ${flex("aic")}
          `}
        >
          {current?.ID && (
            <Button styles="icon mr" onClick={handleCancelEdits}>
              <ArrowLeft />
            </Button>
          )}

          {!current?.ID ? (
            <Text styles="h1">Design Styles</Text>
          ) : (
            <input
              className={courseNameStyle}
              value={current?.Name || ""}
              onChange={(e) => {
                const Name = e.target.value;
                setCurrent({ ...current, Name });
              }}
            />
          )}
        </Div>
        {current?.ID ? (
          <Div
            css={css`
              ${flex("aic")}
            `}
          >
            <Button styles="secondary mr" onClick={handleCancelEdits}>
              Cancel
            </Button>
            <Button
              onClick={() => setSavePrompt(true)}
              disabled={!designStyleProps?.hasChanges || isSaving}
            >
              {isSaving ? "Saving..." : "Save"}
            </Button>
          </Div>
        ) : (
          <Div
            css={css`
              ${flex("aic")}
            `}
          >
            <Button styles="secondary mr" onClick={handleClose}>
              Close
            </Button>
            <Button
              onClick={() => setSavePrompt(true)}
              disabled={!themeHasChanged || isSaving}
            >
              {isSaving ? "Saving..." : "Apply Changes"}
            </Button>
          </Div>
        )}
      </Div>

      <DesignStyles
        course={course}
        themeId={themeId}
        setThemeId={setThemeId}
        designStyleProps={designStyleProps}
      />

      {/* Save confirmation */}
      <ConfirmationPrompt
        name={designStyleProps?.current?.Name}
        hasChanges={designStyleProps?.hasChanges}
        display={savePrompt}
        onSave={handleSave}
        onClose={() => setSavePrompt(false)}
      />

      {/* Cancel with changes confirmation */}
      <ConfirmationModal
        display={cancelChanges}
        onClose={() => {
          setCancelChanges(false);
          designStyleProps?.resetCurrent();
          designStyleProps?.setCurrent({});
        }}
        onExit={() => setCancelChanges(false)}
        title="You have unsaved changes, would you like to save them before leaving?"
        closeText="Leave Anyway"
        onConfirm={() => {
          setCancelChanges(false);
          handleSaveEdits();
        }}
      />
    </Modal>
  );
};
